/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type AbstractCartAddressRead = object

export interface AbstractCartJsonldAddressRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface AddressAddressRead {
  id?: number
  recipientName?: string
  recipientStreet?: string
  recipientState?: string | null
  recipientCity?: string
  recipientZipCode?: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null
  recipientCountry?: CountryAddressRead | null
  linkedOrder?: AbstractCartAddressRead
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string
  billingCountry?: CountryAddressRead | null
  billingState?: string
  distinctBillingAddress?: boolean

  /** @format iri-reference */
  relatedRelay?: string | null
  relatedStoreAddress?: StoreAddressRead | null
}

export interface AddressOrderPatch {
  recipientName?: string
  recipientStreet?: string
  recipientState?: string | null
  recipientCity?: string
  recipientZipCode?: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null

  /** @format iri-reference */
  recipientCountry?: string | null
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string

  /** @format iri-reference */
  billingCountry?: string | null
  billingState?: string
  distinctBillingAddress?: boolean

  /** @format iri-reference */
  relatedRelay?: string | null
}

export interface AddressOrderRead {
  id?: number
  recipientName?: string
  recipientStreet?: string
  recipientState?: string | null
  recipientCity?: string
  recipientZipCode?: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null
  recipientCountry?: CountryOrderRead | null
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string
  billingCountry?: CountryOrderRead | null
  billingState?: string
  distinctBillingAddress?: boolean
  relatedRelay?: PostalRelayOrderRead | null
}

export interface AddressOrderReadOrderReadItem {
  id?: number
  recipientName?: string
  recipientStreet?: string
  recipientState?: string | null
  recipientCity?: string
  recipientZipCode?: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null
  recipientCountry?: CountryOrderReadOrderReadItem | null
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string
  billingCountry?: CountryOrderReadOrderReadItem | null
  billingState?: string
  distinctBillingAddress?: boolean
  relatedRelay?: PostalRelayOrderReadOrderReadItem | null
  relatedStoreAddress?: StoreOrderReadOrderReadItem | null
}

export interface AddressProductReadItemProductReadGqlPublic {
  recipientName?: string
  recipientStreet?: string
  recipientCity?: string
  recipientZipCode?: string
  relatedRelay?: PostalRelayProductReadItemProductReadGqlPublic | null
  relatedStoreAddress?: StoreProductReadItemProductReadGqlPublic | null
}

export interface AddressProductReadGqlPublic {
  recipientName?: string
  recipientStreet?: string
  recipientCity?: string
  recipientZipCode?: string
  relatedRelay?: PostalRelayProductReadGqlPublic | null
  relatedStoreAddress?: StoreProductReadGqlPublic | null
}

export interface AddressShippingLabelLogRead {
  recipientName?: string
}

export interface AddressJsonldAddressRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  recipientName?: string
  recipientStreet?: string
  recipientState?: string | null
  recipientCity?: string
  recipientZipCode?: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null
  recipientCountry?: CountryJsonldAddressRead | null
  linkedOrder?: AbstractCartJsonldAddressRead
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string
  billingCountry?: CountryJsonldAddressRead | null
  billingState?: string
  distinctBillingAddress?: boolean

  /** @format iri-reference */
  relatedRelay?: string | null
  relatedStoreAddress?: StoreJsonldAddressRead | null
}

export interface AddressJsonldOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  recipientName?: string
  recipientStreet?: string
  recipientState?: string | null
  recipientCity?: string
  recipientZipCode?: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null
  recipientCountry?: CountryJsonldOrderRead | null
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string
  billingCountry?: CountryJsonldOrderRead | null
  billingState?: string
  distinctBillingAddress?: boolean
  relatedRelay?: PostalRelayJsonldOrderRead | null
}

export interface AddressJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  recipientName?: string
  recipientStreet?: string
  recipientState?: string | null
  recipientCity?: string
  recipientZipCode?: string
  recipientExtraInformations?: string | null
  recipientPhone?: string | null
  recipientHomePhone?: string | null
  recipientCountry?: CountryJsonldOrderReadOrderReadItem | null
  billingName?: string
  billingStreet?: string
  billingExtraInformations?: string
  billingZipCode?: string
  billingCity?: string
  billingCountry?: CountryJsonldOrderReadOrderReadItem | null
  billingState?: string
  distinctBillingAddress?: boolean
  relatedRelay?: PostalRelayJsonldOrderReadOrderReadItem | null
  relatedStoreAddress?: StoreJsonldOrderReadOrderReadItem | null
}

export interface AddressJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  recipientName?: string
  recipientStreet?: string
  recipientCity?: string
  recipientZipCode?: string
  relatedRelay?: PostalRelayJsonldProductReadItemProductReadGqlPublic | null
  relatedStoreAddress?: StoreJsonldProductReadItemProductReadGqlPublic | null
}

export interface AddressJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  recipientName?: string
  recipientStreet?: string
  recipientCity?: string
  recipientZipCode?: string
  relatedRelay?: PostalRelayJsonldProductReadGqlPublic | null
  relatedStoreAddress?: StoreJsonldProductReadGqlPublic | null
}

export interface AddressJsonldShippingLabelLogRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  recipientName?: string
}

export interface Aggregation {
  key?: string | null
  transName?: string | null

  /** @example list */
  filterType?: string
  transHelperText?: string | null
  transShortDescription?: string | null
  buckets?: string[] | null
  linkedAggregations?: string[] | null
  aggregationBucketsIdentifier?: string
  linkedAggregationIdentifier?: string
}

export interface AggregationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  key?: string | null
  transName?: string | null

  /** @example list */
  filterType?: string
  transHelperText?: string | null
  transShortDescription?: string | null
  buckets?: string[] | null
  linkedAggregations?: string[] | null
  aggregationBucketsIdentifier?: string
  linkedAggregationIdentifier?: string
}

export interface AggregationBucket {
  key?: string | null
  transName?: string | null
  count?: number | null
  singleValue?: string | null

  /** @example default */
  optionType?: string
  customSortProperty?: number | null
  iconUrl?: string | null
}

export interface AggregationBucketJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  key?: string | null
  transName?: string | null
  count?: number | null
  singleValue?: string | null

  /** @example default */
  optionType?: string
  customSortProperty?: number | null
  iconUrl?: string | null
}

export interface ArrayCollection {
  /** An array containing the entries of this collection. */
  elements?: string[]

  /** {@inheritDoc} */
  keys?: any

  /** {@inheritDoc} */
  values?: any

  /** {@inheritDoc} */
  empty?: boolean

  /** {@inheritDoc} */
  iterator?: string[]
}

export interface ArrayCollectionJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** An array containing the entries of this collection. */
  elements?: string[]

  /** {@inheritDoc} */
  keys?: any

  /** {@inheritDoc} */
  values?: any

  /** {@inheritDoc} */
  empty?: boolean

  /** {@inheritDoc} */
  iterator?: string[]
}

export interface Artist {
  id?: number
  name?: string
  products?: string[]
}

export interface ArtistProductReadItemProductReadGqlPublic {
  name?: string
}

export interface ArtistProductReadGqlPublic {
  name?: string
}

export interface ArtistJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  products?: string[]
}

export interface ArtistJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface ArtistJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface Author {
  id?: number
  name?: string
  products?: string[]
}

export interface AuthorProductReadItemProductReadGqlPublic {
  name?: string
}

export interface AuthorProductReadGqlPublic {
  name?: string
}

export interface AuthorJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  products?: string[]
}

export interface AuthorJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface AuthorJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface BoardgameData {
  id?: number

  /** @format iri-reference */
  product?: string | null

  /** @format iri-reference */
  retailer?: string | null
  timeInMinutes?: number

  /** @min 0 */
  minPlayers?: number | null

  /** @min 0 */
  maxPlayers?: number | null

  /** @min 0 */
  minAge?: number | null

  /** @min 0 */
  maxAge?: number | null
}

export interface BoardgameDataProductReadItemProductRead {
  retailer?: ProductDistributorProductReadItemProductRead | null
  timeInMinutes?: number
}

export interface BoardgameDataProductReadItemProductReadGqlPublic {
  retailer?: ProductDistributorProductReadItemProductReadGqlPublic | null
  timeInMinutes?: number

  /** @min 0 */
  minPlayers?: number | null

  /** @min 0 */
  maxPlayers?: number | null

  /** @min 0 */
  minAge?: number | null

  /** @min 0 */
  maxAge?: number | null
}

export interface BoardgameDataProductReadGqlPublic {
  retailer?: ProductDistributorProductReadGqlPublic | null
  timeInMinutes?: number

  /** @min 0 */
  minPlayers?: number | null

  /** @min 0 */
  maxPlayers?: number | null

  /** @min 0 */
  minAge?: number | null

  /** @min 0 */
  maxAge?: number | null
}

export interface BoardgameDataJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  product?: string | null

  /** @format iri-reference */
  retailer?: string | null
  timeInMinutes?: number

  /** @min 0 */
  minPlayers?: number | null

  /** @min 0 */
  maxPlayers?: number | null

  /** @min 0 */
  minAge?: number | null

  /** @min 0 */
  maxAge?: number | null
}

export interface BoardgameDataJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  retailer?: ProductDistributorJsonldProductReadItemProductRead | null
  timeInMinutes?: number
}

export interface BoardgameDataJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  retailer?: ProductDistributorJsonldProductReadItemProductReadGqlPublic | null
  timeInMinutes?: number

  /** @min 0 */
  minPlayers?: number | null

  /** @min 0 */
  maxPlayers?: number | null

  /** @min 0 */
  minAge?: number | null

  /** @min 0 */
  maxAge?: number | null
}

export interface BoardgameDataJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  retailer?: ProductDistributorJsonldProductReadGqlPublic | null
  timeInMinutes?: number

  /** @min 0 */
  minPlayers?: number | null

  /** @min 0 */
  maxPlayers?: number | null

  /** @min 0 */
  minAge?: number | null

  /** @min 0 */
  maxAge?: number | null
}

export interface BoardgameRule {
  id?: number
  locale?: string

  /** @format iri-reference */
  product?: string | null
  label?: string | null
  ruleRelativeUrl?: string
  ruleUrl?: string | null
}

export interface BoardgameRuleProductReadItemProductReadGqlPublic {
  locale?: string
  product?: SealedProductProductReadItemProductReadGqlPublic | null
  label?: string | null
  ruleUrl?: string | null
}

export interface BoardgameRuleProductReadGqlPublic {
  locale?: string
  product?: SealedProductProductReadGqlPublic | null
  label?: string | null
  ruleUrl?: string | null
}

export interface BoardgameRuleJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  locale?: string

  /** @format iri-reference */
  product?: string | null
  label?: string | null
  ruleRelativeUrl?: string
  ruleUrl?: string | null
}

export interface BoardgameRuleJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  product?: SealedProductJsonldProductReadItemProductReadGqlPublic | null
  label?: string | null
  ruleUrl?: string | null
}

export interface BoardgameRuleJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  product?: SealedProductJsonldProductReadGqlPublic | null
  label?: string | null
  ruleUrl?: string | null
}

export interface Brand {
  id?: number
  name?: string
}

export interface BrandProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
}

export interface BrandProductReadGqlPublic {
  id?: number
  name?: string
}

export interface BrandJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
}

export interface BrandJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
}

export interface BrandJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
}

export interface CardCardPropertiesRead {
  id?: number
  family?: FamilyCardPropertiesRead
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  old?: boolean
  colors?: CardColorCardPropertiesRead[]
  variations?: CardVariationCardPropertiesRead[]
  cardTypes?: CardTypeCardPropertiesRead[]
  defaultVariation?: CardVariationCardPropertiesRead | null

  /** @format date-time */
  priceCheckedAt?: string | null
  lastCheckedBy?: CollaboratorCardPropertiesRead | null
  pricingTag?: PricingTagCardPropertiesRead | null

  /** @format date-time */
  taggedAt?: string | null
  taggedBy?: CollaboratorCardPropertiesRead | null
}

export interface CardCardPropertiesReadCardPropertiesReadItem {
  id?: number
  family?: FamilyCardPropertiesReadCardPropertiesReadItem
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorCardPropertiesReadCardPropertiesReadItem[]
  variations?: CardVariationCardPropertiesReadCardPropertiesReadItem[]
  cardTypes?: CardTypeCardPropertiesReadCardPropertiesReadItem[]
  defaultVariation?: CardVariationCardPropertiesReadCardPropertiesReadItem | null

  /** @format date-time */
  priceCheckedAt?: string | null
  lastCheckedBy?: CollaboratorCardPropertiesReadCardPropertiesReadItem | null
  pricingTag?: PricingTagCardPropertiesReadCardPropertiesReadItem | null

  /** @format date-time */
  taggedAt?: string | null
  taggedBy?: CollaboratorCardPropertiesReadCardPropertiesReadItem | null
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
}

export interface CardCardVariationRead {
  id?: number
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  colors?: CardColorCardVariationRead[]
  cardTypes?: string[]
}

export interface CardCardVariationReadCardVariationReadItem {
  id?: number
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  colors?: CardColorCardVariationReadCardVariationReadItem[]
  cardTypes?: string[]
}

export type CardCardRead = object

export interface CardCardWrite {
  /** @format iri-reference */
  pricingTag?: string | null
}

export interface CardPricingTagRead {
  /** @format iri-reference */
  family?: string
  name?: string
  englishName?: string

  /** @format date-time */
  priceCheckedAt?: string | null

  /** @format iri-reference */
  lastCheckedBy?: string | null

  /** @format date-time */
  taggedAt?: string | null

  /** @format iri-reference */
  taggedBy?: string | null
}

export interface CardProductReadItemProductReadGqlPublic {
  id?: number
  family?: FamilyProductReadItemProductReadGqlPublic
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorProductReadItemProductReadGqlPublic[]
  variations?: CardVariationProductReadItemProductReadGqlPublic[]
  cardTypes?: CardTypeProductReadItemProductReadGqlPublic[]
  defaultVariation?: CardVariationProductReadItemProductReadGqlPublic | null
  features?: FeatureProductReadItemProductReadGqlPublic[]

  /** @min 0 */
  lowestPrice?: number | null
  power?: string | null
  toughness?: string | null
  translations?: CardTranslationProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transRulesText?: string | null
  rulesTextFront?: string
  rulesTextBack?: string | null
  rulesTextEnFront?: string
  rulesTextEnBack?: string | null
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
  transRulesTextFront?: string | null
  transRulesTextBack?: string | null
}

export interface CardProductReadGqlPublic {
  id?: number
  family?: FamilyProductReadGqlPublic
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorProductReadGqlPublic[]
  variations?: CardVariationProductReadGqlPublic[]
  cardTypes?: CardTypeProductReadGqlPublic[]
  defaultVariation?: CardVariationProductReadGqlPublic | null
  features?: FeatureProductReadGqlPublic[]

  /** @min 0 */
  lowestPrice?: number | null
  power?: string | null
  toughness?: string | null
  translations?: CardTranslationProductReadGqlPublic[]
  transName?: string | null
  transRulesText?: string | null
  rulesTextFront?: string
  rulesTextBack?: string | null
  rulesTextEnFront?: string
  rulesTextEnBack?: string | null
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
  transRulesTextFront?: string | null
  transRulesTextBack?: string | null
}

export interface CardProductReadProductReadItemProductStats {
  id?: number
  family?: FamilyProductReadProductReadItemProductStats
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorProductReadProductReadItemProductStats[]
  cardTypes?: string[]
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
}

export interface CardProductReadProductStats {
  id?: number
  family?: FamilyProductReadProductStats
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorProductReadProductStats[]
  cardTypes?: string[]
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
}

export interface CardJsonldCardPropertiesRead {
  '@id'?: string
  '@type'?: string
  id?: number
  family?: FamilyJsonldCardPropertiesRead
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  old?: boolean
  colors?: CardColorJsonldCardPropertiesRead[]
  variations?: CardVariationJsonldCardPropertiesRead[]
  cardTypes?: CardTypeJsonldCardPropertiesRead[]
  defaultVariation?: CardVariationJsonldCardPropertiesRead | null

  /** @format date-time */
  priceCheckedAt?: string | null
  lastCheckedBy?: CollaboratorJsonldCardPropertiesRead | null
  pricingTag?: PricingTagJsonldCardPropertiesRead | null

  /** @format date-time */
  taggedAt?: string | null
  taggedBy?: CollaboratorJsonldCardPropertiesRead | null
}

export interface CardJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  family?: FamilyJsonldCardPropertiesReadCardPropertiesReadItem
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorJsonldCardPropertiesReadCardPropertiesReadItem[]
  variations?: CardVariationJsonldCardPropertiesReadCardPropertiesReadItem[]
  cardTypes?: CardTypeJsonldCardPropertiesReadCardPropertiesReadItem[]
  defaultVariation?: CardVariationJsonldCardPropertiesReadCardPropertiesReadItem | null

  /** @format date-time */
  priceCheckedAt?: string | null
  lastCheckedBy?: CollaboratorJsonldCardPropertiesReadCardPropertiesReadItem | null
  pricingTag?: PricingTagJsonldCardPropertiesReadCardPropertiesReadItem | null

  /** @format date-time */
  taggedAt?: string | null
  taggedBy?: CollaboratorJsonldCardPropertiesReadCardPropertiesReadItem | null
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
}

export interface CardJsonldCardVariationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  colors?: CardColorJsonldCardVariationRead[]
  cardTypes?: string[]
}

export interface CardJsonldCardVariationReadCardVariationReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  colors?: CardColorJsonldCardVariationReadCardVariationReadItem[]
  cardTypes?: string[]
}

export interface CardJsonldCardRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface CardJsonldPricingTagRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  family?: string
  name?: string
  englishName?: string

  /** @format date-time */
  priceCheckedAt?: string | null

  /** @format iri-reference */
  lastCheckedBy?: string | null

  /** @format date-time */
  taggedAt?: string | null

  /** @format iri-reference */
  taggedBy?: string | null
}

export interface CardJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  family?: FamilyJsonldProductReadItemProductReadGqlPublic
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorJsonldProductReadItemProductReadGqlPublic[]
  variations?: CardVariationJsonldProductReadItemProductReadGqlPublic[]
  cardTypes?: CardTypeJsonldProductReadItemProductReadGqlPublic[]
  defaultVariation?: CardVariationJsonldProductReadItemProductReadGqlPublic | null
  features?: FeatureJsonldProductReadItemProductReadGqlPublic[]

  /** @min 0 */
  lowestPrice?: number | null
  power?: string | null
  toughness?: string | null
  translations?: CardTranslationJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transRulesText?: string | null
  rulesTextFront?: string
  rulesTextBack?: string | null
  rulesTextEnFront?: string
  rulesTextEnBack?: string | null
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
  transRulesTextFront?: string | null
  transRulesTextBack?: string | null
}

export interface CardJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  family?: FamilyJsonldProductReadGqlPublic
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorJsonldProductReadGqlPublic[]
  variations?: CardVariationJsonldProductReadGqlPublic[]
  cardTypes?: CardTypeJsonldProductReadGqlPublic[]
  defaultVariation?: CardVariationJsonldProductReadGqlPublic | null
  features?: FeatureJsonldProductReadGqlPublic[]

  /** @min 0 */
  lowestPrice?: number | null
  power?: string | null
  toughness?: string | null
  translations?: CardTranslationJsonldProductReadGqlPublic[]
  transName?: string | null
  transRulesText?: string | null
  rulesTextFront?: string
  rulesTextBack?: string | null
  rulesTextEnFront?: string
  rulesTextEnBack?: string | null
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
  transRulesTextFront?: string | null
  transRulesTextBack?: string | null
}

export interface CardJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  family?: FamilyJsonldProductReadProductReadItemProductStats
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorJsonldProductReadProductReadItemProductStats[]
  cardTypes?: string[]
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
}

export interface CardJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  family?: FamilyJsonldProductReadProductStats
  name?: string
  englishName?: string
  hiddenName?: string
  manaValue?: number
  manaCost?: string
  rulesText?: string
  rulesTextEn?: string
  type?: string
  old?: boolean
  subType?: string
  colors?: CardColorJsonldProductReadProductStats[]
  cardTypes?: string[]
  colorCode?: { colorLetterCode?: string; colorHexCode?: string; darkText?: boolean }
  totalQuantityInStock?: number
}

export interface CardColorCardColorRead {
  code: string
  name: string
  englishName: string

  /** @format iri-reference */
  range?: string
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorCardPropertiesRead {
  code: string
  name: string
  englishName: string
  range?: FamilyCardPropertiesRead
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorCardPropertiesReadCardPropertiesReadItem {
  code: string
  name: string
  englishName: string
  range?: FamilyCardPropertiesReadCardPropertiesReadItem
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorCardVariationRead {
  code: string
  name: string
  englishName: string

  /** @format iri-reference */
  range?: string
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorCardVariationReadCardVariationReadItem {
  code: string
  name: string
  englishName: string

  /** @format iri-reference */
  range?: string
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorProductReadItemProductReadGqlPublic {
  id: number
  code: string
  name: string
  englishName: string
  range?: FamilyProductReadItemProductReadGqlPublic
  hexCode?: string
  transName?: string | null

  /** @format uri */
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardColorProductReadGqlPublic {
  id: number
  code: string
  name: string
  englishName: string
  range?: FamilyProductReadGqlPublic
  hexCode?: string
  transName?: string | null

  /** @format uri */
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardColorProductReadProductReadItemProductStats {
  code: string
  name: string
  englishName: string
  range?: FamilyProductReadProductReadItemProductStats
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorProductReadProductStats {
  code: string
  name: string
  englishName: string
  range?: FamilyProductReadProductStats
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorJsonldCardColorRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  code: string
  name: string
  englishName: string

  /** @format iri-reference */
  range?: string
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  name: string
  englishName: string
  range?: FamilyJsonldCardPropertiesRead
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  name: string
  englishName: string
  range?: FamilyJsonldCardPropertiesReadCardPropertiesReadItem
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorJsonldCardVariationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  name: string
  englishName: string

  /** @format iri-reference */
  range?: string
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorJsonldCardVariationReadCardVariationReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  name: string
  englishName: string

  /** @format iri-reference */
  range?: string
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  code: string
  name: string
  englishName: string
  range?: FamilyJsonldProductReadItemProductReadGqlPublic
  hexCode?: string
  transName?: string | null

  /** @format uri */
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardColorJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  code: string
  name: string
  englishName: string
  range?: FamilyJsonldProductReadGqlPublic
  hexCode?: string
  transName?: string | null

  /** @format uri */
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardColorJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  name: string
  englishName: string
  range?: FamilyJsonldProductReadProductReadItemProductStats
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardColorJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  name: string
  englishName: string
  range?: FamilyJsonldProductReadProductStats
  hexCode?: string

  /** @format uri */
  iconRelativeUrl?: string | null
}

export interface CardData {
  id?: number

  /** @format iri-reference */
  declination?: string | null

  /** @format iri-reference */
  variation?: string | null

  /** @format iri-reference */
  card?: string | null
}

export interface CardDataProductReadItemProductReadGqlPublic {
  declination?: CardDeclinationProductReadItemProductReadGqlPublic | null
  variation?: CardVariationProductReadItemProductReadGqlPublic | null
}

export interface CardDataProductReadGqlPublic {
  declination?: CardDeclinationProductReadGqlPublic | null
  variation?: CardVariationProductReadGqlPublic | null
}

export interface CardDataProductReadProductReadItemProductStats {
  declination?: CardDeclinationProductReadProductReadItemProductStats | null
  variation?: CardVariationProductReadProductReadItemProductStats | null
}

export interface CardDataProductReadProductStats {
  declination?: CardDeclinationProductReadProductStats | null
  variation?: CardVariationProductReadProductStats | null
}

export interface CardDataJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  declination?: string | null

  /** @format iri-reference */
  variation?: string | null

  /** @format iri-reference */
  card?: string | null
}

export interface CardDataJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  declination?: CardDeclinationJsonldProductReadItemProductReadGqlPublic | null
  variation?: CardVariationJsonldProductReadItemProductReadGqlPublic | null
}

export interface CardDataJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  declination?: CardDeclinationJsonldProductReadGqlPublic | null
  variation?: CardVariationJsonldProductReadGqlPublic | null
}

export interface CardDataJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  declination?: CardDeclinationJsonldProductReadProductReadItemProductStats | null
  variation?: CardVariationJsonldProductReadProductReadItemProductStats | null
}

export interface CardDataJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  declination?: CardDeclinationJsonldProductReadProductStats | null
  variation?: CardVariationJsonldProductReadProductStats | null
}

export interface CardDeclination {
  id?: number

  /** @format iri-reference */
  grading?: string | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean

  /** @format iri-reference */
  lang?: string

  /** @format iri-reference */
  scan?: string | null
}

export interface CardDeclinationProductReadItemProductReadGqlPublic {
  id?: number
  grading?: CardGradingProductReadItemProductReadGqlPublic | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangProductReadItemProductReadGqlPublic
  scan?: CardScanProductReadItemProductReadGqlPublic | null
}

export interface CardDeclinationProductReadGqlPublic {
  id?: number
  grading?: CardGradingProductReadGqlPublic | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangProductReadGqlPublic
  scan?: CardScanProductReadGqlPublic | null
}

export interface CardDeclinationProductReadProductReadItemProductStats {
  id?: number
  grading?: CardGradingProductReadProductReadItemProductStats | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangProductReadProductReadItemProductStats
  scan?: CardScanProductReadProductReadItemProductStats | null
}

export interface CardDeclinationProductReadProductStats {
  id?: number
  grading?: CardGradingProductReadProductStats | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangProductReadProductStats
  scan?: CardScanProductReadProductStats | null
}

export interface CardDeclinationJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  grading?: string | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean

  /** @format iri-reference */
  lang?: string

  /** @format iri-reference */
  scan?: string | null
}

export interface CardDeclinationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  grading?: CardGradingJsonldProductReadItemProductReadGqlPublic | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangJsonldProductReadItemProductReadGqlPublic
  scan?: CardScanJsonldProductReadItemProductReadGqlPublic | null
}

export interface CardDeclinationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  grading?: CardGradingJsonldProductReadGqlPublic | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangJsonldProductReadGqlPublic
  scan?: CardScanJsonldProductReadGqlPublic | null
}

export interface CardDeclinationJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  grading?: CardGradingJsonldProductReadProductReadItemProductStats | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangJsonldProductReadProductReadItemProductStats
  scan?: CardScanJsonldProductReadProductReadItemProductStats | null
}

export interface CardDeclinationJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  grading?: CardGradingJsonldProductReadProductStats | null

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  signed: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  inked: boolean

  /**
   * @pattern ^([ON]+)$
   * @example false
   */
  foil: boolean
  firstEdition?: boolean
  lang?: LangJsonldProductReadProductStats
  scan?: CardScanJsonldProductReadProductStats | null
}

export interface CardEditionCardEditionRead {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryCardEditionRead | null
  block?: CardEditionCategoryCardEditionRead | null

  /** @min 0 */
  cardCount?: number

  /** @format iri-reference */
  family?: string | null
  printExclusions?: PrintExclusionCardEditionRead[]

  /** @format date-time */
  releasedAt?: string

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: CardEditionCardEditionRead[]
  extraEdition?: CardEditionCardEditionRead | null
  promoEdition?: CardEditionCardEditionRead | null
  imageUrl?: string | null
  translations?: CardEditionTranslationCardEditionRead[]
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: CardEditionStoryblokStoryCardEditionRead[]
  parentEdition?: CardEditionCardEditionRead | null
  childrenEditions?: CardEditionCardEditionRead[]
}

export interface CardEditionCardPropertiesRead {
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryCardPropertiesRead | null
  block?: CardEditionCategoryCardPropertiesRead | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionCardPropertiesReadCardPropertiesReadItem {
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryCardPropertiesReadCardPropertiesReadItem | null
  block?: CardEditionCategoryCardPropertiesReadCardPropertiesReadItem | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionCardVariationRead {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryCardVariationRead | null
  block?: CardEditionCategoryCardVariationRead | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionCardVariationReadCardVariationReadItem {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryCardVariationReadCardVariationReadItem | null
  block?: CardEditionCategoryCardVariationReadCardVariationReadItem | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionPrintExclusionRead {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string

  /** @format date-time */
  releasedAt?: string
}

export interface CardEditionProductReadItemProductRead {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryProductReadItemProductRead | null
  block?: CardEditionCategoryProductReadItemProductRead | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionProductReadItemProductReadGqlPublic {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryProductReadItemProductReadGqlPublic | null
  block?: CardEditionCategoryProductReadItemProductReadGqlPublic | null

  /** @min 0 */
  cardCount?: number
  family?: FamilyProductReadItemProductReadGqlPublic | null
  sealedProducts?: SealedProductProductReadItemProductReadGqlPublic[]
  cardVariations?: CardVariationProductReadItemProductReadGqlPublic[]

  /** @format date-time */
  releasedAt?: string

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: CardEditionProductReadItemProductReadGqlPublic[]
  imageUrl?: string | null
  translations?: CardEditionTranslationProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transProductsPageShortDescription?: string | null
  transProductsPageMetaDescription?: string | null
  transCardsPageShortDescription?: string | null
  transCardsPageMetaDescription?: string | null
  transMetaDescription?: string | null
  transMetaTitle?: string | null
  transPlace?: string | null
  transTheme?: string | null
  transKeywords?: string | null
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
  parentEdition?: CardEditionProductReadItemProductReadGqlPublic | null
  childrenEditions?: CardEditionProductReadItemProductReadGqlPublic[]
  approximateReleasedAt?: string | null
  approximateReleasedAtEn?: string | null
  revealedCardsCount?: number
}

export interface CardEditionProductReadGqlPublic {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryProductReadGqlPublic | null
  block?: CardEditionCategoryProductReadGqlPublic | null

  /** @min 0 */
  cardCount?: number
  family?: FamilyProductReadGqlPublic | null
  sealedProducts?: SealedProductProductReadGqlPublic[]
  cardVariations?: CardVariationProductReadGqlPublic[]

  /** @format date-time */
  releasedAt?: string

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: CardEditionProductReadGqlPublic[]
  imageUrl?: string | null
  translations?: CardEditionTranslationProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transProductsPageShortDescription?: string | null
  transProductsPageMetaDescription?: string | null
  transCardsPageShortDescription?: string | null
  transCardsPageMetaDescription?: string | null
  transMetaDescription?: string | null
  transMetaTitle?: string | null
  transPlace?: string | null
  transTheme?: string | null
  transKeywords?: string | null
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
  parentEdition?: CardEditionProductReadGqlPublic | null
  childrenEditions?: CardEditionProductReadGqlPublic[]
  approximateReleasedAt?: string | null
  approximateReleasedAtEn?: string | null
  revealedCardsCount?: number
}

export interface CardEditionProductReadProductReadItemProductStats {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryProductReadProductReadItemProductStats | null
  block?: CardEditionCategoryProductReadProductReadItemProductStats | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionProductReadProductStats {
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryProductReadProductStats | null
  block?: CardEditionCategoryProductReadProductStats | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionJsonldCardEditionRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldCardEditionRead | null
  block?: CardEditionCategoryJsonldCardEditionRead | null

  /** @min 0 */
  cardCount?: number

  /** @format iri-reference */
  family?: string | null
  printExclusions?: PrintExclusionJsonldCardEditionRead[]

  /** @format date-time */
  releasedAt?: string

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: CardEditionJsonldCardEditionRead[]
  extraEdition?: CardEditionJsonldCardEditionRead | null
  promoEdition?: CardEditionJsonldCardEditionRead | null
  imageUrl?: string | null
  translations?: CardEditionTranslationJsonldCardEditionRead[]
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: CardEditionStoryblokStoryJsonldCardEditionRead[]
  parentEdition?: CardEditionJsonldCardEditionRead | null
  childrenEditions?: CardEditionJsonldCardEditionRead[]
}

export interface CardEditionJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldCardPropertiesRead | null
  block?: CardEditionCategoryJsonldCardPropertiesRead | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldCardPropertiesReadCardPropertiesReadItem | null
  block?: CardEditionCategoryJsonldCardPropertiesReadCardPropertiesReadItem | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionJsonldCardVariationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldCardVariationRead | null
  block?: CardEditionCategoryJsonldCardVariationRead | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionJsonldCardVariationReadCardVariationReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldCardVariationReadCardVariationReadItem | null
  block?: CardEditionCategoryJsonldCardVariationReadCardVariationReadItem | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionJsonldPrintExclusionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string

  /** @format date-time */
  releasedAt?: string
}

export interface CardEditionJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldProductReadItemProductRead | null
  block?: CardEditionCategoryJsonldProductReadItemProductRead | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldProductReadItemProductReadGqlPublic | null
  block?: CardEditionCategoryJsonldProductReadItemProductReadGqlPublic | null

  /** @min 0 */
  cardCount?: number
  family?: FamilyJsonldProductReadItemProductReadGqlPublic | null
  sealedProducts?: SealedProductJsonldProductReadItemProductReadGqlPublic[]
  cardVariations?: CardVariationJsonldProductReadItemProductReadGqlPublic[]

  /** @format date-time */
  releasedAt?: string

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: CardEditionJsonldProductReadItemProductReadGqlPublic[]
  imageUrl?: string | null
  translations?: CardEditionTranslationJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transProductsPageShortDescription?: string | null
  transProductsPageMetaDescription?: string | null
  transCardsPageShortDescription?: string | null
  transCardsPageMetaDescription?: string | null
  transMetaDescription?: string | null
  transMetaTitle?: string | null
  transPlace?: string | null
  transTheme?: string | null
  transKeywords?: string | null
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
  parentEdition?: CardEditionJsonldProductReadItemProductReadGqlPublic | null
  childrenEditions?: CardEditionJsonldProductReadItemProductReadGqlPublic[]
  approximateReleasedAt?: string | null
  approximateReleasedAtEn?: string | null
  revealedCardsCount?: number
}

export interface CardEditionJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldProductReadGqlPublic | null
  block?: CardEditionCategoryJsonldProductReadGqlPublic | null

  /** @min 0 */
  cardCount?: number
  family?: FamilyJsonldProductReadGqlPublic | null
  sealedProducts?: SealedProductJsonldProductReadGqlPublic[]
  cardVariations?: CardVariationJsonldProductReadGqlPublic[]

  /** @format date-time */
  releasedAt?: string

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: CardEditionJsonldProductReadGqlPublic[]
  imageUrl?: string | null
  translations?: CardEditionTranslationJsonldProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transProductsPageShortDescription?: string | null
  transProductsPageMetaDescription?: string | null
  transCardsPageShortDescription?: string | null
  transCardsPageMetaDescription?: string | null
  transMetaDescription?: string | null
  transMetaTitle?: string | null
  transPlace?: string | null
  transTheme?: string | null
  transKeywords?: string | null
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
  parentEdition?: CardEditionJsonldProductReadGqlPublic | null
  childrenEditions?: CardEditionJsonldProductReadGqlPublic[]
  approximateReleasedAt?: string | null
  approximateReleasedAtEn?: string | null
  revealedCardsCount?: number
}

export interface CardEditionJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldProductReadProductReadItemProductStats | null
  block?: CardEditionCategoryJsonldProductReadProductReadItemProductStats | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example to_be_released */
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  category?: CardEditionCategoryJsonldProductReadProductStats | null
  block?: CardEditionCategoryJsonldProductReadProductStats | null

  /** @min 0 */
  cardCount?: number
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]
}

export interface CardEditionCategoryCardEditionCategoryRead {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryCardEditionCategoryWrite {
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryCardEditionRead {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryCardPropertiesRead {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryCardPropertiesReadCardPropertiesReadItem {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryCardVariationRead {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryCardVariationReadCardVariationReadItem {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryProductReadItemProductRead {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryProductReadProductReadItemProductStats {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryProductReadProductStats {
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldCardEditionCategoryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldCardEditionCategoryWrite {
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldCardEditionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldCardVariationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldCardVariationReadCardVariationReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionCategoryJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
}

export interface CardEditionDto {
  translations?: CardEditionTranslation[]

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  id?: number | null
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  imageDirectory?: string
  description?: string
  descriptionEn?: string

  /** @format iri-reference */
  category?: string

  /** @format iri-reference */
  block?: string | null

  /** @min 0 */
  cardCount?: number

  /** @format iri-reference */
  family?: string
  printExclusions?: Collection
  inventories?: Collection
  sealedProducts?: string[]
  cardVariations?: string[]

  /** @format date-time */
  releasedAt?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  numbered?: boolean
  recent?: boolean
  atTheEnd?: boolean
  sortedInEnglish?: boolean
  tournamentLegal?: boolean

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: string[]

  /** @format iri-reference */
  extraEdition?: string | null

  /** @format iri-reference */
  parentExtraEdition?: string | null

  /** @format iri-reference */
  promoEdition?: string | null

  /** @format iri-reference */
  parentPromoEdition?: string | null
  imageUrl?: string | null
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transProductsPageShortDescription?: string | null
  transProductsPageMetaDescription?: string | null
  transCardsPageShortDescription?: string | null
  transCardsPageMetaDescription?: string | null
  transMetaDescription?: string | null
  transMetaTitle?: string | null
  transPlace?: string | null
  transTheme?: string | null
  transKeywords?: string | null
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]

  /** @format iri-reference */
  parentEdition?: string | null
  childrenEditions?: string[]
  approximateReleasedAt?: string | null
  approximateReleasedAtEn?: string | null
  cardsSortedByCollectionNumber?: string[]
  translation?: CardEditionTranslation
  revealedCardsCount?: number
  translationLocales?: string[]
}

export interface CardEditionDtoJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  translations?: CardEditionTranslationJsonld[]

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  id?: number | null
  status?: string
  name: string
  nameEn: string
  codeName?: string | null
  imageDirectory?: string
  description?: string
  descriptionEn?: string

  /** @format iri-reference */
  category?: string

  /** @format iri-reference */
  block?: string | null

  /** @min 0 */
  cardCount?: number

  /** @format iri-reference */
  family?: string
  printExclusions?: CollectionJsonld
  inventories?: CollectionJsonld
  sealedProducts?: string[]
  cardVariations?: string[]

  /** @format date-time */
  releasedAt?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  numbered?: boolean
  recent?: boolean
  atTheEnd?: boolean
  sortedInEnglish?: boolean
  tournamentLegal?: boolean

  /** @min 0 */
  visibleProductCount?: number
  linkedEditions?: string[]

  /** @format iri-reference */
  extraEdition?: string | null

  /** @format iri-reference */
  parentExtraEdition?: string | null

  /** @format iri-reference */
  promoEdition?: string | null

  /** @format iri-reference */
  parentPromoEdition?: string | null
  imageUrl?: string | null
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transProductsPageShortDescription?: string | null
  transProductsPageMetaDescription?: string | null
  transCardsPageShortDescription?: string | null
  transCardsPageMetaDescription?: string | null
  transMetaDescription?: string | null
  transMetaTitle?: string | null
  transPlace?: string | null
  transTheme?: string | null
  transKeywords?: string | null
  iconUrl?: string
  wallpaperUrl?: string
  symbolUrl?: string | null
  storyblokStories?: string[]

  /** @format iri-reference */
  parentEdition?: string | null
  childrenEditions?: string[]
  approximateReleasedAt?: string | null
  approximateReleasedAtEn?: string | null
  cardsSortedByCollectionNumber?: string[]
  translation?: CardEditionTranslationJsonld
  revealedCardsCount?: number
  translationLocales?: string[]
}

export interface CardEditionGroupByDate {
  id?: string
  editions?: string[]
  group?: string
}

export interface CardEditionGroupByDateJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: string
  editions?: string[]
  group?: string
}

export interface CardEditionGroupByName {
  id?: string
  editions?: string[]
  group?: string
}

export interface CardEditionGroupByNameJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: string
  editions?: string[]
  group?: string
}

export interface CardEditionStoryblokStory {
  id?: number

  /** @format iri-reference */
  cardEdition?: string
  storyId?: number
}

export interface CardEditionStoryblokStoryCardEditionRead {
  id?: number
  cardEdition?: CardEditionCardEditionRead
  storyId?: number
}

export interface CardEditionStoryblokStoryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  cardEdition?: string
  storyId?: number
}

export interface CardEditionStoryblokStoryJsonldCardEditionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  cardEdition?: CardEditionJsonldCardEditionRead
  storyId?: number
}

export interface CardEditionTranslation {
  id?: number
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  productsPageShortDescription?: string
  productsPageMetaDescription?: string
  cardsPageShortDescription?: string
  cardsPageMetaDescription?: string

  /** @format iri-reference */
  cardEdition?: string
  metaTitle?: string
  metaDescription?: string
  place?: string
  theme?: string
  keywords?: string
  approximateReleasedAt?: string
  translatable?: TranslatableInterface | null
  products?: string[]
  cards?: string[]
}

export interface CardEditionTranslationCardEditionRead {
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  products?: string[]
  cards?: string[]
}

export interface CardEditionTranslationProductReadItemProductReadGqlPublic {
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  products?: string[]
  cards?: string[]
}

export interface CardEditionTranslationProductReadGqlPublic {
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  products?: string[]
  cards?: string[]
}

export interface CardEditionTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  productsPageShortDescription?: string
  productsPageMetaDescription?: string
  cardsPageShortDescription?: string
  cardsPageMetaDescription?: string

  /** @format iri-reference */
  cardEdition?: string
  metaTitle?: string
  metaDescription?: string
  place?: string
  theme?: string
  keywords?: string
  approximateReleasedAt?: string
  translatable?: TranslatableInterfaceJsonld | null
  products?: string[]
  cards?: string[]
}

export interface CardEditionTranslationJsonldCardEditionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  products?: string[]
  cards?: string[]
}

export interface CardEditionTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  products?: string[]
  cards?: string[]
}

export interface CardEditionTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  shortDescription?: string
  longDescription?: string
  products?: string[]
  cards?: string[]
}

export interface CardGrading {
  id?: number
  name: string
  position?: number
  ranking?: number
  color?: string | null
  textColor?: string | null
  translations?: CardGradingTranslation[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  translation?: TranslationInterface[]
  translationLocales?: string[]
}

export interface CardGradingProductReadItemProductReadGqlPublic {
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
  transName?: string | null
}

export interface CardGradingProductReadGqlPublic {
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
  transName?: string | null
}

export interface CardGradingProductReadProductReadItemProductStats {
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
}

export interface CardGradingProductReadProductStats {
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
}

export interface CardGradingJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name: string
  position?: number
  ranking?: number
  color?: string | null
  textColor?: string | null
  translations?: CardGradingTranslationJsonld[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  translation?: TranslationInterfaceJsonld[]
  translationLocales?: string[]
}

export interface CardGradingJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
  transName?: string | null
}

export interface CardGradingJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
  transName?: string | null
}

export interface CardGradingJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
}

export interface CardGradingJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  color?: string | null
  textColor?: string | null
}

export interface CardGradingTranslation {
  id?: number
  locale?: string
  name?: string | null

  /** @format iri-reference */
  cardGrading?: string
  translatable?: TranslatableInterface | null
}

export interface CardGradingTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null

  /** @format iri-reference */
  cardGrading?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface CardOpening {
  id?: number

  /** @format iri-reference */
  stock?: string | null
}

export type CardOpeningCardOpeningRead = object

export type CardOpeningCardOpeningWrite = object

export interface CardOpeningJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  stock?: string | null
}

export interface CardOpeningJsonldCardOpeningRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export type CardOpeningJsonldCardOpeningWrite = object

export interface CardProduct {
  /** @format iri-reference */
  cardData?: string | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string

  /** @format iri-reference */
  linkedEdition?: string | null

  /** @format iri-reference */
  category?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null

  /** Protected to allow access in classes using this Trait or extending provided AbstractTranslatable */
  translations?: Record<string, TranslationInterface>
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  printCategory?: string
  printSubCategory?: string
  bookLaw?: boolean
  quantity?: number
  transName?: string | null
  imageUrl?: string | null
  googleShoppingEan?: string | null
  manufacturerPartNumber?: string | null
  googleCategoryId?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  lang?: string | null
  stockEntries?: string[]
  orderEntries?: string[]
  warehouseStockEntries?: string[]
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: string[]

  /** @format iri-reference */
  tax?: string
  depositEntries?: string[]
  productStats?: string[]
  inventoryEntries?: string[]
  productsInPack?: string[]
  packsContainingProduct?: string[]
  pack?: boolean
  inPack?: boolean
  inFidelityProgram?: boolean

  /** @format iri-reference */
  secondHandOf?: string | null
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  secondHandProducts?: string[]
  collectionEntries?: string[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  managedByHiboutik?: boolean
  isEligibleToInstallments?: boolean

  /** @format date-time */
  nextRestockAt?: string | null
  restock?: boolean
  productsInPackUnique?: string[] | null
  productsInPackMerged?: string[] | null
  preorder?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
  sellable?: boolean

  /** {@inheritdoc} */
  translation?: TranslationInterface
  translationLocales?: string[]
}

export interface CardProductProductReadItemProductReadGqlPublic {
  cardData?: CardDataProductReadItemProductReadGqlPublic | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionProductReadItemProductReadGqlPublic | null
  category?: ProductCategoryProductReadItemProductReadGqlPublic | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  transName?: string | null
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadItemProductReadGqlPublic | null
  lang?: LangProductReadItemProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadItemProductReadGqlPublic[]
  tax?: TaxProductReadItemProductReadGqlPublic
  productStats?: ProductStatsProductReadItemProductReadGqlPublic[]
  productsInPack?: ProductPackProductReadItemProductReadGqlPublic[]
  packsContainingProduct?: ProductPackProductReadItemProductReadGqlPublic[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryProductReadItemProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface CardProductProductReadGqlPublic {
  cardData?: CardDataProductReadGqlPublic | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionProductReadGqlPublic | null
  category?: ProductCategoryProductReadGqlPublic | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  transName?: string | null
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadGqlPublic | null
  lang?: LangProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadGqlPublic[]
  tax?: TaxProductReadGqlPublic
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
}

export interface CardProductProductReadProductReadItemProductStats {
  cardData?: CardDataProductReadProductReadItemProductStats | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionProductReadProductReadItemProductStats | null
  category?: ProductCategoryProductReadProductReadItemProductStats | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadProductReadItemProductStats | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadProductReadItemProductStats | null
  lang?: LangProductReadProductReadItemProductStats | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadProductReadItemProductStats[]
  tax?: TaxProductReadProductReadItemProductStats
  productStats?: ProductStatsProductReadProductReadItemProductStats[]
  productsInPack?: ProductPackProductReadProductReadItemProductStats[]
  packsContainingProduct?: ProductPackProductReadProductReadItemProductStats[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  isDiscounted?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface CardProductProductReadProductStats {
  cardData?: CardDataProductReadProductStats | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  category?: ProductCategoryProductReadProductStats | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadProductStats | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadProductStats | null
  lang?: LangProductReadProductStats | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadProductStats[]
  tax?: TaxProductReadProductStats
  productStats?: ProductStatsProductReadProductStats[]
  pack?: boolean
  inPack?: boolean
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  isDiscounted?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
}

export interface CardProductJsonld {
  /** @format iri-reference */
  cardData?: string | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string

  /** @format iri-reference */
  linkedEdition?: string | null

  /** @format iri-reference */
  category?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null

  /** Protected to allow access in classes using this Trait or extending provided AbstractTranslatable */
  translations?: Record<string, TranslationInterfaceJsonld>
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  printCategory?: string
  printSubCategory?: string
  bookLaw?: boolean
  quantity?: number
  transName?: string | null
  imageUrl?: string | null
  googleShoppingEan?: string | null
  manufacturerPartNumber?: string | null
  googleCategoryId?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  lang?: string | null
  stockEntries?: string[]
  orderEntries?: string[]
  warehouseStockEntries?: string[]
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: string[]

  /** @format iri-reference */
  tax?: string
  depositEntries?: string[]
  productStats?: string[]
  inventoryEntries?: string[]
  productsInPack?: string[]
  packsContainingProduct?: string[]
  pack?: boolean
  inPack?: boolean
  inFidelityProgram?: boolean

  /** @format iri-reference */
  secondHandOf?: string | null
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  secondHandProducts?: string[]
  collectionEntries?: string[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  managedByHiboutik?: boolean
  isEligibleToInstallments?: boolean

  /** @format date-time */
  nextRestockAt?: string | null
  restock?: boolean
  productsInPackUnique?: string[] | null
  productsInPackMerged?: string[] | null
  preorder?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
  sellable?: boolean

  /** {@inheritdoc} */
  translation?: TranslationInterfaceJsonld
  translationLocales?: string[]
}

export interface CardProductJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  cardData?: CardDataJsonldProductReadItemProductReadGqlPublic | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionJsonldProductReadItemProductReadGqlPublic | null
  category?: ProductCategoryJsonldProductReadItemProductReadGqlPublic | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  transName?: string | null
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadItemProductReadGqlPublic | null
  lang?: LangJsonldProductReadItemProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadItemProductReadGqlPublic[]
  tax?: TaxJsonldProductReadItemProductReadGqlPublic
  productStats?: ProductStatsJsonldProductReadItemProductReadGqlPublic[]
  productsInPack?: ProductPackJsonldProductReadItemProductReadGqlPublic[]
  packsContainingProduct?: ProductPackJsonldProductReadItemProductReadGqlPublic[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryJsonldProductReadItemProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface CardProductJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  cardData?: CardDataJsonldProductReadGqlPublic | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionJsonldProductReadGqlPublic | null
  category?: ProductCategoryJsonldProductReadGqlPublic | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  transName?: string | null
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadGqlPublic | null
  lang?: LangJsonldProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadGqlPublic[]
  tax?: TaxJsonldProductReadGqlPublic
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryJsonldProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
}

export interface CardProductJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  cardData?: CardDataJsonldProductReadProductReadItemProductStats | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionJsonldProductReadProductReadItemProductStats | null
  category?: ProductCategoryJsonldProductReadProductReadItemProductStats | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadProductReadItemProductStats | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadProductReadItemProductStats | null
  lang?: LangJsonldProductReadProductReadItemProductStats | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadProductReadItemProductStats[]
  tax?: TaxJsonldProductReadProductReadItemProductStats
  productStats?: ProductStatsJsonldProductReadProductReadItemProductStats[]
  productsInPack?: ProductPackJsonldProductReadProductReadItemProductStats[]
  packsContainingProduct?: ProductPackJsonldProductReadProductReadItemProductStats[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  isDiscounted?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface CardProductJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  cardData?: CardDataJsonldProductReadProductStats | null
  quantityInBuybackNotValidated?: number | null
  quantityInRack?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  category?: ProductCategoryJsonldProductReadProductStats | null
  nameEn?: string
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @format date-time */
  releasedAt?: string | null
  quantity?: number
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadProductStats | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadProductStats | null
  lang?: LangJsonldProductReadProductStats | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadProductStats[]
  tax?: TaxJsonldProductReadProductStats
  productStats?: ProductStatsJsonldProductReadProductStats[]
  pack?: boolean
  inPack?: boolean
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  isDiscounted?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
}

export interface CardRarityCardPropertiesReadCardPropertiesReadItem {
  id: number
  name: string
}

export interface CardRarityCardRarityRead {
  id: number
  name: string
  shortName: string
  family?: FamilyCardRarityRead
  color?: string | null
  backgroundColor?: string | null
  iconRelativeUrl?: string | null
}

export interface CardRarityProductReadItemProductReadGqlPublic {
  id: number
  name: string
  nameEn: string
  shortName: string
  family?: FamilyProductReadItemProductReadGqlPublic
  color?: string | null
  backgroundColor?: string | null
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardRarityProductReadGqlPublic {
  id: number
  name: string
  nameEn: string
  shortName: string
  family?: FamilyProductReadGqlPublic
  color?: string | null
  backgroundColor?: string | null
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardRarityProductReadProductReadItemProductStats {
  id: number
  name: string
  shortName: string
  family?: FamilyProductReadProductReadItemProductStats
  color?: string | null
  backgroundColor?: string | null
  iconRelativeUrl?: string | null
}

export interface CardRarityProductReadProductStats {
  id: number
  name: string
  shortName: string
  family?: FamilyProductReadProductStats
  color?: string | null
  backgroundColor?: string | null
  iconRelativeUrl?: string | null
}

export interface CardRarityJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
}

export interface CardRarityJsonldCardRarityRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id: number
  name: string
  shortName: string
  family?: FamilyJsonldCardRarityRead
  color?: string | null
  backgroundColor?: string | null
  iconRelativeUrl?: string | null
}

export interface CardRarityJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  nameEn: string
  shortName: string
  family?: FamilyJsonldProductReadItemProductReadGqlPublic
  color?: string | null
  backgroundColor?: string | null
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardRarityJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  nameEn: string
  shortName: string
  family?: FamilyJsonldProductReadGqlPublic
  color?: string | null
  backgroundColor?: string | null
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface CardRarityJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  shortName: string
  family?: FamilyJsonldProductReadProductReadItemProductStats
  color?: string | null
  backgroundColor?: string | null
  iconRelativeUrl?: string | null
}

export interface CardRarityJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  shortName: string
  family?: FamilyJsonldProductReadProductStats
  color?: string | null
  backgroundColor?: string | null
  iconRelativeUrl?: string | null
}

export interface CardScan {
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanProductReadItemProductReadGqlPublic {
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanProductReadGqlPublic {
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanProductReadProductReadItemProductStats {
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanProductReadProductStats {
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardScanJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  imgRecto?: string | null
  imgVerso?: string | null
  description?: string | null
}

export interface CardTranslation {
  id?: number
  locale?: string
  name?: string | null
  rulesText?: string | null

  /** @format iri-reference */
  card?: string
  translatable?: TranslatableInterface | null
}

export interface CardTranslationProductReadItemProductReadGqlPublic {
  name?: string | null
  rulesText?: string | null
}

export interface CardTranslationProductReadGqlPublic {
  name?: string | null
  rulesText?: string | null
}

export interface CardTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null
  rulesText?: string | null

  /** @format iri-reference */
  card?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface CardTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string | null
  rulesText?: string | null
}

export interface CardTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string | null
  rulesText?: string | null
}

export interface CardTypeCardPropertiesRead {
  id?: number
  name?: string
  family?: FamilyCardPropertiesRead | null
}

export interface CardTypeCardPropertiesReadCardPropertiesReadItem {
  id?: number
  name?: string
  family?: FamilyCardPropertiesReadCardPropertiesReadItem | null
}

export interface CardTypeCardTypesRead {
  id?: number
  name?: string

  /** @format iri-reference */
  family?: string | null
  highlight?: boolean
}

export interface CardTypeProductReadItemProductReadGqlPublic {
  /** @min 0 */
  position: number
  transName?: string | null
  slug?: string
}

export interface CardTypeProductReadGqlPublic {
  /** @min 0 */
  position: number
  transName?: string | null
  slug?: string
}

export interface CardTypeJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  family?: FamilyJsonldCardPropertiesRead | null
}

export interface CardTypeJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  family?: FamilyJsonldCardPropertiesReadCardPropertiesReadItem | null
}

export interface CardTypeJsonldCardTypesRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string

  /** @format iri-reference */
  family?: string | null
  highlight?: boolean
}

export interface CardTypeJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @min 0 */
  position: number
  transName?: string | null
  slug?: string
}

export interface CardTypeJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @min 0 */
  position: number
  transName?: string | null
  slug?: string
}

export interface CardVariationCardPropertiesRead {
  id?: number
  name?: string
  nameEn?: string

  /** @format iri-reference */
  rarity?: string | null
  edition?: CardEditionCardPropertiesRead | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string
  priceUpdatedBy?: CollaboratorCardPropertiesRead | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationCardPropertiesReadCardPropertiesReadItem {
  id?: number
  name?: string
  nameEn?: string
  rarity?: CardRarityCardPropertiesReadCardPropertiesReadItem | null
  edition?: CardEditionCardPropertiesReadCardPropertiesReadItem | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string
  priceUpdatedBy?: CollaboratorCardPropertiesReadCardPropertiesReadItem | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationCardVariationRead {
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardCardVariationRead | null
  edition?: CardEditionCardVariationRead | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  totalQuantity?: number
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationCardVariationReadCardVariationReadItem {
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardCardVariationReadCardVariationReadItem | null

  /** @format iri-reference */
  rarity?: string | null
  edition?: CardEditionCardVariationReadCardVariationReadItem | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  totalQuantity?: number
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardProductReadItemProductReadGqlPublic | null
  rarity?: CardRarityProductReadItemProductReadGqlPublic | null
  edition?: CardEditionProductReadItemProductReadGqlPublic | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  artist?: string
  cheapestProductRegular?: CardProductProductReadItemProductReadGqlPublic | null
  cheapestProductFoil?: CardProductProductReadItemProductReadGqlPublic | null
  transName?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
  previousCollectionNumber?: string | null
  nextCollectionNumber?: string | null
  products?: CardProductProductReadItemProductReadGqlPublic[]
}

export interface CardVariationProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardProductReadGqlPublic | null
  rarity?: CardRarityProductReadGqlPublic | null
  edition?: CardEditionProductReadGqlPublic | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  artist?: string
  cheapestProductRegular?: CardProductProductReadGqlPublic | null
  cheapestProductFoil?: CardProductProductReadGqlPublic | null
  transName?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
  previousCollectionNumber?: string | null
  nextCollectionNumber?: string | null
  products?: CardProductProductReadGqlPublic[]
}

export interface CardVariationProductReadProductReadItemProductStats {
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardProductReadProductReadItemProductStats | null
  rarity?: CardRarityProductReadProductReadItemProductStats | null
  edition?: CardEditionProductReadProductReadItemProductStats | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationProductReadProductStats {
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardProductReadProductStats | null
  rarity?: CardRarityProductReadProductStats | null
  edition?: CardEditionProductReadProductStats | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string

  /** @format iri-reference */
  rarity?: string | null
  edition?: CardEditionJsonldCardPropertiesRead | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string
  priceUpdatedBy?: CollaboratorJsonldCardPropertiesRead | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  rarity?: CardRarityJsonldCardPropertiesReadCardPropertiesReadItem | null
  edition?: CardEditionJsonldCardPropertiesReadCardPropertiesReadItem | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string
  priceUpdatedBy?: CollaboratorJsonldCardPropertiesReadCardPropertiesReadItem | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationJsonldCardVariationRead {
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardJsonldCardVariationRead | null
  edition?: CardEditionJsonldCardVariationRead | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  totalQuantity?: number
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationJsonldCardVariationReadCardVariationReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardJsonldCardVariationReadCardVariationReadItem | null

  /** @format iri-reference */
  rarity?: string | null
  edition?: CardEditionJsonldCardVariationReadCardVariationReadItem | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  totalQuantity?: number
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardJsonldProductReadItemProductReadGqlPublic | null
  rarity?: CardRarityJsonldProductReadItemProductReadGqlPublic | null
  edition?: CardEditionJsonldProductReadItemProductReadGqlPublic | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  artist?: string
  cheapestProductRegular?: CardProductJsonldProductReadItemProductReadGqlPublic | null
  cheapestProductFoil?: CardProductJsonldProductReadItemProductReadGqlPublic | null
  transName?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
  previousCollectionNumber?: string | null
  nextCollectionNumber?: string | null
  products?: CardProductJsonldProductReadItemProductReadGqlPublic[]
}

export interface CardVariationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardJsonldProductReadGqlPublic | null
  rarity?: CardRarityJsonldProductReadGqlPublic | null
  edition?: CardEditionJsonldProductReadGqlPublic | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  artist?: string
  cheapestProductRegular?: CardProductJsonldProductReadGqlPublic | null
  cheapestProductFoil?: CardProductJsonldProductReadGqlPublic | null
  transName?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
  previousCollectionNumber?: string | null
  nextCollectionNumber?: string | null
  products?: CardProductJsonldProductReadGqlPublic[]
}

export interface CardVariationJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardJsonldProductReadProductReadItemProductStats | null
  rarity?: CardRarityJsonldProductReadProductReadItemProductStats | null
  edition?: CardEditionJsonldProductReadProductReadItemProductStats | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CardVariationJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  cardProperties?: CardJsonldProductReadProductStats | null
  rarity?: CardRarityJsonldProductReadProductStats | null
  edition?: CardEditionJsonldProductReadProductStats | null
  collectionNumber?: string

  /** @format date-time */
  insertedAt?: string

  /** @format date-time */
  priceUpdatedAt?: string

  /** @format iri-reference */
  priceUpdatedBy?: string | null
  imageUrl?: string
  imageBackUrl?: string | null
}

export interface CartEntry {
  id?: number

  /** @format iri-reference */
  customer?: string

  /** @format iri-reference */
  product?: SealedProductProductRead

  /** @format iri-reference */
  store?: string | null

  /** @min 0 */
  quantity?: number
  savedForLater?: boolean

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  savedForLaterAt?: string | null
}

export interface CartEntryProductReadItemProductReadGqlPublic {
  id?: number
  customer?: CustomerProductReadItemProductReadGqlPublic
  store?: StoreProductReadItemProductReadGqlPublic | null

  /** @min 0 */
  quantity?: number
  savedForLater?: boolean

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  savedForLaterAt?: string | null
}

export interface CartEntryProductReadGqlPublic {
  id?: number
  customer?: CustomerProductReadGqlPublic
  store?: StoreProductReadGqlPublic | null

  /** @min 0 */
  quantity?: number
  savedForLater?: boolean

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  savedForLaterAt?: string | null
}

export interface CartEntryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  customer?: string

  /** @format iri-reference */
  product?: SealedProductProductRead

  /** @format iri-reference */
  store?: string | null

  /** @min 0 */
  quantity?: number
  savedForLater?: boolean

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  savedForLaterAt?: string | null
}

export interface CartEntryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  customer?: CustomerJsonldProductReadItemProductReadGqlPublic
  store?: StoreJsonldProductReadItemProductReadGqlPublic | null

  /** @min 0 */
  quantity?: number
  savedForLater?: boolean

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  savedForLaterAt?: string | null
}

export interface CartEntryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  customer?: CustomerJsonldProductReadGqlPublic
  store?: StoreJsonldProductReadGqlPublic | null

  /** @min 0 */
  quantity?: number
  savedForLater?: boolean

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  savedForLaterAt?: string | null
}

export interface CashboxControlCashControlClose {
  comment?: string | null
}

export interface CashboxControlCashControlCreate {
  /** @format iri-reference */
  store: string

  /** @min 0 */
  cashFundStartCalculated?: number
}

export interface CashboxControlCashControlFund {
  cashFundEndCalculated?: number
}

export interface CashboxControlCashControlRead {
  id?: number

  /** @example created */
  status?: CashboxStatus
  store: StoreCashControlRead

  /** @min 0 */
  cashFundStart?: number

  /** @min 0 */
  cashFundStartCalculated?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorCashControlRead
  totalCash?: number
  totalCashCalculated?: number
  totalChecks?: number
  totalChecksCalculated?: number
  totalGiftVouchers?: number
  totalGiftVouchersCalculated?: number
  totalCreditCard?: number
  totalMealBenefits?: number
  totalRefund?: number
  totalEvents?: number

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorCashControlRead | null
  cashFundEnd?: number
  cashFundEndCalculated?: number

  /** @format date-time */
  fundedAt?: string | null
  fundedBy?: CollaboratorCashControlRead | null
  comment?: string | null

  /** @format date-time */
  closedAt?: string | null
  closedBy?: CollaboratorCashControlRead | null
}

export interface CashboxControlCashControlValidate {
  totalCashCalculated?: number
  totalChecksCalculated?: number
  totalGiftVouchersCalculated?: number
}

export interface CashboxControlJsonldCashControlCreate {
  /** @format iri-reference */
  store: string

  /** @min 0 */
  cashFundStartCalculated?: number
}

export interface CashboxControlJsonldCashControlRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @example created */
  status?: CashboxStatus
  store: StoreJsonldCashControlRead

  /** @min 0 */
  cashFundStart?: number

  /** @min 0 */
  cashFundStartCalculated?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldCashControlRead
  totalCash?: number
  totalCashCalculated?: number
  totalChecks?: number
  totalChecksCalculated?: number
  totalGiftVouchers?: number
  totalGiftVouchersCalculated?: number
  totalCreditCard?: number
  totalMealBenefits?: number
  totalRefund?: number
  totalEvents?: number

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorJsonldCashControlRead | null
  cashFundEnd?: number
  cashFundEndCalculated?: number

  /** @format date-time */
  fundedAt?: string | null
  fundedBy?: CollaboratorJsonldCashControlRead | null
  comment?: string | null

  /** @format date-time */
  closedAt?: string | null
  closedBy?: CollaboratorJsonldCashControlRead | null
}

export interface Catalog {
  aggregations?: string[] | null
  sortOrders?: string[] | null

  /** @format iri-reference */
  suggestion?: string | null
  results?: string[]

  /** @example mixed */
  searchType?: string
  search?: string | null

  /** @example true */
  searchInName?: boolean

  /** @example true */
  searchInText?: boolean

  /** @example true */
  searchInType?: boolean

  /** @example true */
  searchInFrench?: boolean

  /** @example true */
  searchInEnglish?: boolean
  commercialOffer?: number | null
  commercialOfferType?: string | null
  isRewarded?: boolean | null
  reward?: number | null
  rewardCategory?: number | null
  family?: number | null
  family_list?: string[]
  brand?: number | null
  brand_list?: string[]
  familySlug?: string | null
  category?: number | null
  category_list?: string[]
  extension?: number | null
  editor?: number | null
  editor_list?: string[]
  theme?: number | null
  theme_list?: string[]
  type?: number | null
  type_list?: string[]
  highlight?: number | null
  highlight_list?: string[]
  age?: number | null
  age_list?: string[]
  players?: number | null
  players_list?: string[]
  time?: string | null
  time_list?: string[]
  rating?: string | null
  rating_list?: string[]
  licenseSlug?: string | null
  collectionNumber?: string | null
  cardType?: number | null
  cardType_list?: string[]

  /** @example or */
  cardTypeListOperator?: string
  cardColor?: number | null
  cardColor_list?: string[]

  /** @example or */
  cardColorListOperator?: string
  cardRarity?: number | null
  cardRarity_list?: string[]
  cardEdition?: number | null
  cardEdition_list?: string[]
  cardGrading?: number | null
  cardGrading_list?: string[]
  feature?: number | null
  feature_list?: string[]

  /** @example or */
  featureListOperator?: string
  manaCost?: number | null

  /** @example equalTo */
  manaCostOperator?: string | null
  power?: number | null

  /** @example equalTo */
  powerOperator?: string
  toughness?: number | null

  /** @example equalTo */
  toughnessOperator?: string
  pitch?: string | null
  pitch_list?: string[]
  isLegendary?: boolean | null
  isSnow?: boolean | null
  isFoil?: boolean | null
  levelMin?: number | null
  levelMax?: number | null
  link?: string | null
  link_list?: string[]
  priceMin?: number | null
  priceMax?: number | null
  isDiscounted?: boolean | null
  isAvailableForDelivery?: boolean | null
  isAvailableForClickAndCollect?: boolean | null
  isAvailableInStore?: boolean | null
  favoriteStore?: number | null
  filters?: string[] | null
  lang?: string | null
  lang_list?: string[]
  sortLang?: string | null
  acceptLanguage?: string
  catalogIdentifier?: string
  cardTypeList?: string[]
  cardColorList?: string[]
  cardRarityList?: string[]
  cardEditionList?: string[]
  cardGradingList?: string[]
  featureList?: string[]
  familyList?: string[]
  brandList?: string[]
  categoryList?: string[]
  editorList?: string[]
  themeList?: string[]
  typeList?: string[]
  highlightList?: string[]
  langList?: string[]
  ageList?: string[]
  playersList?: string[]
  timeList?: string[]
  ratingList?: string[]
  rewarded?: boolean | null
  pitchList?: string[]
  legendary?: boolean | null
  snow?: boolean | null
  discounted?: boolean | null
  availableForDelivery?: boolean | null
  availableForClickAndCollect?: boolean | null
  availableInStore?: boolean | null
  foil?: boolean | null
  linkList?: string[]
}

export interface CatalogJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  aggregations?: string[] | null
  sortOrders?: string[] | null

  /** @format iri-reference */
  suggestion?: string | null
  results?: string[]

  /** @example mixed */
  searchType?: string
  search?: string | null

  /** @example true */
  searchInName?: boolean

  /** @example true */
  searchInText?: boolean

  /** @example true */
  searchInType?: boolean

  /** @example true */
  searchInFrench?: boolean

  /** @example true */
  searchInEnglish?: boolean
  commercialOffer?: number | null
  commercialOfferType?: string | null
  isRewarded?: boolean | null
  reward?: number | null
  rewardCategory?: number | null
  family?: number | null
  family_list?: string[]
  brand?: number | null
  brand_list?: string[]
  familySlug?: string | null
  category?: number | null
  category_list?: string[]
  extension?: number | null
  editor?: number | null
  editor_list?: string[]
  theme?: number | null
  theme_list?: string[]
  type?: number | null
  type_list?: string[]
  highlight?: number | null
  highlight_list?: string[]
  age?: number | null
  age_list?: string[]
  players?: number | null
  players_list?: string[]
  time?: string | null
  time_list?: string[]
  rating?: string | null
  rating_list?: string[]
  licenseSlug?: string | null
  collectionNumber?: string | null
  cardType?: number | null
  cardType_list?: string[]

  /** @example or */
  cardTypeListOperator?: string
  cardColor?: number | null
  cardColor_list?: string[]

  /** @example or */
  cardColorListOperator?: string
  cardRarity?: number | null
  cardRarity_list?: string[]
  cardEdition?: number | null
  cardEdition_list?: string[]
  cardGrading?: number | null
  cardGrading_list?: string[]
  feature?: number | null
  feature_list?: string[]

  /** @example or */
  featureListOperator?: string
  manaCost?: number | null

  /** @example equalTo */
  manaCostOperator?: string | null
  power?: number | null

  /** @example equalTo */
  powerOperator?: string
  toughness?: number | null

  /** @example equalTo */
  toughnessOperator?: string
  pitch?: string | null
  pitch_list?: string[]
  isLegendary?: boolean | null
  isSnow?: boolean | null
  isFoil?: boolean | null
  levelMin?: number | null
  levelMax?: number | null
  link?: string | null
  link_list?: string[]
  priceMin?: number | null
  priceMax?: number | null
  isDiscounted?: boolean | null
  isAvailableForDelivery?: boolean | null
  isAvailableForClickAndCollect?: boolean | null
  isAvailableInStore?: boolean | null
  favoriteStore?: number | null
  filters?: string[] | null
  lang?: string | null
  lang_list?: string[]
  sortLang?: string | null
  acceptLanguage?: string
  catalogIdentifier?: string
  cardTypeList?: string[]
  cardColorList?: string[]
  cardRarityList?: string[]
  cardEditionList?: string[]
  cardGradingList?: string[]
  featureList?: string[]
  familyList?: string[]
  brandList?: string[]
  categoryList?: string[]
  editorList?: string[]
  themeList?: string[]
  typeList?: string[]
  highlightList?: string[]
  langList?: string[]
  ageList?: string[]
  playersList?: string[]
  timeList?: string[]
  ratingList?: string[]
  rewarded?: boolean | null
  pitchList?: string[]
  legendary?: boolean | null
  snow?: boolean | null
  discounted?: boolean | null
  availableForDelivery?: boolean | null
  availableForClickAndCollect?: boolean | null
  availableInStore?: boolean | null
  foil?: boolean | null
  linkList?: string[]
}

export interface CatalogResult {
  /** @format iri-reference */
  card?: string | null

  /** @format iri-reference */
  sealedProduct?: string | null
  identifier?: number
}

export interface CatalogResultJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  card?: string | null

  /** @format iri-reference */
  sealedProduct?: string | null
  identifier?: number
}

export interface CategoryStoryblokStory {
  id?: number

  /** @format iri-reference */
  category?: string
  storyId?: number
}

export interface CategoryStoryblokStoryProductReadItemProductReadGqlPublic {
  id?: number
  category?: ProductCategoryProductReadItemProductReadGqlPublic
  storyId?: number
}

export interface CategoryStoryblokStoryProductReadGqlPublic {
  id?: number
  category?: ProductCategoryProductReadGqlPublic
  storyId?: number
}

export interface CategoryStoryblokStoryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  category?: string
  storyId?: number
}

export interface CategoryStoryblokStoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  category?: ProductCategoryJsonldProductReadItemProductReadGqlPublic
  storyId?: number
}

export interface CategoryStoryblokStoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  category?: ProductCategoryJsonldProductReadGqlPublic
  storyId?: number
}

export interface Collaborator {
  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string
  password?: string

  /** @example true */
  active?: boolean

  /** @format iri-reference */
  mainStore?: string

  /** @format date-time */
  createdAt?: string

  /** @format iri-reference */
  createdBy?: string | null

  /** @format date-time */
  disabledAt?: string | null

  /** @format iri-reference */
  disabledBy?: string | null
  stores?: string[]
  collaboratorRoles?: string[]
  additionalActions?: string[]
  reachableRoles?: string[]
  reachableActions?: string[]
  reachableRolesList?: CollaboratorRoleJsonldUserReadUserReadItem[]
  reachableActionsList?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
  restockPrints?: string[]
  logEntries?: string[]
  warehouseStocks?: string[]
  receivedWarehouseStocks?: string[]
  paybacks?: string[]
  cashedOrders?: string[]
  cashedEventOrders?: string[]

  /** @format iri-reference */
  customerAccount?: string | null
  cashboxName?: string | null
  accessibleStores?: ArrayCollection | null
  userIdentifier?: string
  username?: string
  roles?: string[]
  salt?: string | null
  fullName?: string
  printRestockLogs?: string[]
}

export interface CollaboratorCardPropertiesRead {
  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorCardPropertiesReadCardPropertiesReadItem {
  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorCashControlRead {
  firstname?: string
  lastname?: string
}

export type CollaboratorCollaboratorActivate = object

export type CollaboratorCollaboratorReset = object

export interface CollaboratorCollaboratorWrite {
  /** @format email */
  email?: string
  firstname?: string
  lastname?: string

  /** @format iri-reference */
  mainStore?: string
  stores?: string[]
  collaboratorRoles?: string[]
  additionalActions?: string[]

  /** @format iri-reference */
  customerAccount?: string | null
  cashboxName?: string | null
}

export interface CollaboratorCollaboratorWriteCollaboratorCreate {
  /** @format email */
  email?: string
  firstname?: string
  lastname?: string

  /** @format iri-reference */
  mainStore?: string
  stores?: string[]
  collaboratorRoles?: string[]
  additionalActions?: string[]

  /** @format iri-reference */
  customerAccount?: string | null
  cashboxName?: string | null
}

export interface CollaboratorCustomerRead {
  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorCustomerReadCustomerReadItem {
  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorEventOrderRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorEventRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorEventReadEventReadItem {
  firstname?: string
  lastname?: string
}

export interface CollaboratorGiftCardRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorLitigationRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorLogEntryRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorOrderPrintRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorOrderReadOrderReadItem {
  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorProductCreationRequestRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorRestockPrintRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorRetailerReadCustomerRead {
  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorShippingLabelLogRead {
  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorStockIssueRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorStockReadItemStockRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorStockReadItemStockReadStockStats {
  firstname?: string
  lastname?: string
}

export interface CollaboratorStockReadStockTransferRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorStockReadStockReadItem {
  firstname?: string
  lastname?: string
}

export interface CollaboratorUserRead {
  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string

  /** @example true */
  active?: boolean
  mainStore?: StoreUserRead

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorUserRead | null

  /** @format date-time */
  disabledAt?: string | null
  disabledBy?: CollaboratorUserRead | null
  stores?: StoreUserRead[]
  collaboratorRoles?: CollaboratorRoleUserRead[]
  additionalActions?: CollaboratorRoleActionUserRead[]
  reachableRoles?: string[]
  reachableActions?: string[]
  reachableRolesList?: CollaboratorRoleJsonldUserReadUserReadItem[]
  reachableActionsList?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
  customerAccount?: CustomerUserRead | null
  cashboxName?: string | null
}

export interface CollaboratorUserReadUserReadItem {
  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string

  /** @example true */
  active?: boolean
  mainStore?: StoreUserReadUserReadItem

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorUserReadUserReadItem | null

  /** @format date-time */
  disabledAt?: string | null
  disabledBy?: CollaboratorUserReadUserReadItem | null
  stores?: StoreUserReadUserReadItem[]
  collaboratorRoles?: CollaboratorRoleUserReadUserReadItem[]
  additionalActions?: CollaboratorRoleActionUserReadUserReadItem[]
  reachableRoles?: string[]
  reachableActions?: string[]
  reachableRolesList?: CollaboratorRoleJsonldUserReadUserReadItem[]
  reachableActionsList?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
  customerAccount?: CustomerUserReadUserReadItem | null
  cashboxName?: string | null
}

export interface CollaboratorWarehouseStockRead {
  firstname?: string
  lastname?: string
}

export interface CollaboratorCollaboratorUpdatePasswordInput {
  /** Get the value of oldPassword */
  oldPassword?: string

  /** Get the value of newPassword */
  newPassword?: string
}

export interface CollaboratorJsonld {
  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string
  password?: string

  /** @example true */
  active?: boolean

  /** @format iri-reference */
  mainStore?: string

  /** @format date-time */
  createdAt?: string

  /** @format iri-reference */
  createdBy?: string | null

  /** @format date-time */
  disabledAt?: string | null

  /** @format iri-reference */
  disabledBy?: string | null
  stores?: string[]
  collaboratorRoles?: string[]
  additionalActions?: string[]
  reachableRoles?: string[]
  reachableActions?: string[]
  reachableRolesList?: CollaboratorRoleJsonldUserReadUserReadItem[]
  reachableActionsList?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
  restockPrints?: string[]
  logEntries?: string[]
  warehouseStocks?: string[]
  receivedWarehouseStocks?: string[]
  paybacks?: string[]
  cashedOrders?: string[]
  cashedEventOrders?: string[]

  /** @format iri-reference */
  customerAccount?: string | null
  cashboxName?: string | null
  accessibleStores?: ArrayCollectionJsonld | null
  userIdentifier?: string
  username?: string
  roles?: string[]
  salt?: string | null
  fullName?: string
  printRestockLogs?: string[]
}

export interface CollaboratorJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldCashControlRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export type CollaboratorJsonldCollaboratorActivate = object

export type CollaboratorJsonldCollaboratorReset = object

export interface CollaboratorJsonldCollaboratorWriteCollaboratorCreate {
  /** @format email */
  email?: string
  firstname?: string
  lastname?: string

  /** @format iri-reference */
  mainStore?: string
  stores?: string[]
  collaboratorRoles?: string[]
  additionalActions?: string[]

  /** @format iri-reference */
  customerAccount?: string | null
  cashboxName?: string | null
}

export interface CollaboratorJsonldCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldEventOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldEventRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldGiftCardRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldLitigationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldLogEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldOrderPrintRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldProductCreationRequestRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldRestockPrintRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldRetailerReadCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldShippingLabelLogRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldStockIssueRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldStockReadStockTransferRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorJsonldUserRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string

  /** @example true */
  active?: boolean
  mainStore?: StoreJsonldUserRead

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldUserRead | null

  /** @format date-time */
  disabledAt?: string | null
  disabledBy?: CollaboratorJsonldUserRead | null
  stores?: StoreJsonldUserRead[]
  collaboratorRoles?: CollaboratorRoleJsonldUserRead[]
  additionalActions?: CollaboratorRoleActionJsonldUserRead[]
  reachableRoles?: string[]
  reachableActions?: string[]
  reachableRolesList?: CollaboratorRoleJsonldUserReadUserReadItem[]
  reachableActionsList?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
  customerAccount?: CustomerJsonldUserRead | null
  cashboxName?: string | null
}

export interface CollaboratorJsonldUserReadUserReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example 1 */
  id?: number

  /** @format email */
  email?: string
  firstname?: string
  lastname?: string

  /** @example true */
  active?: boolean
  mainStore?: StoreJsonldUserReadUserReadItem

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldUserReadUserReadItem | null

  /** @format date-time */
  disabledAt?: string | null
  disabledBy?: CollaboratorJsonldUserReadUserReadItem | null
  stores?: StoreJsonldUserReadUserReadItem[]
  collaboratorRoles?: CollaboratorRoleJsonldUserReadUserReadItem[]
  additionalActions?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
  reachableRoles?: string[]
  reachableActions?: string[]
  reachableRolesList?: CollaboratorRoleJsonldUserReadUserReadItem[]
  reachableActionsList?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
  customerAccount?: CustomerJsonldUserReadUserReadItem | null
  cashboxName?: string | null
}

export interface CollaboratorJsonldWarehouseStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  firstname?: string
  lastname?: string
}

export interface CollaboratorRoleCollaboratorActionRead {
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
}

export interface CollaboratorRoleCollaboratorRoleRead {
  id?: number
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: CollaboratorRoleActionCollaboratorRoleRead[]
  inheritedRoles?: CollaboratorRoleCollaboratorRoleRead[]
}

export interface CollaboratorRoleCollaboratorRoleWrite {
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: string[]
  inheritedRoles?: CollaboratorRoleCollaboratorRoleWrite[]
}

export interface CollaboratorRoleUserRead {
  id?: number
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: CollaboratorRoleActionUserRead[]
}

export interface CollaboratorRoleUserReadUserReadItem {
  id?: number
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: CollaboratorRoleActionUserReadUserReadItem[]
}

export interface CollaboratorRoleJsonldCollaboratorActionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
}

export interface CollaboratorRoleJsonldCollaboratorRoleRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: CollaboratorRoleActionJsonldCollaboratorRoleRead[]
  inheritedRoles?: CollaboratorRoleJsonldCollaboratorRoleRead[]
}

export interface CollaboratorRoleJsonldCollaboratorRoleWrite {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: string[]
  inheritedRoles?: CollaboratorRoleJsonldCollaboratorRoleWrite[]
}

export interface CollaboratorRoleJsonldUserRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: CollaboratorRoleActionJsonldUserRead[]
}

export interface CollaboratorRoleJsonldUserReadUserReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  roleName?: Role
  name?: string
  description?: string | null

  /** @example true */
  franchised?: boolean
  collaboratorActions?: CollaboratorRoleActionJsonldUserReadUserReadItem[]
}

export interface CollaboratorRoleActionCollaboratorActionRead {
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  collaboratorRoles?: CollaboratorRoleCollaboratorActionRead[]
  franchised?: boolean
}

export interface CollaboratorRoleActionCollaboratorActionWrite {
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  collaboratorRoles?: string[]
  franchised?: boolean
}

export interface CollaboratorRoleActionCollaboratorRoleRead {
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  franchised?: boolean
}

export interface CollaboratorRoleActionUserRead {
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  franchised?: boolean
}

export interface CollaboratorRoleActionUserReadUserReadItem {
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  franchised?: boolean
}

export interface CollaboratorRoleActionJsonldCollaboratorActionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  collaboratorRoles?: CollaboratorRoleJsonldCollaboratorActionRead[]
  franchised?: boolean
}

export interface CollaboratorRoleActionJsonldCollaboratorActionWrite {
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  collaboratorRoles?: string[]
  franchised?: boolean
}

export interface CollaboratorRoleActionJsonldCollaboratorRoleRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  franchised?: boolean
}

export interface CollaboratorRoleActionJsonldUserRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  franchised?: boolean
}

export interface CollaboratorRoleActionJsonldUserReadUserReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: RoleAction
  displayName?: string
  description?: string | null
  franchised?: boolean
}

export interface Collection {
  /** Checks whether the collection is empty (contains no elements). */
  empty?: boolean

  /** Gets all keys/indices of the collection. */
  keys?: number[]

  /** Gets all values of the collection. */
  values?: string[]
  iterator?: any
}

export type CollectionEventOrderRead = object

export type CollectionLitigationRead = object

export interface CollectionJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** Checks whether the collection is empty (contains no elements). */
  empty?: boolean

  /** Gets all keys/indices of the collection. */
  keys?: number[]

  /** Gets all values of the collection. */
  values?: string[]
  iterator?: any
}

export interface CollectionJsonldEventOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface CollectionJsonldLitigationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface CommercialOffer {
  id?: number
  type?: CommercialOfferType
  defaultSort?: string | null
  translations?: Record<string, CommercialOfferTranslation>
  transName?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  linkedCommercialOffer?: string | null
  hasCards?: boolean
  hasProducts?: boolean

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string | null
  sealedProducts?: string[]
  cards?: string[]
  isPromoOffer?: boolean

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  active?: boolean
  translation?: TranslationInterface[]
  name?: string
  translationLocales?: string[]
}

export interface CommercialOfferProductReadItemProductReadGqlPublic {
  id?: number
  type?: CommercialOfferType
  defaultSort?: string | null
  translations?: Record<string, CommercialOfferTranslationProductReadItemProductReadGqlPublic>
  transName?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  family?: FamilyProductReadItemProductReadGqlPublic | null
  linkedCommercialOffer?: CommercialOfferProductReadItemProductReadGqlPublic | null
  hasCards?: boolean
  hasProducts?: boolean

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string | null
  sealedProducts?: SealedProductProductReadItemProductReadGqlPublic[]
  cards?: CardProductReadItemProductReadGqlPublic[]
  isPromoOffer?: boolean
  name?: string
}

export interface CommercialOfferProductReadGqlPublic {
  id?: number
  type?: CommercialOfferType
  defaultSort?: string | null
  translations?: Record<string, CommercialOfferTranslationProductReadGqlPublic>
  transName?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  family?: FamilyProductReadGqlPublic | null
  linkedCommercialOffer?: CommercialOfferProductReadGqlPublic | null
  hasCards?: boolean
  hasProducts?: boolean

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string | null
  sealedProducts?: SealedProductProductReadGqlPublic[]
  cards?: CardProductReadGqlPublic[]
  isPromoOffer?: boolean
  name?: string
}

export interface CommercialOfferJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  type?: CommercialOfferType
  defaultSort?: string | null
  translations?: Record<string, CommercialOfferTranslationJsonld>
  transName?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  linkedCommercialOffer?: string | null
  hasCards?: boolean
  hasProducts?: boolean

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string | null
  sealedProducts?: string[]
  cards?: string[]
  isPromoOffer?: boolean

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  active?: boolean
  translation?: TranslationInterfaceJsonld[]
  name?: string
  translationLocales?: string[]
}

export interface CommercialOfferJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  type?: CommercialOfferType
  defaultSort?: string | null
  translations?: Record<string, CommercialOfferTranslationJsonldProductReadItemProductReadGqlPublic>
  transName?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  family?: FamilyJsonldProductReadItemProductReadGqlPublic | null
  linkedCommercialOffer?: CommercialOfferJsonldProductReadItemProductReadGqlPublic | null
  hasCards?: boolean
  hasProducts?: boolean

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string | null
  sealedProducts?: SealedProductJsonldProductReadItemProductReadGqlPublic[]
  cards?: CardJsonldProductReadItemProductReadGqlPublic[]
  isPromoOffer?: boolean
  name?: string
}

export interface CommercialOfferJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  type?: CommercialOfferType
  defaultSort?: string | null
  translations?: Record<string, CommercialOfferTranslationJsonldProductReadGqlPublic>
  transName?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  family?: FamilyJsonldProductReadGqlPublic | null
  linkedCommercialOffer?: CommercialOfferJsonldProductReadGqlPublic | null
  hasCards?: boolean
  hasProducts?: boolean

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string | null
  sealedProducts?: SealedProductJsonldProductReadGqlPublic[]
  cards?: CardJsonldProductReadGqlPublic[]
  isPromoOffer?: boolean
  name?: string
}

export interface CommercialOfferTranslation {
  id?: number
  locale?: string

  /** @format iri-reference */
  commercialOffer?: string | null
  name?: string
  metaTitle?: string
  metaDescription?: string
  shortDescription?: string
  longDescription?: string | null
  translatable?: TranslatableInterface | null
}

export interface CommercialOfferTranslationProductReadItemProductReadGqlPublic {
  id?: number
  locale?: string
  name?: string
  metaTitle?: string
  metaDescription?: string
  shortDescription?: string
  longDescription?: string | null
}

export interface CommercialOfferTranslationProductReadGqlPublic {
  id?: number
  locale?: string
  name?: string
  metaTitle?: string
  metaDescription?: string
  shortDescription?: string
  longDescription?: string | null
}

export interface CommercialOfferTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string

  /** @format iri-reference */
  commercialOffer?: string | null
  name?: string
  metaTitle?: string
  metaDescription?: string
  shortDescription?: string
  longDescription?: string | null
  translatable?: TranslatableInterfaceJsonld | null
}

export interface CommercialOfferTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string
  metaTitle?: string
  metaDescription?: string
  shortDescription?: string
  longDescription?: string | null
}

export interface CommercialOfferTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string
  metaTitle?: string
  metaDescription?: string
  shortDescription?: string
  longDescription?: string | null
}

export interface CompanyCompanyRead {
  id?: number
  name?: string
  siret?: string
  rcs?: string
  address?: string
  postalCode?: string
  city?: string
  socialCapital?: number
  legalStatus?: string
  stores?: string[]
}

export interface CompanyStoreRead {
  id?: number
  name?: string
  siret?: string
  rcs?: string
  address?: string
  postalCode?: string
  city?: string
  socialCapital?: number
  legalStatus?: string
}

export interface CompanyStoreReadStoreReadItem {
  id?: number
  name?: string
  siret?: string
  rcs?: string
  address?: string
  postalCode?: string
  city?: string
  socialCapital?: number
  legalStatus?: string
}

export interface CompanyJsonldCompanyRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  siret?: string
  rcs?: string
  address?: string
  postalCode?: string
  city?: string
  socialCapital?: number
  legalStatus?: string
  stores?: string[]
}

export interface CompanyJsonldStoreRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  siret?: string
  rcs?: string
  address?: string
  postalCode?: string
  city?: string
  socialCapital?: number
  legalStatus?: string
}

export interface CompanyJsonldStoreReadStoreReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  siret?: string
  rcs?: string
  address?: string
  postalCode?: string
  city?: string
  socialCapital?: number
  legalStatus?: string
}

export interface Coordinates {
  id?: number
  lat?: number | null
  lng?: number | null
}

export interface CoordinatesProductReadItemProductReadGqlPublic {
  lat?: number | null
  lng?: number | null
}

export interface CoordinatesProductReadGqlPublic {
  lat?: number | null
  lng?: number | null
}

export interface CoordinatesJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  lat?: number | null
  lng?: number | null
}

export interface CoordinatesJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  lat?: number | null
  lng?: number | null
}

export interface CoordinatesJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  lat?: number | null
  lng?: number | null
}

export interface CountryAddressRead {
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryCountryRead {
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
}

export interface CountryOrderRead {
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryOrderReadOrderReadItem {
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryProductReadItemProductReadGqlPublic {
  name: string
  nameEn: string
  transName?: string | null
}

export interface CountryProductReadGqlPublic {
  name: string
  nameEn: string
  transName?: string | null
}

export interface CountryRelayRead {
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryShippingLabelLogRead {
  alpha2?: string
}

export interface CountryJsonldAddressRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryJsonldCountryRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
}

export interface CountryJsonldOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  nameEn: string
  transName?: string | null
}

export interface CountryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  nameEn: string
  transName?: string | null
}

export interface CountryJsonldRelayRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn: string
  transName?: string | null
  alpha2?: string
  shippingZone?: number
}

export interface CountryJsonldShippingLabelLogRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  alpha2?: string
}

export interface CouponCouponRead {
  code: string
  description?: string

  /** @min 0 */
  numberOfUses?: number

  /** @format date-time */
  expiresAt?: string

  /** @example true */
  valid?: boolean

  /** @example true */
  gift?: boolean

  /** @format iri-reference */
  relatedCustomer?: string | null
  fidelityValue?: number
  value?: number
  preorder?: number
  discount?: number
  campaign?: CouponCampaignCouponRead | null
  fidelityModifier?: number
  fidelityMultiplier?: number
  valueMultiplier?: number
  discountMultiplier?: number
  shippingOffered?: boolean
}

export interface CouponCouponWrite {
  code: string
  description?: string

  /** @min 0 */
  numberOfUses?: number

  /** @format date-time */
  expiresAt?: string

  /** @example true */
  valid?: boolean

  /** @example true */
  gift?: boolean

  /** @format iri-reference */
  relatedCustomer?: string | null
  fidelityValue?: number
  value?: number
  preorder?: number
  discount?: number

  /** @format iri-reference */
  campaign?: string | null
}

export interface CouponOrderCouponRead {
  /** @example true */
  gift?: boolean
}

export interface CouponOrderReadOrderReadItem {
  code: string
  description?: string

  /** @example true */
  gift?: boolean
}

export interface CouponPaybackRead {
  code: string
}

export interface CouponJsonldCouponRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  description?: string

  /** @min 0 */
  numberOfUses?: number

  /** @format date-time */
  expiresAt?: string

  /** @example true */
  valid?: boolean

  /** @example true */
  gift?: boolean

  /** @format iri-reference */
  relatedCustomer?: string | null
  fidelityValue?: number
  value?: number
  preorder?: number
  discount?: number
  campaign?: CouponCampaignJsonldCouponRead | null
  fidelityModifier?: number
  fidelityMultiplier?: number
  valueMultiplier?: number
  discountMultiplier?: number
  shippingOffered?: boolean
}

export interface CouponJsonldCouponWrite {
  code: string
  description?: string

  /** @min 0 */
  numberOfUses?: number

  /** @format date-time */
  expiresAt?: string

  /** @example true */
  valid?: boolean

  /** @example true */
  gift?: boolean

  /** @format iri-reference */
  relatedCustomer?: string | null
  fidelityValue?: number
  value?: number
  preorder?: number
  discount?: number

  /** @format iri-reference */
  campaign?: string | null
}

export interface CouponJsonldOrderCouponRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example true */
  gift?: boolean
}

export interface CouponJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
  description?: string

  /** @example true */
  gift?: boolean
}

export interface CouponJsonldPaybackRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  code: string
}

export interface CouponCampaign {
  id?: number
  name?: string

  /** @format date-time */
  expiresAt?: string
  coupons?: string[]
}

export interface CouponCampaignCouponRead {
  id?: number
  name?: string

  /** @format date-time */
  expiresAt?: string
}

export interface CouponCampaignJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string

  /** @format date-time */
  expiresAt?: string
  coupons?: string[]
}

export interface CouponCampaignJsonldCouponRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string

  /** @format date-time */
  expiresAt?: string
}

export interface CreditNote {
  id?: number

  /** @format iri-reference */
  order?: string

  /** @format date-time */
  createdAt?: string
  entries?: CreditNoteEntry[]
  shippingFees?: number
  paymentFees?: number
  commercialGesture?: number
  rentalGesture?: number
  couponDiscount?: number
  cards?: Collection
  sealedProducts?: Collection
}

export interface CreditNoteOrderReadOrderReadItem {
  id?: number

  /** @format date-time */
  createdAt?: string
  entries?: CreditNoteEntryOrderReadOrderReadItem[]
  shippingFees?: number
  paymentFees?: number
  commercialGesture?: number
  rentalGesture?: number
  couponDiscount?: number
}

export type CreditNoteProductReadItemProductReadGqlPublic = object

export type CreditNoteProductReadGqlPublic = object

export interface CreditNoteJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  order?: string

  /** @format date-time */
  createdAt?: string
  entries?: CreditNoteEntryJsonld[]
  shippingFees?: number
  paymentFees?: number
  commercialGesture?: number
  rentalGesture?: number
  couponDiscount?: number
  cards?: CollectionJsonld
  sealedProducts?: CollectionJsonld
}

export interface CreditNoteJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format date-time */
  createdAt?: string
  entries?: CreditNoteEntryJsonldOrderReadOrderReadItem[]
  shippingFees?: number
  paymentFees?: number
  commercialGesture?: number
  rentalGesture?: number
  couponDiscount?: number
}

export interface CreditNoteJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface CreditNoteJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface CreditNoteEntry {
  id?: number
  creditNote?: CreditNote

  /** @format iri-reference */
  product?: SealedProductProductRead

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  price?: number
  buyPrice?: number | null

  /** @format iri-reference */
  tax?: string

  /**
   * @min 0
   * @max 9999999
   */
  totalTax?: number
  typeTax?: 'margin' | 'classic'
}

export interface CreditNoteEntryOrderReadOrderReadItem {
  id?: number
  product?: SealedProductProductRead

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  price?: number
  buyPrice?: number | null

  /** @format iri-reference */
  tax?: string

  /**
   * @min 0
   * @max 9999999
   */
  totalTax?: number
  typeTax?: 'margin' | 'classic'
}

export interface CreditNoteEntryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  creditNote?: CreditNoteJsonld

  /** @format iri-reference */
  product?: SealedProductProductRead

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  price?: number
  buyPrice?: number | null

  /** @format iri-reference */
  tax?: string

  /**
   * @min 0
   * @max 9999999
   */
  totalTax?: number
  typeTax?: 'margin' | 'classic'
}

export interface CreditNoteEntryJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: SealedProductProductRead

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  price?: number
  buyPrice?: number | null

  /** @format iri-reference */
  tax?: string

  /**
   * @min 0
   * @max 9999999
   */
  totalTax?: number
  typeTax?: 'margin' | 'classic'
}

export interface Customer {
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  password?: string | null
  vouchers?: string[]
  fidelityPoints?: string[]
  litigations?: string[]
  orders?: string[]
  reviews?: string[]
  warehouseStocks?: string[]
  cartEntries?: string[]
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  events?: string[]

  /** @format iri-reference */
  playinStore?: string | null

  /** @format iri-reference */
  playinAccountType?: string | null
  relatedCoupons?: string[]

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean

  /** @format iri-reference */
  collaboratorAccount?: string | null
  googleId?: string | null

  /** @format iri-reference */
  preferredStore?: string | null
  playinInternal?: boolean

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
  token?: string | null

  /** @format iri-reference */
  customerId?: string | null

  /** @format iri-reference */
  storeFranchise?: string | null
  totalPointsToConvert?: number | null
  totalVoucherNextLevelPoints?: number | null
  totalMissingFidelityPoints?: number | null
  logEntries?: string[]
  stockAlerts?: string[]
  userIdentifier?: string
  username?: string
  salt?: string | null
  roles?: string[]
  fullName?: string | null
  totalVouchersValue?: number
  orderedFidelityPoints?: Collection
  totalRemainingFidelityPoints?: number
  buybacks?: Collection

  /** @format date-time */
  firstOrderDate?: string
  monthsElapsedSinceFirstOrder?: number

  /** @format iri-reference */
  nextVoucher?: string | null
  totalVouchersNextUse?: number | null

  /** @format date-time */
  expiryDateNextVouchers?: string | null

  /** Renvoie la valeur totale des achats du client, sans compter ses rachats. */
  totalOrdersValue?: number

  /** Renvoie la valeur totale des achats du client, sans compter ses rachats. */
  totalBuybacksValue?: number

  /**
   * Renvoie le nombre total de commandes faites par ce client, sans compter
   * ses rachats.
   */
  ordersCount?: number

  /**
   * Renvoie le nombre total de commandes faites par ce client, sans compter
   * ses rachats.
   */
  buybacksCount?: number

  /** Renvoie le panier moyen du client, sans compter ses rachats. */
  meanCartValue?: number

  /** Renvoie le panier moyen du client, sans compter ses rachats. */
  meanBuybackValue?: number
  sellermania?: boolean
  cardTrader?: boolean
  storeEvents?: string[]
  futureEventOrders?: string[]
  futureEvents?: string[]
  eventsByDate?: string[]
}

export interface CustomerCustomerCreate {
  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  fidelityCardNumber?: string | null
  googleId?: string | null
}

export interface CustomerCustomerPatch {
  vatRegistrationNumber?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  fidelityCardNumber?: string | null

  /** @format iri-reference */
  playinStore?: string | null
  comment?: string | null
  blacklisted?: boolean
}

export interface CustomerCustomerRead {
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreCustomerRead | null
  playinAccountType?: CustomerInternalTypeCustomerRead | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorCustomerRead | null
  googleId?: string | null
  preferredStore?: StoreCustomerRead | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
  fullName?: string | null
}

export interface CustomerCustomerReadCustomerReadItem {
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreCustomerReadCustomerReadItem | null
  playinAccountType?: CustomerInternalTypeCustomerReadCustomerReadItem | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorCustomerReadCustomerReadItem | null
  googleId?: string | null
  preferredStore?: StoreCustomerReadCustomerReadItem | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
  fullName?: string | null
  futureEventOrders?: StoreEventOrderCustomerReadCustomerReadItem[]
}

export type CustomerCustomerRemove = object

export type CustomerCustomerTransformPro = object

export interface CustomerEventCustomerRead {
  id?: number

  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
}

export interface CustomerEventOrderRead {
  id?: number

  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
}

export interface CustomerEventReadEventReadItem {
  id?: number

  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
}

export interface CustomerOrderRead {
  id?: number

  /** @format email */
  email: string
  playinAccountType?: CustomerInternalTypeOrderRead | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  fullName?: string | null
}

export interface CustomerOrderReadOrderReadItem {
  id?: number

  /** @format email */
  email: string
  playinAccountType?: CustomerInternalTypeOrderReadOrderReadItem | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorOrderReadOrderReadItem | null
  preferredStore?: StoreOrderReadOrderReadItem | null
  fullName?: string | null
}

export interface CustomerProductRead {
  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerProductReadItemProductRead {
  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerProductReadItemProductReadGqlPublic {
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
  vouchers?: VoucherProductReadItemProductReadGqlPublic[]
  fidelityPoints?: string[]
  litigations?: string[]
  orders?: OrderProductReadItemProductReadGqlPublic[]
  reviews?: ReviewProductReadItemProductReadGqlPublic[]
  warehouseStocks?: WarehouseStockProductReadItemProductReadGqlPublic[]
  cartEntries?: CartEntryProductReadItemProductReadGqlPublic[]
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null
  country?: CountryProductReadItemProductReadGqlPublic | null
  fidelityCardNumber?: string | null
  preferredStore?: StoreProductReadItemProductReadGqlPublic | null
  token?: string | null
  totalPointsToConvert?: number | null
  totalVoucherNextLevelPoints?: number | null
  totalMissingFidelityPoints?: number | null
  stockAlerts?: StockAlertProductReadItemProductReadGqlPublic[]
  totalVouchersValue?: number
  totalRemainingFidelityPoints?: number
  totalVouchersNextUse?: number | null

  /** @format date-time */
  expiryDateNextVouchers?: string | null
  futureEvents?: FutureEventProductReadItemProductReadGqlPublic[]
  eventsByDate?: string[]
}

export interface CustomerProductReadGqlPublic {
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
  vouchers?: VoucherProductReadGqlPublic[]
  fidelityPoints?: string[]
  litigations?: string[]
  orders?: OrderProductReadGqlPublic[]
  reviews?: ReviewProductReadGqlPublic[]
  warehouseStocks?: WarehouseStockProductReadGqlPublic[]
  cartEntries?: CartEntryProductReadGqlPublic[]
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null
  country?: CountryProductReadGqlPublic | null
  fidelityCardNumber?: string | null
  preferredStore?: StoreProductReadGqlPublic | null
  token?: string | null
  totalPointsToConvert?: number | null
  totalVoucherNextLevelPoints?: number | null
  totalMissingFidelityPoints?: number | null
  stockAlerts?: StockAlertProductReadGqlPublic[]
  totalVouchersValue?: number
  totalRemainingFidelityPoints?: number
  totalVouchersNextUse?: number | null

  /** @format date-time */
  expiryDateNextVouchers?: string | null
  futureEvents?: FutureEventProductReadGqlPublic[]
  eventsByDate?: string[]
}

export interface CustomerProductReadProductStats {
  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerStockEntryRead {
  fullName?: string | null
}

export interface CustomerStockIssueRead {
  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerStockRead {
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerStockReadItemStockRead {
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerStockReadItemStockReadStockStats {
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerStockReadStockTransferRead {
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerStockReadStockReadItem {
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerUserRead {
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerUserReadUserReadItem {
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerWarehouseStockEntryRead {
  fullName?: string | null
}

export interface CustomerWarehouseStockRead {
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerJsonld {
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  password?: string | null
  vouchers?: string[]
  fidelityPoints?: string[]
  litigations?: string[]
  orders?: string[]
  reviews?: string[]
  warehouseStocks?: string[]
  cartEntries?: string[]
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  events?: string[]

  /** @format iri-reference */
  playinStore?: string | null

  /** @format iri-reference */
  playinAccountType?: string | null
  relatedCoupons?: string[]

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean

  /** @format iri-reference */
  collaboratorAccount?: string | null
  googleId?: string | null

  /** @format iri-reference */
  preferredStore?: string | null
  playinInternal?: boolean

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
  token?: string | null

  /** @format iri-reference */
  customerId?: string | null

  /** @format iri-reference */
  storeFranchise?: string | null
  totalPointsToConvert?: number | null
  totalVoucherNextLevelPoints?: number | null
  totalMissingFidelityPoints?: number | null
  logEntries?: string[]
  stockAlerts?: string[]
  userIdentifier?: string
  username?: string
  salt?: string | null
  roles?: string[]
  fullName?: string | null
  totalVouchersValue?: number
  orderedFidelityPoints?: CollectionJsonld
  totalRemainingFidelityPoints?: number
  buybacks?: CollectionJsonld

  /** @format date-time */
  firstOrderDate?: string
  monthsElapsedSinceFirstOrder?: number

  /** @format iri-reference */
  nextVoucher?: string | null
  totalVouchersNextUse?: number | null

  /** @format date-time */
  expiryDateNextVouchers?: string | null

  /** Renvoie la valeur totale des achats du client, sans compter ses rachats. */
  totalOrdersValue?: number

  /** Renvoie la valeur totale des achats du client, sans compter ses rachats. */
  totalBuybacksValue?: number

  /**
   * Renvoie le nombre total de commandes faites par ce client, sans compter
   * ses rachats.
   */
  ordersCount?: number

  /**
   * Renvoie le nombre total de commandes faites par ce client, sans compter
   * ses rachats.
   */
  buybacksCount?: number

  /** Renvoie le panier moyen du client, sans compter ses rachats. */
  meanCartValue?: number

  /** Renvoie le panier moyen du client, sans compter ses rachats. */
  meanBuybackValue?: number
  sellermania?: boolean
  cardTrader?: boolean
  storeEvents?: string[]
  futureEventOrders?: string[]
  futureEvents?: string[]
  eventsByDate?: string[]
}

export interface CustomerJsonldCustomerCreate {
  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  fidelityCardNumber?: string | null
  googleId?: string | null
}

export interface CustomerJsonldCustomerRead {
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreJsonldCustomerRead | null
  playinAccountType?: CustomerInternalTypeJsonldCustomerRead | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorJsonldCustomerRead | null
  googleId?: string | null
  preferredStore?: StoreJsonldCustomerRead | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
  fullName?: string | null
}

export interface CustomerJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreJsonldCustomerReadCustomerReadItem | null
  playinAccountType?: CustomerInternalTypeJsonldCustomerReadCustomerReadItem | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorJsonldCustomerReadCustomerReadItem | null
  googleId?: string | null
  preferredStore?: StoreJsonldCustomerReadCustomerReadItem | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
  fullName?: string | null
  futureEventOrders?: StoreEventOrderJsonldCustomerReadCustomerReadItem[]
}

export type CustomerJsonldCustomerRemove = object

export type CustomerJsonldCustomerTransformPro = object

export interface CustomerJsonldEventCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldEventOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  deleted?: boolean
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  playinAccountType?: CustomerInternalTypeJsonldOrderRead | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  fullName?: string | null
}

export interface CustomerJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  playinAccountType?: CustomerInternalTypeJsonldOrderReadOrderReadItem | null

  /** @example CUSTOMER */
  accountType?: CustomerType
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorJsonldOrderReadOrderReadItem | null
  preferredStore?: StoreJsonldOrderReadOrderReadItem | null
  fullName?: string | null
}

export interface CustomerJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
  vouchers?: VoucherJsonldProductReadItemProductReadGqlPublic[]
  fidelityPoints?: string[]
  litigations?: string[]
  orders?: OrderJsonldProductReadItemProductReadGqlPublic[]
  reviews?: ReviewJsonldProductReadItemProductReadGqlPublic[]
  warehouseStocks?: WarehouseStockJsonldProductReadItemProductReadGqlPublic[]
  cartEntries?: CartEntryJsonldProductReadItemProductReadGqlPublic[]
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null
  country?: CountryJsonldProductReadItemProductReadGqlPublic | null
  fidelityCardNumber?: string | null
  preferredStore?: StoreJsonldProductReadItemProductReadGqlPublic | null
  token?: string | null
  totalPointsToConvert?: number | null
  totalVoucherNextLevelPoints?: number | null
  totalMissingFidelityPoints?: number | null
  stockAlerts?: StockAlertJsonldProductReadItemProductReadGqlPublic[]
  totalVouchersValue?: number
  totalRemainingFidelityPoints?: number
  totalVouchersNextUse?: number | null

  /** @format date-time */
  expiryDateNextVouchers?: string | null
  futureEvents?: FutureEventJsonldProductReadItemProductReadGqlPublic[]
  eventsByDate?: string[]
}

export interface CustomerJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
  vouchers?: VoucherJsonldProductReadGqlPublic[]
  fidelityPoints?: string[]
  litigations?: string[]
  orders?: OrderJsonldProductReadGqlPublic[]
  reviews?: ReviewJsonldProductReadGqlPublic[]
  warehouseStocks?: WarehouseStockJsonldProductReadGqlPublic[]
  cartEntries?: CartEntryJsonldProductReadGqlPublic[]
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null
  country?: CountryJsonldProductReadGqlPublic | null
  fidelityCardNumber?: string | null
  preferredStore?: StoreJsonldProductReadGqlPublic | null
  token?: string | null
  totalPointsToConvert?: number | null
  totalVoucherNextLevelPoints?: number | null
  totalMissingFidelityPoints?: number | null
  stockAlerts?: StockAlertJsonldProductReadGqlPublic[]
  totalVouchersValue?: number
  totalRemainingFidelityPoints?: number
  totalVouchersNextUse?: number | null

  /** @format date-time */
  expiryDateNextVouchers?: string | null
  futureEvents?: FutureEventJsonldProductReadGqlPublic[]
  eventsByDate?: string[]
}

export interface CustomerJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  fullName?: string | null
}

export interface CustomerJsonldStockIssueRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerJsonldStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerJsonldStockReadStockTransferRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerJsonldUserRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldUserReadUserReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  firstname?: string
  lastname?: string
}

export interface CustomerJsonldWarehouseStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  fullName?: string | null
}

export interface CustomerJsonldWarehouseStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format email */
  email: string
  fullName?: string | null
}

export interface CustomerIdCustomerIdRead {
  id?: number
  firstName?: string
  lastName?: string
  valid?: boolean

  /** @format date-time */
  uploadedAt?: string
  imageUrl?: string | null
}

export interface CustomerIdCustomerIdWrite {
  firstName?: string
  lastName?: string

  /**
   * Un scan d'une pièce d'identité, au format JPG, PDF ou PNG, de moins
   * de 3Mo.
   * @format binary
   */
  image?: File | null
}

export interface CustomerIdJsonldCustomerIdRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  firstName?: string
  lastName?: string
  valid?: boolean

  /** @format date-time */
  uploadedAt?: string
  imageUrl?: string | null
}

export interface CustomerInternalTypeCustomerInternalTypeRead {
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface CustomerInternalTypeCustomerRead {
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface CustomerInternalTypeCustomerReadCustomerReadItem {
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface CustomerInternalTypeOrderRead {
  codeName?: string
}

export interface CustomerInternalTypeOrderReadOrderReadItem {
  codeName?: string
}

export interface CustomerInternalTypeRetailerReadCustomerRead {
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface CustomerInternalTypeJsonldCustomerInternalTypeRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface CustomerInternalTypeJsonldCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface CustomerInternalTypeJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface CustomerInternalTypeJsonldOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  codeName?: string
}

export interface CustomerInternalTypeJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  codeName?: string
}

export interface CustomerInternalTypeJsonldRetailerReadCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  codeName?: string
  autoGenerated?: boolean
}

export interface DeckDeckRead {
  name?: string
  nameEn?: string
}

export interface DeckDeckReadDeckReadItem {
  name?: string
  nameEn?: string
}

export interface DeckProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  linkedProduct?: SealedProductProductReadItemProductReadGqlPublic | null
  family?: FamilyProductReadItemProductReadGqlPublic
  entries?: DeckEntryProductReadItemProductReadGqlPublic[]
  translations?: DeckTranslationProductReadItemProductReadGqlPublic[]
  cardCount?: number
  cardSideboardCount?: number
  transName?: string | null
  transDescription?: string | null
  groupEntries?: DeckGroupEntryProductReadItemProductReadGqlPublic[]
}

export interface DeckProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  linkedProduct?: SealedProductProductReadGqlPublic | null
  family?: FamilyProductReadGqlPublic
  entries?: DeckEntryProductReadGqlPublic[]
  translations?: DeckTranslationProductReadGqlPublic[]
  cardCount?: number
  cardSideboardCount?: number
  transName?: string | null
  transDescription?: string | null
  groupEntries?: DeckGroupEntryProductReadGqlPublic[]
}

export interface DeckJsonldDeckRead {
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface DeckJsonldDeckReadDeckReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface DeckJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  linkedProduct?: SealedProductJsonldProductReadItemProductReadGqlPublic | null
  family?: FamilyJsonldProductReadItemProductReadGqlPublic
  entries?: DeckEntryJsonldProductReadItemProductReadGqlPublic[]
  translations?: DeckTranslationJsonldProductReadItemProductReadGqlPublic[]
  cardCount?: number
  cardSideboardCount?: number
  transName?: string | null
  transDescription?: string | null
  groupEntries?: DeckGroupEntryJsonldProductReadItemProductReadGqlPublic[]
}

export interface DeckJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  linkedProduct?: SealedProductJsonldProductReadGqlPublic | null
  family?: FamilyJsonldProductReadGqlPublic
  entries?: DeckEntryJsonldProductReadGqlPublic[]
  translations?: DeckTranslationJsonldProductReadGqlPublic[]
  cardCount?: number
  cardSideboardCount?: number
  transName?: string | null
  transDescription?: string | null
  groupEntries?: DeckGroupEntryJsonldProductReadGqlPublic[]
}

export type DeckEntryDeckEntryRead = object

export type DeckEntryDeckEntryReadDeckEntryReadItem = object

export interface DeckEntryProductReadItemProductReadGqlPublic {
  card?: CardProductReadItemProductReadGqlPublic

  /** @min 0 */
  quantity?: number

  /** @example false */
  sideboard?: boolean
}

export interface DeckEntryProductReadGqlPublic {
  card?: CardProductReadGqlPublic

  /** @min 0 */
  quantity?: number

  /** @example false */
  sideboard?: boolean
}

export interface DeckEntryJsonldDeckEntryRead {
  '@id'?: string
  '@type'?: string
}

export interface DeckEntryJsonldDeckEntryReadDeckEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface DeckEntryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  card?: CardJsonldProductReadItemProductReadGqlPublic

  /** @min 0 */
  quantity?: number

  /** @example false */
  sideboard?: boolean
}

export interface DeckEntryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  card?: CardJsonldProductReadGqlPublic

  /** @min 0 */
  quantity?: number

  /** @example false */
  sideboard?: boolean
}

export interface DeckGroupEntry {
  transName?: string
  entries?: string[]
  identifiers?: string
}

export interface DeckGroupEntryProductReadItemProductReadGqlPublic {
  transName?: string
  entries?: DeckEntryProductReadItemProductReadGqlPublic[]
}

export interface DeckGroupEntryProductReadGqlPublic {
  transName?: string
  entries?: DeckEntryProductReadGqlPublic[]
}

export interface DeckGroupEntryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  transName?: string
  entries?: string[]
  identifiers?: string
}

export interface DeckGroupEntryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  transName?: string
  entries?: DeckEntryJsonldProductReadItemProductReadGqlPublic[]
}

export interface DeckGroupEntryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  transName?: string
  entries?: DeckEntryJsonldProductReadGqlPublic[]
}

export interface DeckTranslationProductReadItemProductReadGqlPublic {
  name?: string | null
  description?: string | null
}

export interface DeckTranslationProductReadGqlPublic {
  name?: string | null
  description?: string | null
}

export interface DeckTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string | null
  description?: string | null
}

export interface DeckTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string | null
  description?: string | null
}

export interface DepositDepositEntryRead {
  id?: number
  depositary?: DepositaryDepositEntryRead
}

export interface DepositDepositRead {
  id?: number

  /** @format date-time */
  createdAt?: string
  comment?: string

  /** @format iri-reference */
  depositary?: string
  depositEntries?: DepositEntryDepositRead[]
}

export interface DepositJsonldDepositEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  depositary?: DepositaryJsonldDepositEntryRead
}

export interface DepositJsonldDepositRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format date-time */
  createdAt?: string
  comment?: string

  /** @format iri-reference */
  depositary?: string
  depositEntries?: DepositEntryJsonldDepositRead[]
}

export interface DepositEntryDepositEntryRead {
  deposit?: DepositDepositEntryRead

  /** @format iri-reference */
  product?: string

  /** @example 1 */
  quantity?: number
  soldQuantity?: number

  /**
   * @min 0
   * @max 100
   */
  reimbursementRate?: number
  buyPrice?: number

  /** @format date-time */
  createdAt?: string
  openingEntry?: boolean
}

export interface DepositEntryDepositRead {
  /** @format iri-reference */
  product?: string

  /** @example 1 */
  quantity?: number
  soldQuantity?: number

  /**
   * @min 0
   * @max 100
   */
  reimbursementRate?: number
  buyPrice?: number

  /** @format date-time */
  createdAt?: string
  openingEntry?: boolean
}

export interface DepositEntryJsonldDepositEntryRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  deposit?: DepositJsonldDepositEntryRead

  /** @format iri-reference */
  product?: string

  /** @example 1 */
  quantity?: number
  soldQuantity?: number

  /**
   * @min 0
   * @max 100
   */
  reimbursementRate?: number
  buyPrice?: number

  /** @format date-time */
  createdAt?: string
  openingEntry?: boolean
}

export interface DepositEntryJsonldDepositRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  product?: string

  /** @example 1 */
  quantity?: number
  soldQuantity?: number

  /**
   * @min 0
   * @max 100
   */
  reimbursementRate?: number
  buyPrice?: number

  /** @format date-time */
  createdAt?: string
  openingEntry?: boolean
}

export interface DepositaryDepositEntryRead {
  id?: number
  lastname?: string
  firstname?: string
}

export interface DepositaryDepositaryRead {
  id?: number
  lastname?: string
  firstname?: string
  address?: string
  phone?: string
  city?: string
  zipCode?: string
  deposits?: string[]
}

export interface DepositaryJsonldDepositEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  lastname?: string
  firstname?: string
}

export interface DepositaryJsonldDepositaryRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  lastname?: string
  firstname?: string
  address?: string
  phone?: string
  city?: string
  zipCode?: string
  deposits?: string[]
}

export interface EditorDistributor {
  id?: number

  /** @format iri-reference */
  editor?: string

  /** @format iri-reference */
  distributor?: string
  priority?: number
}

export interface EditorDistributorJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  editor?: string

  /** @format iri-reference */
  distributor?: string
  priority?: number
}

export interface EmailTypeEmailTypeRead {
  id?: number
  title?: string
  subject?: string
  sender?: string
  message?: string
}

export interface EmailTypeJsonldEmailTypeRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  title?: string
  subject?: string
  sender?: string
  message?: string
}

export interface FamilyCardPropertiesRead {
  codeName: FamilyName
}

export interface FamilyCardPropertiesReadCardPropertiesReadItem {
  codeName: FamilyName
}

export interface FamilyCardRarityRead {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyFamilyRead {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  shortName?: string
  shortNameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  cards?: boolean
  hasUnitCards?: boolean
  productCategories?: string[]

  /** @example true */
  isHighlighted?: boolean

  /**
   * @min 0
   * @max 3
   * @example 2
   */
  status?: number
  stores?: string[]
  warehouseSorting?: number
  tcg?: boolean
  playset?: number | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
  statusName?: string
  old?: boolean
  isHidden?: boolean
  isDefault?: boolean
  isVisible?: boolean
}

export interface FamilyOrderEntryRead {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyOrderEntryReadOrderEntryReadItem {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyOrderReadOrderReadItem {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyProductCreationRequestRead {
  codeName: FamilyName
}

export interface FamilyProductRead {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyProductReadItemProductRead {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyProductReadItemProductReadGqlPublic {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  pages?: FamilyPageProductReadItemProductReadGqlPublic[]
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
  doodleUrl?: string | null
  iconUrl?: string | null
  imageUrl?: string | null
}

export interface FamilyProductReadGqlPublic {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  pages?: FamilyPageProductReadGqlPublic[]
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
  doodleUrl?: string | null
  iconUrl?: string | null
  imageUrl?: string | null
}

export interface FamilyProductReadProductReadItemProductStats {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyProductReadProductStats {
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyStockReadItemStockRead {
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyStockReadItemStockReadStockStats {
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyStockReadStockReadItem {
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  codeName: FamilyName
}

export interface FamilyJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  codeName: FamilyName
}

export interface FamilyJsonldCardRarityRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldFamilyRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  shortName?: string
  shortNameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  cards?: boolean
  hasUnitCards?: boolean
  productCategories?: string[]

  /** @example true */
  isHighlighted?: boolean

  /**
   * @min 0
   * @max 3
   * @example 2
   */
  status?: number
  stores?: string[]
  warehouseSorting?: number
  tcg?: boolean
  playset?: number | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
  statusName?: string
  old?: boolean
  isHidden?: boolean
  isDefault?: boolean
  isVisible?: boolean
}

export interface FamilyJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldProductCreationRequestRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  codeName: FamilyName
}

export interface FamilyJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  pages?: FamilyPageJsonldProductReadItemProductReadGqlPublic[]
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
  doodleUrl?: string | null
  iconUrl?: string | null
  imageUrl?: string | null
}

export interface FamilyJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  pages?: FamilyPageJsonldProductReadGqlPublic[]
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
  doodleUrl?: string | null
  iconUrl?: string | null
  imageUrl?: string | null
}

export interface FamilyJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  transShortName?: string | null
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  codeName: FamilyName
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  nameEn?: string
  slug: FamilySlug
  slugEn: FamilySlug
  doodleRelativeUrl?: string | null
  iconRelativeUrl?: string | null
  imageRelativeUrl?: string | null
}

export interface FamilyPage {
  id?: number
  name?: FamilyPageName
  translations?: FamilyPageTranslation[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null

  /** @format iri-reference */
  family?: string
  currentLocale?: string | null
  fallbackLocale?: string | null
  translation?: TranslationInterface[]
  translationLocales?: string[]
}

export interface FamilyPageProductReadItemProductReadGqlPublic {
  id?: number
  name?: FamilyPageName
  translations?: FamilyPageTranslationProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  family?: FamilyProductReadItemProductReadGqlPublic
}

export interface FamilyPageProductReadGqlPublic {
  id?: number
  name?: FamilyPageName
  translations?: FamilyPageTranslationProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  family?: FamilyProductReadGqlPublic
}

export interface FamilyPageJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: FamilyPageName
  translations?: FamilyPageTranslationJsonld[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null

  /** @format iri-reference */
  family?: string
  currentLocale?: string | null
  fallbackLocale?: string | null
  translation?: TranslationInterfaceJsonld[]
  translationLocales?: string[]
}

export interface FamilyPageJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: FamilyPageName
  translations?: FamilyPageTranslationJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  family?: FamilyJsonldProductReadItemProductReadGqlPublic
}

export interface FamilyPageJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: FamilyPageName
  translations?: FamilyPageTranslationJsonldProductReadGqlPublic[]
  transName?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  family?: FamilyJsonldProductReadGqlPublic
}

export interface FamilyPageTranslation {
  id?: number

  /** @format iri-reference */
  familyPage: string
  locale?: string
  name?: string | null
  metaTitle?: string | null
  metaDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  translatable?: TranslatableInterface | null
}

export interface FamilyPageTranslationProductReadItemProductReadGqlPublic {
  name?: string | null
  metaTitle?: string | null
  metaDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface FamilyPageTranslationProductReadGqlPublic {
  name?: string | null
  metaTitle?: string | null
  metaDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface FamilyPageTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  familyPage: string
  locale?: string
  name?: string | null
  metaTitle?: string | null
  metaDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  translatable?: TranslatableInterfaceJsonld | null
}

export interface FamilyPageTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string | null
  metaTitle?: string | null
  metaDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface FamilyPageTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string | null
  metaTitle?: string | null
  metaDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface FeatureFeatureRead {
  id?: number
  featureGroup?: FeatureGroupFeatureRead | null
  name?: string
  nameEn?: string
  transName?: string | null
  position?: number | null
  iconUrl?: string | null
  absoluteIconUrl?: string | null
}

export interface FeatureProductReadItemProductReadGqlPublic {
  featureGroup?: FeatureGroupProductReadItemProductReadGqlPublic | null
  name?: string
  nameEn?: string
  transName?: string | null
  position?: number | null
  iconUrl?: string | null
  absoluteIconUrl?: string | null
}

export interface FeatureProductReadGqlPublic {
  featureGroup?: FeatureGroupProductReadGqlPublic | null
  name?: string
  nameEn?: string
  transName?: string | null
  position?: number | null
  iconUrl?: string | null
  absoluteIconUrl?: string | null
}

export interface FeatureJsonldFeatureRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  featureGroup?: FeatureGroupJsonldFeatureRead | null
  name?: string
  nameEn?: string
  transName?: string | null
  position?: number | null
  iconUrl?: string | null
  absoluteIconUrl?: string | null
}

export interface FeatureJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  featureGroup?: FeatureGroupJsonldProductReadItemProductReadGqlPublic | null
  name?: string
  nameEn?: string
  transName?: string | null
  position?: number | null
  iconUrl?: string | null
  absoluteIconUrl?: string | null
}

export interface FeatureJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  featureGroup?: FeatureGroupJsonldProductReadGqlPublic | null
  name?: string
  nameEn?: string
  transName?: string | null
  position?: number | null
  iconUrl?: string | null
  absoluteIconUrl?: string | null
}

export interface FeatureGroupFeatureGroupRead {
  id?: number
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FeatureGroupFeatureRead {
  id?: number
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FeatureGroupProductReadItemProductReadGqlPublic {
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FeatureGroupProductReadGqlPublic {
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FeatureGroupJsonldFeatureGroupRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FeatureGroupJsonldFeatureRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FeatureGroupJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FeatureGroupJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
  slug?: string | null
  transName?: string | null
}

export interface FidelityCode {
  id?: number
  code?: string
  value?: number

  /** @format iri-reference */
  relatedOrder?: string

  /** @format date-time */
  expiresAt?: string

  /** @example true */
  valid?: boolean
}

export interface FidelityCodeJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  code?: string
  value?: number

  /** @format iri-reference */
  relatedOrder?: string

  /** @format date-time */
  expiresAt?: string

  /** @example true */
  valid?: boolean
}

export interface FidelityPoint {
  id?: number

  /** @format iri-reference */
  store?: string | null

  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  createdAt?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @example true */
  valid?: boolean

  /** @min 0 */
  value?: number
  consumed?: number
  comment?: string
  category?: FidelityPointCategory

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  linkedStoreEvent?: string | null
  remainingValue?: number
  used?: boolean
  active?: boolean
  waiting?: boolean
}

export interface FidelityPointFidelityPointRead {
  id?: number
  store?: StoreFidelityPointRead | null

  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  createdAt?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @example true */
  valid?: boolean

  /** @min 0 */
  value?: number
  consumed?: number
  comment?: string
  category?: FidelityPointCategory
  used?: boolean
  active?: boolean
  waiting?: boolean
}

export interface FidelityPointFidelityPointWrite {
  /** @format iri-reference */
  store?: string | null

  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @min 0 */
  value?: number
  comment?: string
  category?: FidelityPointCategory
}

export interface FidelityPointOrderReadOrderReadItem {
  /** @format date-time */
  createdAt?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @example true */
  valid?: boolean

  /** @min 0 */
  value?: number
  consumed?: number
  comment?: string
}

export interface FidelityPointJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  store?: string | null

  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  createdAt?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @example true */
  valid?: boolean

  /** @min 0 */
  value?: number
  consumed?: number
  comment?: string
  category?: FidelityPointCategory

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  linkedStoreEvent?: string | null
  remainingValue?: number
  used?: boolean
  active?: boolean
  waiting?: boolean
}

export interface FidelityPointJsonldFidelityPointRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldFidelityPointRead | null

  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  createdAt?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @example true */
  valid?: boolean

  /** @min 0 */
  value?: number
  consumed?: number
  comment?: string
  category?: FidelityPointCategory
  used?: boolean
  active?: boolean
  waiting?: boolean
}

export interface FidelityPointJsonldFidelityPointWrite {
  /** @format iri-reference */
  store?: string | null

  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @min 0 */
  value?: number
  comment?: string
  category?: FidelityPointCategory
}

export interface FidelityPointJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format date-time */
  createdAt?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /** @example true */
  valid?: boolean

  /** @min 0 */
  value?: number
  consumed?: number
  comment?: string
}

export interface FidelityPointVoucher {
  fidelityPoints?: number
  voucherValue?: number
}

export interface FidelityPointVoucherJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  fidelityPoints?: number
  voucherValue?: number
}

export interface FutureEvent {
  id?: number

  /** @format iri-reference */
  event?: string

  /** @format date-time */
  orderedAt?: string
  paidPrice?: number
}

export interface FutureEventProductReadItemProductReadGqlPublic {
  id?: number
  event?: StoreEventProductReadItemProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  paidPrice?: number
}

export interface FutureEventProductReadGqlPublic {
  id?: number
  event?: StoreEventProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  paidPrice?: number
}

export interface FutureEventJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  event?: string

  /** @format date-time */
  orderedAt?: string
  paidPrice?: number
}

export interface FutureEventJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  event?: StoreEventJsonldProductReadItemProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  paidPrice?: number
}

export interface FutureEventJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  event?: StoreEventJsonldProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  paidPrice?: number
}

export interface GiftCardGiftCardActivate {
  /** @format iri-reference */
  store?: string | null
}

export interface GiftCardGiftCardRead {
  id?: number

  /** @pattern ^(\S+)$ */
  ean128: string
  active?: boolean
  used?: boolean

  /**
   * @min 0
   * @example 20
   */
  value?: number

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  activatedAt?: string | null

  /** @format date-time */
  usedAt?: string | null

  /** @format iri-reference */
  store?: string | null
  activatedBy?: CollaboratorGiftCardRead | null
}

export interface GiftCardJsonldGiftCardActivate {
  /** @format iri-reference */
  store?: string | null
}

export interface GiftCardJsonldGiftCardRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @pattern ^(\S+)$ */
  ean128: string
  active?: boolean
  used?: boolean

  /**
   * @min 0
   * @example 20
   */
  value?: number

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  activatedAt?: string | null

  /** @format date-time */
  usedAt?: string | null

  /** @format iri-reference */
  store?: string | null
  activatedBy?: CollaboratorJsonldGiftCardRead | null
}

export interface HiboutikStore {
  id?: number
  name?: string

  /** @format iri-reference */
  playinStore?: string | null

  /** @format iri-reference */
  defaultShipping?: string | null
  hiboutikStoreId?: number
}

export interface HiboutikStoreJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string

  /** @format iri-reference */
  playinStore?: string | null

  /** @format iri-reference */
  defaultShipping?: string | null
  hiboutikStoreId?: number
}

export interface InventoryTypeInventoryTypeRead {
  id?: number
  name?: string
  ordering?: number
  description?: string | null
}

export interface InventoryTypeJsonldInventoryTypeRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  ordering?: number
  description?: string | null
}

export interface Lang {
  id?: number
  name?: string
  nameEn?: string
  mkmId?: number
  code?: string
  ietf?: string
  isoCode?: string | null
  translations?: LangTranslation[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  flagIconUrl?: string[]
  translation?: LangTranslation
  translationLocales?: string[]
}

export interface LangOrderEntryRead {
  name?: string
  code?: string
  isoCode?: string | null
}

export interface LangOrderEntryReadOrderEntryReadItem {
  name?: string
  code?: string
  isoCode?: string | null
}

export interface LangOrderReadOrderReadItem {
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangProductRead {
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangProductReadItemProductRead {
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  transName?: string | null
  flagIconUrl?: string[]
}

export interface LangProductReadGqlPublic {
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  transName?: string | null
  flagIconUrl?: string[]
}

export interface LangProductReadProductReadItemProductStats {
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangProductReadProductStats {
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  nameEn?: string
  mkmId?: number
  code?: string
  ietf?: string
  isoCode?: string | null
  translations?: LangTranslationJsonld[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  flagIconUrl?: string[]
  translation?: LangTranslationJsonld
  translationLocales?: string[]
}

export interface LangJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  code?: string
  isoCode?: string | null
}

export interface LangJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  code?: string
  isoCode?: string | null
}

export interface LangJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  transName?: string | null
  flagIconUrl?: string[]
}

export interface LangJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  transName?: string | null
  flagIconUrl?: string[]
}

export interface LangJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mkmId?: number
  code?: string
  isoCode?: string | null
  flagIconUrl?: string[]
}

export interface LangTranslation {
  id?: number
  locale?: string
  name?: string | null

  /** @format iri-reference */
  lang?: string
  translatable?: TranslatableInterface | null
}

export interface LangTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null

  /** @format iri-reference */
  lang?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface LicenseLicenseRead {
  id?: number
  slug?: string
  translations?: LicenseTranslationLicenseRead[]
  transName?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
}

export interface LicenseJsonldLicenseRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  slug?: string
  translations?: LicenseTranslationJsonldLicenseRead[]
  transName?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
}

export interface LicenseStoryblokStory {
  id?: number

  /** @format iri-reference */
  license?: string
  storyId?: number
}

export interface LicenseStoryblokStoryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  license?: string
  storyId?: number
}

export interface LicenseTranslation {
  id?: number
  locale?: string

  /** @format iri-reference */
  license?: string
  name: string
  shortName: string
  metaTitle: string
  metaDescription: string
  shortDescription: string
  longDescription?: string
  translatable?: TranslatableInterface | null
}

export interface LicenseTranslationLicenseRead {
  id?: number
  locale?: string
  name: string
  metaTitle: string
  metaDescription: string
  shortDescription: string
  longDescription?: string
}

export interface LicenseTranslationJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  locale?: string

  /** @format iri-reference */
  license?: string
  name: string
  shortName: string
  metaTitle: string
  metaDescription: string
  shortDescription: string
  longDescription?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface LicenseTranslationJsonldLicenseRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name: string
  metaTitle: string
  metaDescription: string
  shortDescription: string
  longDescription?: string
}

export interface Litigation {
  id?: number

  /** @format iri-reference */
  customer?: string | null

  /** @format iri-reference */
  cardPreparer?: string | null

  /** @format iri-reference */
  productPreparer?: string | null

  /** @format iri-reference */
  cardPacker?: string | null

  /** @format iri-reference */
  productPacker?: string | null

  /** @format iri-reference */
  sender?: string | null

  /** @format date-time */
  issuedAt?: string

  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  relatedOrder?: string | null

  /** @format iri-reference */
  packer?: string | null
  relatedCollaborators?: Collection
}

export type LitigationLitigationPatch = object

export interface LitigationLitigationRead {
  id?: number
  cardPreparer?: CollaboratorLitigationRead | null
  productPreparer?: CollaboratorLitigationRead | null
  cardPacker?: CollaboratorLitigationRead | null
  productPacker?: CollaboratorLitigationRead | null
  sender?: CollaboratorLitigationRead | null

  /** @format date-time */
  issuedAt?: string
  category?: LitigationCategoryLitigationRead | null
  relatedOrder?: OrderLitigationRead | null
  relatedCollaborators?: CollectionLitigationRead
}

export interface LitigationLitigationWrite {
  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  relatedOrder?: string | null
}

export interface LitigationOrderReadOrderReadItem {
  /** @format date-time */
  issuedAt?: string
  category?: LitigationCategoryOrderReadOrderReadItem | null
}

export interface LitigationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  customer?: string | null

  /** @format iri-reference */
  cardPreparer?: string | null

  /** @format iri-reference */
  productPreparer?: string | null

  /** @format iri-reference */
  cardPacker?: string | null

  /** @format iri-reference */
  productPacker?: string | null

  /** @format iri-reference */
  sender?: string | null

  /** @format date-time */
  issuedAt?: string

  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  relatedOrder?: string | null

  /** @format iri-reference */
  packer?: string | null
  relatedCollaborators?: CollectionJsonld
}

export interface LitigationJsonldLitigationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  cardPreparer?: CollaboratorJsonldLitigationRead | null
  productPreparer?: CollaboratorJsonldLitigationRead | null
  cardPacker?: CollaboratorJsonldLitigationRead | null
  productPacker?: CollaboratorJsonldLitigationRead | null
  sender?: CollaboratorJsonldLitigationRead | null

  /** @format date-time */
  issuedAt?: string
  category?: LitigationCategoryJsonldLitigationRead | null
  relatedOrder?: OrderJsonldLitigationRead | null
  relatedCollaborators?: CollectionJsonldLitigationRead
}

export interface LitigationJsonldLitigationWrite {
  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  relatedOrder?: string | null
}

export interface LitigationJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format date-time */
  issuedAt?: string
  category?: LitigationCategoryJsonldOrderReadOrderReadItem | null
}

export interface LitigationCategoryLitigationRead {
  id?: number
  name?: string
}

export interface LitigationCategoryOrderReadOrderReadItem {
  name?: string
}

export interface LitigationCategoryJsonldLitigationRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
}

export interface LitigationCategoryJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface LogEntryLogEntryRead {
  collaborator?: CollaboratorLogEntryRead | null

  /** @format iri-reference */
  customer?: string | null
  message?: string

  /** @format date-time */
  loggedAt?: string

  /** @example 200 */
  level?: number
}

export interface LogEntryJsonldLogEntryRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  collaborator?: CollaboratorJsonldLogEntryRead | null

  /** @format iri-reference */
  customer?: string | null
  message?: string

  /** @format date-time */
  loggedAt?: string

  /** @example 200 */
  level?: number
}

export interface Opening {
  id?: number

  /** @format date-time */
  createdAt?: string
  comment?: string

  /** @format iri-reference */
  depositary?: string
  depositEntries?: string[]
}

export interface OpeningJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format date-time */
  createdAt?: string
  comment?: string

  /** @format iri-reference */
  depositary?: string
  depositEntries?: string[]
}

export interface Order {
  purchaseOrder?: string

  /** @format iri-reference */
  customer?: string | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @format iri-reference */
  sentBy?: string | null
  productReceived?: boolean

  /** @example false */
  validated?: boolean

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null

  /** @format iri-reference */
  canceledBy?: string | null
  cancelReason?: string
  cancelRequested?: boolean
  trackingDataSend?: boolean | null
  treated?: boolean
  returnedPackage?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean

  /** @format iri-reference */
  stock?: string | null

  /** @format date-time */
  createdAt?: string | null

  /** @format iri-reference */
  createdBy?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format iri-reference */
  notifiedReadyBy?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  entries?: string[]
  creditNotes?: CreditNote[]
  hiboutikId?: number | null
  litigations?: string[]

  /** @format iri-reference */
  shippingMode?: string | null
  weight?: number
  shippingFees?: number

  /** @format iri-reference */
  paymentMode?: string | null
  paymentFees?: number
  sourceDetails?: string | null
  sourceReference?: string | null
  mkmId?: number | null

  /** @example validated */
  status?: OrderStatus

  /** @format iri-reference */
  address?: string | null
  preorder?: boolean
  coupons?: string[]
  vouchers?: string[]
  prioritySend?: boolean
  storePlace?: string | null
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null

  /** @format iri-reference */
  printedBy?: string | null
  excludedFromPrints?: boolean
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  isEmailPreparedSent?: boolean
  notSplittable?: boolean

  /** @format iri-reference */
  parentOrder?: string | null
  splitOrder?: string[]

  /** @format iri-reference */
  productPreparer?: string | null

  /** @format iri-reference */
  cardPreparer?: string | null

  /** @format iri-reference */
  productChecker?: string | null

  /** @format iri-reference */
  cardChecker?: string | null

  /** @format iri-reference */
  bippedBy?: string | null

  /** @format iri-reference */
  paymentReceivedStore?: string | null
  cardPreparedSecondaryStatus?: OrderSecondaryStatus | null
  cardCheckedSecondaryStatus?: OrderSecondaryStatus | null
  productPreparedSecondaryStatus?: OrderSecondaryStatus | null
  productCheckedSecondaryStatus?: OrderSecondaryStatus | null
  cardInTransit?: boolean
  productInTransit?: boolean

  /** @format date-time */
  cardPreparationAt?: string | null

  /** @format date-time */
  productPreparationAt?: string | null
  orderPayments?: string[]
  paybacks?: string[]

  /** @format iri-reference */
  paymentReceivedBy?: string | null

  /** @format iri-reference */
  fidelityPoint?: string | null
  inCurrentCheck?: boolean

  /** @example true */
  associated?: boolean

  /** @format date-time */
  checkedReadyAt?: string

  /** @format iri-reference */
  checkedReadyBy?: string | null
  warningInCheckReady?: string | null
  metaOrder?: boolean

  /** @example true */
  sendPrintEmail?: boolean
  instruction?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  familiesInOrder?: string[]
  vaultCards?: string[]
  recentCards?: string[]
  notNumberedCards?: string[]
  defaultCards?: string[]
  concluded?: boolean
  emailPreparedSent?: boolean
  cards?: Collection
  sealedProducts?: Collection
  sourceLink?: string | null
  sourceName?: string | null
  sourceColor?: string | null
  linkedEditions?: string[]
  remainingShippingFees?: number
  remainingPaymentFees?: number

  /** @format date-time */
  preorderEndDate?: string | null
  totalValue?: number
  totalValueWithoutTaxes?: number
  totalsDiscountTaxByTax?: string[]
  couponDiscount?: number
  remainingCouponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutput
  commercialGesture?: number
  remainingCommercialGesture?: number
  rentalGesture?: number
  remainingRentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  totalToPayWithoutTaxes?: number
  splitOrders?: string[]
  productCount?: number
  couponGift?: boolean
  stringPaymentMode?: string
  prioritaryOrder?: boolean
  subjectToVat?: boolean
  referenceNumber?: string
  invoiced?: boolean
  invoiceable?: boolean
}

export interface OrderLitigationRead {
  id?: number
  billNumber?: string | null
  referenceNumber?: string
}

export interface OrderOrderCouponRead {
  subTotal?: number
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderOrderEntryRead {
  /** @example false */
  paymentReceived?: boolean
  shippingFees?: number
  paymentFees?: number
  coupons?: OrderCouponOrderEntryRead[]
  subTotal?: number

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderOrderEntryReadOrderEntryReadItem {
  /** @example false */
  paymentReceived?: boolean
  shippingFees?: number
  paymentFees?: number
  coupons?: OrderCouponOrderEntryReadOrderEntryReadItem[]
  subTotal?: number

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderOrderPaymentRead {
  subTotal?: number
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export type OrderOrderAddPayment = object

export type OrderOrderAssignBillNumber = object

export type OrderOrderCancelReady = object

export type OrderOrderCancelSend = object

export type OrderOrderCancelTreat = object

export type OrderOrderCardSecondaryStatus = object

export type OrderOrderClone = object

export type OrderOrderDeletedCoupon = object

export interface OrderOrderNotifyReady {
  storePlace?: string | null
}

export interface OrderOrderPatch {
  purchaseOrder?: string

  /** @format iri-reference */
  customer?: string | null
  comment?: string
  cancelRequested?: boolean
  returnedPackage?: boolean

  /** @example false */
  paymentReceived?: boolean

  /** @format iri-reference */
  shippingMode?: string | null
  shippingFees?: number
  paymentFees?: number

  /** @example validated */
  status?: OrderStatus
  address?: AddressOrderPatch | null
  coupons?: string[]
  prioritySend?: boolean
  printed?: boolean
  excludedFromPrints?: boolean

  /** @format iri-reference */
  productPreparer?: string | null

  /** @format iri-reference */
  cardPreparer?: string | null

  /** @format iri-reference */
  productChecker?: string | null

  /** @format iri-reference */
  cardChecker?: string | null

  /** @format iri-reference */
  paymentReceivedStore?: string | null
  cardPreparedSecondaryStatus?: OrderSecondaryStatus | null
  cardCheckedSecondaryStatus?: OrderSecondaryStatus | null
  productPreparedSecondaryStatus?: OrderSecondaryStatus | null
  productCheckedSecondaryStatus?: OrderSecondaryStatus | null
  cardInTransit?: boolean
  productInTransit?: boolean
  instruction?: string | null
}

export type OrderOrderPrepare = object

export interface OrderOrderRead {
  purchaseOrder?: string
  customer?: CustomerOrderRead | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  cancelRequested?: boolean
  treated?: boolean
  returnedPackage?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean
  stock?: StoreOrderRead | null

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  hiboutikId?: number | null
  shippingMode?: ShippingModeOrderRead | null
  weight?: number
  shippingFees?: number
  paymentFees?: number
  sourceDetails?: string | null
  sourceReference?: string | null

  /** @example validated */
  status?: OrderStatus
  address?: AddressOrderRead | null
  preorder?: boolean
  prioritySend?: boolean
  storePlace?: string | null
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  excludedFromPrints?: boolean
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  cardPreparedSecondaryStatus?: OrderSecondaryStatus | null
  cardCheckedSecondaryStatus?: OrderSecondaryStatus | null
  productPreparedSecondaryStatus?: OrderSecondaryStatus | null
  productCheckedSecondaryStatus?: OrderSecondaryStatus | null
  cardInTransit?: boolean
  productInTransit?: boolean

  /** @format date-time */
  cardPreparationAt?: string | null

  /** @format date-time */
  productPreparationAt?: string | null
  instruction?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  familiesInOrder?: string[]
  sourceLink?: string | null
  sourceName?: string | null
  sourceColor?: string | null
  linkedEditions?: string[]
  total?: number
  referenceNumber?: string
  invoiced?: boolean
}

export interface OrderOrderReadOrderReadItem {
  purchaseOrder?: string
  customer?: CustomerOrderReadOrderReadItem | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null
  sentBy?: CollaboratorOrderReadOrderReadItem | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  canceledBy?: CollaboratorOrderReadOrderReadItem | null
  cancelReason?: string
  cancelRequested?: boolean
  treated?: boolean
  returnedPackage?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean
  stock?: StoreOrderReadOrderReadItem | null

  /** @format date-time */
  createdAt?: string | null
  createdBy?: CollaboratorOrderReadOrderReadItem | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null
  notifiedReadyBy?: CollaboratorOrderReadOrderReadItem | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorOrderReadOrderReadItem | null
  entries?: OrderEntryOrderReadOrderReadItem[]
  creditNotes?: CreditNoteOrderReadOrderReadItem[]
  hiboutikId?: number | null
  litigations?: LitigationOrderReadOrderReadItem[]
  shippingMode?: ShippingModeOrderReadOrderReadItem | null
  weight?: number
  shippingFees?: number
  paymentFees?: number
  sourceDetails?: string | null
  sourceReference?: string | null

  /** @example validated */
  status?: OrderStatus
  address?: AddressOrderReadOrderReadItem | null
  preorder?: boolean
  coupons?: OrderCouponOrderReadOrderReadItem[]
  vouchers?: VoucherOrderReadOrderReadItem[]
  prioritySend?: boolean
  storePlace?: string | null
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorOrderReadOrderReadItem | null
  excludedFromPrints?: boolean
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  productPreparer?: CollaboratorOrderReadOrderReadItem | null
  cardPreparer?: CollaboratorOrderReadOrderReadItem | null
  productChecker?: CollaboratorOrderReadOrderReadItem | null
  cardChecker?: CollaboratorOrderReadOrderReadItem | null
  paymentReceivedStore?: StoreOrderReadOrderReadItem | null
  cardPreparedSecondaryStatus?: OrderSecondaryStatus | null
  cardCheckedSecondaryStatus?: OrderSecondaryStatus | null
  productPreparedSecondaryStatus?: OrderSecondaryStatus | null
  productCheckedSecondaryStatus?: OrderSecondaryStatus | null
  cardInTransit?: boolean
  productInTransit?: boolean

  /** @format date-time */
  cardPreparationAt?: string | null

  /** @format date-time */
  productPreparationAt?: string | null
  paybacks?: PaybackOrderReadOrderReadItem[]
  paymentReceivedBy?: CollaboratorOrderReadOrderReadItem | null
  fidelityPoint?: FidelityPointOrderReadOrderReadItem | null
  instruction?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  familiesInOrder?: string[]
  sourceLink?: string | null
  sourceName?: string | null
  sourceColor?: string | null
  linkedEditions?: string[]
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutputOrderReadOrderReadItem
  commercialGesture?: number
  rentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  splitOrders?: string[]
  referenceNumber?: string
  invoiced?: boolean
}

export type OrderOrderRemovePayment = object

export type OrderOrderSecondaryStatus = object

export interface OrderOrderSend {
  trackingNumber?: string
  weight?: number
}

export interface OrderOrderWrite {
  /** @format iri-reference */
  customer?: string | null

  /** @format iri-reference */
  stock?: string | null

  /** @format iri-reference */
  shippingMode?: string | null
}

export interface OrderPaybackRead {
  /** @format iri-reference */
  customer?: string | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  canceled?: boolean
  cancelRequested?: boolean
  treated?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean
  shippingFees?: number
  paymentFees?: number
  coupons?: OrderCouponPaybackRead[]
  subTotal?: number
  paybacks?: PaybackPaybackRead[]
  familiesInOrder?: string[]
  linkedEditions?: string[]
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderProductReadItemProductReadGqlPublic {
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  cancelReason?: string
  cancelRequested?: boolean
  treated?: boolean

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  entries?: OrderEntryProductReadItemProductReadGqlPublic[]
  creditNotes?: CreditNoteProductReadItemProductReadGqlPublic[]
  shippingMode?: ShippingModeProductReadItemProductReadGqlPublic | null
  shippingFees?: number
  paymentFees?: number

  /** @example validated */
  status?: OrderStatus
  address?: AddressProductReadItemProductReadGqlPublic | null
  preorder?: boolean
  coupons?: OrderCouponProductReadItemProductReadGqlPublic[]
  vouchers?: VoucherProductReadItemProductReadGqlPublic[]
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  orderPayments?: OrderPaymentProductReadItemProductReadGqlPublic[]

  /** @format iri-reference */
  fidelityPoint?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutputProductReadItemProductReadGqlPublic
  commercialGesture?: number
  rentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  productCount?: number
  referenceNumber?: string
  invoiced?: boolean
}

export interface OrderProductReadGqlPublic {
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  cancelReason?: string
  cancelRequested?: boolean
  treated?: boolean

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  entries?: OrderEntryProductReadGqlPublic[]
  creditNotes?: CreditNoteProductReadGqlPublic[]
  shippingMode?: ShippingModeProductReadGqlPublic | null
  shippingFees?: number
  paymentFees?: number

  /** @example validated */
  status?: OrderStatus
  address?: AddressProductReadGqlPublic | null
  preorder?: boolean
  coupons?: OrderCouponProductReadGqlPublic[]
  vouchers?: VoucherProductReadGqlPublic[]
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  orderPayments?: OrderPaymentProductReadGqlPublic[]

  /** @format iri-reference */
  fidelityPoint?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutputProductReadGqlPublic
  commercialGesture?: number
  rentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  productCount?: number
  referenceNumber?: string
  invoiced?: boolean
}

export interface OrderShippingLabelLogRead {
  address?: AddressShippingLabelLogRead | null
  id?: number
  billNumber?: string | null
  referenceNumber?: string
}

export interface OrderVoucherRead {
  subTotal?: number
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderCreditNoteInput {
  splitEntries?: OrderSplitEntryInput[]

  /** Get the value of shippingFees */
  shippingFees?: number

  /** Get the value of paymentFees */
  paymentFees?: number

  /** Get the value of commercialGesture */
  commercialGesture?: number

  /** Get the value of couponDiscount */
  couponDiscount?: number

  /** Get the value of rentalGesture */
  rentalGesture?: number

  /**
   * Get the value of paymentMode
   * @format iri-reference
   */
  paymentMode?: string

  /** Get the value of stockError */
  stockError?: boolean

  /**
   * Get the value of refundStore
   * @format iri-reference
   */
  refundStore?: string

  /**
   * Get the value of order
   * @format iri-reference
   */
  order?: string
}

export interface OrderCreditNoteInputJsonld {
  splitEntries?: OrderSplitEntryInputJsonld[]

  /** Get the value of shippingFees */
  shippingFees?: number

  /** Get the value of paymentFees */
  paymentFees?: number

  /** Get the value of commercialGesture */
  commercialGesture?: number

  /** Get the value of couponDiscount */
  couponDiscount?: number

  /** Get the value of rentalGesture */
  rentalGesture?: number

  /**
   * Get the value of paymentMode
   * @format iri-reference
   */
  paymentMode?: string

  /** Get the value of stockError */
  stockError?: boolean

  /**
   * Get the value of refundStore
   * @format iri-reference
   */
  refundStore?: string

  /**
   * Get the value of order
   * @format iri-reference
   */
  order?: string
}

export interface OrderOrderCancelInput {
  /** @format iri-reference */
  paymentMode?: string | null
  paymentModes?: OrderCancelPaymentInput[]

  /** @format iri-reference */
  store?: string | null

  /** @example true */
  sendEmail?: boolean
  cancelReason?: string | null
  isLost?: boolean
}

export interface OrderOrderCancelInputJsonld {
  /** @format iri-reference */
  paymentMode?: string | null
  paymentModes?: OrderCancelPaymentInputJsonld[]

  /** @format iri-reference */
  store?: string | null

  /** @example true */
  sendEmail?: boolean
  cancelReason?: string | null
  isLost?: boolean
}

export interface OrderOrderDeleteEntriesInput {
  deletedEntries?: string[]
}

export interface OrderOrderDeleteEntriesInputJsonld {
  deletedEntries?: string[]
}

export interface OrderOrderDeleteVouchersInput {
  deletedVouchers?: string[]
}

export interface OrderOrderDeleteVouchersInputJsonld {
  deletedVouchers?: string[]
}

export interface OrderOrderSplitInput {
  splitEntries?: OrderSplitEntryInput[]
}

export interface OrderOrderSplitInputJsonld {
  splitEntries?: OrderSplitEntryInputJsonld[]
}

export interface OrderJsonld {
  purchaseOrder?: string

  /** @format iri-reference */
  customer?: string | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @format iri-reference */
  sentBy?: string | null
  productReceived?: boolean

  /** @example false */
  validated?: boolean

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null

  /** @format iri-reference */
  canceledBy?: string | null
  cancelReason?: string
  cancelRequested?: boolean
  trackingDataSend?: boolean | null
  treated?: boolean
  returnedPackage?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean

  /** @format iri-reference */
  stock?: string | null

  /** @format date-time */
  createdAt?: string | null

  /** @format iri-reference */
  createdBy?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format iri-reference */
  notifiedReadyBy?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  entries?: string[]
  creditNotes?: CreditNoteJsonld[]
  hiboutikId?: number | null
  litigations?: string[]

  /** @format iri-reference */
  shippingMode?: string | null
  weight?: number
  shippingFees?: number

  /** @format iri-reference */
  paymentMode?: string | null
  paymentFees?: number
  sourceDetails?: string | null
  sourceReference?: string | null
  mkmId?: number | null

  /** @example validated */
  status?: OrderStatus

  /** @format iri-reference */
  address?: string | null
  preorder?: boolean
  coupons?: string[]
  vouchers?: string[]
  prioritySend?: boolean
  storePlace?: string | null
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null

  /** @format iri-reference */
  printedBy?: string | null
  excludedFromPrints?: boolean
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  isEmailPreparedSent?: boolean
  notSplittable?: boolean

  /** @format iri-reference */
  parentOrder?: string | null
  splitOrder?: string[]

  /** @format iri-reference */
  productPreparer?: string | null

  /** @format iri-reference */
  cardPreparer?: string | null

  /** @format iri-reference */
  productChecker?: string | null

  /** @format iri-reference */
  cardChecker?: string | null

  /** @format iri-reference */
  bippedBy?: string | null

  /** @format iri-reference */
  paymentReceivedStore?: string | null
  cardPreparedSecondaryStatus?: OrderSecondaryStatus | null
  cardCheckedSecondaryStatus?: OrderSecondaryStatus | null
  productPreparedSecondaryStatus?: OrderSecondaryStatus | null
  productCheckedSecondaryStatus?: OrderSecondaryStatus | null
  cardInTransit?: boolean
  productInTransit?: boolean

  /** @format date-time */
  cardPreparationAt?: string | null

  /** @format date-time */
  productPreparationAt?: string | null
  orderPayments?: string[]
  paybacks?: string[]

  /** @format iri-reference */
  paymentReceivedBy?: string | null

  /** @format iri-reference */
  fidelityPoint?: string | null
  inCurrentCheck?: boolean

  /** @example true */
  associated?: boolean

  /** @format date-time */
  checkedReadyAt?: string

  /** @format iri-reference */
  checkedReadyBy?: string | null
  warningInCheckReady?: string | null
  metaOrder?: boolean

  /** @example true */
  sendPrintEmail?: boolean
  instruction?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  familiesInOrder?: string[]
  vaultCards?: string[]
  recentCards?: string[]
  notNumberedCards?: string[]
  defaultCards?: string[]
  concluded?: boolean
  emailPreparedSent?: boolean
  cards?: CollectionJsonld
  sealedProducts?: CollectionJsonld
  sourceLink?: string | null
  sourceName?: string | null
  sourceColor?: string | null
  linkedEditions?: string[]
  remainingShippingFees?: number
  remainingPaymentFees?: number

  /** @format date-time */
  preorderEndDate?: string | null
  totalValue?: number
  totalValueWithoutTaxes?: number
  totalsDiscountTaxByTax?: string[]
  couponDiscount?: number
  remainingCouponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutputJsonld
  commercialGesture?: number
  remainingCommercialGesture?: number
  rentalGesture?: number
  remainingRentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  totalToPayWithoutTaxes?: number
  splitOrders?: string[]
  productCount?: number
  couponGift?: boolean
  stringPaymentMode?: string
  prioritaryOrder?: boolean
  subjectToVat?: boolean
  referenceNumber?: string
  invoiced?: boolean
  invoiceable?: boolean
}

export interface OrderJsonldLitigationRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  referenceNumber?: string
}

export interface OrderJsonldOrderCouponRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  subTotal?: number
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example false */
  paymentReceived?: boolean
  shippingFees?: number
  paymentFees?: number
  coupons?: OrderCouponJsonldOrderEntryRead[]
  subTotal?: number

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example false */
  paymentReceived?: boolean
  shippingFees?: number
  paymentFees?: number
  coupons?: OrderCouponJsonldOrderEntryReadOrderEntryReadItem[]
  subTotal?: number

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderJsonldOrderPaymentRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  subTotal?: number
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export type OrderJsonldOrderAddPayment = object

export type OrderJsonldOrderCancelReady = object

export type OrderJsonldOrderCancelSend = object

export type OrderJsonldOrderCancelTreat = object

export type OrderJsonldOrderClone = object

export type OrderJsonldOrderDeletedCoupon = object

export type OrderJsonldOrderPrepare = object

export interface OrderJsonldOrderRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  purchaseOrder?: string
  customer?: CustomerJsonldOrderRead | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  cancelRequested?: boolean
  treated?: boolean
  returnedPackage?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean
  stock?: StoreJsonldOrderRead | null

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  hiboutikId?: number | null
  shippingMode?: ShippingModeJsonldOrderRead | null
  weight?: number
  shippingFees?: number
  paymentFees?: number
  sourceDetails?: string | null
  sourceReference?: string | null

  /** @example validated */
  status?: OrderStatus
  address?: AddressJsonldOrderRead | null
  preorder?: boolean
  prioritySend?: boolean
  storePlace?: string | null
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  excludedFromPrints?: boolean
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  cardPreparedSecondaryStatus?: OrderSecondaryStatus | null
  cardCheckedSecondaryStatus?: OrderSecondaryStatus | null
  productPreparedSecondaryStatus?: OrderSecondaryStatus | null
  productCheckedSecondaryStatus?: OrderSecondaryStatus | null
  cardInTransit?: boolean
  productInTransit?: boolean

  /** @format date-time */
  cardPreparationAt?: string | null

  /** @format date-time */
  productPreparationAt?: string | null
  instruction?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  familiesInOrder?: string[]
  sourceLink?: string | null
  sourceName?: string | null
  sourceColor?: string | null
  linkedEditions?: string[]
  total?: number
  referenceNumber?: string
  invoiced?: boolean
}

export interface OrderJsonldOrderReadOrderReadItem {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  purchaseOrder?: string
  customer?: CustomerJsonldOrderReadOrderReadItem | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null
  sentBy?: CollaboratorJsonldOrderReadOrderReadItem | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  canceledBy?: CollaboratorJsonldOrderReadOrderReadItem | null
  cancelReason?: string
  cancelRequested?: boolean
  treated?: boolean
  returnedPackage?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean
  stock?: StoreJsonldOrderReadOrderReadItem | null

  /** @format date-time */
  createdAt?: string | null
  createdBy?: CollaboratorJsonldOrderReadOrderReadItem | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null
  notifiedReadyBy?: CollaboratorJsonldOrderReadOrderReadItem | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorJsonldOrderReadOrderReadItem | null
  entries?: OrderEntryJsonldOrderReadOrderReadItem[]
  creditNotes?: CreditNoteJsonldOrderReadOrderReadItem[]
  hiboutikId?: number | null
  litigations?: LitigationJsonldOrderReadOrderReadItem[]
  shippingMode?: ShippingModeJsonldOrderReadOrderReadItem | null
  weight?: number
  shippingFees?: number
  paymentFees?: number
  sourceDetails?: string | null
  sourceReference?: string | null

  /** @example validated */
  status?: OrderStatus
  address?: AddressJsonldOrderReadOrderReadItem | null
  preorder?: boolean
  coupons?: OrderCouponJsonldOrderReadOrderReadItem[]
  vouchers?: VoucherJsonldOrderReadOrderReadItem[]
  prioritySend?: boolean
  storePlace?: string | null
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorJsonldOrderReadOrderReadItem | null
  excludedFromPrints?: boolean
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  productPreparer?: CollaboratorJsonldOrderReadOrderReadItem | null
  cardPreparer?: CollaboratorJsonldOrderReadOrderReadItem | null
  productChecker?: CollaboratorJsonldOrderReadOrderReadItem | null
  cardChecker?: CollaboratorJsonldOrderReadOrderReadItem | null
  paymentReceivedStore?: StoreJsonldOrderReadOrderReadItem | null
  cardPreparedSecondaryStatus?: OrderSecondaryStatus | null
  cardCheckedSecondaryStatus?: OrderSecondaryStatus | null
  productPreparedSecondaryStatus?: OrderSecondaryStatus | null
  productCheckedSecondaryStatus?: OrderSecondaryStatus | null
  cardInTransit?: boolean
  productInTransit?: boolean

  /** @format date-time */
  cardPreparationAt?: string | null

  /** @format date-time */
  productPreparationAt?: string | null
  paybacks?: PaybackJsonldOrderReadOrderReadItem[]
  paymentReceivedBy?: CollaboratorJsonldOrderReadOrderReadItem | null
  fidelityPoint?: FidelityPointJsonldOrderReadOrderReadItem | null
  instruction?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  familiesInOrder?: string[]
  sourceLink?: string | null
  sourceName?: string | null
  sourceColor?: string | null
  linkedEditions?: string[]
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutputJsonldOrderReadOrderReadItem
  commercialGesture?: number
  rentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  splitOrders?: string[]
  referenceNumber?: string
  invoiced?: boolean
}

export type OrderJsonldOrderRemovePayment = object

export interface OrderJsonldOrderWrite {
  /** @format iri-reference */
  customer?: string | null

  /** @format iri-reference */
  stock?: string | null

  /** @format iri-reference */
  shippingMode?: string | null
}

export interface OrderJsonldPaybackRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  customer?: string | null
  comment?: string
  trackingNumber?: string

  /** @example false */
  canceled?: boolean
  cancelRequested?: boolean
  treated?: boolean

  /** @example false */
  paymentSent?: boolean

  /** @example false */
  paymentReceived?: boolean

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean
  shippingFees?: number
  paymentFees?: number
  coupons?: OrderCouponJsonldPaybackRead[]
  subTotal?: number
  paybacks?: PaybackJsonldPaybackRead[]
  familiesInOrder?: string[]
  linkedEditions?: string[]
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  cancelReason?: string
  cancelRequested?: boolean
  treated?: boolean

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  entries?: OrderEntryJsonldProductReadItemProductReadGqlPublic[]
  creditNotes?: CreditNoteJsonldProductReadItemProductReadGqlPublic[]
  shippingMode?: ShippingModeJsonldProductReadItemProductReadGqlPublic | null
  shippingFees?: number
  paymentFees?: number

  /** @example validated */
  status?: OrderStatus
  address?: AddressJsonldProductReadItemProductReadGqlPublic | null
  preorder?: boolean
  coupons?: OrderCouponJsonldProductReadItemProductReadGqlPublic[]
  vouchers?: VoucherJsonldProductReadItemProductReadGqlPublic[]
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  orderPayments?: OrderPaymentJsonldProductReadItemProductReadGqlPublic[]

  /** @format iri-reference */
  fidelityPoint?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutputJsonldProductReadItemProductReadGqlPublic
  commercialGesture?: number
  rentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  productCount?: number
  referenceNumber?: string
  invoiced?: boolean
}

export interface OrderJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  trackingNumber?: string

  /** @example false */
  productSent?: boolean

  /** @format date-time */
  sentAt?: string | null

  /** @example false */
  canceled?: boolean

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  cancelRequestedAt?: string | null
  cancelReason?: string
  cancelRequested?: boolean
  treated?: boolean

  /** @format date-time */
  createdAt?: string | null
  ready?: boolean

  /** @format date-time */
  notifiedReadyAt?: string | null

  /** @format date-time */
  paidAt?: string | null

  /** @format date-time */
  treatedAt?: string | null
  entries?: OrderEntryJsonldProductReadGqlPublic[]
  creditNotes?: CreditNoteJsonldProductReadGqlPublic[]
  shippingMode?: ShippingModeJsonldProductReadGqlPublic | null
  shippingFees?: number
  paymentFees?: number

  /** @example validated */
  status?: OrderStatus
  address?: AddressJsonldProductReadGqlPublic | null
  preorder?: boolean
  coupons?: OrderCouponJsonldProductReadGqlPublic[]
  vouchers?: VoucherJsonldProductReadGqlPublic[]
  subTotal?: number

  /** @format date-time */
  restockedAt?: string | null

  /** @format date-time */
  preorderAt?: string | null
  approximatePreorderDate?: string | null
  approximatePreorderDateEn?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  orderPayments?: OrderPaymentJsonldProductReadGqlPublic[]

  /** @format iri-reference */
  fidelityPoint?: string | null

  /** @format date-time */
  billedAt?: string | null
  id?: number
  billNumber?: string | null
  totalValue?: number
  couponDiscount?: number
  voucherDiscount?: number
  paymentModes?: OrderPaymentsCollectionOutputJsonldProductReadGqlPublic
  commercialGesture?: number
  rentalGesture?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
  productCount?: number
  referenceNumber?: string
  invoiced?: boolean
}

export interface OrderJsonldShippingLabelLogRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  address?: AddressJsonldShippingLabelLogRead | null
  id?: number
  billNumber?: string | null
  referenceNumber?: string
}

export interface OrderJsonldVoucherRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  subTotal?: number
  totalValue?: number
  total?: number
  leftToPay?: number
  totalToPay?: number
}

export interface OrderCancelPaymentInput {
  /** @format iri-reference */
  paymentMode: string

  /** @format iri-reference */
  store: string
  refundedAmount: number
}

export interface OrderCancelPaymentInputEventCustomerWrite {
  /** @format iri-reference */
  paymentMode: string

  /** @format iri-reference */
  store: string
  refundedAmount: number
}

export interface OrderCancelPaymentInputJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  paymentMode: string

  /** @format iri-reference */
  store: string
  refundedAmount: number
}

export interface OrderCancelPaymentInputJsonldEventCustomerWrite {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  paymentMode: string

  /** @format iri-reference */
  store: string
  refundedAmount: number
}

export interface OrderCheckReadyOrderCheckReadyPatch {
  treated?: boolean
}

export interface OrderCheckReadyOrderCheckReadyRead {
  /** @format iri-reference */
  store?: string
  treated?: boolean

  /** @format date-time */
  startedAt?: string

  /** @format iri-reference */
  startedBy?: string

  /** @format iri-reference */
  treatedBy?: string | null
}

export interface OrderCheckReadyOrderCheckReadyWrite {
  /** @format iri-reference */
  store?: string
}

export interface OrderCheckReadyJsonldOrderCheckReadyRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  store?: string
  treated?: boolean

  /** @format date-time */
  startedAt?: string

  /** @format iri-reference */
  startedBy?: string

  /** @format iri-reference */
  treatedBy?: string | null
}

export interface OrderCheckReadyJsonldOrderCheckReadyWrite {
  /** @format iri-reference */
  store?: string
}

export interface OrderCoupon {
  id?: number

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponOrderCouponRead {
  id?: number
  linkedOrder?: OrderOrderCouponRead | null
  coupon?: CouponOrderCouponRead

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponOrderCouponWrite {
  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  coupon?: string
}

export interface OrderCouponOrderEntryRead {
  id?: number

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponOrderEntryReadOrderEntryReadItem {
  id?: number

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponOrderReadOrderReadItem {
  id?: number
  coupon?: CouponOrderReadOrderReadItem

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponPaybackRead {
  id?: number
  coupon?: CouponPaybackRead

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponProductReadItemProductReadGqlPublic {
  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponProductReadGqlPublic {
  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonldOrderCouponRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  linkedOrder?: OrderJsonldOrderCouponRead | null
  coupon?: CouponJsonldOrderCouponRead

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonldOrderCouponWrite {
  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  coupon?: string
}

export interface OrderCouponJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  coupon?: CouponJsonldOrderReadOrderReadItem

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonldPaybackRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  coupon?: CouponJsonldPaybackRead

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderCouponJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  coupon?: string

  /** @min 0 */
  value?: number
  fidelityPoints?: number
}

export interface OrderEntry {
  /** @format iri-reference */
  order?: string | null

  /** @format iri-reference */
  product?: SealedProductProductRead | null

  /** @format iri-reference */
  productPack?: ProductPackJsonldOrderEntryRead | string | null

  /** @format iri-reference */
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: string[]

  /** @min 0 */
  quantity?: number
  displayedQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null

  /** @format iri-reference */
  stock?: string | null
  productAssociations?: string[]
  id?: number

  /** @format iri-reference */
  productStore?: string | null
  quantityWithTaxes?: number
  paidPriceWithoutTaxes?: number
  totalTaxes?: number
  childPackUnique?: Collection
  inPack?: boolean
  pack?: boolean

  /** @format date-time */
  restockAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  lastYear?: boolean
}

export interface OrderEntryOrderEntryRead {
  order?: OrderOrderEntryRead | null
  product?: SealedProductProductRead | null
  productPack?: ProductPackJsonldOrderEntryRead | string | null
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: OrderEntryOrderEntryRead[]

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null
  stock?: StoreOrderEntryRead | null
  id?: number
  productStore?: ProductStoreOrderEntryRead | null

  /** @format date-time */
  restockAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
}

export interface OrderEntryOrderEntryReadOrderEntryReadItem {
  order?: OrderOrderEntryReadOrderEntryReadItem | null
  product?: SealedProductProductRead | null
  productPack?: ProductPackJsonldOrderEntryRead | string | null
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: OrderEntryOrderEntryReadOrderEntryReadItem[]

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null
  stock?: StoreOrderEntryReadOrderEntryReadItem | null
  id?: number
  productStore?: ProductStoreOrderEntryReadOrderEntryReadItem | null

  /** @format date-time */
  restockAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
}

export interface OrderEntryOrderEntryWrite {
  /** @format iri-reference */
  order?: string | null

  /** @format iri-reference */
  product?: string

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number
}

export interface OrderEntryOrderReadOrderReadItem {
  product?: SealedProductProductRead | null

  /** @format iri-reference */
  productPack?: ProductPackJsonldOrderEntryRead | string | null
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: OrderEntryOrderReadOrderReadItem[]

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null
  stock?: StoreOrderReadOrderReadItem | null
  id?: number
  productStore?: ProductStoreOrderReadOrderReadItem | null
}

export interface OrderEntryProductReadItemProductReadGqlPublic {
  product?: SealedProductProductRead | null

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number
  tax?: TaxProductReadItemProductReadGqlPublic | null
  id?: number
}

export interface OrderEntryProductReadGqlPublic {
  product?: SealedProductProductRead | null

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number
  tax?: TaxProductReadGqlPublic | null
  id?: number
}

export interface OrderEntryOrderSplitInput {
  splitEntries?: OrderSplitEntryInput[]
}

export interface OrderEntryOrderSplitInputJsonld {
  splitEntries?: OrderSplitEntryInputJsonld[]
}

export interface OrderEntryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  order?: string | null

  /** @format iri-reference */
  product?: SealedProductProductRead | null

  /** @format iri-reference */
  productPack?: ProductPackJsonldOrderEntryRead | string | null

  /** @format iri-reference */
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: string[]

  /** @min 0 */
  quantity?: number
  displayedQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null

  /** @format iri-reference */
  stock?: string | null
  productAssociations?: string[]
  id?: number

  /** @format iri-reference */
  productStore?: string | null
  quantityWithTaxes?: number
  paidPriceWithoutTaxes?: number
  totalTaxes?: number
  childPackUnique?: CollectionJsonld
  inPack?: boolean
  pack?: boolean

  /** @format date-time */
  restockAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  lastYear?: boolean
}

export interface OrderEntryJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  order?: OrderJsonldOrderEntryRead | null
  product?: SealedProductProductRead | null
  productPack?: ProductPackJsonldOrderEntryRead | string | null
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: OrderEntryJsonldOrderEntryRead[]

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null
  stock?: StoreJsonldOrderEntryRead | null
  id?: number
  productStore?: ProductStoreJsonldOrderEntryRead | null

  /** @format date-time */
  restockAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
}

export interface OrderEntryJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  order?: OrderJsonldOrderEntryReadOrderEntryReadItem | null
  product?: SealedProductProductRead | null
  productPack?: ProductPackJsonldOrderEntryRead | string | null
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: OrderEntryJsonldOrderEntryReadOrderEntryReadItem[]

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null
  stock?: StoreJsonldOrderEntryReadOrderEntryReadItem | null
  id?: number
  productStore?: ProductStoreJsonldOrderEntryReadOrderEntryReadItem | null

  /** @format date-time */
  restockAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
}

export interface OrderEntryJsonldOrderEntryWrite {
  /** @format iri-reference */
  order?: string | null

  /** @format iri-reference */
  product?: string

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number
}

export interface OrderEntryJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: SealedProductProductRead | null

  /** @format iri-reference */
  productPack?: ProductPackJsonldOrderEntryRead | string | null
  parentPack?: OrderEntryJsonldOrderEntryRead | string | null
  childPack?: OrderEntryJsonldOrderReadOrderReadItem[]

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number

  /** @format iri-reference */
  tax?: string | null
  stock?: StoreJsonldOrderReadOrderReadItem | null
  id?: number
  productStore?: ProductStoreJsonldOrderReadOrderReadItem | null
}

export interface OrderEntryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: SealedProductProductRead | null

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number
  tax?: TaxJsonldProductReadItemProductReadGqlPublic | null
  id?: number
}

export interface OrderEntryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: SealedProductProductRead | null

  /** @min 0 */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  originalPrice?: number
  tax?: TaxJsonldProductReadGqlPublic | null
  id?: number
}

export interface OrderPayment {
  id?: number

  /** @format iri-reference */
  relatedOrder: string

  /** @format iri-reference */
  paymentMode: string

  /** @min 0 */
  value?: number
}

export interface OrderPaymentOrderPaymentRead {
  id?: number
  relatedOrder: OrderOrderPaymentRead

  /** @format iri-reference */
  paymentMode: string
}

export interface OrderPaymentProductReadItemProductReadGqlPublic {
  /** @format iri-reference */
  paymentMode: string

  /** @min 0 */
  value?: number
}

export interface OrderPaymentProductReadGqlPublic {
  /** @format iri-reference */
  paymentMode: string

  /** @min 0 */
  value?: number
}

export interface OrderPaymentJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  relatedOrder: string

  /** @format iri-reference */
  paymentMode: string

  /** @min 0 */
  value?: number
}

export interface OrderPaymentJsonldOrderPaymentRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  relatedOrder: OrderJsonldOrderPaymentRead

  /** @format iri-reference */
  paymentMode: string
}

export interface OrderPaymentJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  paymentMode: string

  /** @min 0 */
  value?: number
}

export interface OrderPaymentJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  paymentMode: string

  /** @min 0 */
  value?: number
}

export interface OrderPaymentOutput {
  id?: number | null

  /** @format iri-reference */
  paymentMode?: string | null
  orderPaymentId?: number | null
  voucherId?: number | null
  name?: string
  value?: number
  voucherCategory?: string | null
  voucherCategoryId?: number | null
}

export interface OrderPaymentOutputOrderReadOrderReadItem {
  id?: number | null
  paymentMode?: PaymentModeOrderReadOrderReadItem | null
  orderPaymentId?: number | null
  voucherId?: number | null
  name?: string
  value?: number
  voucherCategory?: string | null
  voucherCategoryId?: number | null
}

export interface OrderPaymentOutputJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number | null

  /** @format iri-reference */
  paymentMode?: string | null
  orderPaymentId?: number | null
  voucherId?: number | null
  name?: string
  value?: number
  voucherCategory?: string | null
  voucherCategoryId?: number | null
}

export interface OrderPaymentOutputJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number | null
  paymentMode?: PaymentModeJsonldOrderReadOrderReadItem | null
  orderPaymentId?: number | null
  voucherId?: number | null
  name?: string
  value?: number
  voucherCategory?: string | null
  voucherCategoryId?: number | null
}

export interface OrderPaymentsCollectionOutput {
  payments?: OrderPaymentOutput[]
}

export interface OrderPaymentsCollectionOutputOrderReadOrderReadItem {
  payments?: OrderPaymentOutputOrderReadOrderReadItem[]
}

export type OrderPaymentsCollectionOutputProductReadItemProductReadGqlPublic = object

export type OrderPaymentsCollectionOutputProductReadGqlPublic = object

export interface OrderPaymentsCollectionOutputJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  payments?: OrderPaymentOutputJsonld[]
}

export interface OrderPaymentsCollectionOutputJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  payments?: OrderPaymentOutputJsonldOrderReadOrderReadItem[]
}

export interface OrderPaymentsCollectionOutputJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface OrderPaymentsCollectionOutputJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface OrderPrintOrderPrintRead {
  id?: number

  /** @format date-time */
  printedAt?: string
  orders?: string
  printedBy?: CollaboratorOrderPrintRead
  type?: string
  downloadLink?: string | null
  hasCards?: boolean
}

export interface OrderPrintJsonldOrderPrintRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format date-time */
  printedAt?: string
  orders?: string
  printedBy?: CollaboratorJsonldOrderPrintRead
  type?: string
  downloadLink?: string | null
  hasCards?: boolean
}

export interface OrderSplitEntryInput {
  /** @format iri-reference */
  orderEntry?: string

  /** @example 1 */
  quantityToSplit?: number
}

export interface OrderSplitEntryInputJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  orderEntry?: string

  /** @example 1 */
  quantityToSplit?: number
}

export interface PaybackOrderReadOrderReadItem {
  id?: number
  relatedCollaborator?: CollaboratorOrderReadOrderReadItem
  product: ProductOrderReadOrderReadItem

  /** @min 0 */
  quantity?: number
  paymentMode: PaymentModeOrderReadOrderReadItem

  /** @min 0 */
  total?: number

  /** @format date-time */
  paidBackAt?: string
  relatedStore: StoreOrderReadOrderReadItem
}

export interface PaybackPaybackRead {
  id?: number

  /** @format iri-reference */
  relatedCollaborator?: string
  relatedOrder: OrderPaybackRead

  /** @format iri-reference */
  product: string

  /** @min 0 */
  quantity?: number
  paymentMode: PaymentModePaybackRead

  /** @min 0 */
  total?: number

  /** @format date-time */
  paidBackAt?: string

  /** @format iri-reference */
  relatedStore: string
}

export interface PaybackPaybackInput {
  quantity?: number

  /** @format iri-reference */
  paymentMode?: string
  total?: number

  /** @format iri-reference */
  relatedStore?: string

  /** @format iri-reference */
  relatedOrderEntry?: string
  stockError?: boolean
}

export interface PaybackPaybackInputJsonld {
  quantity?: number

  /** @format iri-reference */
  paymentMode?: string
  total?: number

  /** @format iri-reference */
  relatedStore?: string

  /** @format iri-reference */
  relatedOrderEntry?: string
  stockError?: boolean
}

export interface PaybackJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  relatedCollaborator?: CollaboratorJsonldOrderReadOrderReadItem
  product: ProductJsonldOrderReadOrderReadItem

  /** @min 0 */
  quantity?: number
  paymentMode: PaymentModeJsonldOrderReadOrderReadItem

  /** @min 0 */
  total?: number

  /** @format date-time */
  paidBackAt?: string
  relatedStore: StoreJsonldOrderReadOrderReadItem
}

export interface PaybackJsonldPaybackRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  relatedCollaborator?: string
  relatedOrder: OrderJsonldPaybackRead

  /** @format iri-reference */
  product: string

  /** @min 0 */
  quantity?: number
  paymentMode: PaymentModeJsonldPaybackRead

  /** @min 0 */
  total?: number

  /** @format date-time */
  paidBackAt?: string

  /** @format iri-reference */
  relatedStore: string
}

export interface PaymentModeEventCustomerRead {
  name?: string
  nameEn?: string
}

export interface PaymentModeEventOrderRead {
  hiboutikName: string
  name?: string
  nameEn?: string
}

export interface PaymentModeEventReadEventReadItem {
  hiboutikName: string
  name?: string
  nameEn?: string
}

export interface PaymentModeOrderReadOrderReadItem {
  id?: number
  codeName?: string | null
  name?: string
  nameEn?: string
}

export interface PaymentModePaybackRead {
  id?: number
  codeName?: string | null
}

export interface PaymentModePaymentModeRead {
  id?: number
  availableInShop?: boolean
  availableInStock?: boolean
  availableInShipping?: boolean
  availableInStand?: boolean
  hiboutikName: string
  codeName?: string | null

  /** @example true */
  givesFidelity?: boolean
  name?: string
  nameEn?: string
  transName?: string | null
  availableInEvent?: boolean
  cashbox?: boolean
}

export interface PaymentModeStockReadItemStockRead {
  id?: number
  codeName?: string | null
}

export interface PaymentModeStockReadItemStockReadStockStats {
  id?: number
  codeName?: string | null
}

export interface PaymentModeStockReadStockReadItem {
  id?: number
  codeName?: string | null
}

export interface PaymentModeWarehouseStockRead {
  id?: number
}

export interface PaymentModeJsonldEventCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface PaymentModeJsonldEventOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  hiboutikName: string
  name?: string
  nameEn?: string
}

export interface PaymentModeJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  hiboutikName: string
  name?: string
  nameEn?: string
}

export interface PaymentModeJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  codeName?: string | null
  name?: string
  nameEn?: string
}

export interface PaymentModeJsonldPaybackRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  codeName?: string | null
}

export interface PaymentModeJsonldPaymentModeRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  availableInShop?: boolean
  availableInStock?: boolean
  availableInShipping?: boolean
  availableInStand?: boolean
  hiboutikName: string
  codeName?: string | null

  /** @example true */
  givesFidelity?: boolean
  name?: string
  nameEn?: string
  transName?: string | null
  availableInEvent?: boolean
  cashbox?: boolean
}

export interface PaymentModeJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  codeName?: string | null
}

export interface PaymentModeJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  codeName?: string | null
}

export interface PaymentModeJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  codeName?: string | null
}

export interface PaymentModeJsonldWarehouseStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
}

export interface PostalRelayOrderRead {
  id?: number
  postalReference?: string
  country?: CountryOrderRead
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PostalRelayOrderReadOrderReadItem {
  id?: number
  postalReference?: string
  country?: CountryOrderReadOrderReadItem
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PostalRelayProductReadItemProductReadGqlPublic {
  name?: string
  streetAddress?: string
  city?: string
  postalCode?: string
}

export interface PostalRelayProductReadGqlPublic {
  name?: string
  streetAddress?: string
  city?: string
  postalCode?: string
}

export interface PostalRelayRelayRead {
  id?: number
  postalReference?: string
  country?: CountryRelayRead
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PostalRelayRelayWrite {
  postalReference?: string

  /** @format iri-reference */
  country?: string
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PostalRelayPostalRelayCreateInputRelayWrite {
  relayId: string

  /** @example RELAY */
  labelType?: string
}

export interface PostalRelayPostalRelayCreateInputJsonldRelayWrite {
  relayId: string

  /** @example RELAY */
  labelType?: string
}

export interface PostalRelayJsonldOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  postalReference?: string
  country?: CountryJsonldOrderRead
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PostalRelayJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  postalReference?: string
  country?: CountryJsonldOrderReadOrderReadItem
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PostalRelayJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  streetAddress?: string
  city?: string
  postalCode?: string
}

export interface PostalRelayJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  streetAddress?: string
  city?: string
  postalCode?: string
}

export interface PostalRelayJsonldRelayRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  postalReference?: string
  country?: CountryJsonldRelayRead
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PostalRelayJsonldRelayWrite {
  postalReference?: string

  /** @format iri-reference */
  country?: string
  name?: string
  streetAddress?: string
  regionRelay?: string | null
  city?: string
  postalCode?: string
  extraInformation?: string | null
}

export interface PricingPolicyPricingPolicyRead {
  msrp?: number
  price?: number
}

export interface PricingPolicyJsonldPricingPolicyRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  msrp?: number
  price?: number
}

export interface PricingTagCardPropertiesRead {
  id?: number
  name?: string

  /** @example 1 */
  nbDay?: number
}

export interface PricingTagCardPropertiesReadCardPropertiesReadItem {
  id?: number
  name?: string

  /** @example 1 */
  nbDay?: number
}

export interface PricingTagPricingTagRead {
  id?: number
  name?: string

  /** @example 1 */
  nbDay?: number
  cards?: CardPricingTagRead[]
}

export interface PricingTagJsonldCardPropertiesRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string

  /** @example 1 */
  nbDay?: number
}

export interface PricingTagJsonldCardPropertiesReadCardPropertiesReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string

  /** @example 1 */
  nbDay?: number
}

export interface PricingTagJsonldPricingTagRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string

  /** @example 1 */
  nbDay?: number
  cards?: CardJsonldPricingTagRead[]
}

export interface PrintExclusion {
  id?: number

  /** @format iri-reference */
  edition?: string

  /** @format iri-reference */
  store?: string
}

export interface PrintExclusionCardEditionRead {
  /** @format iri-reference */
  store?: string
}

export interface PrintExclusionPrintExclusionRead {
  id?: number
  edition?: CardEditionPrintExclusionRead
  store?: StorePrintExclusionRead
}

export interface PrintExclusionPrintExclusionWrite {
  /** @format iri-reference */
  edition?: string

  /** @format iri-reference */
  store?: string
}

export interface PrintExclusionJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  edition?: string

  /** @format iri-reference */
  store?: string
}

export interface PrintExclusionJsonldCardEditionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  store?: string
}

export interface PrintExclusionJsonldPrintExclusionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  edition?: CardEditionJsonldPrintExclusionRead
  store?: StoreJsonldPrintExclusionRead
}

export interface PrintExclusionJsonldPrintExclusionWrite {
  /** @format iri-reference */
  edition?: string

  /** @format iri-reference */
  store?: string
}

export interface ProductInventoryRead {
  manufacturerPartNumber?: string | null
  name?: string
}

export interface ProductInventoryReadInventoryReadItem {
  manufacturerPartNumber?: string | null
  name?: string
}

export interface ProductOrderEntryRead {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
  family?: FamilyOrderEntryRead | null
  lang?: LangOrderEntryRead | null
  productsInPack?: ProductPackOrderEntryRead[]
  pack?: boolean
  redirectTo?: string | null
}

export interface ProductOrderEntryReadOrderEntryReadItem {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
  family?: FamilyOrderEntryReadOrderEntryReadItem | null
  lang?: LangOrderEntryReadOrderEntryReadItem | null
  productsInPack?: ProductPackOrderEntryReadOrderEntryReadItem[]
  pack?: boolean
  redirectTo?: string | null
}

export interface ProductOrderReadOrderReadItem {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
  family?: FamilyOrderReadOrderReadItem | null
  lang?: LangOrderReadOrderReadItem | null
  productsInPack?: string[]
  pack?: boolean
}

export interface ProductProductReadItemProductReadGqlPublic {
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataProductReadItemProductReadGqlPublic | null
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadItemProductReadGqlPublic | null
  lang?: LangProductReadItemProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadItemProductReadGqlPublic[]
  tax?: TaxProductReadItemProductReadGqlPublic
  productStats?: ProductStatsProductReadItemProductReadGqlPublic[]
  linkedEdition?: CardEditionProductReadItemProductReadGqlPublic | null
  productsInPack?: ProductPackProductReadItemProductReadGqlPublic[]
  packsContainingProduct?: ProductPackProductReadItemProductReadGqlPublic[]
  pack?: boolean
  inPack?: boolean
  category?: ProductCategoryProductReadItemProductReadGqlPublic | null
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryProductReadItemProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  transName?: string | null
  imageUrl?: string | null
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface ProductProductReadGqlPublic {
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataProductReadGqlPublic | null
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadGqlPublic | null
  lang?: LangProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadGqlPublic[]
  tax?: TaxProductReadGqlPublic
  linkedEdition?: CardEditionProductReadGqlPublic | null
  pack?: boolean
  inPack?: boolean
  category?: ProductCategoryProductReadGqlPublic | null
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  transName?: string | null
  imageUrl?: string | null
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
}

export interface ProductStockEntryRead {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  lang?: string | null
  productStores?: string[]

  /** @format iri-reference */
  category?: string | null
}

export interface ProductStockIssueRead {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
}

export interface ProductStockReadItemStockRead {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number
  family?: FamilyStockReadItemStockRead | null

  /** @format iri-reference */
  lang?: string | null
  tax?: TaxStockReadItemStockRead

  /** @format iri-reference */
  category?: string | null
}

export interface ProductStockReadItemStockReadStockStats {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number
  family?: FamilyStockReadItemStockReadStockStats | null

  /** @format iri-reference */
  lang?: string | null
  tax?: TaxStockReadItemStockReadStockStats

  /** @format iri-reference */
  category?: string | null
}

export interface ProductStockReadStockReadItem {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number
  family?: FamilyStockReadStockReadItem | null

  /** @format iri-reference */
  lang?: string | null
  tax?: TaxStockReadStockReadItem

  /** @format iri-reference */
  category?: string | null
}

export interface ProductWarehouseStockEntryRead {
  manufacturerPartNumber?: string | null
  name?: string

  /** @format iri-reference */
  category?: string | null
}

export interface ProductWarehouseStockRead {
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  lang?: string | null

  /** @format iri-reference */
  category?: string | null
}

export interface ProductJsonldInventoryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  manufacturerPartNumber?: string | null
  name?: string
}

export interface ProductJsonldInventoryReadInventoryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  manufacturerPartNumber?: string | null
  name?: string
}

export interface ProductJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
  family?: FamilyJsonldOrderEntryRead | null
  lang?: LangJsonldOrderEntryRead | null
  productsInPack?: ProductPackJsonldOrderEntryRead[]
  pack?: boolean
  redirectTo?: string | null
}

export interface ProductJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
  family?: FamilyJsonldOrderEntryReadOrderEntryReadItem | null
  lang?: LangJsonldOrderEntryReadOrderEntryReadItem | null
  productsInPack?: ProductPackJsonldOrderEntryReadOrderEntryReadItem[]
  pack?: boolean
  redirectTo?: string | null
}

export interface ProductJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
  family?: FamilyJsonldOrderReadOrderReadItem | null
  lang?: LangJsonldOrderReadOrderReadItem | null
  productsInPack?: string[]
  pack?: boolean
}

export interface ProductJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataJsonldProductReadItemProductReadGqlPublic | null
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadItemProductReadGqlPublic | null
  lang?: LangJsonldProductReadItemProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadItemProductReadGqlPublic[]
  tax?: TaxJsonldProductReadItemProductReadGqlPublic
  productStats?: ProductStatsJsonldProductReadItemProductReadGqlPublic[]
  linkedEdition?: CardEditionJsonldProductReadItemProductReadGqlPublic | null
  productsInPack?: ProductPackJsonldProductReadItemProductReadGqlPublic[]
  packsContainingProduct?: ProductPackJsonldProductReadItemProductReadGqlPublic[]
  pack?: boolean
  inPack?: boolean
  category?: ProductCategoryJsonldProductReadItemProductReadGqlPublic | null
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryJsonldProductReadItemProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  transName?: string | null
  imageUrl?: string | null
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface ProductJsonldProductReadGqlPublic {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataJsonldProductReadGqlPublic | null
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadGqlPublic | null
  lang?: LangJsonldProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  schemaAvailability?: string
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadGqlPublic[]
  tax?: TaxJsonldProductReadGqlPublic
  linkedEdition?: CardEditionJsonldProductReadGqlPublic | null
  pack?: boolean
  inPack?: boolean
  category?: ProductCategoryJsonldProductReadGqlPublic | null
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryJsonldProductReadGqlPublic[]
  redirectTo?: string | null
  maxSellQuantity?: number | null
  isSpecialDiscount?: boolean
  transName?: string | null
  imageUrl?: string | null
  discountedPrice?: number
  isDiscounted?: boolean
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
}

export interface ProductJsonldStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  lang?: string | null
  productStores?: string[]

  /** @format iri-reference */
  category?: string | null
}

export interface ProductJsonldStockIssueRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string
}

export interface ProductJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number
  family?: FamilyJsonldStockReadItemStockRead | null

  /** @format iri-reference */
  lang?: string | null
  tax?: TaxJsonldStockReadItemStockRead

  /** @format iri-reference */
  category?: string | null
}

export interface ProductJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number
  family?: FamilyJsonldStockReadItemStockReadStockStats | null

  /** @format iri-reference */
  lang?: string | null
  tax?: TaxJsonldStockReadItemStockReadStockStats

  /** @format iri-reference */
  category?: string | null
}

export interface ProductJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number
  family?: FamilyJsonldStockReadStockReadItem | null

  /** @format iri-reference */
  lang?: string | null
  tax?: TaxJsonldStockReadStockReadItem

  /** @format iri-reference */
  category?: string | null
}

export interface ProductJsonldWarehouseStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  manufacturerPartNumber?: string | null
  name?: string

  /** @format iri-reference */
  category?: string | null
}

export interface ProductJsonldWarehouseStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  manufacturerPartNumber?: string | null
  name?: string

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /** @format iri-reference */
  family?: string | null

  /** @format iri-reference */
  lang?: string | null

  /** @format iri-reference */
  category?: string | null
}

export interface ProductAssociationAssociationRead {
  id?: number

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  orderEntry?: string

  /**
   * @min 0
   * @example 1
   */
  quantity?: number
  sellPrice?: number | null
  taxRate?: number
  taxOnMargin?: boolean
  buyPrice?: number
}

export interface ProductAssociationJsonldAssociationRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  orderEntry?: string

  /**
   * @min 0
   * @example 1
   */
  quantity?: number
  sellPrice?: number | null
  taxRate?: number
  taxOnMargin?: boolean
  buyPrice?: number
}

export interface ProductBadgeProductBadgeRead {
  id?: number
  translations?: ProductBadgeTranslationProductBadgeRead[]
  imagePath?: string
}

export interface ProductBadgeProductReadItemProductReadGqlPublic {
  id?: number
  translations?: ProductBadgeTranslationProductReadItemProductReadGqlPublic[]
  imagePath?: string
  transName?: string | null
  transDescription?: string | null
}

export interface ProductBadgeProductReadGqlPublic {
  id?: number
  translations?: ProductBadgeTranslationProductReadGqlPublic[]
  imagePath?: string
  transName?: string | null
  transDescription?: string | null
}

export interface ProductBadgeJsonldProductBadgeRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  translations?: ProductBadgeTranslationJsonldProductBadgeRead[]
  imagePath?: string
}

export interface ProductBadgeJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  translations?: ProductBadgeTranslationJsonldProductReadItemProductReadGqlPublic[]
  imagePath?: string
  transName?: string | null
  transDescription?: string | null
}

export interface ProductBadgeJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  translations?: ProductBadgeTranslationJsonldProductReadGqlPublic[]
  imagePath?: string
  transName?: string | null
  transDescription?: string | null
}

export interface ProductBadgeTranslationProductBadgeRead {
  locale?: string
  name?: string
  description?: string
}

export interface ProductBadgeTranslationProductReadItemProductReadGqlPublic {
  locale?: string
  name?: string
  description?: string
}

export interface ProductBadgeTranslationProductReadGqlPublic {
  locale?: string
  name?: string
  description?: string
}

export interface ProductBadgeTranslationJsonldProductBadgeRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  description?: string
}

export interface ProductBadgeTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  description?: string
}

export interface ProductBadgeTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  description?: string
}

export interface ProductCategoryCategoryRead {
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  textHome?: string
  textHomeEn?: string
  titleGoogle?: string | null
  titleGoogleEn?: string | null
  imageRelativeUrl?: string

  /** @format iri-reference */
  family?: string | null
  children?: ProductCategoryCategoryRead[]
  old?: boolean
  productCategoryGroup?: ProductCategoryGroupCategoryRead | null
}

export interface ProductCategoryProductCreationRequestRead {
  name?: string
  nameEn?: string
}

export interface ProductCategoryProductRead {
  name?: string
  nameEn?: string
}

export interface ProductCategoryProductReadItemProductRead {
  name?: string
  nameEn?: string
}

export interface ProductCategoryProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  imageRelativeUrl?: string
  family?: FamilyProductReadItemProductReadGqlPublic | null
  parent?: ProductCategoryProductReadItemProductReadGqlPublic | null
  children?: ProductCategoryProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  position?: number
  storyblokStories?: CategoryStoryblokStoryProductReadItemProductReadGqlPublic[]
  imageUrl?: string | null
}

export interface ProductCategoryProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  imageRelativeUrl?: string
  family?: FamilyProductReadGqlPublic | null
  parent?: ProductCategoryProductReadGqlPublic | null
  children?: ProductCategoryProductReadGqlPublic[]
  transName?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  position?: number
  storyblokStories?: CategoryStoryblokStoryProductReadGqlPublic[]
  imageUrl?: string | null
}

export interface ProductCategoryProductReadProductReadItemProductStats {
  name?: string
  nameEn?: string
}

export interface ProductCategoryProductReadProductStats {
  name?: string
  nameEn?: string
}

export interface ProductCategoryJsonldCategoryRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  textHome?: string
  textHomeEn?: string
  titleGoogle?: string | null
  titleGoogleEn?: string | null
  imageRelativeUrl?: string

  /** @format iri-reference */
  family?: string | null
  children?: ProductCategoryJsonldCategoryRead[]
  old?: boolean
  productCategoryGroup?: ProductCategoryGroupJsonldCategoryRead | null
}

export interface ProductCategoryJsonldProductCreationRequestRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface ProductCategoryJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface ProductCategoryJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface ProductCategoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  imageRelativeUrl?: string
  family?: FamilyJsonldProductReadItemProductReadGqlPublic | null
  parent?: ProductCategoryJsonldProductReadItemProductReadGqlPublic | null
  children?: ProductCategoryJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  position?: number
  storyblokStories?: CategoryStoryblokStoryJsonldProductReadItemProductReadGqlPublic[]
  imageUrl?: string | null
}

export interface ProductCategoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  imageRelativeUrl?: string
  family?: FamilyJsonldProductReadGqlPublic | null
  parent?: ProductCategoryJsonldProductReadGqlPublic | null
  children?: ProductCategoryJsonldProductReadGqlPublic[]
  transName?: string | null
  transShortDescription?: string | null
  transLongDescription?: string | null
  transMetaTitle?: string | null
  transMetaDescription?: string | null
  position?: number
  storyblokStories?: CategoryStoryblokStoryJsonldProductReadGqlPublic[]
  imageUrl?: string | null
}

export interface ProductCategoryJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface ProductCategoryJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
}

export interface ProductCategoryGroupCategoryRead {
  id?: number
  name?: string
  position?: number | null
}

export interface ProductCategoryGroupJsonldCategoryRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  position?: number | null
}

export interface ProductCollection {
  id?: number
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean

  /** @format binary */
  image?: File | null
  imageUrl?: string | null
  imagePath?: string | null

  /** @format date-time */
  imageUpdatedAt?: string | null
  slogan?: string | null
  sloganEn?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  categories?: string[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  products?: string[]

  /** @format iri-reference */
  masterProduct?: string | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  translations?: ProductCollectionTranslation[]
  transName?: string | null
  transSlogan?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transTitleDescription?: string | null
  storyblokStories?: string[]
  currentLocale?: string | null
  fallbackLocale?: string | null
  productsCount?: number
  imageRelativeUrl?: string | null
  translation?: ProductCollectionTranslation
  translationLocales?: string[]
}

export interface ProductCollectionProductCollectionRead {
  id?: number
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null

  /** @format date-time */
  imageUpdatedAt?: string | null
  slogan?: string | null
  sloganEn?: string | null

  /** ProductCategory[] */
  displayedCategories?: string[]

  /** @format iri-reference */
  masterProduct?: string | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  productsCount?: number
}

export interface ProductCollectionProductCollectionReadProductCollectionReadItem {
  id?: number
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null

  /** @format date-time */
  imageUpdatedAt?: string | null
  slogan?: string | null
  sloganEn?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  categories?: ProductCollectionCategoryProductCollectionReadProductCollectionReadItem[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  masterProduct?: SealedProductProductCollectionReadProductCollectionReadItem | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  productsCount?: number
}

export interface ProductCollectionProductCollectionUploadImage {
  /** @format binary */
  image?: File | null
}

export interface ProductCollectionProductCollectionWrite {
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean
  slogan?: string | null
  sloganEn?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  categories?: string[]

  /** ProductCategory[] */
  displayedCategories?: string[]

  /** @format iri-reference */
  masterProduct?: string | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
}

export interface ProductCollectionProductReadItemProductReadGqlPublic {
  id?: number
  name: string
  englishName?: string | null
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null
  slogan?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  categories?: ProductCollectionCategoryProductReadItemProductReadGqlPublic[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  translations?: ProductCollectionTranslationProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transTitleDescription?: string | null
  storyblokStories?: ProductCollectionStoryblokStoryProductReadItemProductReadGqlPublic[]
  productsCount?: number
  imageRelativeUrl?: string | null
}

export interface ProductCollectionProductReadGqlPublic {
  id?: number
  name: string
  englishName?: string | null
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null
  slogan?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  categories?: ProductCollectionCategoryProductReadGqlPublic[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  translations?: ProductCollectionTranslationProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transTitleDescription?: string | null
  storyblokStories?: ProductCollectionStoryblokStoryProductReadGqlPublic[]
  productsCount?: number
  imageRelativeUrl?: string | null
}

export interface ProductCollectionJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean

  /** @format binary */
  image?: File | null
  imageUrl?: string | null
  imagePath?: string | null

  /** @format date-time */
  imageUpdatedAt?: string | null
  slogan?: string | null
  sloganEn?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  categories?: string[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  products?: string[]

  /** @format iri-reference */
  masterProduct?: string | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  translations?: ProductCollectionTranslationJsonld[]
  transName?: string | null
  transSlogan?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transTitleDescription?: string | null
  storyblokStories?: string[]
  currentLocale?: string | null
  fallbackLocale?: string | null
  productsCount?: number
  imageRelativeUrl?: string | null
  translation?: ProductCollectionTranslationJsonld
  translationLocales?: string[]
}

export interface ProductCollectionJsonldProductCollectionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null

  /** @format date-time */
  imageUpdatedAt?: string | null
  slogan?: string | null
  sloganEn?: string | null

  /** ProductCategory[] */
  displayedCategories?: string[]

  /** @format iri-reference */
  masterProduct?: string | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  productsCount?: number
}

export interface ProductCollectionJsonldProductCollectionReadProductCollectionReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null

  /** @format date-time */
  imageUpdatedAt?: string | null
  slogan?: string | null
  sloganEn?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  categories?: ProductCollectionCategoryJsonldProductCollectionReadProductCollectionReadItem[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  masterProduct?: SealedProductJsonldProductCollectionReadProductCollectionReadItem | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  productsCount?: number
}

export interface ProductCollectionJsonldProductCollectionWrite {
  name: string
  englishName?: string | null
  onFamilyHome?: boolean
  onCollectionHome?: boolean
  dedicatedPage?: boolean
  active?: boolean
  slogan?: string | null
  sloganEn?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  categories?: string[]

  /** ProductCategory[] */
  displayedCategories?: string[]

  /** @format iri-reference */
  masterProduct?: string | null
  titleDescription?: string | null
  titleDescriptionEn?: string | null
}

export interface ProductCollectionJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  englishName?: string | null
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null
  slogan?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  categories?: ProductCollectionCategoryJsonldProductReadItemProductReadGqlPublic[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  translations?: ProductCollectionTranslationJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transTitleDescription?: string | null
  storyblokStories?: ProductCollectionStoryblokStoryJsonldProductReadItemProductReadGqlPublic[]
  productsCount?: number
  imageRelativeUrl?: string | null
}

export interface ProductCollectionJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  englishName?: string | null
  dedicatedPage?: boolean
  active?: boolean
  imageUrl?: string | null
  slogan?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  categories?: ProductCollectionCategoryJsonldProductReadGqlPublic[]

  /** ProductCategory[] */
  displayedCategories?: string[]
  titleDescription?: string | null
  titleDescriptionEn?: string | null
  translations?: ProductCollectionTranslationJsonldProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transLongDescription?: string | null
  transShortDescription?: string | null
  transTitleDescription?: string | null
  storyblokStories?: ProductCollectionStoryblokStoryJsonldProductReadGqlPublic[]
  productsCount?: number
  imageRelativeUrl?: string | null
}

export interface ProductCollectionCategory {
  id?: number
  name: string
  position?: number | null
  nameEn?: string | null
  description?: string | null
  descriptionEn?: string | null

  /** @example true */
  ownProductsShown?: boolean

  /** @format iri-reference */
  collection?: string | null
  linkedCategories?: string[]
  entries?: string[]
  translations?: ProductCollectionCategoryTranslation[]
  transName?: string | null
  transDescription?: string | null
  currentLocale?: string | null
  fallbackLocale?: string | null
  translation?: ProductCollectionCategoryTranslation
  catalogEntries?: string[]
  translationLocales?: string[]
}

export interface ProductCollectionCategoryProductCollectionCategoryRead {
  name: string
  position?: number | null
  nameEn?: string | null
  description?: string | null
  descriptionEn?: string | null

  /** @example true */
  ownProductsShown?: boolean

  /** @format iri-reference */
  collection?: string | null
  linkedCategories?: string[]
  entries?: ProductCollectionEntryProductCollectionCategoryRead[]
}

export interface ProductCollectionCategoryProductCollectionCategoryWrite {
  name: string
  position?: number | null
  nameEn?: string | null
  description?: string | null
  descriptionEn?: string | null

  /** @example true */
  ownProductsShown?: boolean

  /** @format iri-reference */
  collection?: string | null
  linkedCategories?: string[]
}

export interface ProductCollectionCategoryProductCollectionEntryRead {
  id?: number
  name: string
}

export interface ProductCollectionCategoryProductCollectionReadProductCollectionReadItem {
  id?: number
  name: string
  position?: number | null
  nameEn?: string | null
  description?: string | null
  descriptionEn?: string | null

  /** @example true */
  ownProductsShown?: boolean
  entries?: string[]
}

export interface ProductCollectionCategoryProductReadItemProductReadGqlPublic {
  id?: number
  name: string
  description?: string | null
  entries?: ProductCollectionEntryProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
  catalogEntries?: ProductCollectionEntryDtoProductReadItemProductReadGqlPublic[]
}

export interface ProductCollectionCategoryProductReadGqlPublic {
  id?: number
  name: string
  description?: string | null
  entries?: ProductCollectionEntryProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
  catalogEntries?: ProductCollectionEntryDtoProductReadGqlPublic[]
}

export interface ProductCollectionCategoryProductCollectionCategoryCreateInputProductCollectionCategoryCreate {
  name: string | null
  nameEn: string | null
  position?: number | null
  description: string | null
  descriptionEn: string | null

  /** @example true */
  ownProductsShown?: boolean

  /** @format iri-reference */
  collection: string
  linkedCategories: string[]
}

export interface ProductCollectionCategoryProductCollectionCategoryCreateInputJsonldProductCollectionCategoryCreate {
  name: string | null
  nameEn: string | null
  position?: number | null
  description: string | null
  descriptionEn: string | null

  /** @example true */
  ownProductsShown?: boolean

  /** @format iri-reference */
  collection: string
  linkedCategories: string[]
}

export interface ProductCollectionCategoryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  position?: number | null
  nameEn?: string | null
  description?: string | null
  descriptionEn?: string | null

  /** @example true */
  ownProductsShown?: boolean

  /** @format iri-reference */
  collection?: string | null
  linkedCategories?: string[]
  entries?: string[]
  translations?: ProductCollectionCategoryTranslationJsonld[]
  transName?: string | null
  transDescription?: string | null
  currentLocale?: string | null
  fallbackLocale?: string | null
  translation?: ProductCollectionCategoryTranslationJsonld
  catalogEntries?: string[]
  translationLocales?: string[]
}

export interface ProductCollectionCategoryJsonldProductCollectionCategoryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  position?: number | null
  nameEn?: string | null
  description?: string | null
  descriptionEn?: string | null

  /** @example true */
  ownProductsShown?: boolean

  /** @format iri-reference */
  collection?: string | null
  linkedCategories?: string[]
  entries?: ProductCollectionEntryJsonldProductCollectionCategoryRead[]
}

export interface ProductCollectionCategoryJsonldProductCollectionEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
}

export interface ProductCollectionCategoryJsonldProductCollectionReadProductCollectionReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  position?: number | null
  nameEn?: string | null
  description?: string | null
  descriptionEn?: string | null

  /** @example true */
  ownProductsShown?: boolean
  entries?: string[]
}

export interface ProductCollectionCategoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  description?: string | null
  entries?: ProductCollectionEntryJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
  catalogEntries?: ProductCollectionEntryDtoJsonldProductReadItemProductReadGqlPublic[]
}

export interface ProductCollectionCategoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  description?: string | null
  entries?: ProductCollectionEntryJsonldProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
  catalogEntries?: ProductCollectionEntryDtoJsonldProductReadGqlPublic[]
}

export interface ProductCollectionCategoryDto {
  id?: number
  transName?: string

  /** @example true */
  ownProductsShown?: boolean
  displayedCategories?: string[]
}

export interface ProductCollectionCategoryDtoProductReadItemProductReadGqlPublic {
  id?: number
  transName?: string

  /** @example true */
  ownProductsShown?: boolean
  displayedCategories?: string[]
}

export interface ProductCollectionCategoryDtoProductReadGqlPublic {
  id?: number
  transName?: string

  /** @example true */
  ownProductsShown?: boolean
  displayedCategories?: string[]
}

export interface ProductCollectionCategoryDtoJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string

  /** @example true */
  ownProductsShown?: boolean
  displayedCategories?: string[]
}

export interface ProductCollectionCategoryDtoJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string

  /** @example true */
  ownProductsShown?: boolean
  displayedCategories?: string[]
}

export interface ProductCollectionCategoryDtoJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string

  /** @example true */
  ownProductsShown?: boolean
  displayedCategories?: string[]
}

export interface ProductCollectionCategoryTranslation {
  id?: number
  locale?: string
  name?: string | null
  description?: string | null

  /** @format iri-reference */
  productCollectionCategory?: string
  translatable?: TranslatableInterface | null
}

export interface ProductCollectionCategoryTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null
  description?: string | null

  /** @format iri-reference */
  productCollectionCategory?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface ProductCollectionEntry {
  id?: number

  /** @format iri-reference */
  productCollectionCategory?: string | null

  /** @format iri-reference */
  product?: string | null
}

export interface ProductCollectionEntryProductCollectionCategoryRead {
  id?: number
  product?: SealedProductProductCollectionCategoryRead | null
}

export interface ProductCollectionEntryProductCollectionEntryRead {
  id?: number
  productCollectionCategory?: ProductCollectionCategoryProductCollectionEntryRead | null
  product?: SealedProductProductCollectionEntryRead | null
}

export interface ProductCollectionEntryProductCollectionEntryWrite {
  /** @format iri-reference */
  productCollectionCategory?: string | null
  product?: SealedProductProductCollectionEntryWrite | null
}

export interface ProductCollectionEntryProductReadItemProductReadGqlPublic {
  product?: SealedProductProductReadItemProductReadGqlPublic | null
}

export interface ProductCollectionEntryProductReadGqlPublic {
  product?: SealedProductProductReadGqlPublic | null
}

export interface ProductCollectionEntryProductCollectionEntryCreateInputProductCollectionEntryCreate {
  /** @format iri-reference */
  category: string

  /** @format iri-reference */
  product: string
  baseBox?: boolean
  expansionBox?: boolean
}

export interface ProductCollectionEntryProductCollectionEntryCreateInputJsonldProductCollectionEntryCreate {
  /** @format iri-reference */
  category: string

  /** @format iri-reference */
  product: string
  baseBox?: boolean
  expansionBox?: boolean
}

export interface ProductCollectionEntryJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  productCollectionCategory?: string | null

  /** @format iri-reference */
  product?: string | null
}

export interface ProductCollectionEntryJsonldProductCollectionCategoryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: SealedProductJsonldProductCollectionCategoryRead | null
}

export interface ProductCollectionEntryJsonldProductCollectionEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  productCollectionCategory?: ProductCollectionCategoryJsonldProductCollectionEntryRead | null
  product?: SealedProductJsonldProductCollectionEntryRead | null
}

export interface ProductCollectionEntryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: SealedProductJsonldProductReadItemProductReadGqlPublic | null
}

export interface ProductCollectionEntryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: SealedProductJsonldProductReadGqlPublic | null
}

export interface ProductCollectionEntryDto {
  id?: number

  /** @format iri-reference */
  product?: string
  position?: number
  positionBis?: number
}

export interface ProductCollectionEntryDtoProductReadItemProductReadGqlPublic {
  id?: number
  product?: SealedProductProductReadItemProductReadGqlPublic
}

export interface ProductCollectionEntryDtoProductReadGqlPublic {
  id?: number
  product?: SealedProductProductReadGqlPublic
}

export interface ProductCollectionEntryDtoJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  product?: string
  position?: number
  positionBis?: number
}

export interface ProductCollectionEntryDtoJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: SealedProductJsonldProductReadItemProductReadGqlPublic
}

export interface ProductCollectionEntryDtoJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: SealedProductJsonldProductReadGqlPublic
}

export interface ProductCollectionStoryblokStory {
  id?: number

  /** @format iri-reference */
  productCollection?: string
  storyId?: number
}

export interface ProductCollectionStoryblokStoryProductReadItemProductReadGqlPublic {
  id?: number
  productCollection?: ProductCollectionProductReadItemProductReadGqlPublic
  storyId?: number
}

export interface ProductCollectionStoryblokStoryProductReadGqlPublic {
  id?: number
  productCollection?: ProductCollectionProductReadGqlPublic
  storyId?: number
}

export interface ProductCollectionStoryblokStoryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  productCollection?: string
  storyId?: number
}

export interface ProductCollectionStoryblokStoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  productCollection?: ProductCollectionJsonldProductReadItemProductReadGqlPublic
  storyId?: number
}

export interface ProductCollectionStoryblokStoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  productCollection?: ProductCollectionJsonldProductReadGqlPublic
  storyId?: number
}

export interface ProductCollectionTranslation {
  id?: number
  locale?: string
  name?: string | null
  slogan?: string | null
  titleDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null

  /** @format iri-reference */
  productCollection?: string
  translatable?: TranslatableInterface | null
}

export interface ProductCollectionTranslationProductReadItemProductReadGqlPublic {
  locale?: string
  name?: string | null
  slogan?: string | null
  titleDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface ProductCollectionTranslationProductReadGqlPublic {
  locale?: string
  name?: string | null
  slogan?: string | null
  titleDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface ProductCollectionTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null
  slogan?: string | null
  titleDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null

  /** @format iri-reference */
  productCollection?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface ProductCollectionTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string | null
  slogan?: string | null
  titleDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface ProductCollectionTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string | null
  slogan?: string | null
  titleDescription?: string | null
  shortDescription?: string | null
  longDescription?: string | null
}

export interface ProductCreationRequestProductCreationRequestRead {
  id?: number
  name: string

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number
  advisedPrice?: number

  /** @example true */
  pricingPolicy?: boolean
  barcode?: string
  family: FamilyProductCreationRequestRead | null
  tax: TaxProductCreationRequestRead | null

  /** @format iri-reference */
  distributor?: string | null
  category: ProductCategoryProductCreationRequestRead | null
  comment?: string

  /** @example created */
  status?: ProductCreationRequestStatus

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorProductCreationRequestRead

  /** @format iri-reference */
  product?: string | null
  bookLaw?: boolean
}

export interface ProductCreationRequestProductCreationRequestReject {
  /** @example created */
  status?: ProductCreationRequestStatus
}

export interface ProductCreationRequestProductCreationRequestValidate {
  /** @example created */
  status?: ProductCreationRequestStatus

  /** @format iri-reference */
  product?: string | null
}

export interface ProductCreationRequestProductCreationRequestWrite {
  name: string

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number
  advisedPrice?: number

  /** @example true */
  pricingPolicy?: boolean
  barcode?: string

  /** @format iri-reference */
  family: string | null

  /** @format iri-reference */
  tax: string | null

  /** @format iri-reference */
  distributor?: string | null

  /** @format iri-reference */
  category: string | null
  comment?: string
  bookLaw?: boolean
}

export interface ProductCreationRequestJsonldProductCreationRequestRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number
  advisedPrice?: number

  /** @example true */
  pricingPolicy?: boolean
  barcode?: string
  family: FamilyJsonldProductCreationRequestRead | null
  tax: TaxJsonldProductCreationRequestRead | null

  /** @format iri-reference */
  distributor?: string | null
  category: ProductCategoryJsonldProductCreationRequestRead | null
  comment?: string

  /** @example created */
  status?: ProductCreationRequestStatus

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldProductCreationRequestRead

  /** @format iri-reference */
  product?: string | null
  bookLaw?: boolean
}

export interface ProductCreationRequestJsonldProductCreationRequestWrite {
  name: string

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number
  advisedPrice?: number

  /** @example true */
  pricingPolicy?: boolean
  barcode?: string

  /** @format iri-reference */
  family: string | null

  /** @format iri-reference */
  tax: string | null

  /** @format iri-reference */
  distributor?: string | null

  /** @format iri-reference */
  category: string | null
  comment?: string
  bookLaw?: boolean
}

export interface ProductDistributor {
  id?: number
  name?: string
  editors?: EditorDistributor[]
}

export interface ProductDistributorProductReadItemProductRead {
  name?: string
}

export interface ProductDistributorProductReadItemProductReadGqlPublic {
  name?: string
}

export interface ProductDistributorProductReadGqlPublic {
  name?: string
}

export interface ProductDistributorProductReadProductStats {
  name?: string
}

export interface ProductDistributorJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  editors?: EditorDistributorJsonld[]
}

export interface ProductDistributorJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface ProductDistributorJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface ProductDistributorJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface ProductDistributorJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
}

export interface ProductEditor {
  id?: number
  name?: string
  distributors?: EditorDistributor[]
  products?: string[]

  /** @format iri-reference */
  mainDistributor?: string | null
}

export interface ProductEditorProductReadItemProductRead {
  id?: number
  name?: string
}

export interface ProductEditorProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
}

export interface ProductEditorProductReadGqlPublic {
  id?: number
  name?: string
}

export interface ProductEditorProductReadProductStats {
  id?: number
  name?: string
  mainDistributor?: ProductDistributorProductReadProductStats | null
}

export interface ProductEditorJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  distributors?: EditorDistributorJsonld[]
  products?: string[]

  /** @format iri-reference */
  mainDistributor?: string | null
}

export interface ProductEditorJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
}

export interface ProductEditorJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
}

export interface ProductEditorJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
}

export interface ProductEditorJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  mainDistributor?: ProductDistributorJsonldProductReadProductStats | null
}

export interface ProductImage {
  id?: number

  /** @format iri-reference */
  product?: string
  imageUrl: string
  title: string
  titleEn?: string

  /** @min 0 */
  position: number
}

export interface ProductImageJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  product?: string
  imageUrl: string
  title: string
  titleEn?: string

  /** @min 0 */
  position: number
}

export type ProductInventoryInventoryCompare = object

export interface ProductInventoryInventoryCreate {
  name?: string

  /** @format iri-reference */
  store?: string | null
  collaborators?: string[]
}

export type ProductInventoryInventoryMerge = object

export interface ProductInventoryInventoryRead {
  id?: number

  /** @format iri-reference */
  linkedEdition?: string | null

  /** @format date-time */
  startedAt?: string | null
  ended?: boolean
  archived?: boolean
  name?: string
  comparisonInventory?: ProductInventoryInventoryRead | null

  /** @format iri-reference */
  store?: string | null
  onlyFoils?: boolean | null
  collaborators?: string[]
}

export interface ProductInventoryInventoryReadInventoryReadItem {
  id?: number

  /** @format iri-reference */
  linkedEdition?: string | null

  /** @format date-time */
  startedAt?: string | null
  ended?: boolean
  archived?: boolean
  name?: string
  comparisonInventory?: ProductInventoryInventoryReadInventoryReadItem | null

  /** @format iri-reference */
  store?: string | null
  onlyFoils?: boolean | null
  entries?: ProductInventoryEntryInventoryReadInventoryReadItem[]
  collaborators?: string[]
}

export type ProductInventoryJsonldInventoryCompare = object

export interface ProductInventoryJsonldInventoryCreate {
  name?: string

  /** @format iri-reference */
  store?: string | null
  collaborators?: string[]
}

export type ProductInventoryJsonldInventoryMerge = object

export interface ProductInventoryJsonldInventoryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  linkedEdition?: string | null

  /** @format date-time */
  startedAt?: string | null
  ended?: boolean
  archived?: boolean
  name?: string
  comparisonInventory?: ProductInventoryJsonldInventoryRead | null

  /** @format iri-reference */
  store?: string | null
  onlyFoils?: boolean | null
  collaborators?: string[]
}

export interface ProductInventoryJsonldInventoryReadInventoryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  linkedEdition?: string | null

  /** @format date-time */
  startedAt?: string | null
  ended?: boolean
  archived?: boolean
  name?: string
  comparisonInventory?: ProductInventoryJsonldInventoryReadInventoryReadItem | null

  /** @format iri-reference */
  store?: string | null
  onlyFoils?: boolean | null
  entries?: ProductInventoryEntryJsonldInventoryReadInventoryReadItem[]
  collaborators?: string[]
}

export interface ProductInventoryEntryInventoryRead {
  id?: number
  product?: ProductInventoryRead

  /** @format date-time */
  addedAt?: string
  countedQuantity?: number

  /** @example -1 */
  expectedQuantity?: number
  checked?: boolean

  /** @format iri-reference */
  checkedBy?: string | null
}

export interface ProductInventoryEntryInventoryReadInventoryReadItem {
  id?: number
  product?: ProductInventoryReadInventoryReadItem

  /** @format date-time */
  addedAt?: string
  countedQuantity?: number

  /** @example -1 */
  expectedQuantity?: number
  checked?: boolean

  /** @format iri-reference */
  checkedBy?: string | null
}

export interface ProductInventoryEntryJsonldInventoryRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  product?: ProductJsonldInventoryRead

  /** @format date-time */
  addedAt?: string
  countedQuantity?: number

  /** @example -1 */
  expectedQuantity?: number
  checked?: boolean

  /** @format iri-reference */
  checkedBy?: string | null
}

export interface ProductInventoryEntryJsonldInventoryReadInventoryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: ProductJsonldInventoryReadInventoryReadItem

  /** @format date-time */
  addedAt?: string
  countedQuantity?: number

  /** @example -1 */
  expectedQuantity?: number
  checked?: boolean

  /** @format iri-reference */
  checkedBy?: string | null
}

export interface ProductPackOrderEntryRead {
  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
}

export interface ProductPackOrderEntryReadOrderEntryReadItem {
  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
}

export interface ProductPackProductPackRead {
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
}

export interface ProductPackProductReadItemProductRead {
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
  productName?: string
  productImageLink?: string
}

export interface ProductPackProductReadItemProductReadGqlPublic {
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
  productName?: string
  productImageLink?: string
}

export interface ProductPackProductReadProductReadItemProductStats {
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
  productName?: string
  productImageLink?: string
}

export interface ProductPackJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
}

export interface ProductPackJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
}

export interface ProductPackJsonldProductPackRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
}

export interface ProductPackJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
  productName?: string
  productImageLink?: string
}

export interface ProductPackJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
  productName?: string
  productImageLink?: string
}

export interface ProductPackJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  pack?: string

  /** @format iri-reference */
  product?: string
  quantity?: number
  unitPrice?: number
  productName?: string
  productImageLink?: string
}

export interface ProductRepurchaseProductRepurchaseRead {
  id?: number

  /** @format iri-reference */
  product?: string | null
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseProductRead {
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseProductReadItemProductRead {
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseProductReadItemProductReadGqlPublic {
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseProductReadGqlPublic {
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseProductReadProductReadItemProductStats {
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseProductReadProductStats {
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseJsonldProductRepurchaseRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  product?: string | null
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductRepurchaseJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  repurchasePrice?: number
  priceIncreased?: boolean
  priceDropped?: boolean

  /** @format date-time */
  updatedAt?: string
}

export interface ProductStatsProductStatsRead {
  id?: number

  /** @format iri-reference */
  product?: string | null

  /** @format iri-reference */
  store?: string | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
  marginPercentage?: number
}

export interface ProductStatsProductReadItemProductRead {
  store?: StoreProductReadItemProductRead | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStatsProductReadItemProductReadGqlPublic {
  store?: StoreProductReadItemProductReadGqlPublic | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStatsProductReadProductReadItemProductStats {
  store?: StoreProductReadProductReadItemProductStats | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStatsProductReadProductStats {
  store?: StoreProductReadProductStats | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStatsJsonldProductStatsRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  product?: string | null

  /** @format iri-reference */
  store?: string | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
  marginPercentage?: number
}

export interface ProductStatsJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  store?: StoreJsonldProductReadItemProductRead | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStatsJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  store?: StoreJsonldProductReadItemProductReadGqlPublic | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStatsJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  store?: StoreJsonldProductReadProductReadItemProductStats | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStatsJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  store?: StoreJsonldProductReadProductStats | null
  lastWeekSells?: number
  lastMonthSells?: number
  lastQuarterSells?: number
  lastYearSells?: number
  allTimeSells?: number
  averageBuyPrice?: number
  lastBuyPrice?: number
  buyCount?: number
  discountSales?: number
}

export interface ProductStoreOrderEntryRead {
  id?: number
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
}

export interface ProductStoreOrderEntryReadOrderEntryReadItem {
  id?: number
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
}

export interface ProductStoreOrderReadOrderReadItem {
  id?: number
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
}

export interface ProductStoreProductStoreCreateProductStoreWrite {
  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  store?: string

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductStoreRead {
  id?: number

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  store?: string

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductStoreWrite {
  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductRead {
  id?: number
  store?: StoreProductRead

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductReadItemProductRead {
  id?: number
  store?: StoreProductReadItemProductRead

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductReadItemProductReadGqlPublic {
  id?: number
  product?: ProductProductReadItemProductReadGqlPublic
  store?: StoreProductReadItemProductReadGqlPublic

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null

  /** @min 0 */
  publicQuantity: number
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductReadGqlPublic {
  id?: number
  product?: ProductProductReadGqlPublic
  store?: StoreProductReadGqlPublic

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null

  /** @min 0 */
  publicQuantity: number
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductReadProductReadItemProductStats {
  id?: number
  store?: StoreProductReadProductReadItemProductStats

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreProductReadProductStats {
  id?: number
  store?: StoreProductReadProductStats

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
}

export interface ProductStoreJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
}

export interface ProductStoreJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
}

export interface ProductStoreJsonldProductStoreCreateProductStoreWrite {
  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  store?: string

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldProductStoreRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  store?: string

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductRead

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductReadItemProductRead

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: ProductJsonldProductReadItemProductReadGqlPublic
  store?: StoreJsonldProductReadItemProductReadGqlPublic

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null

  /** @min 0 */
  publicQuantity: number
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: ProductJsonldProductReadGqlPublic
  store?: StoreJsonldProductReadGqlPublic

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null

  /** @min 0 */
  publicQuantity: number
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductReadProductReadItemProductStats

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductStoreJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductReadProductStats

  /** @format iri-reference */
  storeZone?: string | null
  localPosition?: string | null
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number | null
  specialDiscount?: boolean | null
  synchronizedWithHiboutik?: boolean
  restock?: boolean

  /** @format date-time */
  closestRestockDate?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  alertQuantity?: number | null

  /** @example true */
  defaultAlertQuantity?: boolean
  transApproximateRestockDate?: string | null
  customerSellPrice?: number
  customerDiscount?: number
  customerDiscountPercentage?: number
  discountPercentage?: number
  customerSpecialDiscount?: number
}

export interface ProductSuggestion {
  id?: number

  /** @format iri-reference */
  sourceProduct?: string

  /** @format iri-reference */
  suggestedProduct?: string
  manuallySuggested?: boolean
}

export interface ProductSuggestionProductReadItemProductReadGqlPublic {
  suggestedProduct?: SealedProductProductReadItemProductReadGqlPublic
  manuallySuggested?: boolean
}

export interface ProductSuggestionProductReadGqlPublic {
  suggestedProduct?: SealedProductProductReadGqlPublic
  manuallySuggested?: boolean
}

export interface ProductSuggestionJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  sourceProduct?: string

  /** @format iri-reference */
  suggestedProduct?: string
  manuallySuggested?: boolean
}

export interface ProductSuggestionJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  suggestedProduct?: SealedProductJsonldProductReadItemProductReadGqlPublic
  manuallySuggested?: boolean
}

export interface ProductSuggestionJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  suggestedProduct?: SealedProductJsonldProductReadGqlPublic
  manuallySuggested?: boolean
}

export interface ProductTag {
  id?: number
  products?: string[]
  name: string
  nameEn?: string
  theme?: boolean
  type?: boolean
  range?: boolean
  hidden?: boolean
  description?: string
  descriptionEn?: string
  translations?: ProductTagTranslation[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  translation?: TranslationInterface[]
  transDescription?: string | null
  translationLocales?: string[]
}

export interface ProductTagProductReadItemProductReadGqlPublic {
  id?: number
  name: string
  nameEn?: string
  theme?: boolean
  type?: boolean
  description?: string
  descriptionEn?: string
  transName?: string | null
}

export interface ProductTagProductReadGqlPublic {
  id?: number
  name: string
  nameEn?: string
  theme?: boolean
  type?: boolean
  description?: string
  descriptionEn?: string
  transName?: string | null
}

export interface ProductTagJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  products?: string[]
  name: string
  nameEn?: string
  theme?: boolean
  type?: boolean
  range?: boolean
  hidden?: boolean
  description?: string
  descriptionEn?: string
  translations?: ProductTagTranslationJsonld[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  translation?: TranslationInterfaceJsonld[]
  transDescription?: string | null
  translationLocales?: string[]
}

export interface ProductTagJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn?: string
  theme?: boolean
  type?: boolean
  description?: string
  descriptionEn?: string
  transName?: string | null
}

export interface ProductTagJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn?: string
  theme?: boolean
  type?: boolean
  description?: string
  descriptionEn?: string
  transName?: string | null
}

export interface ProductTagTranslation {
  id?: number
  locale?: string
  name?: string | null
  description?: string | null

  /** @format iri-reference */
  productTag?: string
  translatable?: TranslatableInterface | null
}

export interface ProductTagTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null
  description?: string | null

  /** @format iri-reference */
  productTag?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface ProductType {
  id?: number
  name: string
  nameEn?: string | null
}

export interface ProductTypeJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name: string
  nameEn?: string | null
}

export interface ProductVideo {
  id?: number
  youtubeId: string

  /** @format iri-reference */
  product?: string
  title?: string | null
}

export interface ProductVideoProductReadItemProductReadGqlPublic {
  youtubeId: string
  title?: string | null
}

export interface ProductVideoProductReadGqlPublic {
  youtubeId: string
  title?: string | null
}

export interface ProductVideoJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  youtubeId: string

  /** @format iri-reference */
  product?: string
  title?: string | null
}

export interface ProductVideoJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  youtubeId: string
  title?: string | null
}

export interface ProductVideoJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  youtubeId: string
  title?: string | null
}

export interface RestockPrintRestockPrintRead {
  id?: number

  /** @format iri-reference */
  store?: string

  /** @format date-time */
  printedAt?: string
  printedBy?: CollaboratorRestockPrintRead | null
  downloadLink?: string
}

export interface RestockPrintJsonldRestockPrintRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  store?: string

  /** @format date-time */
  printedAt?: string
  printedBy?: CollaboratorJsonldRestockPrintRead | null
  downloadLink?: string
}

export interface Retailer {
  token?: string | null
  fullName?: string | null
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  password?: string | null
  vouchers?: string[]
  fidelityPoints?: string[]
  litigations?: string[]
  orders?: string[]
  reviews?: string[]
  warehouseStocks?: string[]
  cartEntries?: string[]
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  events?: string[]

  /** @format iri-reference */
  playinStore?: string | null

  /** @format iri-reference */
  playinAccountType?: string | null
  relatedCoupons?: string[]
  accountType?: 'INTERNAL' | 'CUSTOMER' | 'SELLERMANIA' | 'CARDTRADER' | 'CARDMARKET' | 'EMPLOYEE' | 'STORE_PC'
  comment?: string | null
  blacklisted?: boolean

  /** @format iri-reference */
  collaboratorAccount?: string | null
  googleId?: string | null

  /** @format iri-reference */
  preferredStore?: string | null
  playinInternal?: boolean

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null

  /** @format iri-reference */
  customerId?: string | null

  /** @format iri-reference */
  storeFranchise?: string | null
  totalPointsToConvert?: number | null
  totalVoucherNextLevelPoints?: number | null
  totalMissingFidelityPoints?: number | null
  logEntries?: string[]
  stockAlerts?: string[]
  userIdentifier?: string
  username?: string
  salt?: string | null
  roles?: string[]
  totalVouchersValue?: number
  orderedFidelityPoints?: Collection
  totalRemainingFidelityPoints?: number
  buybacks?: Collection

  /** @format date-time */
  firstOrderDate?: string
  monthsElapsedSinceFirstOrder?: number

  /** @format iri-reference */
  nextVoucher?: string | null
  totalVouchersNextUse?: number | null

  /** @format date-time */
  expiryDateNextVouchers?: string | null

  /** Renvoie la valeur totale des achats du client, sans compter ses rachats. */
  totalOrdersValue?: number

  /** Renvoie la valeur totale des achats du client, sans compter ses rachats. */
  totalBuybacksValue?: number

  /**
   * Renvoie le nombre total de commandes faites par ce client, sans compter
   * ses rachats.
   */
  ordersCount?: number

  /**
   * Renvoie le nombre total de commandes faites par ce client, sans compter
   * ses rachats.
   */
  buybacksCount?: number

  /** Renvoie le panier moyen du client, sans compter ses rachats. */
  meanCartValue?: number

  /** Renvoie le panier moyen du client, sans compter ses rachats. */
  meanBuybackValue?: number
  sellermania?: boolean
  cardTrader?: boolean
  storeEvents?: string[]
  futureEventOrders?: string[]
  futureEvents?: string[]
  eventsByDate?: string[]
}

export interface RetailerCustomerPatch {
  vatRegistrationNumber?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  fidelityCardNumber?: string | null

  /** @format iri-reference */
  playinStore?: string | null
  comment?: string | null
  blacklisted?: boolean
}

export interface RetailerCustomerRead {
  fullName?: string | null
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreCustomerRead | null
  playinAccountType?: CustomerInternalTypeCustomerRead | null
  accountType?: 'INTERNAL' | 'CUSTOMER' | 'SELLERMANIA' | 'CARDTRADER' | 'CARDMARKET' | 'EMPLOYEE' | 'STORE_PC'
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorCustomerRead | null
  googleId?: string | null
  preferredStore?: StoreCustomerRead | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
}

export interface RetailerRetailerReadCustomerRead {
  fullName?: string | null
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreRetailerReadCustomerRead | null
  playinAccountType?: CustomerInternalTypeRetailerReadCustomerRead | null
  accountType?: 'INTERNAL' | 'CUSTOMER' | 'SELLERMANIA' | 'CARDTRADER' | 'CARDMARKET' | 'EMPLOYEE' | 'STORE_PC'
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorRetailerReadCustomerRead | null
  googleId?: string | null
  preferredStore?: StoreRetailerReadCustomerRead | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
}

export type RetailerRetailerTransformPro = object

export interface RetailerJsonldCustomerPatch {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  vatRegistrationNumber?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  fidelityCardNumber?: string | null

  /** @format iri-reference */
  playinStore?: string | null
  comment?: string | null
  blacklisted?: boolean
}

export interface RetailerJsonldCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  fullName?: string | null
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreJsonldCustomerRead | null
  playinAccountType?: CustomerInternalTypeJsonldCustomerRead | null
  accountType?: 'INTERNAL' | 'CUSTOMER' | 'SELLERMANIA' | 'CARDTRADER' | 'CARDMARKET' | 'EMPLOYEE' | 'STORE_PC'
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorJsonldCustomerRead | null
  googleId?: string | null
  preferredStore?: StoreJsonldCustomerRead | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
}

export interface RetailerJsonldRetailerReadCustomerRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  fullName?: string | null
  id?: number

  /** @format email */
  email: string
  vatRegistrationNumber?: string | null
  remoteProfileModificationToken?: string | null
  deleted?: boolean
  firstname?: string
  lastname?: string
  newsletter?: boolean
  commercialEmails?: boolean
  address?: string | null
  extraAddress?: string | null
  zipCode?: string | null
  city?: string | null
  state?: string
  phone?: string | null
  homePhone?: string | null

  /** @format iri-reference */
  country?: string | null
  synchronizedWithHiboutik?: boolean
  hiboutikId?: number
  fidelityCardNumber?: string | null
  playinStore?: StoreJsonldRetailerReadCustomerRead | null
  playinAccountType?: CustomerInternalTypeJsonldRetailerReadCustomerRead | null
  accountType?: 'INTERNAL' | 'CUSTOMER' | 'SELLERMANIA' | 'CARDTRADER' | 'CARDMARKET' | 'EMPLOYEE' | 'STORE_PC'
  comment?: string | null
  blacklisted?: boolean
  collaboratorAccount?: CollaboratorJsonldRetailerReadCustomerRead | null
  googleId?: string | null
  preferredStore?: StoreJsonldRetailerReadCustomerRead | null

  /** @format date-time */
  registerAt?: string

  /** @format date-time */
  lastLogin?: string | null
}

export type RetailerJsonldRetailerTransformPro = object

export interface Review {
  id?: number

  /** @format iri-reference */
  customer?: string

  /** @format iri-reference */
  product?: string
  rating?: number
  comment?: string | null
  userName?: string
  valid?: boolean
  treated?: boolean

  /** @format date-time */
  date?: string
  isValidPurchase?: boolean
  isAnonymous?: boolean
}

export interface ReviewProductReadItemProductReadGqlPublic {
  id?: number
  customer?: CustomerProductReadItemProductReadGqlPublic
  product?: SealedProductProductReadItemProductReadGqlPublic
  rating?: number
  comment?: string | null
  userName?: string

  /** @format date-time */
  date?: string
  isValidPurchase?: boolean
  isAnonymous?: boolean
}

export interface ReviewProductReadGqlPublic {
  id?: number
  customer?: CustomerProductReadGqlPublic
  product?: SealedProductProductReadGqlPublic
  rating?: number
  comment?: string | null
  userName?: string

  /** @format date-time */
  date?: string
  isValidPurchase?: boolean
  isAnonymous?: boolean
}

export interface ReviewJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  customer?: string

  /** @format iri-reference */
  product?: string
  rating?: number
  comment?: string | null
  userName?: string
  valid?: boolean
  treated?: boolean

  /** @format date-time */
  date?: string
  isValidPurchase?: boolean
  isAnonymous?: boolean
}

export interface ReviewJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  customer?: CustomerJsonldProductReadItemProductReadGqlPublic
  product?: SealedProductJsonldProductReadItemProductReadGqlPublic
  rating?: number
  comment?: string | null
  userName?: string

  /** @format date-time */
  date?: string
  isValidPurchase?: boolean
  isAnonymous?: boolean
}

export interface ReviewJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  customer?: CustomerJsonldProductReadGqlPublic
  product?: SealedProductJsonldProductReadGqlPublic
  rating?: number
  comment?: string | null
  userName?: string

  /** @format date-time */
  date?: string
  isValidPurchase?: boolean
  isAnonymous?: boolean
}

export interface Reward {
  id?: number
  name?: string
  nameEn?: string

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  category?: string

  /** @format date-time */
  date?: string
  image?: string

  /** @example true */
  active?: boolean
  translations?: RewardTranslation[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  translation?: RewardTranslation
  translationLocales?: string[]
}

export interface RewardProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
  category?: RewardCategoryProductReadItemProductReadGqlPublic

  /** @format date-time */
  date?: string
  transName?: string | null
}

export interface RewardProductReadGqlPublic {
  id?: number
  name?: string
  category?: RewardCategoryProductReadGqlPublic

  /** @format date-time */
  date?: string
  transName?: string | null
}

export interface RewardJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  nameEn?: string

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  category?: string

  /** @format date-time */
  date?: string
  image?: string

  /** @example true */
  active?: boolean
  translations?: RewardTranslationJsonld[]
  transName?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  translation?: RewardTranslationJsonld
  translationLocales?: string[]
}

export interface RewardJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  category?: RewardCategoryJsonldProductReadItemProductReadGqlPublic

  /** @format date-time */
  date?: string
  transName?: string | null
}

export interface RewardJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  category?: RewardCategoryJsonldProductReadGqlPublic

  /** @format date-time */
  date?: string
  transName?: string | null
}

export interface RewardCategory {
  id?: number
  name?: string
  nameEn?: string

  /** @min 0 */
  position: number
  imageRelativeUrl?: string | null

  /** @example true */
  active?: boolean
  iconRelativeUrl?: string | null
  translations?: RewardCategoryTranslation[]
  rewards?: string[]
  transName?: string | null
  transSlogan?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transLongDescription?: string | null
  storyblokStories?: string[]

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  iconUrl?: string | null
  imageName?: string | null
  imageUrl?: string | null
  imagePath?: string | null
  translation?: RewardCategoryTranslation
  translationLocales?: string[]
}

export interface RewardCategoryProductReadItemProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  iconRelativeUrl?: string | null
  translations?: RewardCategoryTranslationProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transLongDescription?: string | null
  storyblokStories?: RewardCategoryStoryblokStoryProductReadItemProductReadGqlPublic[]
  iconUrl?: string | null
  imageUrl?: string | null
  imagePath?: string | null
}

export interface RewardCategoryProductReadGqlPublic {
  id?: number
  name?: string
  nameEn?: string
  iconRelativeUrl?: string | null
  translations?: RewardCategoryTranslationProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transLongDescription?: string | null
  storyblokStories?: RewardCategoryStoryblokStoryProductReadGqlPublic[]
  iconUrl?: string | null
  imageUrl?: string | null
  imagePath?: string | null
}

export interface RewardCategoryJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  nameEn?: string

  /** @min 0 */
  position: number
  imageRelativeUrl?: string | null

  /** @example true */
  active?: boolean
  iconRelativeUrl?: string | null
  translations?: RewardCategoryTranslationJsonld[]
  rewards?: string[]
  transName?: string | null
  transSlogan?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transLongDescription?: string | null
  storyblokStories?: string[]

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  iconUrl?: string | null
  imageName?: string | null
  imageUrl?: string | null
  imagePath?: string | null
  translation?: RewardCategoryTranslationJsonld
  translationLocales?: string[]
}

export interface RewardCategoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  iconRelativeUrl?: string | null
  translations?: RewardCategoryTranslationJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transLongDescription?: string | null
  storyblokStories?: RewardCategoryStoryblokStoryJsonldProductReadItemProductReadGqlPublic[]
  iconUrl?: string | null
  imageUrl?: string | null
  imagePath?: string | null
}

export interface RewardCategoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  iconRelativeUrl?: string | null
  translations?: RewardCategoryTranslationJsonldProductReadGqlPublic[]
  transName?: string | null
  transSlogan?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transLongDescription?: string | null
  storyblokStories?: RewardCategoryStoryblokStoryJsonldProductReadGqlPublic[]
  iconUrl?: string | null
  imageUrl?: string | null
  imagePath?: string | null
}

export interface RewardCategoryStoryblokStory {
  id?: number

  /** @format iri-reference */
  rewardCategory?: string
  storyId?: number
}

export interface RewardCategoryStoryblokStoryProductReadItemProductReadGqlPublic {
  id?: number
  rewardCategory?: RewardCategoryProductReadItemProductReadGqlPublic
  storyId?: number
}

export interface RewardCategoryStoryblokStoryProductReadGqlPublic {
  id?: number
  rewardCategory?: RewardCategoryProductReadGqlPublic
  storyId?: number
}

export interface RewardCategoryStoryblokStoryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  rewardCategory?: string
  storyId?: number
}

export interface RewardCategoryStoryblokStoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  rewardCategory?: RewardCategoryJsonldProductReadItemProductReadGqlPublic
  storyId?: number
}

export interface RewardCategoryStoryblokStoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  rewardCategory?: RewardCategoryJsonldProductReadGqlPublic
  storyId?: number
}

export interface RewardCategoryTranslation {
  id?: number
  locale?: string

  /** @format iri-reference */
  rewardCategory?: string
  name: string
  slogan?: string
  shortDescription?: string
  description?: string
  longDescription?: string | null
  translatable?: TranslatableInterface | null
}

export interface RewardCategoryTranslationProductReadItemProductReadGqlPublic {
  locale?: string
  name: string
  slogan?: string
  shortDescription?: string
  description?: string
  longDescription?: string | null
}

export interface RewardCategoryTranslationProductReadGqlPublic {
  locale?: string
  name: string
  slogan?: string
  shortDescription?: string
  description?: string
  longDescription?: string | null
}

export interface RewardCategoryTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string

  /** @format iri-reference */
  rewardCategory?: string
  name: string
  slogan?: string
  shortDescription?: string
  description?: string
  longDescription?: string | null
  translatable?: TranslatableInterfaceJsonld | null
}

export interface RewardCategoryTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name: string
  slogan?: string
  shortDescription?: string
  description?: string
  longDescription?: string | null
}

export interface RewardCategoryTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name: string
  slogan?: string
  shortDescription?: string
  description?: string
  longDescription?: string | null
}

export interface RewardTranslation {
  id?: number
  locale?: string
  name?: string | null

  /** @format iri-reference */
  reward?: string
  translatable?: TranslatableInterface | null
}

export interface RewardTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null

  /** @format iri-reference */
  reward?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface SealedProductProductCollectionCategoryRead {
  baseBox?: boolean
  expansionBox?: boolean

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean
  mainImageUrl?: string | null
  imagesUrl?: string[]
  id?: number
  name?: string
  manufacturerPartNumber?: string | null
}

export interface SealedProductProductCollectionEntryRead {
  baseBox?: boolean
  expansionBox?: boolean

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean
  mainImageUrl?: string | null
  imagesUrl?: string[]
  id?: number
  name?: string
  manufacturerPartNumber?: string | null
}

export interface SealedProductProductCollectionEntryWrite {
  baseBox?: boolean
  expansionBox?: boolean
  id?: number
}

export interface SealedProductProductCollectionReadProductCollectionReadItem {
  baseBox?: boolean
  expansionBox?: boolean
  mainImageUrl?: string | null
  imagesUrl?: string[]
  id?: number
  name?: string
  manufacturerPartNumber?: string | null
}

export interface SealedProductProductRead {
  distributor?: CustomerProductRead | null
  baseBox?: boolean
  expansionBox?: boolean
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  secondaryCategories?: ProductCategoryProductRead[]
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean

  /** @format iri-reference */
  productRange?: string | null

  /** @format iri-reference */
  productCollection?: string | null
  multipackQuantity?: number | null
  bundle?: boolean
  bookLaw?: boolean
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  category?: ProductCategoryProductRead | null
  reviewsCount?: number
  quantity?: number
  schemaAvailability?: string
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductRead | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductRead | null
  lang?: LangProductRead | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductRead[]
  tax?: TaxProductRead
  pack?: boolean
  inPack?: boolean
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
}

export interface SealedProductProductReadItemProductRead {
  distributor?: CustomerProductReadItemProductRead | null
  baseBox?: boolean
  expansionBox?: boolean
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorProductReadItemProductRead | null
  secondaryCategories?: ProductCategoryProductReadItemProductRead[]
  boardgameData?: BoardgameDataProductReadItemProductRead | null
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean
  suggestedProducts?: string[]

  /** @format iri-reference */
  productRange?: string | null

  /** @format iri-reference */
  productCollection?: string | null
  multipackQuantity?: number | null
  bundle?: boolean
  bookLaw?: boolean
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionProductReadItemProductRead | null
  category?: ProductCategoryProductReadItemProductRead | null
  topCategory?: ProductCategoryProductReadItemProductRead | null
  reviewsCount?: number
  quantity?: number
  schemaAvailability?: string
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadItemProductRead | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadItemProductRead | null
  lang?: LangProductReadItemProductRead | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadItemProductRead[]
  tax?: TaxProductReadItemProductRead
  productStats?: ProductStatsProductReadItemProductRead[]
  productsInPack?: ProductPackProductReadItemProductRead[]
  packsContainingProduct?: ProductPackProductReadItemProductRead[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface SealedProductProductReadItemProductReadGqlPublic {
  distributor?: CustomerProductReadItemProductReadGqlPublic | null
  baseBox?: boolean
  expansionBox?: boolean
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  longDescription?: string | null
  longDescriptionEn?: string | null
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null

  /** @format date-time */
  nextRestockAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorProductReadItemProductReadGqlPublic | null
  secondaryCategories?: ProductCategoryProductReadItemProductReadGqlPublic[]
  boardgameData?: BoardgameDataProductReadItemProductReadGqlPublic | null
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean
  brand?: BrandProductReadItemProductReadGqlPublic | null
  rewards?: RewardProductReadItemProductReadGqlPublic[]
  badges?: ProductBadgeProductReadItemProductReadGqlPublic[]
  reviews?: ReviewProductReadItemProductReadGqlPublic[]
  types?: ProductTagProductReadItemProductReadGqlPublic[]
  themes?: ProductTagProductReadItemProductReadGqlPublic[]
  artists?: ArtistProductReadItemProductReadGqlPublic[]
  authors?: AuthorProductReadItemProductReadGqlPublic[]
  tags?: ProductTagProductReadItemProductReadGqlPublic[]
  content?: string | null
  rules?: string | null
  contentEn?: string | null
  rulesEn?: string | null
  boardgameRules?: BoardgameRuleProductReadItemProductReadGqlPublic[]
  suggestedProducts?: ProductSuggestionProductReadItemProductReadGqlPublic[]
  productRange?: ProductCollectionProductReadItemProductReadGqlPublic | null
  productCollection?: ProductCollectionProductReadItemProductReadGqlPublic | null
  multipackQuantity?: number | null
  bundle?: boolean
  translations?: SealedProductTranslationProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transContent?: string
  transRules?: string
  transShortDescription?: string | null
  transLongDescription?: string | null
  transApproximateReleaseDate?: string | null
  transApproximateRestockDate?: string | null
  decks?: DeckProductReadItemProductReadGqlPublic[]
  suggestedAccessories?: SealedProductProductReadItemProductReadGqlPublic[]
  videos?: ProductVideoProductReadItemProductReadGqlPublic[]
  storyblokStories?: SealedProductStoryblokStoryProductReadItemProductReadGqlPublic[]
  bookLaw?: boolean
  commercialOffers?: CommercialOfferProductReadItemProductReadGqlPublic[]
  stockAlerts?: StockAlertProductReadItemProductReadGqlPublic[]
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataProductReadItemProductReadGqlPublic | null
  name?: string
  linkedEdition?: CardEditionProductReadItemProductReadGqlPublic | null
  category?: ProductCategoryProductReadItemProductReadGqlPublic | null
  topCategory?: ProductCategoryProductReadItemProductReadGqlPublic | null
  reviewsCount?: number
  reviewsRatingAverage?: number | null
  reviewsAverageRating?: number | null
  reviewsByRating?: string[]
  quantity?: number
  schemaAvailability?: string
  productCollectionCategories?: ProductCollectionCategoryDtoProductReadItemProductReadGqlPublic[]
  sellable?: boolean
  inWarehouse?: boolean
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadItemProductReadGqlPublic | null
  lang?: LangProductReadItemProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadItemProductReadGqlPublic[]
  tax?: TaxProductReadItemProductReadGqlPublic
  productStats?: ProductStatsProductReadItemProductReadGqlPublic[]
  productsInPack?: ProductPackProductReadItemProductReadGqlPublic[]
  packsContainingProduct?: ProductPackProductReadItemProductReadGqlPublic[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryProductReadItemProductReadGqlPublic[]
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface SealedProductProductReadGqlPublic {
  distributor?: CustomerProductReadGqlPublic | null
  baseBox?: boolean
  expansionBox?: boolean
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  longDescription?: string | null
  longDescriptionEn?: string | null
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null

  /** @format date-time */
  nextRestockAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorProductReadGqlPublic | null
  secondaryCategories?: ProductCategoryProductReadGqlPublic[]
  boardgameData?: BoardgameDataProductReadGqlPublic | null
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean
  brand?: BrandProductReadGqlPublic | null
  rewards?: RewardProductReadGqlPublic[]
  badges?: ProductBadgeProductReadGqlPublic[]
  reviews?: ReviewProductReadGqlPublic[]
  types?: ProductTagProductReadGqlPublic[]
  themes?: ProductTagProductReadGqlPublic[]
  artists?: ArtistProductReadGqlPublic[]
  authors?: AuthorProductReadGqlPublic[]
  tags?: ProductTagProductReadGqlPublic[]
  content?: string | null
  rules?: string | null
  contentEn?: string | null
  rulesEn?: string | null
  boardgameRules?: BoardgameRuleProductReadGqlPublic[]
  suggestedProducts?: ProductSuggestionProductReadGqlPublic[]
  productRange?: ProductCollectionProductReadGqlPublic | null
  productCollection?: ProductCollectionProductReadGqlPublic | null
  multipackQuantity?: number | null
  bundle?: boolean
  translations?: SealedProductTranslationProductReadGqlPublic[]
  transName?: string | null
  transContent?: string
  transRules?: string
  transShortDescription?: string | null
  transLongDescription?: string | null
  transApproximateReleaseDate?: string | null
  transApproximateRestockDate?: string | null
  decks?: DeckProductReadGqlPublic[]
  suggestedAccessories?: SealedProductProductReadGqlPublic[]
  videos?: ProductVideoProductReadGqlPublic[]
  storyblokStories?: SealedProductStoryblokStoryProductReadGqlPublic[]
  bookLaw?: boolean
  commercialOffers?: CommercialOfferProductReadGqlPublic[]
  stockAlerts?: StockAlertProductReadGqlPublic[]
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataProductReadGqlPublic | null
  name?: string
  linkedEdition?: CardEditionProductReadGqlPublic | null
  category?: ProductCategoryProductReadGqlPublic | null
  reviewsCount?: number
  reviewsRatingAverage?: number | null
  reviewsAverageRating?: number | null
  reviewsByRating?: string[]
  quantity?: number
  schemaAvailability?: string
  productCollectionCategories?: ProductCollectionCategoryDtoProductReadGqlPublic[]
  sellable?: boolean
  inWarehouse?: boolean
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadGqlPublic | null
  lang?: LangProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadGqlPublic[]
  tax?: TaxProductReadGqlPublic
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryProductReadGqlPublic[]
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
}

export interface SealedProductProductReadProductStats {
  distributor?: CustomerProductReadProductStats | null
  baseBox?: boolean
  expansionBox?: boolean
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorProductReadProductStats | null
  secondaryCategories?: ProductCategoryProductReadProductStats[]
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean

  /** @format iri-reference */
  productRange?: string | null

  /** @format iri-reference */
  productCollection?: string | null
  multipackQuantity?: number | null
  bundle?: boolean
  bookLaw?: boolean
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  category?: ProductCategoryProductReadProductStats | null
  reviewsCount?: number
  quantity?: number
  schemaAvailability?: string
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseProductReadProductStats | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyProductReadProductStats | null
  lang?: LangProductReadProductStats | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreProductReadProductStats[]
  tax?: TaxProductReadProductStats
  productStats?: ProductStatsProductReadProductStats[]
  pack?: boolean
  inPack?: boolean
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
}

export interface SealedProductProductWrite {
  /** @format iri-reference */
  distributor?: string | null
  baseBox?: boolean
  expansionBox?: boolean
  barCode?: string | null
  old?: boolean

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string

  /** @example true */
  pricingPolicy?: boolean
  suggestedProducts?: string[]

  /** @format iri-reference */
  productRange?: string | null

  /** @format iri-reference */
  productCollection?: string | null
  bookLaw?: boolean
  maxSellQuantity?: number | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number

  /** @format iri-reference */
  repurchase?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number

  /** @format iri-reference */
  lang?: string | null

  /** @format iri-reference */
  tax?: string
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  percentageDiscount?: number | null
}

export interface SealedProductProductWritePlace {
  warehousePosition?: string
}

export interface SealedProductJsonldProductCollectionCategoryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  baseBox?: boolean
  expansionBox?: boolean

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean
  mainImageUrl?: string | null
  imagesUrl?: string[]
  id?: number
  name?: string
  manufacturerPartNumber?: string | null
}

export interface SealedProductJsonldProductCollectionEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  baseBox?: boolean
  expansionBox?: boolean

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean
  mainImageUrl?: string | null
  imagesUrl?: string[]
  id?: number
  name?: string
  manufacturerPartNumber?: string | null
}

export interface SealedProductJsonldProductCollectionReadProductCollectionReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  baseBox?: boolean
  expansionBox?: boolean
  mainImageUrl?: string | null
  imagesUrl?: string[]
  id?: number
  name?: string
  manufacturerPartNumber?: string | null
}

export interface SealedProductJsonldProductRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  distributor?: CustomerJsonldProductRead | null
  baseBox?: boolean
  expansionBox?: boolean
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  secondaryCategories?: ProductCategoryJsonldProductRead[]
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean

  /** @format iri-reference */
  productRange?: string | null

  /** @format iri-reference */
  productCollection?: string | null
  multipackQuantity?: number | null
  bundle?: boolean
  bookLaw?: boolean
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  category?: ProductCategoryJsonldProductRead | null
  reviewsCount?: number
  quantity?: number
  schemaAvailability?: string
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductRead | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductRead | null
  lang?: LangJsonldProductRead | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductRead[]
  tax?: TaxJsonldProductRead
  pack?: boolean
  inPack?: boolean
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
}

export interface SealedProductJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  distributor?: CustomerJsonldProductReadItemProductRead | null
  baseBox?: boolean
  expansionBox?: boolean
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorJsonldProductReadItemProductRead | null
  secondaryCategories?: ProductCategoryJsonldProductReadItemProductRead[]
  boardgameData?: BoardgameDataJsonldProductReadItemProductRead | null
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean
  suggestedProducts?: string[]

  /** @format iri-reference */
  productRange?: string | null

  /** @format iri-reference */
  productCollection?: string | null
  multipackQuantity?: number | null
  bundle?: boolean
  bookLaw?: boolean
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  linkedEdition?: CardEditionJsonldProductReadItemProductRead | null
  category?: ProductCategoryJsonldProductReadItemProductRead | null
  topCategory?: ProductCategoryJsonldProductReadItemProductRead | null
  reviewsCount?: number
  quantity?: number
  schemaAvailability?: string
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadItemProductRead | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadItemProductRead | null
  lang?: LangJsonldProductReadItemProductRead | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadItemProductRead[]
  tax?: TaxJsonldProductReadItemProductRead
  productStats?: ProductStatsJsonldProductReadItemProductRead[]
  productsInPack?: ProductPackJsonldProductReadItemProductRead[]
  packsContainingProduct?: ProductPackJsonldProductReadItemProductRead[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface SealedProductJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  distributor?: CustomerJsonldProductReadItemProductReadGqlPublic | null
  baseBox?: boolean
  expansionBox?: boolean
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  longDescription?: string | null
  longDescriptionEn?: string | null
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null

  /** @format date-time */
  nextRestockAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorJsonldProductReadItemProductReadGqlPublic | null
  secondaryCategories?: ProductCategoryJsonldProductReadItemProductReadGqlPublic[]
  boardgameData?: BoardgameDataJsonldProductReadItemProductReadGqlPublic | null
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean
  brand?: BrandJsonldProductReadItemProductReadGqlPublic | null
  rewards?: RewardJsonldProductReadItemProductReadGqlPublic[]
  badges?: ProductBadgeJsonldProductReadItemProductReadGqlPublic[]
  reviews?: ReviewJsonldProductReadItemProductReadGqlPublic[]
  types?: ProductTagJsonldProductReadItemProductReadGqlPublic[]
  themes?: ProductTagJsonldProductReadItemProductReadGqlPublic[]
  artists?: ArtistJsonldProductReadItemProductReadGqlPublic[]
  authors?: AuthorJsonldProductReadItemProductReadGqlPublic[]
  tags?: ProductTagJsonldProductReadItemProductReadGqlPublic[]
  content?: string | null
  rules?: string | null
  contentEn?: string | null
  rulesEn?: string | null
  boardgameRules?: BoardgameRuleJsonldProductReadItemProductReadGqlPublic[]
  suggestedProducts?: ProductSuggestionJsonldProductReadItemProductReadGqlPublic[]
  productRange?: ProductCollectionJsonldProductReadItemProductReadGqlPublic | null
  productCollection?: ProductCollectionJsonldProductReadItemProductReadGqlPublic | null
  multipackQuantity?: number | null
  bundle?: boolean
  translations?: SealedProductTranslationJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transContent?: string
  transRules?: string
  transShortDescription?: string | null
  transLongDescription?: string | null
  transApproximateReleaseDate?: string | null
  transApproximateRestockDate?: string | null
  decks?: DeckJsonldProductReadItemProductReadGqlPublic[]
  suggestedAccessories?: SealedProductJsonldProductReadItemProductReadGqlPublic[]
  videos?: ProductVideoJsonldProductReadItemProductReadGqlPublic[]
  storyblokStories?: SealedProductStoryblokStoryJsonldProductReadItemProductReadGqlPublic[]
  bookLaw?: boolean
  commercialOffers?: CommercialOfferJsonldProductReadItemProductReadGqlPublic[]
  stockAlerts?: StockAlertJsonldProductReadItemProductReadGqlPublic[]
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataJsonldProductReadItemProductReadGqlPublic | null
  name?: string
  linkedEdition?: CardEditionJsonldProductReadItemProductReadGqlPublic | null
  category?: ProductCategoryJsonldProductReadItemProductReadGqlPublic | null
  topCategory?: ProductCategoryJsonldProductReadItemProductReadGqlPublic | null
  reviewsCount?: number
  reviewsRatingAverage?: number | null
  reviewsAverageRating?: number | null
  reviewsByRating?: string[]
  quantity?: number
  schemaAvailability?: string
  productCollectionCategories?: ProductCollectionCategoryDtoJsonldProductReadItemProductReadGqlPublic[]
  sellable?: boolean
  inWarehouse?: boolean
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadItemProductReadGqlPublic | null
  lang?: LangJsonldProductReadItemProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadItemProductReadGqlPublic[]
  tax?: TaxJsonldProductReadItemProductReadGqlPublic
  productStats?: ProductStatsJsonldProductReadItemProductReadGqlPublic[]
  productsInPack?: ProductPackJsonldProductReadItemProductReadGqlPublic[]
  packsContainingProduct?: ProductPackJsonldProductReadItemProductReadGqlPublic[]
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryJsonldProductReadItemProductReadGqlPublic[]
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
  height?: number | null
  width?: number | null
  length?: number | null
}

export interface SealedProductJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  distributor?: CustomerJsonldProductReadGqlPublic | null
  baseBox?: boolean
  expansionBox?: boolean
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  longDescription?: string | null
  longDescriptionEn?: string | null
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null

  /** @format date-time */
  nextRestockAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorJsonldProductReadGqlPublic | null
  secondaryCategories?: ProductCategoryJsonldProductReadGqlPublic[]
  boardgameData?: BoardgameDataJsonldProductReadGqlPublic | null
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean
  brand?: BrandJsonldProductReadGqlPublic | null
  rewards?: RewardJsonldProductReadGqlPublic[]
  badges?: ProductBadgeJsonldProductReadGqlPublic[]
  reviews?: ReviewJsonldProductReadGqlPublic[]
  types?: ProductTagJsonldProductReadGqlPublic[]
  themes?: ProductTagJsonldProductReadGqlPublic[]
  artists?: ArtistJsonldProductReadGqlPublic[]
  authors?: AuthorJsonldProductReadGqlPublic[]
  tags?: ProductTagJsonldProductReadGqlPublic[]
  content?: string | null
  rules?: string | null
  contentEn?: string | null
  rulesEn?: string | null
  boardgameRules?: BoardgameRuleJsonldProductReadGqlPublic[]
  suggestedProducts?: ProductSuggestionJsonldProductReadGqlPublic[]
  productRange?: ProductCollectionJsonldProductReadGqlPublic | null
  productCollection?: ProductCollectionJsonldProductReadGqlPublic | null
  multipackQuantity?: number | null
  bundle?: boolean
  translations?: SealedProductTranslationJsonldProductReadGqlPublic[]
  transName?: string | null
  transContent?: string
  transRules?: string
  transShortDescription?: string | null
  transLongDescription?: string | null
  transApproximateReleaseDate?: string | null
  transApproximateRestockDate?: string | null
  decks?: DeckJsonldProductReadGqlPublic[]
  suggestedAccessories?: SealedProductJsonldProductReadGqlPublic[]
  videos?: ProductVideoJsonldProductReadGqlPublic[]
  storyblokStories?: SealedProductStoryblokStoryJsonldProductReadGqlPublic[]
  bookLaw?: boolean
  commercialOffers?: CommercialOfferJsonldProductReadGqlPublic[]
  stockAlerts?: StockAlertJsonldProductReadGqlPublic[]
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  cardData?: CardDataJsonldProductReadGqlPublic | null
  name?: string
  linkedEdition?: CardEditionJsonldProductReadGqlPublic | null
  category?: ProductCategoryJsonldProductReadGqlPublic | null
  reviewsCount?: number
  reviewsRatingAverage?: number | null
  reviewsAverageRating?: number | null
  reviewsByRating?: string[]
  quantity?: number
  schemaAvailability?: string
  productCollectionCategories?: ProductCollectionCategoryDtoJsonldProductReadGqlPublic[]
  sellable?: boolean
  inWarehouse?: boolean
  imageUrl?: string | null
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadGqlPublic | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadGqlPublic | null
  lang?: LangJsonldProductReadGqlPublic | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadGqlPublic[]
  tax?: TaxJsonldProductReadGqlPublic
  pack?: boolean
  inPack?: boolean
  weight?: number
  weightInGrams?: number | null
  heightInMillimeters?: number | null
  widthInMillimeters?: number | null
  lengthInMillimeters?: number | null
  collectionEntries?: ProductCollectionEntryJsonldProductReadGqlPublic[]
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  discountedPrice?: number
  percentageDiscount?: number | null
  isEligibleToInstallments?: boolean
  secondHand?: boolean
}

export interface SealedProductJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  distributor?: CustomerJsonldProductReadProductStats | null
  baseBox?: boolean
  expansionBox?: boolean
  hiddenDescription?: string
  barCode?: string | null
  old?: boolean
  inStore?: boolean
  nameEn?: string
  storeLabelTitle?: string
  storeLabelSubtitle?: string

  /** @example 1 */
  packing?: number
  warehousePosition?: string

  /** @format date-time */
  releasedAt?: string | null
  isBestSeller?: boolean

  /** @min 0 */
  advisedPrice?: number | null
  visibility?: ProductVisibility
  specialOffer?: string | null
  mainImageUrl?: string | null
  imagesUrl?: string[]
  editor?: ProductEditorJsonldProductReadProductStats | null
  secondaryCategories?: ProductCategoryJsonldProductReadProductStats[]
  approximateReleaseDate?: string
  approximateReleaseDateEn?: string
  isDiscounted?: boolean

  /** @example true */
  pricingPolicy?: boolean

  /** @format iri-reference */
  productRange?: string | null

  /** @format iri-reference */
  productCollection?: string | null
  multipackQuantity?: number | null
  bundle?: boolean
  bookLaw?: boolean
  maxSellQuantity?: number | null
  quantityInOngoingStocks?: number
  quantityInOngoingOrders?: number
  id?: number
  name?: string
  category?: ProductCategoryJsonldProductReadProductStats | null
  reviewsCount?: number
  quantity?: number
  schemaAvailability?: string
  manufacturerPartNumber?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  price?: number

  /**
   * @min 0
   * @max 9999999
   */
  sellPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  priceWithoutDiscount?: number
  fidelityPoints?: number
  repurchase?: ProductRepurchaseJsonldProductReadProductStats | null

  /**
   * @min 0
   * @max 9999999
   */
  discount?: number

  /**
   * @min 0
   * @max 100
   */
  discountPercentage?: number
  family?: FamilyJsonldProductReadProductStats | null
  lang?: LangJsonldProductReadProductStats | null
  onlineQuantity?: number
  hiboutikId?: number | null
  synchronizedHiboutik?: boolean
  productStores?: ProductStoreJsonldProductReadProductStats[]
  tax?: TaxJsonldProductReadProductStats
  productStats?: ProductStatsJsonldProductReadProductStats[]
  pack?: boolean
  inPack?: boolean
  redirectTo?: string | null
  isSpecialDiscount?: boolean
  percentageDiscount?: number | null
  secondHand?: boolean
}

export interface SealedProductStoryblokStory {
  id?: number

  /** @format iri-reference */
  sealedProduct?: string | null
  storyId?: number
}

export interface SealedProductStoryblokStoryProductReadItemProductReadGqlPublic {
  id?: number
  sealedProduct?: SealedProductProductReadItemProductReadGqlPublic | null
  storyId?: number
}

export interface SealedProductStoryblokStoryProductReadGqlPublic {
  id?: number
  sealedProduct?: SealedProductProductReadGqlPublic | null
  storyId?: number
}

export interface SealedProductStoryblokStoryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  sealedProduct?: string | null
  storyId?: number
}

export interface SealedProductStoryblokStoryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  sealedProduct?: SealedProductJsonldProductReadItemProductReadGqlPublic | null
  storyId?: number
}

export interface SealedProductStoryblokStoryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  sealedProduct?: SealedProductJsonldProductReadGqlPublic | null
  storyId?: number
}

export interface SealedProductTranslation {
  id?: number
  locale?: string
  name?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  approximateReleaseDate?: string | null
  approximateRestockDate?: string | null
  content?: string | null
  rules?: string | null

  /** @format iri-reference */
  sealedProduct?: string
  translatable?: TranslatableInterface | null
}

export interface SealedProductTranslationProductReadItemProductReadGqlPublic {
  locale?: string
  name?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  approximateReleaseDate?: string | null
  approximateRestockDate?: string | null
  content?: string | null
  rules?: string | null
}

export interface SealedProductTranslationProductReadGqlPublic {
  locale?: string
  name?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  approximateReleaseDate?: string | null
  approximateRestockDate?: string | null
  content?: string | null
  rules?: string | null
}

export interface SealedProductTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  approximateReleaseDate?: string | null
  approximateRestockDate?: string | null
  content?: string | null
  rules?: string | null

  /** @format iri-reference */
  sealedProduct?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface SealedProductTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  approximateReleaseDate?: string | null
  approximateRestockDate?: string | null
  content?: string | null
  rules?: string | null
}

export interface SealedProductTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  approximateReleaseDate?: string | null
  approximateRestockDate?: string | null
  content?: string | null
  rules?: string | null
}

export interface SearchResultSuggestionSearchResultSuggestionRead {
  id?: number
  translations?: SearchResultSuggestionTranslationSearchResultSuggestionRead[]
  imagePath?: string
  transName?: string | null
  transDescription?: string | null
}

export interface SearchResultSuggestionJsonldSearchResultSuggestionRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  translations?: SearchResultSuggestionTranslationJsonldSearchResultSuggestionRead[]
  imagePath?: string
  transName?: string | null
  transDescription?: string | null
}

export interface SearchResultSuggestionKeyword {
  id?: number
  keyword?: string

  /** @format iri-reference */
  searchResultSuggestion?: string
}

export interface SearchResultSuggestionKeywordJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  keyword?: string

  /** @format iri-reference */
  searchResultSuggestion?: string
}

export interface SearchResultSuggestionTranslationSearchResultSuggestionRead {
  locale?: string
  name?: string
  description?: string
}

export interface SearchResultSuggestionTranslationJsonldSearchResultSuggestionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  description?: string
}

/**
 * A shipping label
 */
export interface ShippingLabelLog {
  id?: number

  /** @format iri-reference */
  relatedOrder?: string
  shippingNumber?: string

  /** @format iri-reference */
  shippingCountry?: string

  /** @format date-time */
  printedAt?: string

  /** @format iri-reference */
  printedBy?: string
  deleted?: boolean
  type?: string
  shippingNum?: string | null
}

/**
 * A shipping label
 */
export interface ShippingLabelLogShippingLabelLogRead {
  id?: number
  relatedOrder?: OrderShippingLabelLogRead
  shippingNumber?: string
  shippingCountry?: CountryShippingLabelLogRead

  /** @format date-time */
  printedAt?: string
  printedBy?: CollaboratorShippingLabelLogRead
  deleted?: boolean
  type?: string
}

/**
 * A shipping label
 */
export interface ShippingLabelLogJsonld {
  id?: number

  /** @format iri-reference */
  relatedOrder?: string
  shippingNumber?: string

  /** @format iri-reference */
  shippingCountry?: string

  /** @format date-time */
  printedAt?: string

  /** @format iri-reference */
  printedBy?: string
  deleted?: boolean
  type?: string
  shippingNum?: string | null
}

/**
 * A shipping label
 */
export interface ShippingLabelLogJsonldShippingLabelLogRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  relatedOrder?: OrderJsonldShippingLabelLogRead
  shippingNumber?: string
  shippingCountry?: CountryJsonldShippingLabelLogRead

  /** @format date-time */
  printedAt?: string
  printedBy?: CollaboratorJsonldShippingLabelLogRead
  deleted?: boolean
  type?: string
}

export interface ShippingMode {
  id?: number
  name: string
  nameEn: string | null
  technicalName?: string
  description?: string | null
  descriptionEn?: string | null
  standAddress?: string | null
  imageUrl?: string | null
  imageUrlEn?: string | null
  price?: number

  /** @format date-time */
  startsAt?: string | null

  /** @format date-time */
  endsAt?: string | null
  shop?: boolean
  stand?: boolean

  /** @format iri-reference */
  store?: string | null
  shippingType?: ShippingTypeName
  labelType?: ShippingLabelTypeName | null

  /** @example true */
  active?: boolean
  old?: boolean
  labelGenerated?: boolean
  color?: string | null
  backgroundColor?: string | null
  additionalFidelityPoint?: number
  translations?: ShippingModeTranslation[]
  transName?: string | null
  transDescription?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  publicLabelType?: string
  labelTypeDefinedWhenLabelCanBeGenerated?: boolean
  shippingTypeLegalForLabel?: boolean
  translation?: TranslationInterface[]
  translationLocales?: string[]
}

export interface ShippingModeOrderRead {
  name: string
  technicalName?: string
  store?: StoreOrderRead | null
  shippingType?: ShippingTypeName
  labelType?: ShippingLabelTypeName | null
  labelGenerated?: boolean
}

export interface ShippingModeOrderReadOrderReadItem {
  name: string
  technicalName?: string
  store?: StoreOrderReadOrderReadItem | null
  shippingType?: ShippingTypeName
  labelType?: ShippingLabelTypeName | null
  labelGenerated?: boolean
}

export interface ShippingModeProductReadItemProductReadGqlPublic {
  translations?: ShippingModeTranslationProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
}

export interface ShippingModeProductReadGqlPublic {
  translations?: ShippingModeTranslationProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
}

export interface ShippingModeJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name: string
  nameEn: string | null
  technicalName?: string
  description?: string | null
  descriptionEn?: string | null
  standAddress?: string | null
  imageUrl?: string | null
  imageUrlEn?: string | null
  price?: number

  /** @format date-time */
  startsAt?: string | null

  /** @format date-time */
  endsAt?: string | null
  shop?: boolean
  stand?: boolean

  /** @format iri-reference */
  store?: string | null
  shippingType?: ShippingTypeName
  labelType?: ShippingLabelTypeName | null

  /** @example true */
  active?: boolean
  old?: boolean
  labelGenerated?: boolean
  color?: string | null
  backgroundColor?: string | null
  additionalFidelityPoint?: number
  translations?: ShippingModeTranslationJsonld[]
  transName?: string | null
  transDescription?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  publicLabelType?: string
  labelTypeDefinedWhenLabelCanBeGenerated?: boolean
  shippingTypeLegalForLabel?: boolean
  translation?: TranslationInterfaceJsonld[]
  translationLocales?: string[]
}

export interface ShippingModeJsonldOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  technicalName?: string
  store?: StoreJsonldOrderRead | null
  shippingType?: ShippingTypeName
  labelType?: ShippingLabelTypeName | null
  labelGenerated?: boolean
}

export interface ShippingModeJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  technicalName?: string
  store?: StoreJsonldOrderReadOrderReadItem | null
  shippingType?: ShippingTypeName
  labelType?: ShippingLabelTypeName | null
  labelGenerated?: boolean
}

export interface ShippingModeJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  translations?: ShippingModeTranslationJsonldProductReadItemProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
}

export interface ShippingModeJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  translations?: ShippingModeTranslationJsonldProductReadGqlPublic[]
  transName?: string | null
  transDescription?: string | null
}

export interface ShippingModeTranslation {
  id?: number
  locale?: string

  /** @format iri-reference */
  shippingMode?: string
  name?: string
  description?: string | null
  translatable?: TranslatableInterface | null
}

export interface ShippingModeTranslationProductReadItemProductReadGqlPublic {
  locale?: string
  name?: string
  description?: string | null
}

export interface ShippingModeTranslationProductReadGqlPublic {
  locale?: string
  name?: string
  description?: string | null
}

export interface ShippingModeTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string

  /** @format iri-reference */
  shippingMode?: string
  name?: string
  description?: string | null
  translatable?: TranslatableInterfaceJsonld | null
}

export interface ShippingModeTranslationJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  description?: string | null
}

export interface ShippingModeTranslationJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  locale?: string
  name?: string
  description?: string | null
}

export interface ShippingType {
  id?: number
  name?: string
  technicalName?: string
  fast?: boolean
}

export interface ShippingTypeJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string
  technicalName?: string
  fast?: boolean
}

export interface SortOrder {
  key?: string | null
  direction?: string | null
  isDefault?: boolean
  isSelected?: boolean
  transName?: string
}

export interface SortOrderJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  key?: string | null
  direction?: string | null
  isDefault?: boolean
  isSelected?: boolean
  transName?: string
}

export type Stock = object

export interface StockStockEntryRead {
  id?: number
  billNumber?: string | null

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string

  /** @format iri-reference */
  createdBy?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  treatedAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  store: StoreStockEntryRead
  retailer?: CustomerStockEntryRead

  /** @example created */
  status?: StockStatus
}

export interface StockStockEntryTransferred {
  id?: number
  billNumber?: string | null

  /** @format date-time */
  createdAt?: string
  validated: boolean
  treated: boolean
  store: StoreStockEntryTransferred
}

export interface StockStockIssueRead {
  id?: number
  billNumber?: string | null

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number
  retailer?: CustomerStockIssueRead
}

export interface StockStockCreate {
  /** @format iri-reference */
  store: string

  /** @format iri-reference */
  retailer?: string
}

export interface StockStockRead {
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  validatedAt?: string | null
  paymentReceived: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  canceledAt?: string | null
  store: StoreStockRead
  retailer?: CustomerStockRead

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
}

export interface StockStockReadItemStockRead {
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorStockReadItemStockRead | null

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorStockReadItemStockRead | null
  paymentReceived: boolean
  paymentMode?: PaymentModeStockReadItemStockRead | null

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean
  receivedBy?: CollaboratorStockReadItemStockRead | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorStockReadItemStockRead | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorStockReadItemStockRead | null
  store: StoreStockReadItemStockRead
  retailer?: CustomerStockReadItemStockRead
  entries: StockEntryStockReadItemStockRead[]

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorStockReadItemStockRead | null
  totalBuyValue?: number
  cancelable?: boolean
}

export interface StockStockReadItemStockReadStockStats {
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorStockReadItemStockReadStockStats | null

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorStockReadItemStockReadStockStats | null
  paymentReceived: boolean
  paymentMode?: PaymentModeStockReadItemStockReadStockStats | null

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean
  receivedBy?: CollaboratorStockReadItemStockReadStockStats | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorStockReadItemStockReadStockStats | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorStockReadItemStockReadStockStats | null
  store: StoreStockReadItemStockReadStockStats
  retailer?: CustomerStockReadItemStockReadStockStats
  entries: StockEntryStockReadItemStockReadStockStats[]

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorStockReadItemStockReadStockStats | null
  totalBuyValue?: number
  totalBuyValueWithTransferedEntry?: number
  cancelable?: boolean
  potentialSells?: number
  realisedSells?: number
  remainingSellsSellPrice?: number
  remainingSellsBuyPrice?: number
  reimbursementPercentage?: number
}

export interface StockStockReadStockReadItem {
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees?: number

  /** @min 0 */
  miscFees?: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorStockReadStockReadItem | null

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorStockReadStockReadItem | null
  paymentReceived?: boolean
  paymentMode?: PaymentModeStockReadStockReadItem | null

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived?: boolean
  receivedBy?: CollaboratorStockReadStockReadItem | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorStockReadStockReadItem | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorStockReadStockReadItem | null
  store?: StoreStockReadStockReadItem
  retailer?: CustomerStockReadStockReadItem
  entries?: StockEntryStockReadStockReadItem[]

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorStockReadStockReadItem | null
  totalBuyValue?: number
  cancelable?: boolean
}

export interface StockStockWrite {
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees?: number

  /** @min 0 */
  miscFees?: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number
  paymentReceived?: boolean

  /** @format iri-reference */
  paymentMode?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived?: boolean

  /** @format iri-reference */
  store?: string

  /** @format iri-reference */
  retailer?: string

  /** @example created */
  status?: StockStatus
  printed?: boolean
}

export interface StockJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface StockJsonldStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string

  /** @format iri-reference */
  createdBy?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  treatedAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  store: StoreJsonldStockEntryRead
  retailer?: CustomerJsonldStockEntryRead

  /** @example created */
  status?: StockStatus
}

export interface StockJsonldStockEntryTransferred {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null

  /** @format date-time */
  createdAt?: string
  validated: boolean
  treated: boolean
  store: StoreJsonldStockEntryTransferred
}

export interface StockJsonldStockIssueRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number
  retailer?: CustomerJsonldStockIssueRead
}

export interface StockJsonldStockCreate {
  /** @format iri-reference */
  store: string

  /** @format iri-reference */
  retailer?: string
}

export interface StockJsonldStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  validatedAt?: string | null
  paymentReceived: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  canceledAt?: string | null
  store: StoreJsonldStockRead
  retailer?: CustomerJsonldStockRead

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
}

export interface StockJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldStockReadItemStockRead | null

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorJsonldStockReadItemStockRead | null
  paymentReceived: boolean
  paymentMode?: PaymentModeJsonldStockReadItemStockRead | null

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean
  receivedBy?: CollaboratorJsonldStockReadItemStockRead | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorJsonldStockReadItemStockRead | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorJsonldStockReadItemStockRead | null
  store: StoreJsonldStockReadItemStockRead
  retailer?: CustomerJsonldStockReadItemStockRead
  entries: StockEntryJsonldStockReadItemStockRead[]

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorJsonldStockReadItemStockRead | null
  totalBuyValue?: number
  cancelable?: boolean
}

export interface StockJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldStockReadItemStockReadStockStats | null

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorJsonldStockReadItemStockReadStockStats | null
  paymentReceived: boolean
  paymentMode?: PaymentModeJsonldStockReadItemStockReadStockStats | null

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean
  receivedBy?: CollaboratorJsonldStockReadItemStockReadStockStats | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorJsonldStockReadItemStockReadStockStats | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorJsonldStockReadItemStockReadStockStats | null
  store: StoreJsonldStockReadItemStockReadStockStats
  retailer?: CustomerJsonldStockReadItemStockReadStockStats
  entries: StockEntryJsonldStockReadItemStockReadStockStats[]

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorJsonldStockReadItemStockReadStockStats | null
  totalBuyValue?: number
  totalBuyValueWithTransferedEntry?: number
  cancelable?: boolean
  potentialSells?: number
  realisedSells?: number
  remainingSellsSellPrice?: number
  remainingSellsBuyPrice?: number
  reimbursementPercentage?: number
}

export interface StockJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees?: number

  /** @min 0 */
  miscFees?: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldStockReadStockReadItem | null

  /** @format date-time */
  validatedAt?: string | null
  validatedBy?: CollaboratorJsonldStockReadStockReadItem | null
  paymentReceived?: boolean
  paymentMode?: PaymentModeJsonldStockReadStockReadItem | null

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived?: boolean
  receivedBy?: CollaboratorJsonldStockReadStockReadItem | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorJsonldStockReadStockReadItem | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorJsonldStockReadStockReadItem | null
  store?: StoreJsonldStockReadStockReadItem
  retailer?: CustomerJsonldStockReadStockReadItem
  entries?: StockEntryJsonldStockReadStockReadItem[]

  /** @example created */
  status?: StockStatus
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
  printedBy?: CollaboratorJsonldStockReadStockReadItem | null
  totalBuyValue?: number
  cancelable?: boolean
}

export interface StockAlert {
  id?: number

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  customer?: string

  /** @format date-time */
  createdAt?: string

  /** @example fr */
  lang?: string

  /** @example N */
  treated?: string
  isTreated?: boolean
}

export interface StockAlertProductReadItemProductReadGqlPublic {
  id?: number
  product?: SealedProductProductReadItemProductReadGqlPublic
  customer?: CustomerProductReadItemProductReadGqlPublic

  /** @format date-time */
  createdAt?: string

  /** @example fr */
  lang?: string

  /** @example N */
  treated?: string
  isTreated?: boolean
}

export interface StockAlertProductReadGqlPublic {
  id?: number
  product?: SealedProductProductReadGqlPublic
  customer?: CustomerProductReadGqlPublic

  /** @format date-time */
  createdAt?: string

  /** @example fr */
  lang?: string

  /** @example N */
  treated?: string
  isTreated?: boolean
}

export interface StockAlertJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  product?: string

  /** @format iri-reference */
  customer?: string

  /** @format date-time */
  createdAt?: string

  /** @example fr */
  lang?: string

  /** @example N */
  treated?: string
  isTreated?: boolean
}

export interface StockAlertJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: SealedProductJsonldProductReadItemProductReadGqlPublic
  customer?: CustomerJsonldProductReadItemProductReadGqlPublic

  /** @format date-time */
  createdAt?: string

  /** @example fr */
  lang?: string

  /** @example N */
  treated?: string
  isTreated?: boolean
}

export interface StockAlertJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: SealedProductJsonldProductReadGqlPublic
  customer?: CustomerJsonldProductReadGqlPublic

  /** @format date-time */
  createdAt?: string

  /** @example fr */
  lang?: string

  /** @example N */
  treated?: string
  isTreated?: boolean
}

export interface StockEntryStockEntryRead {
  id?: number
  stock?: StockStockEntryRead | null
  product?: ProductStockEntryRead | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxStockEntryRead | null

  /** @format date-time */
  addedAt?: string | null
  remainingQuantity?: number
}

export interface StockEntryStockEntryTransferred {
  stock?: StockStockEntryTransferred | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number
  sourceId?: number | null
}

export interface StockEntryStockEntryWrite {
  /** @format iri-reference */
  stock?: string | null

  /** @format iri-reference */
  product?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @format iri-reference */
  tax?: string | null
  sourceType?: string | null
  sourceId?: number | null
}

export interface StockEntryStockReadItemStockRead {
  id?: number
  product?: ProductStockReadItemStockRead | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxStockReadItemStockRead | null
  remainingQuantity?: number
  customerSellPriceWithDiscount?: number
}

export interface StockEntryStockReadItemStockReadStockStats {
  id?: number
  product?: ProductStockReadItemStockReadStockStats | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxStockReadItemStockReadStockStats | null
  remainingQuantity?: number
  customerSellPriceWithDiscount?: number
}

export interface StockEntryStockReadStockReadItem {
  id?: number
  product?: ProductStockReadStockReadItem | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxStockReadStockReadItem | null
  remainingQuantity?: number
  customerSellPriceWithDiscount?: number
}

export interface StockEntryJsonldStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  stock?: StockJsonldStockEntryRead | null
  product?: ProductJsonldStockEntryRead | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxJsonldStockEntryRead | null

  /** @format date-time */
  addedAt?: string | null
  remainingQuantity?: number
}

export interface StockEntryJsonldStockEntryTransferred {
  '@id'?: string
  '@type'?: string
  stock?: StockJsonldStockEntryTransferred | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number
  sourceId?: number | null
}

export interface StockEntryJsonldStockEntryWrite {
  /** @format iri-reference */
  stock?: string | null

  /** @format iri-reference */
  product?: string | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @format iri-reference */
  tax?: string | null
  sourceType?: string | null
  sourceId?: number | null
}

export interface StockEntryJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: ProductJsonldStockReadItemStockRead | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxJsonldStockReadItemStockRead | null
  remainingQuantity?: number
  customerSellPriceWithDiscount?: number
}

export interface StockEntryJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: ProductJsonldStockReadItemStockReadStockStats | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxJsonldStockReadItemStockReadStockStats | null
  remainingQuantity?: number
  customerSellPriceWithDiscount?: number
}

export interface StockEntryJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  product?: ProductJsonldStockReadStockReadItem | null

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPriceVatExcluded?: number

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number
  tax?: TaxJsonldStockReadStockReadItem | null
  remainingQuantity?: number
  customerSellPriceWithDiscount?: number
}

export interface StockIssueStockIssueRead {
  id?: number
  stock?: StockStockIssueRead | null
  product?: ProductStockIssueRead | null
  sentBack?: boolean

  /**
   * @min 0
   * @max 100
   */
  refundPercentage?: number
  solved?: boolean
  missing?: number
  damaged?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorStockIssueRead | null
}

export interface StockIssueJsonldStockIssueRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  stock?: StockJsonldStockIssueRead | null
  product?: ProductJsonldStockIssueRead | null
  sentBack?: boolean

  /**
   * @min 0
   * @max 100
   */
  refundPercentage?: number
  solved?: boolean
  missing?: number
  damaged?: number

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldStockIssueRead | null
}

export interface StockTransferStockTransferCreate {
  sourceId?: number
  sourceType?: string

  /** @format iri-reference */
  transferredFrom?: string | null

  /** @format iri-reference */
  store: string
}

export interface StockTransferStockReadStockTransferRead {
  /** @format date-time */
  transferredAt?: string
  transferredFrom?: StoreStockReadStockTransferRead | null
  transferredBy?: CollaboratorStockReadStockTransferRead | null
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  validatedAt?: string | null
  paymentReceived: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  canceledAt?: string | null
  store: StoreStockReadStockTransferRead
  retailer?: CustomerStockReadStockTransferRead
  status?: 'created' | 'validated' | 'treated' | 'canceled'
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
}

export interface StockTransferJsonldStockTransferCreate {
  sourceId?: number
  sourceType?: string

  /** @format iri-reference */
  transferredFrom?: string | null

  /** @format iri-reference */
  store: string
}

export interface StockTransferJsonldStockReadStockTransferRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format date-time */
  transferredAt?: string
  transferredFrom?: StoreJsonldStockReadStockTransferRead | null
  transferredBy?: CollaboratorJsonldStockReadStockTransferRead | null
  id?: number
  billNumber?: string | null
  comment?: string | null

  /** @min 0 */
  shippingFees: number

  /** @min 0 */
  miscFees: number

  /**
   * @min 0
   * @max 1
   */
  discountRate?: number

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  validatedAt?: string | null
  paymentReceived: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string | null
  approximateRestockDateEn?: string | null

  /** @format date-time */
  billDate?: string | null
  productReceived: boolean

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  canceledAt?: string | null
  store: StoreJsonldStockReadStockTransferRead
  retailer?: CustomerJsonldStockReadStockTransferRead
  status?: 'created' | 'validated' | 'treated' | 'canceled'
  printed?: boolean

  /** @format date-time */
  printedAt?: string | null
}

export interface Store {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  lat?: string | null
  lng?: string | null
  address?: string | null
  howToGo?: string | null
  howToGoEn?: string | null
  url?: string
  prefixEmail?: string
  franchised?: boolean

  /** @example true */
  eventOrganizer?: boolean
  active?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null
  openingHoursDescription?: string | null
  openingHoursDescriptionEn?: string | null
  exceptionalOpeningDescription?: string | null
  exceptionalOpeningDescriptionEn?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  contactEmail?: string | null
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  cateringDescription?: string | null
  cateringDescriptionEn?: string | null
  menuUrl?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null

  /** @format iri-reference */
  country?: string | null
  orders?: string[]
  restockPrints?: string[]
  productStores?: string[]
  storeZones?: string[]
  hiboutikStore?: HiboutikStore | null

  /** @example 150 */
  defaultCashboxValue?: number
  families?: string[]
  productStats?: string[]
  giftCards?: string[]
  inventories?: string[]
  shippingModes?: string[]
  playinAccounts?: string[]
  cardSeller?: boolean
  givenByPermissions?: boolean
  givenManually?: boolean
  readyOrderChecks?: string[]
  playArea?: boolean
  lastNumOrder?: number

  /** @example 3 */
  warehouseDeliveryTime?: number
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
  gameRentalDescription?: string | null
  gameRentalDescriptionEn?: string | null
  storeHours?: string[]
  pictures?: string[]
  events?: string[]

  /** @format iri-reference */
  company?: string | null
  iban?: string | null
  bic?: string | null
  bankName?: string | null
  bankCity?: string | null
  bankNumber?: string | null
  translations?: StoreTranslation[]
  transHowToGo?: string | null
  transOpeningHoursDescription?: string | null
  transExceptionalOpening?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transPlayAreaDescription?: string | null
  transCateringDescription?: string | null
  transPaybackDescription?: string | null
  transGameRentalDescription?: string | null
  exceptionalHours?: string[]
  weekPlanning?: string[]
  scriptData?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  payBackTcg?: boolean

  /** @format iri-reference */
  coordinates?: string
  translation?: TranslationInterface[]
  eventsByDate?: string[]
  translationLocales?: string[]
}

export interface StoreAddressRead {
  name: string
  address?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null
  country?: CountryAddressRead | null
}

export interface StoreCashControlRead {
  id: number
  shortName: string

  /** @example 150 */
  defaultCashboxValue?: number
}

export interface StoreCustomerRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreCustomerReadCustomerReadItem {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreEventCustomerRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  franchised?: boolean
}

export interface StoreEventOrderRead {
  shortName: string
}

export interface StoreEventRead {
  name: string
  shortName: string
}

export interface StoreEventReadEventReadItem {
  name: string
  shortName: string
}

export interface StoreFidelityPointRead {
  id: number
  name: string
}

export interface StoreOrderEntryRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
}

export interface StoreOrderEntryReadOrderEntryReadItem {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
}

export interface StoreOrderRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreOrderReadOrderReadItem {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null
  country?: CountryOrderReadOrderReadItem | null
  cardSeller?: boolean
}

export interface StorePrintExclusionRead {
  id: number
  name: string
}

export interface StoreProductRead {
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreProductReadItemProductRead {
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreProductReadItemProductReadGqlPublic {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  url?: string

  /** @example true */
  eventOrganizer?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  catering?: boolean
  city?: string | null
  zipCode?: string | null
  country?: CountryProductReadItemProductReadGqlPublic | null
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  storeHours?: StoreHoursProductReadItemProductReadGqlPublic[]
  pictures?: StorePictureProductReadItemProductReadGqlPublic[]
  events?: StoreEventProductReadItemProductReadGqlPublic[]
  transHowToGo?: string | null
  transOpeningHoursDescription?: string | null
  transExceptionalOpening?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transPlayAreaDescription?: string | null
  transCateringDescription?: string | null
  transPaybackDescription?: string | null
  transGameRentalDescription?: string | null
  exceptionalHours?: string[]
  weekPlanning?: string[]
  scriptData?: string | null
  coordinates?: CoordinatesProductReadItemProductReadGqlPublic
  eventsByDate?: string[]
}

export interface StoreProductReadGqlPublic {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  url?: string

  /** @example true */
  eventOrganizer?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  catering?: boolean
  city?: string | null
  zipCode?: string | null
  country?: CountryProductReadGqlPublic | null
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  storeHours?: StoreHoursProductReadGqlPublic[]
  pictures?: StorePictureProductReadGqlPublic[]
  events?: StoreEventProductReadGqlPublic[]
  transHowToGo?: string | null
  transOpeningHoursDescription?: string | null
  transExceptionalOpening?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transPlayAreaDescription?: string | null
  transCateringDescription?: string | null
  transPaybackDescription?: string | null
  transGameRentalDescription?: string | null
  exceptionalHours?: string[]
  weekPlanning?: string[]
  scriptData?: string | null
  coordinates?: CoordinatesProductReadGqlPublic
  eventsByDate?: string[]
}

export interface StoreProductReadProductReadItemProductStats {
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreProductReadProductStats {
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreRetailerReadCustomerRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreStockEntryRead {
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreStockEntryTransferred {
  name: string
}

export interface StoreStockRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreStockReadItemStockRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreStockReadItemStockReadStockStats {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreStockReadStockTransferRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreStockReadStockReadItem {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreStoreHoursRead {
  name: string
}

export interface StoreStoreCreate {
  shortName: string
  franchised?: boolean
  cardSeller?: boolean
}

export interface StoreStorePatch {
  address?: string | null
  howToGo?: string | null
  howToGoEn?: string | null
  franchised?: boolean
  active?: boolean
  facebookPageUrl?: string | null
  openingHoursDescription?: string | null
  openingHoursDescriptionEn?: string | null
  exceptionalOpeningDescription?: string | null
  exceptionalOpeningDescriptionEn?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  contactEmail?: string | null
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  city?: string | null
  zipCode?: string | null
  state?: string | null

  /** @format iri-reference */
  country?: string | null

  /** @example 150 */
  defaultCashboxValue?: number
  families?: string[]
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
  gameRentalDescription?: string | null
  gameRentalDescriptionEn?: string | null
  payBackTcg?: boolean
}

export interface StoreStoreRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  howToGoEn?: string | null
  franchised?: boolean

  /** @example true */
  eventOrganizer?: boolean
  active?: boolean
  facebookPageUrl?: string | null
  openingHoursDescription?: string | null
  openingHoursDescriptionEn?: string | null
  exceptionalOpeningDescription?: string | null
  exceptionalOpeningDescriptionEn?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  contactEmail?: string | null
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  city?: string | null
  zipCode?: string | null
  state?: string | null

  /** @format iri-reference */
  country?: string | null
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
  gameRentalDescription?: string | null
  gameRentalDescriptionEn?: string | null
  company?: CompanyStoreRead | null
  exceptionalHours?: StoreExceptionalHoursStoreRead[]
  scriptData?: string | null
  payBackTcg?: boolean

  /** @format iri-reference */
  coordinates?: string
}

export interface StoreStoreReadStoreReadItem {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  howToGoEn?: string | null
  url?: string
  franchised?: boolean

  /** @example true */
  eventOrganizer?: boolean
  active?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null
  openingHoursDescription?: string | null
  openingHoursDescriptionEn?: string | null
  exceptionalOpeningDescription?: string | null
  exceptionalOpeningDescriptionEn?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  contactEmail?: string | null
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  cateringDescription?: string | null
  cateringDescriptionEn?: string | null
  menuUrl?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null

  /** @format iri-reference */
  country?: string | null
  storeZones?: string[]

  /** @example 150 */
  defaultCashboxValue?: number
  families?: string[]
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
  gameRentalDescription?: string | null
  gameRentalDescriptionEn?: string | null
  company?: CompanyStoreReadStoreReadItem | null
  exceptionalHours?: StoreExceptionalHoursStoreReadStoreReadItem[]
  scriptData?: string | null
  payBackTcg?: boolean

  /** @format iri-reference */
  coordinates?: string
}

export interface StoreUserRead {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  franchised?: boolean
  active?: boolean
  city?: string | null
  zipCode?: string | null
  cardSeller?: boolean
  givenByPermissions?: boolean
  givenManually?: boolean
}

export interface StoreUserReadUserReadItem {
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  franchised?: boolean
  active?: boolean
  city?: string | null
  zipCode?: string | null
  cardSeller?: boolean
  givenByPermissions?: boolean
  givenManually?: boolean
}

export interface StoreJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  lat?: string | null
  lng?: string | null
  address?: string | null
  howToGo?: string | null
  howToGoEn?: string | null
  url?: string
  prefixEmail?: string
  franchised?: boolean

  /** @example true */
  eventOrganizer?: boolean
  active?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null
  openingHoursDescription?: string | null
  openingHoursDescriptionEn?: string | null
  exceptionalOpeningDescription?: string | null
  exceptionalOpeningDescriptionEn?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  contactEmail?: string | null
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  cateringDescription?: string | null
  cateringDescriptionEn?: string | null
  menuUrl?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null

  /** @format iri-reference */
  country?: string | null
  orders?: string[]
  restockPrints?: string[]
  productStores?: string[]
  storeZones?: string[]
  hiboutikStore?: HiboutikStoreJsonld | null

  /** @example 150 */
  defaultCashboxValue?: number
  families?: string[]
  productStats?: string[]
  giftCards?: string[]
  inventories?: string[]
  shippingModes?: string[]
  playinAccounts?: string[]
  cardSeller?: boolean
  givenByPermissions?: boolean
  givenManually?: boolean
  readyOrderChecks?: string[]
  playArea?: boolean
  lastNumOrder?: number

  /** @example 3 */
  warehouseDeliveryTime?: number
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
  gameRentalDescription?: string | null
  gameRentalDescriptionEn?: string | null
  storeHours?: string[]
  pictures?: string[]
  events?: string[]

  /** @format iri-reference */
  company?: string | null
  iban?: string | null
  bic?: string | null
  bankName?: string | null
  bankCity?: string | null
  bankNumber?: string | null
  translations?: StoreTranslationJsonld[]
  transHowToGo?: string | null
  transOpeningHoursDescription?: string | null
  transExceptionalOpening?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transPlayAreaDescription?: string | null
  transCateringDescription?: string | null
  transPaybackDescription?: string | null
  transGameRentalDescription?: string | null
  exceptionalHours?: string[]
  weekPlanning?: string[]
  scriptData?: string | null

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  payBackTcg?: boolean

  /** @format iri-reference */
  coordinates?: string
  translation?: TranslationInterfaceJsonld[]
  eventsByDate?: string[]
  translationLocales?: string[]
}

export interface StoreJsonldAddressRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  address?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null
  country?: CountryJsonldAddressRead | null
}

export interface StoreJsonldCashControlRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  shortName: string

  /** @example 150 */
  defaultCashboxValue?: number
}

export interface StoreJsonldCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldEventCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  franchised?: boolean
}

export interface StoreJsonldEventOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  shortName: string
}

export interface StoreJsonldEventRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  shortName: string
}

export interface StoreJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  shortName: string
}

export interface StoreJsonldFidelityPointRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
}

export interface StoreJsonldOrderEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
}

export interface StoreJsonldOrderEntryReadOrderEntryReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
}

export interface StoreJsonldOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null
  country?: CountryJsonldOrderReadOrderReadItem | null
  cardSeller?: boolean
}

export interface StoreJsonldPrintExclusionRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
}

export interface StoreJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  url?: string

  /** @example true */
  eventOrganizer?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  catering?: boolean
  city?: string | null
  zipCode?: string | null
  country?: CountryJsonldProductReadItemProductReadGqlPublic | null
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  storeHours?: StoreHoursJsonldProductReadItemProductReadGqlPublic[]
  pictures?: StorePictureJsonldProductReadItemProductReadGqlPublic[]
  events?: StoreEventJsonldProductReadItemProductReadGqlPublic[]
  transHowToGo?: string | null
  transOpeningHoursDescription?: string | null
  transExceptionalOpening?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transPlayAreaDescription?: string | null
  transCateringDescription?: string | null
  transPaybackDescription?: string | null
  transGameRentalDescription?: string | null
  exceptionalHours?: string[]
  weekPlanning?: string[]
  scriptData?: string | null
  coordinates?: CoordinatesJsonldProductReadItemProductReadGqlPublic
  eventsByDate?: string[]
}

export interface StoreJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  url?: string

  /** @example true */
  eventOrganizer?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  contactEmail?: string | null
  catering?: boolean
  city?: string | null
  zipCode?: string | null
  country?: CountryJsonldProductReadGqlPublic | null
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  storeHours?: StoreHoursJsonldProductReadGqlPublic[]
  pictures?: StorePictureJsonldProductReadGqlPublic[]
  events?: StoreEventJsonldProductReadGqlPublic[]
  transHowToGo?: string | null
  transOpeningHoursDescription?: string | null
  transExceptionalOpening?: string | null
  transShortDescription?: string | null
  transDescription?: string | null
  transPlayAreaDescription?: string | null
  transCateringDescription?: string | null
  transPaybackDescription?: string | null
  transGameRentalDescription?: string | null
  exceptionalHours?: string[]
  weekPlanning?: string[]
  scriptData?: string | null
  coordinates?: CoordinatesJsonldProductReadGqlPublic
  eventsByDate?: string[]
}

export interface StoreJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldRetailerReadCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldStockEntryTransferred {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
}

export interface StoreJsonldStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldStockReadStockTransferRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  cardSeller?: boolean
}

export interface StoreJsonldStoreHoursRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name: string
}

export interface StoreJsonldStoreCreate {
  shortName: string
  franchised?: boolean
  cardSeller?: boolean
}

export interface StoreJsonldStoreRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  howToGoEn?: string | null
  franchised?: boolean

  /** @example true */
  eventOrganizer?: boolean
  active?: boolean
  facebookPageUrl?: string | null
  openingHoursDescription?: string | null
  openingHoursDescriptionEn?: string | null
  exceptionalOpeningDescription?: string | null
  exceptionalOpeningDescriptionEn?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  contactEmail?: string | null
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  city?: string | null
  zipCode?: string | null
  state?: string | null

  /** @format iri-reference */
  country?: string | null
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
  gameRentalDescription?: string | null
  gameRentalDescriptionEn?: string | null
  company?: CompanyJsonldStoreRead | null
  exceptionalHours?: StoreExceptionalHoursJsonldStoreRead[]
  scriptData?: string | null
  payBackTcg?: boolean

  /** @format iri-reference */
  coordinates?: string
}

export interface StoreJsonldStoreReadStoreReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  howToGo?: string | null
  howToGoEn?: string | null
  url?: string
  franchised?: boolean

  /** @example true */
  eventOrganizer?: boolean
  active?: boolean
  programUrl?: string | null
  facebookPageUrl?: string | null
  openingHoursDescription?: string | null
  openingHoursDescriptionEn?: string | null
  exceptionalOpeningDescription?: string | null
  exceptionalOpeningDescriptionEn?: string | null

  /** @pattern ^(.*(^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$).*)$ */
  phoneNumber?: string | null
  shortDescription?: string | null
  shortDescriptionEn?: string | null
  description?: string | null
  descriptionEn?: string | null
  contactEmail?: string | null
  playAreaDescription?: string | null
  playAreaDescriptionEn?: string | null
  catering?: boolean
  cateringDescription?: string | null
  cateringDescriptionEn?: string | null
  menuUrl?: string | null
  city?: string | null
  zipCode?: string | null
  state?: string | null

  /** @format iri-reference */
  country?: string | null
  storeZones?: string[]

  /** @example 150 */
  defaultCashboxValue?: number
  families?: string[]
  cardSeller?: boolean
  playArea?: boolean

  /** @example 3 */
  warehouseDeliveryTime?: number
  payBackDescription?: string | null
  payBackDescriptionEn?: string | null
  gameRentalDescription?: string | null
  gameRentalDescriptionEn?: string | null
  company?: CompanyJsonldStoreReadStoreReadItem | null
  exceptionalHours?: StoreExceptionalHoursJsonldStoreReadStoreReadItem[]
  scriptData?: string | null
  payBackTcg?: boolean

  /** @format iri-reference */
  coordinates?: string
}

export interface StoreJsonldUserRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  franchised?: boolean
  active?: boolean
  city?: string | null
  zipCode?: string | null
  cardSeller?: boolean
  givenByPermissions?: boolean
  givenManually?: boolean
}

export interface StoreJsonldUserReadUserReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id: number
  name: string
  warehouse?: boolean
  shortName: string
  address?: string | null
  franchised?: boolean
  active?: boolean
  city?: string | null
  zipCode?: string | null
  cardSeller?: boolean
  givenByPermissions?: boolean
  givenManually?: boolean
}

export interface StoreEventCustomerReadCustomerReadItem {
  id?: number
  name?: string
  description?: string
  store?: StoreCustomerReadCustomerReadItem

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string
  format?: StoreEventFormatCustomerReadCustomerReadItem | null
}

export interface StoreEventEventRead {
  id?: number
  name?: string
  nameEn?: string
  description?: string
  store?: StoreEventRead

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  deleted?: boolean
  closed?: boolean
  highlighted?: boolean
  format?: StoreEventFormatEventRead | null
  eventRange?: StoreEventRangeEventRead | null

  /** @example true */
  deliversVouchers?: boolean

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorEventRead | null

  /** @format date-time */
  voucherAddedAt?: string | null
  voucherAddedBy?: CollaboratorEventRead | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorEventRead | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorEventRead | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  sellPrice?: number | null
  customersCount?: number
  status?: StoreEventStatus
  requiredDataNameEn?: string | null
}

export interface StoreEventEventReadEventReadItem {
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  store?: StoreEventReadEventReadItem

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  deleted?: boolean
  closed?: boolean
  highlighted?: boolean
  format?: StoreEventFormatEventReadEventReadItem | null
  customers?: StoreEventCustomerEventReadEventReadItem[]
  eventRange?: StoreEventRangeEventReadEventReadItem | null

  /** @example true */
  deliversVouchers?: boolean

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorEventReadEventReadItem | null

  /** @format date-time */
  voucherAddedAt?: string | null
  voucherAddedBy?: CollaboratorEventReadEventReadItem | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorEventReadEventReadItem | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorEventReadEventReadItem | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  comment?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  sellPrice?: number | null
  customersCount?: number
  status?: StoreEventStatus
  requiredDataNameEn?: string | null
  totalDistributedVouchers?: number
  turnover?: number
}

export interface StoreEventEventWrite {
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  highlighted?: boolean

  /** @format iri-reference */
  format?: string | null

  /** @format iri-reference */
  eventRange?: string | null

  /** @example true */
  deliversVouchers?: boolean

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  comment?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  requiredDataNameEn?: string | null
}

export interface StoreEventEventWriteEventCreate {
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string

  /** @format iri-reference */
  store?: string

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  highlighted?: boolean

  /** @format iri-reference */
  format?: string | null

  /** @format iri-reference */
  eventRange?: string | null

  /** @example true */
  deliversVouchers?: boolean

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  comment?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  requiredDataNameEn?: string | null
}

export interface StoreEventProductReadItemProductReadGqlPublic {
  id?: number
  store?: StoreProductReadItemProductReadGqlPublic

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  highlighted?: boolean
  format?: StoreEventFormatProductReadItemProductReadGqlPublic | null
  eventRange?: StoreEventRangeProductReadItemProductReadGqlPublic | null

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  customerRegistered?: boolean | null
  isInProgress?: boolean
  customersCount?: number
}

export interface StoreEventProductReadGqlPublic {
  id?: number
  store?: StoreProductReadGqlPublic

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  highlighted?: boolean
  format?: StoreEventFormatProductReadGqlPublic | null
  eventRange?: StoreEventRangeProductReadGqlPublic | null

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  customerRegistered?: boolean | null
  isInProgress?: boolean
  customersCount?: number
}

export interface StoreEventStoreEventDeliverVouchersInputEventWrite {
  items?: StoreEventDeliverVouchersItemInputEventWrite[]
}

export interface StoreEventStoreEventDeliverVouchersInputJsonldEventWrite {
  items?: StoreEventDeliverVouchersItemInputJsonldEventWrite[]
}

export interface StoreEventStoreEventDuplicateInputEventWrite {
  /** @format date-time */
  occursAt?: string

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string
}

export interface StoreEventStoreEventDuplicateInputJsonldEventWrite {
  /** @format date-time */
  occursAt?: string

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string
}

export interface StoreEventJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  description?: string
  store?: StoreJsonldCustomerReadCustomerReadItem

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string
  format?: StoreEventFormatJsonldCustomerReadCustomerReadItem | null
}

export interface StoreEventJsonldEventRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  description?: string
  store?: StoreJsonldEventRead

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  deleted?: boolean
  closed?: boolean
  highlighted?: boolean
  format?: StoreEventFormatJsonldEventRead | null
  eventRange?: StoreEventRangeJsonldEventRead | null

  /** @example true */
  deliversVouchers?: boolean

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldEventRead | null

  /** @format date-time */
  voucherAddedAt?: string | null
  voucherAddedBy?: CollaboratorJsonldEventRead | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorJsonldEventRead | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorJsonldEventRead | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  sellPrice?: number | null
  customersCount?: number
  status?: StoreEventStatus
  requiredDataNameEn?: string | null
}

export interface StoreEventJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  store?: StoreJsonldEventReadEventReadItem

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  deleted?: boolean
  closed?: boolean
  highlighted?: boolean
  format?: StoreEventFormatJsonldEventReadEventReadItem | null
  customers?: StoreEventCustomerJsonldEventReadEventReadItem[]
  eventRange?: StoreEventRangeJsonldEventReadEventReadItem | null

  /** @example true */
  deliversVouchers?: boolean

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null

  /** @format date-time */
  createdAt?: string
  createdBy?: CollaboratorJsonldEventReadEventReadItem | null

  /** @format date-time */
  voucherAddedAt?: string | null
  voucherAddedBy?: CollaboratorJsonldEventReadEventReadItem | null

  /** @format date-time */
  treatedAt?: string | null
  treatedBy?: CollaboratorJsonldEventReadEventReadItem | null

  /** @format date-time */
  canceledAt?: string | null
  canceledBy?: CollaboratorJsonldEventReadEventReadItem | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  comment?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  sellPrice?: number | null
  customersCount?: number
  status?: StoreEventStatus
  requiredDataNameEn?: string | null
  totalDistributedVouchers?: number
  turnover?: number
}

export interface StoreEventJsonldEventWriteEventCreate {
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string

  /** @format iri-reference */
  store?: string

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  highlighted?: boolean

  /** @format iri-reference */
  format?: string | null

  /** @format iri-reference */
  eventRange?: string | null

  /** @example true */
  deliversVouchers?: boolean

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  comment?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  requiredDataNameEn?: string | null
}

export interface StoreEventJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductReadItemProductReadGqlPublic

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  highlighted?: boolean
  format?: StoreEventFormatJsonldProductReadItemProductReadGqlPublic | null
  eventRange?: StoreEventRangeJsonldProductReadItemProductReadGqlPublic | null

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  customerRegistered?: boolean | null
  isInProgress?: boolean
  customersCount?: number
}

export interface StoreEventJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductReadGqlPublic

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /**
   * @format date-time
   * @example 14:00
   */
  startsAt?: string

  /**
   * @format date-time
   * @example 18:00
   */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  highlighted?: boolean
  format?: StoreEventFormatJsonldProductReadGqlPublic | null
  eventRange?: StoreEventRangeJsonldProductReadGqlPublic | null

  /** @example true */
  internalRegistration?: boolean

  /** @example true */
  openRegistration?: boolean
  requiredDataName?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  customerRegistered?: boolean | null
  isInProgress?: boolean
  customersCount?: number
}

export interface StoreEventCustomerCustomerReadCustomerReadItem {
  event?: StoreEventCustomerReadCustomerReadItem
  paidPrice?: number
}

export interface StoreEventCustomerEventCustomerRead {
  id?: number
  linkedOrder?: StoreEventOrderEventCustomerRead

  /** @format iri-reference */
  event?: string
  paidPrice?: number
  voucher?: VoucherEventCustomerRead | null
  validated?: boolean
  software?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventCustomerEventReadEventReadItem {
  id?: number
  linkedOrder?: StoreEventOrderEventReadEventReadItem
  paidPrice?: number
  voucher?: VoucherEventReadEventReadItem | null
  validated?: boolean
  software?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventCustomerProductReadItemProductReadGqlPublic {
  id?: number
  linkedOrder?: StoreEventOrderProductReadItemProductReadGqlPublic
  event?: StoreEventProductReadItemProductReadGqlPublic
  paidPrice?: number
  validated?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventCustomerProductReadGqlPublic {
  id?: number
  linkedOrder?: StoreEventOrderProductReadGqlPublic
  event?: StoreEventProductReadGqlPublic
  paidPrice?: number
  validated?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventCustomerStoreEventCustomerCancelInputEventCustomerWrite {
  paymentModes: OrderCancelPaymentInputEventCustomerWrite[]
}

export interface StoreEventCustomerStoreEventCustomerCancelInputJsonldEventCustomerWrite {
  paymentModes: OrderCancelPaymentInputJsonldEventCustomerWrite[]
}

export interface StoreEventCustomerStoreEventCustomerCreateInputEventCustomerWrite {
  /** @format iri-reference */
  event?: string

  /** @format iri-reference */
  customer?: string
  firstName?: string | null
  lastName?: string | null

  /** @format iri-reference */
  paymentMode?: string | null
  price?: number
  additionalData?: string | null

  /** @format iri-reference */
  paymentReceivedBy?: string | null
}

export interface StoreEventCustomerStoreEventCustomerCreateInputJsonldEventCustomerWrite {
  /** @format iri-reference */
  event?: string

  /** @format iri-reference */
  customer?: string
  firstName?: string | null
  lastName?: string | null

  /** @format iri-reference */
  paymentMode?: string | null
  price?: number
  additionalData?: string | null

  /** @format iri-reference */
  paymentReceivedBy?: string | null
}

export interface StoreEventCustomerStoreEventCustomerModifyInputEventCustomerWrite {
  /**
   * @min 0
   * @max 9999999
   */
  paidPrice?: number
  software?: boolean
  additionalData?: string | null
  refunded?: boolean

  /** @format iri-reference */
  paymentReceivedBy?: string | null
}

export interface StoreEventCustomerJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  event?: StoreEventJsonldCustomerReadCustomerReadItem
  paidPrice?: number
}

export interface StoreEventCustomerJsonldEventCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  linkedOrder?: StoreEventOrderJsonldEventCustomerRead

  /** @format iri-reference */
  event?: string
  paidPrice?: number
  voucher?: VoucherJsonldEventCustomerRead | null
  validated?: boolean
  software?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventCustomerJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  linkedOrder?: StoreEventOrderJsonldEventReadEventReadItem
  paidPrice?: number
  voucher?: VoucherJsonldEventReadEventReadItem | null
  validated?: boolean
  software?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventCustomerJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  linkedOrder?: StoreEventOrderJsonldProductReadItemProductReadGqlPublic
  event?: StoreEventJsonldProductReadItemProductReadGqlPublic
  paidPrice?: number
  validated?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventCustomerJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  linkedOrder?: StoreEventOrderJsonldProductReadGqlPublic
  event?: StoreEventJsonldProductReadGqlPublic
  paidPrice?: number
  validated?: boolean
  additionalData?: string | null
  refunded?: boolean
}

export interface StoreEventDeliverVouchersItemInputEventWrite {
  /** @format iri-reference */
  customer?: string
  voucherValue?: number
}

export interface StoreEventDeliverVouchersItemInputJsonldEventWrite {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  customer?: string
  voucherValue?: number
}

export interface StoreEventDto {
  translations?: StoreEventTranslation[]

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  id?: number | null
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string

  /** @format iri-reference */
  store?: string | null

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  deleted?: boolean
  closed?: boolean
  highlighted?: boolean

  /** @format iri-reference */
  format?: string | null
  customers?: string[]

  /** @format iri-reference */
  eventRange?: string | null
  deliversVouchers?: boolean
  internalRegistration?: boolean
  openRegistration?: boolean
  requiredDataName?: string | null

  /** @format date-time */
  createdAt?: string

  /** @format iri-reference */
  createdBy?: string | null

  /** @format date-time */
  voucherAddedAt?: string | null

  /** @format iri-reference */
  voucherAddedBy?: string | null

  /** @format date-time */
  treatedAt?: string

  /** @format iri-reference */
  treatedBy?: string | null

  /** @format date-time */
  canceledAt?: string | null

  /** @format iri-reference */
  canceledBy?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  customerRegistered?: boolean | null
  comment?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  sellPrice?: number | null
  isInProgress?: boolean
  customersCount?: number
  status?: StoreEventStatus
  requiredDataNameEn?: string | null
  totalDistributedVouchers?: number
  turnover?: number
  translation?: TranslationInterface[]
  translationLocales?: string[]
}

export interface StoreEventDtoJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  translations?: StoreEventTranslationJsonld[]

  /** @example fr */
  currentLocale?: string | null

  /** @example fr */
  fallbackLocale?: string | null
  id?: number | null
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string

  /** @format iri-reference */
  store?: string | null

  /** @min 0 */
  price?: number

  /** @min 0 */
  priceWithoutDiscount?: number | null

  /** @format date-time */
  occursAt?: string

  /** @format date-time */
  startsAt?: string

  /** @format date-time */
  endsAt?: string

  /** @min 0 */
  maxCapacity?: number | null
  deleted?: boolean
  closed?: boolean
  highlighted?: boolean

  /** @format iri-reference */
  format?: string | null
  customers?: string[]

  /** @format iri-reference */
  eventRange?: string | null
  deliversVouchers?: boolean
  internalRegistration?: boolean
  openRegistration?: boolean
  requiredDataName?: string | null

  /** @format date-time */
  createdAt?: string

  /** @format iri-reference */
  createdBy?: string | null

  /** @format date-time */
  voucherAddedAt?: string | null

  /** @format iri-reference */
  voucherAddedBy?: string | null

  /** @format date-time */
  treatedAt?: string

  /** @format iri-reference */
  treatedBy?: string | null

  /** @format date-time */
  canceledAt?: string | null

  /** @format iri-reference */
  canceledBy?: string | null
  transName?: string | null
  transSubtitle?: string | null
  transDescription?: string | null
  transRequiredDataName?: string | null
  customerRegistered?: boolean | null
  comment?: string | null
  subtitle?: string | null
  subtitleEn?: string | null
  sellPrice?: number | null
  isInProgress?: boolean
  customersCount?: number
  status?: StoreEventStatus
  requiredDataNameEn?: string | null
  totalDistributedVouchers?: number
  turnover?: number
  translation?: TranslationInterfaceJsonld[]
  translationLocales?: string[]
}

export interface StoreEventFormatCustomerReadCustomerReadItem {
  id?: number
  name?: string

  /** @format iri-reference */
  range?: string
}

export interface StoreEventFormatEventFormatRead {
  id?: number
  name?: string
  nameEn?: string
  position?: number
  range?: StoreEventRangeEventFormatRead
  old?: boolean
  transName?: string | null
}

export interface StoreEventFormatEventFormatWrite {
  name?: string
  nameEn?: string
  position?: number

  /** @format iri-reference */
  range?: string
  old?: boolean
  transName?: string | null
}

export interface StoreEventFormatEventRead {
  name?: string
  nameEn?: string
  range?: StoreEventRangeEventRead
}

export interface StoreEventFormatEventReadEventReadItem {
  name?: string
  nameEn?: string
  range?: StoreEventRangeEventReadEventReadItem
}

export interface StoreEventFormatProductReadItemProductReadGqlPublic {
  id?: number
  range?: StoreEventRangeProductReadItemProductReadGqlPublic
  transName?: string | null
}

export interface StoreEventFormatProductReadGqlPublic {
  id?: number
  range?: StoreEventRangeProductReadGqlPublic
  transName?: string | null
}

export interface StoreEventFormatJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string

  /** @format iri-reference */
  range?: string
}

export interface StoreEventFormatJsonldEventFormatRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name?: string
  nameEn?: string
  position?: number
  range?: StoreEventRangeJsonldEventFormatRead
  old?: boolean
  transName?: string | null
}

export interface StoreEventFormatJsonldEventFormatWrite {
  name?: string
  nameEn?: string
  position?: number

  /** @format iri-reference */
  range?: string
  old?: boolean
  transName?: string | null
}

export interface StoreEventFormatJsonldEventRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
  range?: StoreEventRangeJsonldEventRead
}

export interface StoreEventFormatJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  name?: string
  nameEn?: string
  range?: StoreEventRangeJsonldEventReadEventReadItem
}

export interface StoreEventFormatJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  range?: StoreEventRangeJsonldProductReadItemProductReadGqlPublic
  transName?: string | null
}

export interface StoreEventFormatJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  range?: StoreEventRangeJsonldProductReadGqlPublic
  transName?: string | null
}

export interface StoreEventOrder {
  id?: number
  billNumber?: string | null

  /** @format iri-reference */
  customer?: string

  /** @format date-time */
  orderedAt?: string
  payed?: boolean

  /** @format iri-reference */
  paymentMode?: string | null
  paidPrice?: number
  tracking?: boolean

  /** @format iri-reference */
  seller?: string | null

  /** @format iri-reference */
  paymentReceivedStore?: string | null
  vouchers?: string[]
  storeEventCustomers?: string[]

  /** @format date-time */
  billedAt?: string | null
  redirectUrl?: string | null
  eventRegistrations?: Collection
}

export interface StoreEventOrderCustomerReadCustomerReadItem {
  id?: number

  /** @format date-time */
  orderedAt?: string
  storeEventCustomers?: StoreEventCustomerCustomerReadCustomerReadItem[]
}

export interface StoreEventOrderEventCustomerRead {
  id?: number
  billNumber?: string | null
  customer?: CustomerEventCustomerRead
  payed?: boolean
  paymentMode?: PaymentModeEventCustomerRead | null
  paidPrice?: number
  paymentReceivedStore?: StoreEventCustomerRead | null
}

export interface StoreEventOrderEventOrderRead {
  id?: number
  billNumber?: string | null
  customer?: CustomerEventOrderRead

  /** @format date-time */
  orderedAt?: string
  payed?: boolean
  paymentMode?: PaymentModeEventOrderRead | null
  paidPrice?: number
  tracking?: boolean
  seller?: CollaboratorEventOrderRead | null
  paymentReceivedStore?: StoreEventOrderRead | null
  eventRegistrations?: CollectionEventOrderRead
}

export interface StoreEventOrderEventOrderWrite {
  billNumber?: string | null

  /** @format iri-reference */
  customer?: string

  /** @format iri-reference */
  paymentMode?: string | null
}

export interface StoreEventOrderEventReadEventReadItem {
  id?: number
  billNumber?: string | null
  customer?: CustomerEventReadEventReadItem

  /** @format date-time */
  orderedAt?: string
  payed?: boolean
  paymentMode?: PaymentModeEventReadEventReadItem | null
  paidPrice?: number
  tracking?: boolean
  seller?: CollaboratorEventReadEventReadItem | null
  paymentReceivedStore?: StoreEventReadEventReadItem | null
}

export interface StoreEventOrderProductReadItemProductReadGqlPublic {
  id?: number
  billNumber?: string | null
  customer?: CustomerProductReadItemProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  payed?: boolean

  /** @format iri-reference */
  paymentMode?: string | null
  paidPrice?: number
  storeEventCustomers?: StoreEventCustomerProductReadItemProductReadGqlPublic[]
  redirectUrl?: string | null
}

export interface StoreEventOrderProductReadGqlPublic {
  id?: number
  billNumber?: string | null
  customer?: CustomerProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  payed?: boolean

  /** @format iri-reference */
  paymentMode?: string | null
  paidPrice?: number
  storeEventCustomers?: StoreEventCustomerProductReadGqlPublic[]
  redirectUrl?: string | null
}

export interface StoreEventOrderJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null

  /** @format iri-reference */
  customer?: string

  /** @format date-time */
  orderedAt?: string
  payed?: boolean

  /** @format iri-reference */
  paymentMode?: string | null
  paidPrice?: number
  tracking?: boolean

  /** @format iri-reference */
  seller?: string | null

  /** @format iri-reference */
  paymentReceivedStore?: string | null
  vouchers?: string[]
  storeEventCustomers?: string[]

  /** @format date-time */
  billedAt?: string | null
  redirectUrl?: string | null
  eventRegistrations?: CollectionJsonld
}

export interface StoreEventOrderJsonldCustomerReadCustomerReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format date-time */
  orderedAt?: string
  storeEventCustomers?: StoreEventCustomerJsonldCustomerReadCustomerReadItem[]
}

export interface StoreEventOrderJsonldEventCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  customer?: CustomerJsonldEventCustomerRead
  payed?: boolean
  paymentMode?: PaymentModeJsonldEventCustomerRead | null
  paidPrice?: number
  paymentReceivedStore?: StoreJsonldEventCustomerRead | null
}

export interface StoreEventOrderJsonldEventOrderRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  customer?: CustomerJsonldEventOrderRead

  /** @format date-time */
  orderedAt?: string
  payed?: boolean
  paymentMode?: PaymentModeJsonldEventOrderRead | null
  paidPrice?: number
  tracking?: boolean
  seller?: CollaboratorJsonldEventOrderRead | null
  paymentReceivedStore?: StoreJsonldEventOrderRead | null
  eventRegistrations?: CollectionJsonldEventOrderRead
}

export interface StoreEventOrderJsonldEventOrderWrite {
  billNumber?: string | null

  /** @format iri-reference */
  customer?: string

  /** @format iri-reference */
  paymentMode?: string | null
}

export interface StoreEventOrderJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  customer?: CustomerJsonldEventReadEventReadItem

  /** @format date-time */
  orderedAt?: string
  payed?: boolean
  paymentMode?: PaymentModeJsonldEventReadEventReadItem | null
  paidPrice?: number
  tracking?: boolean
  seller?: CollaboratorJsonldEventReadEventReadItem | null
  paymentReceivedStore?: StoreJsonldEventReadEventReadItem | null
}

export interface StoreEventOrderJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  customer?: CustomerJsonldProductReadItemProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  payed?: boolean

  /** @format iri-reference */
  paymentMode?: string | null
  paidPrice?: number
  storeEventCustomers?: StoreEventCustomerJsonldProductReadItemProductReadGqlPublic[]
  redirectUrl?: string | null
}

export interface StoreEventOrderJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  billNumber?: string | null
  customer?: CustomerJsonldProductReadGqlPublic

  /** @format date-time */
  orderedAt?: string
  payed?: boolean

  /** @format iri-reference */
  paymentMode?: string | null
  paidPrice?: number
  storeEventCustomers?: StoreEventCustomerJsonldProductReadGqlPublic[]
  redirectUrl?: string | null
}

export interface StoreEventRangeEventFormatRangeRead {
  id?: number
  name: string
  nameEn?: string
  old?: boolean
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeEventFormatRangeWrite {
  name: string
  nameEn?: string
  old?: boolean
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeEventFormatRead {
  id?: number
  name: string
  nameEn?: string
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeEventRead {
  id?: number
  name: string
  nameEn?: string
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeEventReadEventReadItem {
  id?: number
  name: string
  nameEn?: string
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeProductReadItemProductReadGqlPublic {
  id?: number
  transName?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeProductReadGqlPublic {
  id?: number
  transName?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeJsonldEventFormatRangeRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn?: string
  old?: boolean
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeJsonldEventFormatRangeWrite {
  name: string
  nameEn?: string
  old?: boolean
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeJsonldEventFormatRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn?: string
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeJsonldEventRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn?: string
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  nameEn?: string
  transName?: string | null
  iconRelativeUrl?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  iconUrl?: string | null
}

export interface StoreEventRangeJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  transName?: string | null
  iconUrl?: string | null
}

export interface StoreEventTranslation {
  id?: number
  locale?: string
  name?: string | null
  subtitle?: string | null
  description?: string | null
  requiredDataName?: string | null

  /** @format iri-reference */
  storeEvent?: string
  translatable?: TranslatableInterface | null
}

export interface StoreEventTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  name?: string | null
  subtitle?: string | null
  description?: string | null
  requiredDataName?: string | null

  /** @format iri-reference */
  storeEvent?: string
  translatable?: TranslatableInterfaceJsonld | null
}

export interface StoreEventsGroupByDate {
  id?: string
  events?: string[]

  /** @format date-time */
  date?: string
}

export interface StoreEventsGroupByDateJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: string
  events?: string[]

  /** @format date-time */
  date?: string
}

export interface StoreExceptionalHoursStoreExcHoursRead {
  id?: number

  /** @format iri-reference */
  store?: string

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreExceptionalHoursStoreExcHoursWrite {
  /** @format iri-reference */
  store?: string

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreExceptionalHoursStoreRead {
  id?: number
  store?: StoreStoreRead

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreExceptionalHoursStoreReadStoreReadItem {
  id?: number
  store?: StoreStoreReadStoreReadItem

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreExceptionalHoursJsonldStoreExcHoursRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  store?: string

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreExceptionalHoursJsonldStoreExcHoursWrite {
  /** @format iri-reference */
  store?: string

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreExceptionalHoursJsonldStoreRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldStoreRead

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreExceptionalHoursJsonldStoreReadStoreReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldStoreReadStoreReadItem

  /** @format date-time */
  exceptionalDate?: string

  /** @format date-time */
  opensAt?: string | null

  /** @format date-time */
  closesAt?: string | null

  /** @example true */
  isOpen?: boolean

  /** @format date-time */
  opensAfternoonAt?: string | null

  /** @format date-time */
  closesAfternoonAt?: string | null
}

export interface StoreHours {
  id?: number

  /** @format iri-reference */
  store?: string

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string

  /** @format date-time */
  opensAtRaw?: string | null

  /** @format date-time */
  closesAtRaw?: string | null
}

export interface StoreHoursProductReadItemProductReadGqlPublic {
  id?: number
  store?: StoreProductReadItemProductReadGqlPublic

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StoreHoursProductReadGqlPublic {
  id?: number
  store?: StoreProductReadGqlPublic

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StoreHoursStoreHoursRead {
  id?: number
  store?: StoreStoreHoursRead

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StoreHoursStoreHoursWrite {
  /** @format iri-reference */
  store?: string

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StoreHoursJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  store?: string

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string

  /** @format date-time */
  opensAtRaw?: string | null

  /** @format date-time */
  closesAtRaw?: string | null
}

export interface StoreHoursJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductReadItemProductReadGqlPublic

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StoreHoursJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldProductReadGqlPublic

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StoreHoursJsonldStoreHoursRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store?: StoreJsonldStoreHoursRead

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StoreHoursJsonldStoreHoursWrite {
  /** @format iri-reference */
  store?: string

  /** @example Salle de jeu */
  activity: string

  /**
   * 1 is monday, 7 is sunday
   * @min 1
   * @max 7
   */
  dayOfWeek?: number

  /** @format date-time */
  opensAt?: string

  /** @format date-time */
  closesAt?: string
}

export interface StorePictureProductReadItemProductReadGqlPublic {
  id?: number
  store: StoreProductReadItemProductReadGqlPublic
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
  url?: string
}

export interface StorePictureProductReadGqlPublic {
  id?: number
  store: StoreProductReadGqlPublic
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
  url?: string
}

export interface StorePictureStorePictureRead {
  id?: number

  /** @format iri-reference */
  store: string
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
}

export interface StorePictureStorePictureWrite {
  /** @format iri-reference */
  store: string
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
}

export interface StorePictureJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store: StoreJsonldProductReadItemProductReadGqlPublic
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
  url?: string
}

export interface StorePictureJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  store: StoreJsonldProductReadGqlPublic
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
  url?: string
}

export interface StorePictureJsonldStorePictureRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  store: string
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
}

export interface StorePictureJsonldStorePictureWrite {
  /** @format iri-reference */
  store: string
  relativeUrl: string
  alt: string
  main?: boolean
  type: 'storeFront' | 'salesArea' | 'playArea' | 'gameRental' | 'playBack'
}

export interface StorePlaceOrder {
  id?: number
  name?: string

  /** @example 1 */
  type?: number
}

export interface StorePlaceOrderJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name?: string

  /** @example 1 */
  type?: number
}

export interface StoreTranslation {
  id?: number
  locale?: string
  howToGo?: string | null
  openingHoursDescription?: string | null
  exceptionalOpening?: string | null
  shortDescription?: string | null
  description?: string | null
  playAreaDescription?: string | null
  cateringDescription?: string | null

  /** @format iri-reference */
  store?: string
  paybackDescription?: string | null
  gameRentalDescription?: string | null
  translatable?: TranslatableInterface | null
}

export interface StoreTranslationJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  locale?: string
  howToGo?: string | null
  openingHoursDescription?: string | null
  exceptionalOpening?: string | null
  shortDescription?: string | null
  description?: string | null
  playAreaDescription?: string | null
  cateringDescription?: string | null

  /** @format iri-reference */
  store?: string
  paybackDescription?: string | null
  gameRentalDescription?: string | null
  translatable?: TranslatableInterfaceJsonld | null
}

export interface StoreZone {
  id?: number
  name: string

  /** @format iri-reference */
  store?: string
}

export interface StoreZoneStoreZoneRead {
  id?: number
  name: string

  /** @format iri-reference */
  store?: string
}

export interface StoreZoneStoreZoneWrite {
  name: string

  /** @format iri-reference */
  store?: string
}

export interface StoreZoneJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string

  /** @format iri-reference */
  store?: string
}

export interface StoreZoneJsonldStoreZoneRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string

  /** @format iri-reference */
  store?: string
}

export interface StoreZoneJsonldStoreZoneWrite {
  name: string

  /** @format iri-reference */
  store?: string
}

export interface TaxProductCreationRequestRead {
  rate: number
}

export interface TaxProductRead {
  id?: number
  rate: number
}

export interface TaxProductReadItemProductRead {
  id?: number
  rate: number
}

export interface TaxProductReadItemProductReadGqlPublic {
  id?: number
  name: string
  rate: number
}

export interface TaxProductReadGqlPublic {
  id?: number
  name: string
  rate: number
}

export interface TaxProductReadProductReadItemProductStats {
  id?: number
  rate: number
}

export interface TaxProductReadProductStats {
  id?: number
  rate: number
}

export interface TaxStockEntryRead {
  id?: number
  name: string
  rate: number
}

export interface TaxStockReadItemStockRead {
  rate: number
}

export interface TaxStockReadItemStockReadStockStats {
  rate: number
}

export interface TaxStockReadStockReadItem {
  rate: number
}

export interface TaxTaxRead {
  id?: number
  name: string
  rate: number
}

export interface TaxWarehouseStockRead {
  rate: number
}

export interface TaxJsonldProductCreationRequestRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  rate: number
}

export interface TaxJsonldProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  rate: number
}

export interface TaxJsonldProductReadItemProductRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  rate: number
}

export interface TaxJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  rate: number
}

export interface TaxJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  rate: number
}

export interface TaxJsonldProductReadProductReadItemProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  rate: number
}

export interface TaxJsonldProductReadProductStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  rate: number
}

export interface TaxJsonldStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  name: string
  rate: number
}

export interface TaxJsonldStockReadItemStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  rate: number
}

export interface TaxJsonldStockReadItemStockReadStockStats {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  rate: number
}

export interface TaxJsonldStockReadStockReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  rate: number
}

export interface TaxJsonldTaxRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number
  name: string
  rate: number
}

export interface TaxJsonldWarehouseStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  rate: number
}

export interface TranslatableInterface {
  translations?: Record<string, TranslationInterface>
  translation?: TranslationInterface
  currentLocale?: string | null
  fallbackLocale?: string | null
}

export interface TranslatableInterfaceJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  translations?: Record<string, TranslationInterfaceJsonld>
  translation?: TranslationInterfaceJsonld
  currentLocale?: string | null
  fallbackLocale?: string | null
}

export interface TranslationInterface {
  translatable?: TranslatableInterface | null
  locale?: string | null
}

export interface TranslationInterfaceJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  translatable?: TranslatableInterfaceJsonld | null
  locale?: string | null
}

export interface Voucher {
  id?: number

  /** @format iri-reference */
  customer?: string | null

  /** @example true */
  valid?: boolean

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string

  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  oldLinkedOrder?: string | null

  /** @format iri-reference */
  linkedTournament?: string | null

  /** @format iri-reference */
  store?: string | null
  idVoucherParent?: number | null

  /** @example true */
  alert?: boolean
  active?: boolean
  usedName?: string | null
}

export interface VoucherEventCustomerRead {
  id?: number
  originalValue?: number
}

export interface VoucherEventReadEventReadItem {
  id?: number
  originalValue?: number
}

export interface VoucherOrderReadOrderReadItem {
  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string
  category?: VoucherCategoryOrderReadOrderReadItem | null
}

export interface VoucherProductReadItemProductReadGqlPublic {
  id?: number
  customer?: CustomerProductReadItemProductReadGqlPublic | null

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  linkedOrder?: OrderProductReadItemProductReadGqlPublic | null
  linkedTournament?: StoreEventOrderProductReadItemProductReadGqlPublic | null
}

export interface VoucherProductReadGqlPublic {
  id?: number
  customer?: CustomerProductReadGqlPublic | null

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  linkedOrder?: OrderProductReadGqlPublic | null
  linkedTournament?: StoreEventOrderProductReadGqlPublic | null
}

/**
 * Create a voucher from a customer's fidelity point
 */
export type VoucherVoucherFromFidelity = object

export interface VoucherVoucherPatch {
  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  comment?: string

  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  linkedTournament?: string | null

  /** @format iri-reference */
  store?: string | null
}

export interface VoucherVoucherRead {
  id?: number

  /** @format iri-reference */
  customer?: string | null

  /** @example true */
  valid?: boolean

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string
  category?: VoucherCategoryVoucherRead | null
  linkedOrder?: OrderVoucherRead | null

  /** @format iri-reference */
  linkedTournament?: string | null

  /** @format iri-reference */
  store?: string | null
  idVoucherParent?: number | null
  active?: boolean
  usedName?: string | null
}

export interface VoucherVoucherWrite {
  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string

  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  linkedTournament?: string | null

  /** @format iri-reference */
  store?: string | null
}

export interface VoucherJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  customer?: string | null

  /** @example true */
  valid?: boolean

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string

  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  oldLinkedOrder?: string | null

  /** @format iri-reference */
  linkedTournament?: string | null

  /** @format iri-reference */
  store?: string | null
  idVoucherParent?: number | null

  /** @example true */
  alert?: boolean
  active?: boolean
  usedName?: string | null
}

export interface VoucherJsonldEventCustomerRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  originalValue?: number
}

export interface VoucherJsonldEventReadEventReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  originalValue?: number
}

export interface VoucherJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string
  category?: VoucherCategoryJsonldOrderReadOrderReadItem | null
}

export interface VoucherJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  customer?: CustomerJsonldProductReadItemProductReadGqlPublic | null

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  linkedOrder?: OrderJsonldProductReadItemProductReadGqlPublic | null
  linkedTournament?: StoreEventOrderJsonldProductReadItemProductReadGqlPublic | null
}

export interface VoucherJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  customer?: CustomerJsonldProductReadGqlPublic | null

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  linkedOrder?: OrderJsonldProductReadGqlPublic | null
  linkedTournament?: StoreEventOrderJsonldProductReadGqlPublic | null
}

/**
 * Create a voucher from a customer's fidelity point
 */
export interface VoucherJsonldVoucherFromFidelity {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
}

export interface VoucherJsonldVoucherRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number

  /** @format iri-reference */
  customer?: string | null

  /** @example true */
  valid?: boolean

  /** @format date-time */
  emittedAt?: string

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string
  category?: VoucherCategoryJsonldVoucherRead | null
  linkedOrder?: OrderJsonldVoucherRead | null

  /** @format iri-reference */
  linkedTournament?: string | null

  /** @format iri-reference */
  store?: string | null
  idVoucherParent?: number | null
  active?: boolean
  usedName?: string | null
}

export interface VoucherJsonldVoucherWrite {
  /** @format iri-reference */
  customer?: string | null

  /** @format date-time */
  expiresAt?: string | null

  /**
   * @min 0
   * @max 99999.99
   */
  value?: number
  originalValue?: number
  comment?: string

  /** @format iri-reference */
  category?: string | null

  /** @format iri-reference */
  linkedOrder?: string | null

  /** @format iri-reference */
  linkedTournament?: string | null

  /** @format iri-reference */
  store?: string | null
}

export interface VoucherCategoryOrderReadOrderReadItem {
  codeName?: VoucherCategoryName
  givesFidelity?: boolean
  onlyInOrder?: boolean
}

export interface VoucherCategoryVoucherCategoryRead {
  id?: number
  codeName?: VoucherCategoryName
  givesFidelity?: boolean

  /** @example 365 */
  daysExpired?: number
  onlyInOrder?: boolean
}

export interface VoucherCategoryVoucherCategoryWrite {
  codeName?: VoucherCategoryName
  givesFidelity?: boolean

  /** @example 365 */
  daysExpired?: number
  onlyInOrder?: boolean
}

export interface VoucherCategoryVoucherRead {
  id?: number
  codeName?: VoucherCategoryName
  givesFidelity?: boolean

  /** @example 365 */
  daysExpired?: number
  onlyInOrder?: boolean
}

export interface VoucherCategoryJsonldOrderReadOrderReadItem {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  codeName?: VoucherCategoryName
  givesFidelity?: boolean
  onlyInOrder?: boolean
}

export interface VoucherCategoryJsonldVoucherCategoryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  codeName?: VoucherCategoryName
  givesFidelity?: boolean

  /** @example 365 */
  daysExpired?: number
  onlyInOrder?: boolean
}

export interface VoucherCategoryJsonldVoucherCategoryWrite {
  codeName?: VoucherCategoryName
  givesFidelity?: boolean

  /** @example 365 */
  daysExpired?: number
  onlyInOrder?: boolean
}

export interface VoucherCategoryJsonldVoucherRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  id?: number
  codeName?: VoucherCategoryName
  givesFidelity?: boolean

  /** @example 365 */
  daysExpired?: number
  onlyInOrder?: boolean
}

export interface WarehouseProductStatsWarehouseProductDataRead {
  /** @format iri-reference */
  product?: string
  allTimeSells?: number
  lastYearSells?: number
  lastMonthSells?: number
  lastMonthSellsSellermania?: number
  lastQuarterSells?: number
  lastQuarterSellsSellermania?: number
  lastYearSellsSellermania?: number
  allTimeSellsSellermania?: number
  lastWeekSells?: number
  lastWeekSellsSellermania?: number
  averageCost?: number
  minRestock?: number
  maxRestock?: number
}

export interface WarehouseProductStatsJsonldWarehouseProductDataRead {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }

  /** @format iri-reference */
  product?: string
  allTimeSells?: number
  lastYearSells?: number
  lastMonthSells?: number
  lastMonthSellsSellermania?: number
  lastQuarterSells?: number
  lastQuarterSellsSellermania?: number
  lastYearSellsSellermania?: number
  allTimeSellsSellermania?: number
  lastWeekSells?: number
  lastWeekSellsSellermania?: number
  averageCost?: number
  minRestock?: number
  maxRestock?: number
}

export interface WarehouseStock {
  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  transfer?: boolean

  /** @format iri-reference */
  parent?: string | null
  children?: string[]

  /** @format iri-reference */
  retailer?: string | null

  /** @format iri-reference */
  address?: string | null
  entries?: string[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format iri-reference */
  transferedFrom?: string | null

  /** @format date-time */
  transferedAt?: string

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  comment?: string | null

  /** @format iri-reference */
  createdBy?: string | null

  /** @format iri-reference */
  receivedBy?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  billDate?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  id?: number
  billNumber?: string | null
  cancelable?: boolean
  illegalStockState?: boolean
  productCount?: number
  totalValue?: number
}

export interface WarehouseStockProductReadItemProductReadGqlPublic {
  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  retailer?: CustomerProductReadItemProductReadGqlPublic | null
  address?: AddressProductReadItemProductReadGqlPublic | null
  entries?: WarehouseStockEntryProductReadItemProductReadGqlPublic[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null
  comment?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  id?: number
  billNumber?: string | null
  productCount?: number
  totalValue?: number
}

export interface WarehouseStockProductReadGqlPublic {
  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  retailer?: CustomerProductReadGqlPublic | null
  address?: AddressProductReadGqlPublic | null
  entries?: WarehouseStockEntryProductReadGqlPublic[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null
  comment?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  id?: number
  billNumber?: string | null
  productCount?: number
  totalValue?: number
}

export type WarehouseStockStockValidate = object

export interface WarehouseStockWarehouseStockEntryRead {
  retailer?: CustomerWarehouseStockEntryRead | null

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  billNumber?: string | null
}

export type WarehouseStockWarehouseStockMarkReceived = object

export interface WarehouseStockWarehouseStockRead {
  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  transfer?: boolean
  parent?: WarehouseStockWarehouseStockRead | null
  retailer?: CustomerWarehouseStockRead | null

  /** @format iri-reference */
  address?: string | null
  entries?: WarehouseStockEntryWarehouseStockRead[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format iri-reference */
  transferedFrom?: string | null

  /** @format date-time */
  transferedAt?: string

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  comment?: string | null
  createdBy?: CollaboratorWarehouseStockRead | null
  receivedBy?: CollaboratorWarehouseStockRead | null
  paymentMode?: PaymentModeWarehouseStockRead | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  billDate?: string | null
  treatedBy?: CollaboratorWarehouseStockRead | null
  id?: number
  billNumber?: string | null
  productCount?: number
  totalValue?: number
}

export type WarehouseStockWarehouseStockTreat = object

export type WarehouseStockWarehouseStockValidate = object

export interface WarehouseStockWarehouseStockWrite {
  /** @format iri-reference */
  retailer?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  comment?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string
  productReceived?: boolean
}

export interface WarehouseStockJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  transfer?: boolean

  /** @format iri-reference */
  parent?: string | null
  children?: string[]

  /** @format iri-reference */
  retailer?: string | null

  /** @format iri-reference */
  address?: string | null
  entries?: string[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format iri-reference */
  transferedFrom?: string | null

  /** @format date-time */
  transferedAt?: string

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  comment?: string | null

  /** @format iri-reference */
  createdBy?: string | null

  /** @format iri-reference */
  receivedBy?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  billDate?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  id?: number
  billNumber?: string | null
  cancelable?: boolean
  illegalStockState?: boolean
  productCount?: number
  totalValue?: number
}

export interface WarehouseStockJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  retailer?: CustomerJsonldProductReadItemProductReadGqlPublic | null
  address?: AddressJsonldProductReadItemProductReadGqlPublic | null
  entries?: WarehouseStockEntryJsonldProductReadItemProductReadGqlPublic[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null
  comment?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  id?: number
  billNumber?: string | null
  productCount?: number
  totalValue?: number
}

export interface WarehouseStockJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  retailer?: CustomerJsonldProductReadGqlPublic | null
  address?: AddressJsonldProductReadGqlPublic | null
  entries?: WarehouseStockEntryJsonldProductReadGqlPublic[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null
  comment?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format iri-reference */
  treatedBy?: string | null
  id?: number
  billNumber?: string | null
  productCount?: number
  totalValue?: number
}

export type WarehouseStockJsonldStockValidate = object

export interface WarehouseStockJsonldWarehouseStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  retailer?: CustomerJsonldWarehouseStockEntryRead | null

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  billNumber?: string | null
}

export type WarehouseStockJsonldWarehouseStockMarkReceived = object

export interface WarehouseStockJsonldWarehouseStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @example N */
  validated?: string
  treated?: boolean

  /** @example N */
  productSent?: string
  transfer?: boolean
  parent?: WarehouseStockJsonldWarehouseStockRead | null
  retailer?: CustomerJsonldWarehouseStockRead | null

  /** @format iri-reference */
  address?: string | null
  entries?: WarehouseStockEntryJsonldWarehouseStockRead[]

  /** @format date-time */
  createdAt?: string

  /** @format date-time */
  treatedAt?: string | null

  /** @format date-time */
  preparedAt?: string | null

  /** @format iri-reference */
  transferedFrom?: string | null

  /** @format date-time */
  transferedAt?: string

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  comment?: string | null
  createdBy?: CollaboratorJsonldWarehouseStockRead | null
  receivedBy?: CollaboratorJsonldWarehouseStockRead | null
  paymentMode?: PaymentModeJsonldWarehouseStockRead | null

  /** @example N */
  paymentSent?: string

  /** @example N */
  paymentReceived?: string
  productReceived?: boolean

  /** @format date-time */
  payBefore?: string | null

  /** @example N */
  canceled?: string

  /** @format date-time */
  canceledAt?: string | null

  /** @format date-time */
  billDate?: string | null
  treatedBy?: CollaboratorJsonldWarehouseStockRead | null
  id?: number
  billNumber?: string | null
  productCount?: number
  totalValue?: number
}

export type WarehouseStockJsonldWarehouseStockTreat = object

export type WarehouseStockJsonldWarehouseStockValidate = object

export interface WarehouseStockJsonldWarehouseStockWrite {
  /** @format iri-reference */
  retailer?: string | null

  /** @format date-time */
  restockedAt?: string | null
  approximateRestockDate?: string
  approximateRestockDateEn?: string
  comment?: string | null

  /** @format iri-reference */
  paymentMode?: string | null

  /** @example N */
  paymentSent?: string
  productReceived?: boolean
}

export interface WarehouseStockEntry {
  /** @format iri-reference */
  product?: string | null

  /** @format iri-reference */
  stock?: string | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /** @format iri-reference */
  tax?: string | null
  sourceType?: string | null
  sourceId?: number | null
  productPlace?: string | null
  id?: number
  remainingQuantity?: number
  sellPrice?: number
}

export interface WarehouseStockEntryProductReadItemProductReadGqlPublic {
  product?: ProductProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number
  id?: number
}

export interface WarehouseStockEntryProductReadGqlPublic {
  product?: ProductProductReadGqlPublic | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number
  id?: number
}

export interface WarehouseStockEntryWarehouseStockEntryRead {
  product?: ProductWarehouseStockEntryRead | null
  stock?: WarehouseStockWarehouseStockEntryRead | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /** @format iri-reference */
  tax?: string | null
  id?: number
  remainingQuantity?: number
}

export type WarehouseStockEntryWarehouseStockEntryWrite = object

export interface WarehouseStockEntryWarehouseStockRead {
  product?: ProductWarehouseStockRead | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number
  tax?: TaxWarehouseStockRead | null
  id?: number
  remainingQuantity?: number
}

export interface WarehouseStockEntryJsonld {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string

  /** @format iri-reference */
  product?: string | null

  /** @format iri-reference */
  stock?: string | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /** @format iri-reference */
  tax?: string | null
  sourceType?: string | null
  sourceId?: number | null
  productPlace?: string | null
  id?: number
  remainingQuantity?: number
  sellPrice?: number
}

export interface WarehouseStockEntryJsonldProductReadItemProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: ProductJsonldProductReadItemProductReadGqlPublic | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number
  id?: number
}

export interface WarehouseStockEntryJsonldProductReadGqlPublic {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: ProductJsonldProductReadGqlPublic | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number
  id?: number
}

export interface WarehouseStockEntryJsonldWarehouseStockEntryRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: ProductJsonldWarehouseStockEntryRead | null
  stock?: WarehouseStockJsonldWarehouseStockEntryRead | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number

  /** @format iri-reference */
  tax?: string | null
  id?: number
  remainingQuantity?: number
}

export type WarehouseStockEntryJsonldWarehouseStockEntryWrite = object

export interface WarehouseStockEntryJsonldWarehouseStockRead {
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  '@id'?: string
  '@type'?: string
  product?: ProductJsonldWarehouseStockRead | null

  /**
   * @min 0
   * @example 1
   */
  quantity?: number

  /** @min 0 */
  soldQuantity?: number

  /**
   * @min 0
   * @max 9999999
   */
  buyPrice?: number
  tax?: TaxJsonldWarehouseStockRead | null
  id?: number
  remainingQuantity?: number
}

export interface WarehouseStockIssue {
  id?: number

  /** @format iri-reference */
  stock?: string | null

  /** @format iri-reference */
  product?: string | null
  sentBack?: boolean

  /**
   * @min 0
   * @max 100
   */
  refundPercentage?: number
  solved?: boolean

  /** @format iri-reference */
  stockError?: string | null
}

export interface WarehouseStockIssueJsonld {
  '@id'?: string
  '@type'?: string
  '@context'?: string | { '@vocab': string; hydra: 'http://www.w3.org/ns/hydra/core#'; [key: string]: any }
  id?: number

  /** @format iri-reference */
  stock?: string | null

  /** @format iri-reference */
  product?: string | null
  sentBack?: boolean

  /**
   * @min 0
   * @max 100
   */
  refundPercentage?: number
  solved?: boolean

  /** @format iri-reference */
  stockError?: string | null
}

export enum CardEditionStatus {
  ToBeReleased = 'to_be_released',
  Preorder = 'preorder',
  Released = 'released'
}

export enum OrderStatus {
  Cart = 'cart',
  Validated = 'validated',
  Paid = 'paid',
  InPreparation = 'inPreparation',
  Send = 'send',
  Ready = 'ready',
  Treated = 'treated',
  CanceledRequested = 'canceledRequested',
  Canceled = 'canceled'
}

export enum FamilyName {
  Fftcg = 'fftcg',
  Jds = 'jds',
  Dbscg = 'dbscg',
  Aos = 'aos',
  Lego = 'lego',
  Xwing = 'xwing',
  W40K = 'w40k',
  Painting = 'painting',
  Models = 'models',
  Cards = 'cards',
  Service = 'service',
  Catering = 'catering',
  Magic = 'magic',
  Accessories = 'accessories',
  Pokemon = 'pokemon',
  Boardgames = 'boardgames',
  Yugioh = 'yugioh',
  Fab = 'fab',
  Lorcana = 'lorcana'
}

export enum FamilySlug {
  Magic = 'magic',
  FinalFantasy = 'final-fantasy',
  Pokemon = 'pokemon',
  Accessoires = 'accessoires',
  JeuxDeSociete = 'jeux-de-societe',
  DragonBall = 'dragon-ball',
  WarhammerAgeOfSigmar = 'warhammer-age-of-sigmar',
  Lego = 'lego',
  XWing = 'x-wing',
  Warhammer40000 = 'warhammer-40-000',
  Peinture = 'peinture',
  Figurine = 'figurine',
  Cartes = 'cartes',
  YuGiOh = 'yu-gi-oh',
  Service = 'service',
  Restauration = 'restauration',
  FleshAndBlood = 'flesh-and-blood',
  Lorcana = 'lorcana',
  IdeeCadeau = 'idee-cadeau'
}

export enum FamilyPageName {
  Home = 'home',
  Catalog = 'catalog',
  CardEditions = 'card-editions',
  CardAdvancedSearch = 'card-advanced-search'
}

export enum StoreEventStatus {
  Created = 'created',
  WaitingVoucher = 'waitingVoucher',
  Treated = 'treated',
  Canceled = 'canceled'
}

export enum CustomerType {
  INTERNAL = 'INTERNAL',
  CUSTOMER = 'CUSTOMER',
  SELLERMANIA = 'SELLERMANIA',
  CARDTRADER = 'CARDTRADER',
  CARDMARKET = 'CARDMARKET',
  EMPLOYEE = 'EMPLOYEE',
  STORE_PC = 'STORE_PC'
}

export enum VoucherCategoryName {
  CreditStore = 'creditStore',
  Event = 'event',
  Refund = 'refund',
  CommercialGesture = 'commercialGesture',
  LoyaltyCard = 'loyaltyCard',
  RentalDiscount = 'rentalDiscount',
  GiftCard = 'giftCard',
  FidelityPoint = 'fidelityPoint',
  OrderCommercialGesture = 'orderCommercialGesture'
}

export enum CommercialOfferType {
  Preorder = 'preorder',
  Promo = 'promo',
  Sales = 'sales',
  BlackFriday = 'blackFriday',
  News = 'news',
  BestSeller = 'bestSeller'
}

export enum FidelityPointCategory {
  Buy = 'buy',
  OldFidelityCard = 'oldFidelityCard',
  Contest = 'contest',
  CreateAccount = 'createAccount',
  Sponsorship = 'sponsorship',
  RetroactiveCode = 'retroactiveCode',
  EventSubscription = 'eventSubscription',
  CommercialGesture = 'commercialGesture',
  CustomerReviews = 'customerReviews'
}

export enum ShippingTypeName {
  SHOP = 'SHOP',
  CLASSIC = 'CLASSIC',
  FAST = 'FAST',
  RELAY = 'RELAY',
  STAND = 'STAND'
}

export enum ShippingLabelTypeName {
  CHRONOPOST = 'CHRONOPOST',
  COLISHIP = 'COLISHIP',
  RELAY = 'RELAY',
  SHOP2SHOP = 'SHOP2SHOP'
}

export enum CashboxStatus {
  Created = 'created',
  Validated = 'validated',
  Funded = 'funded',
  Closed = 'closed'
}

export enum OrderSecondaryStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
  AfterSales = 'afterSales',
  Preorder = 'preorder'
}

export enum ProductVisibility {
  AlwaysShown = 'always_shown',
  Default = 'default',
  AlwaysHidden = 'always_hidden'
}

export enum StockStatus {
  Created = 'created',
  Validated = 'validated',
  Treated = 'treated',
  Canceled = 'canceled'
}

export enum CustomerInternalType {
  InventoryError = 'inventoryError',
  Inventory = 'inventory',
  Shop = 'shop',
  Event = 'event',
  MarketingOffer = 'marketingOffer',
  Reporting = 'reporting',
  CardReporting = 'cardReporting',
  OrderPreparation = 'orderPreparation',
  Consumable = 'consumable',
  LostOrder = 'lostOrder'
}

export enum ProductCreationRequestStatus {
  Created = 'created',
  Validated = 'validated',
  Rejected = 'rejected'
}

export interface Token {
  token: string
  me: string
}

export interface Credentials {
  /** @example p.nom@play-in.com */
  email: string

  /** @example secret */
  password: string
}

export interface Email {
  from?: string
  to?: string
  subject?: string
  message?: string
}

export type Hierarchy = string[][]

export enum Role {
  ROLE_STAFF = 'ROLE_STAFF',
  ROLE_WEB_CONTENT_MANAGER_MANAGER = 'ROLE_WEB_CONTENT_MANAGER_MANAGER',
  ROLE_WEB_CONTENT_MANAGER = 'ROLE_WEB_CONTENT_MANAGER',
  ROLE_MARKETING_PROJECT_MANAGER = 'ROLE_MARKETING_PROJECT_MANAGER',
  ROLE_SELLER_JUNIOR = 'ROLE_SELLER_JUNIOR',
  ROLE_SELLER = 'ROLE_SELLER',
  ROLE_SELLER_MANAGER = 'ROLE_SELLER_MANAGER',
  ROLE_ANIMATION_JUNIOR = 'ROLE_ANIMATION_JUNIOR',
  ROLE_ANIMATION = 'ROLE_ANIMATION',
  ROLE_ANIMATION_MANAGER = 'ROLE_ANIMATION_MANAGER',
  ROLE_PACKAGING_SEALED_JUNIOR = 'ROLE_PACKAGING_SEALED_JUNIOR',
  ROLE_PACKAGING_SEALED = 'ROLE_PACKAGING_SEALED',
  ROLE_PREPARATION_SEALED = 'ROLE_PREPARATION_SEALED',
  ROLE_SUPPLY_MANAGER = 'ROLE_SUPPLY_MANAGER',
  ROLE_PRODUCT_REPURCHASE = 'ROLE_PRODUCT_REPURCHASE',
  ROLE_PRODUCT_REPURCHASE_MANAGER = 'ROLE_PRODUCT_REPURCHASE_MANAGER',
  ROLE_PACKAGING_CARDS_JUNIOR = 'ROLE_PACKAGING_CARDS_JUNIOR',
  ROLE_PACKAGING_CARDS = 'ROLE_PACKAGING_CARDS',
  ROLE_PREPARATION_CARDS = 'ROLE_PREPARATION_CARDS',
  ROLE_PREPARATION_SEALED_JUNIOR = 'ROLE_PREPARATION_SEALED_JUNIOR',
  ROLE_PREPARATION_CARDS_JUNIOR = 'ROLE_PREPARATION_CARDS_JUNIOR',
  ROLE_PREPARATION_MANAGER = 'ROLE_PREPARATION_MANAGER',
  ROLE_STOCK_MANAGER_JUNIOR = 'ROLE_STOCK_MANAGER_JUNIOR',
  ROLE_STOCK_MANAGER = 'ROLE_STOCK_MANAGER',
  ROLE_STOCK_MANAGER_MANAGER = 'ROLE_STOCK_MANAGER_MANAGER',
  ROLE_CUSTOMER_SERVICE = 'ROLE_CUSTOMER_SERVICE',
  ROLE_BYPASS_STORE_RESTRICTION = 'ROLE_BYPASS_STORE_RESTRICTION',
  ROLE_ADMINISTRATIVE_ASSISTANT = 'ROLE_ADMINISTRATIVE_ASSISTANT',
  ROLE_STORE_MANAGER = 'ROLE_STORE_MANAGER',
  ROLE_CATERING_MANAGER = 'ROLE_CATERING_MANAGER',
  ROLE_STORE_OWNER = 'ROLE_STORE_OWNER',
  ROLE_DIRECTION = 'ROLE_DIRECTION',
  ROLE_EXTERNAL_ACCOUNTANT = 'ROLE_EXTERNAL_ACCOUNTANT'
}

export type Roles = Role[]

export enum RoleAction {
  ROLE_ACTION_GIFTCARD_CREATE = 'ROLE_ACTION_GIFTCARD_CREATE',
  ROLE_ACTION_GIFTCARD_ACTIVATE = 'ROLE_ACTION_GIFTCARD_ACTIVATE',
  ROLE_ACTION_CUSTOMER_CREATE = 'ROLE_ACTION_CUSTOMER_CREATE',
  ROLE_ACTION_CUSTOMERS_READ = 'ROLE_ACTION_CUSTOMERS_READ',
  ROLE_ACTION_CUSTOMER_EDIT = 'ROLE_ACTION_CUSTOMER_EDIT',
  ROLE_ACTION_COLLABORATOR_CREATE = 'ROLE_ACTION_COLLABORATOR_CREATE',
  ROLE_ACTION_COLLABORATOR_EDIT = 'ROLE_ACTION_COLLABORATOR_EDIT',
  ROLE_ACTION_STORE_EVENT_CREATE = 'ROLE_ACTION_STORE_EVENT_CREATE',
  ROLE_ACTION_STORE_EVENT_READ = 'ROLE_ACTION_STORE_EVENT_READ',
  ROLE_ACTION_STORE_EVENT_VOUCHER_CUSTOMER = 'ROLE_ACTION_STORE_EVENT_VOUCHER_CUSTOMER',
  ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER = 'ROLE_ACTION_STORE_EVENT_EDIT_CUSTOMER',
  ROLE_ACTION_TRANSFER_CREATE = 'ROLE_ACTION_TRANSFER_CREATE',
  ROLE_ACTION_TRANSFERS_READ = 'ROLE_ACTION_TRANSFERS_READ',
  ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS = 'ROLE_ACTION_COLLABORATOR_EDIT_RIGHTS',
  ROLE_ACTION_PERMISSIONS_MANAGE_READ = 'ROLE_ACTION_PERMISSIONS_MANAGE_READ',
  ROLE_ACTION_PERMISSIONS_MANAGE_EDIT = 'ROLE_ACTION_PERMISSIONS_MANAGE_EDIT',
  ROLE_ACTION_CASHBOXES_MANAGE = 'ROLE_ACTION_CASHBOXES_MANAGE',
  ROLE_ACTION_CASHBOXES_WRITE = 'ROLE_ACTION_CASHBOXES_WRITE',
  ROLE_ACTION_COLLABORATORS_READ = 'ROLE_ACTION_COLLABORATORS_READ',
  ROLE_ACTION_STORE_EDIT = 'ROLE_ACTION_STORE_EDIT',
  ROLE_ACTION_STORES_READ = 'ROLE_ACTION_STORES_READ',
  ROLE_ACTION_STORE_EDIT_CORE = 'ROLE_ACTION_STORE_EDIT_CORE',
  ROLE_ACTION_ORDER_CREATE = 'ROLE_ACTION_ORDER_CREATE',
  ROLE_ACTION_ORDERS_READ = 'ROLE_ACTION_ORDERS_READ',
  ROLE_ACTION_ORDER_EDIT_CONTENTS = 'ROLE_ACTION_ORDER_EDIT_CONTENTS',
  ROLE_ACTION_ORDER_EDIT_CUSTOMER_NOT_TREATED = 'ROLE_ACTION_ORDER_EDIT_CUSTOMER_NOT_TREATED',
  ROLE_ACTION_ORDER_EDIT_CONTENTS_PAID = 'ROLE_ACTION_ORDER_EDIT_CONTENTS_PAID',
  ROLE_ACTION_ORDER_EDIT_DISCOUNTS = 'ROLE_ACTION_ORDER_EDIT_DISCOUNTS',
  ROLE_ACTION_ORDER_EDIT_FEES = 'ROLE_ACTION_ORDER_EDIT_FEES',
  ROLE_ACTION_ORDER_EDIT_PAYMENT_MODE = 'ROLE_ACTION_ORDER_EDIT_PAYMENT_MODE',
  ROLE_ACTION_ORDER_PRINT = 'ROLE_ACTION_ORDER_PRINT',
  ROLE_ACTION_ORDERS_PRINT = 'ROLE_ACTION_ORDERS_PRINT',
  ROLE_ACTION_ORDER_REFUND_CONTENTS = 'ROLE_ACTION_ORDER_REFUND_CONTENTS',
  ROLE_ACTION_ORDER_SPLIT_CONTENTS = 'ROLE_ACTION_ORDER_SPLIT_CONTENTS',
  ROLE_ACTION_ORDER_TREAT = 'ROLE_ACTION_ORDER_TREAT',
  ROLE_ACTION_ORDER_EDIT_CUSTOMER_TREATED = 'ROLE_ACTION_ORDER_EDIT_CUSTOMER_TREATED',
  ROLE_ACTION_ORDER_EDIT_SEND = 'ROLE_ACTION_ORDER_EDIT_SEND',
  ROLE_ACTION_ORDER_EDIT_PREPARATION = 'ROLE_ACTION_ORDER_EDIT_PREPARATION',
  ROLE_ACTION_ORDER_EDIT_PREPARATION_CARDS = 'ROLE_ACTION_ORDER_EDIT_PREPARATION_CARDS',
  ROLE_ACTION_ORDERS_READ_ALL_STOCK = 'ROLE_ACTION_ORDERS_READ_ALL_STOCK',
  ROLE_ACTION_STOCKS_PRINT = 'ROLE_ACTION_STOCKS_PRINT',
  ROLE_ACTION_STOCKS_READ = 'ROLE_ACTION_STOCKS_READ',
  ROLE_ACTION_STOCK_CREATE = 'ROLE_ACTION_STOCK_CREATE',
  ROLE_ACTION_STOCK_EDIT = 'ROLE_ACTION_STOCK_EDIT',
  ROLE_ACTION_STOCK_EDIT_ACTION = 'ROLE_ACTION_STOCK_EDIT_ACTION',
  ROLE_ACTION_STOCK_EDIT_CONTENTS = 'ROLE_ACTION_STOCK_EDIT_CONTENTS',
  ROLE_ACTION_STOCKS_STATS_READ = 'ROLE_ACTION_STOCKS_STATS_READ',
  ROLE_ACTION_STOCK_CANCEL_ACTION = 'ROLE_ACTION_STOCK_CANCEL_ACTION',
  ROLE_ACTION_PRODUCT_EDIT_STOCK_PLACE = 'ROLE_ACTION_PRODUCT_EDIT_STOCK_PLACE',
  ROLE_ACTION_PRODUCTS_READ = 'ROLE_ACTION_PRODUCTS_READ',
  ROLE_ACTION_PRODUCT_CREATE = 'ROLE_ACTION_PRODUCT_CREATE',
  ROLE_ACTION_CARD_CREATE = 'ROLE_ACTION_CARD_CREATE',
  ROLE_ACTION_CARDS_READ = 'ROLE_ACTION_CARDS_READ',
  ROLE_ACTION_CARD_UPDATE_MKM = 'ROLE_ACTION_CARD_UPDATE_MKM',
  ROLE_ACTION_CARD_EDIT_PRICE = 'ROLE_ACTION_CARD_EDIT_PRICE',
  ROLE_ACTION_LINKED_ARTICLE_EDIT = 'ROLE_ACTION_LINKED_ARTICLE_EDIT',
  ROLE_ACTION_PRODUCT_EDIT_CORE = 'ROLE_ACTION_PRODUCT_EDIT_CORE',
  ROLE_ACTION_PRODUCT_COLLECTION_CREATE = 'ROLE_ACTION_PRODUCT_COLLECTION_CREATE',
  ROLE_ACTION_PRODUCT_COLLECTION_EDIT = 'ROLE_ACTION_PRODUCT_COLLECTION_EDIT',
  ROLE_ACTION_PRODUCT_COLLECTION_READ = 'ROLE_ACTION_PRODUCT_COLLECTION_READ',
  ROLE_ACTION_ORDER_CANCEL = 'ROLE_ACTION_ORDER_CANCEL',
  ROLE_ACTION_ORDER_EDIT_SHIPPING_MODE = 'ROLE_ACTION_ORDER_EDIT_SHIPPING_MODE',
  ROLE_ACTION_ORDER_INVENTORY_ERROR_CONTENTS = 'ROLE_ACTION_ORDER_INVENTORY_ERROR_CONTENTS',
  ROLE_ACTION_RESTOCKS_PRINT = 'ROLE_ACTION_RESTOCKS_PRINT',
  ROLE_ACTION_STORE_LABEL_PRINT = 'ROLE_ACTION_STORE_LABEL_PRINT',
  ROLE_ACTION_CUSTOMER_VOUCHER = 'ROLE_ACTION_CUSTOMER_VOUCHER',
  ROLE_ACTION_CUSTOMER_POINTS = 'ROLE_ACTION_CUSTOMER_POINTS',
  ROLE_ACTION_ORDER_CANCEL_TREAT = 'ROLE_ACTION_ORDER_CANCEL_TREAT',
  ROLE_ACTION_ORDER_EDIT_COMMERCIALS_GESTURE = 'ROLE_ACTION_ORDER_EDIT_COMMERCIALS_GESTURE',
  ROLE_ACTION_ORDER_EDIT_LITIGATION = 'ROLE_ACTION_ORDER_EDIT_LITIGATION',
  ROLE_ACTION_EXCLUDE_EXT_PRINT = 'ROLE_ACTION_EXCLUDE_EXT_PRINT',
  ROLE_ACTION_STORE_EVENTS_READ = 'ROLE_ACTION_STORE_EVENTS_READ',
  ROLE_ACTION_ORDER_EDIT_READY = 'ROLE_ACTION_ORDER_EDIT_READY',
  ROLE_ACTION_STORE_EVENT_EDIT = 'ROLE_ACTION_STORE_EVENT_EDIT',
  ROLE_ACTION_STORE_EVENT_EDIT_ACTION = 'ROLE_ACTION_STORE_EVENT_EDIT_ACTION',
  ROLE_ACTION_ORDERS_EDIT_STATUS_PREPARATION_SEALED = 'ROLE_ACTION_ORDERS_EDIT_STATUS_PREPARATION_SEALED',
  ROLE_ACTION_ORDERS_EDIT_STATUS_PREPARATION_CARDS = 'ROLE_ACTION_ORDERS_EDIT_STATUS_PREPARATION_CARDS',
  ROLE_ACTION_ORDERS_PRINT_CARDS = 'ROLE_ACTION_ORDERS_PRINT_CARDS',
  ROLE_ACTION_PRODUCT_EDIT_LOCAL = 'ROLE_ACTION_PRODUCT_EDIT_LOCAL',
  ROLE_ACTION_EDIT_CUSTOMER_RETAILER_CORE = 'ROLE_ACTION_EDIT_CUSTOMER_RETAILER_CORE',
  ROLE_ACTION_ORDER_SEND_EMAIL = 'ROLE_ACTION_ORDER_SEND_EMAIL',
  ROLE_ACTION_ORDER_CANCELREQUEST = 'ROLE_ACTION_ORDER_CANCELREQUEST',
  ROLE_ACTION_PRODUCT_CREATE_REQUEST = 'ROLE_ACTION_PRODUCT_CREATE_REQUEST',
  ROLE_ACTION_STORE_CREATE = 'ROLE_ACTION_STORE_CREATE',
  ROLE_ACTION_PRODUCTS_READ_STATS = 'ROLE_ACTION_PRODUCTS_READ_STATS'
}

export type RoleActions = RoleAction[]

export namespace Api {
  /**
   * @description Retrieves the collection of Address resources.
   * @tags Address
   * @name ApiAddressesGetCollection
   * @summary Retrieves the collection of Address resources.
   * @request GET:/api/addresses
   * @secure
   */
  export namespace GetAddresses {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': AddressJsonldAddressRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Address resource.
   * @tags Address
   * @name ApiAddressesIdGet
   * @summary Retrieves a Address resource.
   * @request GET:/api/addresses/{id}
   * @secure
   */
  export namespace GetAddressesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AddressJsonldAddressRead
  }
  /**
   * @description Retrieves a AggregationBucket resource.
   * @tags AggregationBucket
   * @name ApiAggregationBucketsGet
   * @summary Retrieves a AggregationBucket resource.
   * @request GET:/api/aggregation-buckets
   * @secure
   */
  export namespace GetAggregationBuckets {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AggregationBucketJsonld
  }
  /**
   * @description Retrieves a Aggregation resource.
   * @tags Aggregation
   * @name ApiAggregationsIdGet
   * @summary Retrieves a Aggregation resource.
   * @request GET:/api/aggregations/{id}
   * @secure
   */
  export namespace GetAggregationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AggregationJsonld
  }
  /**
   * @description Retrieves the collection of Artist resources.
   * @tags Artist
   * @name ApiArtistsGetCollection
   * @summary Retrieves the collection of Artist resources.
   * @request GET:/api/artists
   * @secure
   */
  export namespace GetArtists {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ArtistJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Artist resource.
   * @tags Artist
   * @name ApiArtistsIdGet
   * @summary Retrieves a Artist resource.
   * @request GET:/api/artists/{id}
   * @secure
   */
  export namespace GetArtistsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ArtistJsonld
  }
  /**
   * @description Generate new JWT Token
   * @tags Token
   * @name PostCredentialsItemBack
   * @summary Get JWT token to login.
   * @request POST:/api/authenticate
   * @secure
   */
  export namespace PostAuthenticate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = Credentials
    export type RequestHeaders = {}
    export type ResponseBody = Token
  }
  /**
   * @description Generate new JWT Token
   * @tags Token
   * @name PostCredentialsItemFront
   * @summary Get JWT token to login.
   * @request POST:/api/authenticate-front
   * @secure
   */
  export namespace PostAuthenticateFront {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = Credentials
    export type RequestHeaders = {}
    export type ResponseBody = Token
  }
  /**
   * @description Retrieves the collection of Author resources.
   * @tags Author
   * @name ApiAuthorsGetCollection
   * @summary Retrieves the collection of Author resources.
   * @request GET:/api/authors
   * @secure
   */
  export namespace GetAuthors {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': AuthorJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Author resource.
   * @tags Author
   * @name ApiAuthorsIdGet
   * @summary Retrieves a Author resource.
   * @request GET:/api/authors/{id}
   * @secure
   */
  export namespace GetAuthorsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AuthorJsonld
  }
  /**
   * @description Retrieves the collection of BoardgameData resources.
   * @tags BoardgameData
   * @name ApiBoardgameDatasGetCollection
   * @summary Retrieves the collection of BoardgameData resources.
   * @request GET:/api/boardgame-datas
   * @secure
   */
  export namespace GetBoardgameDatas {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': BoardgameDataJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a BoardgameData resource.
   * @tags BoardgameData
   * @name ApiBoardgameDatasIdGet
   * @summary Retrieves a BoardgameData resource.
   * @request GET:/api/boardgame-datas/{id}
   * @secure
   */
  export namespace GetBoardgameDatasId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BoardgameDataJsonld
  }
  /**
   * @description Retrieves the collection of BoardgameRule resources.
   * @tags BoardgameRule
   * @name ApiBoardgameRulesGetCollection
   * @summary Retrieves the collection of BoardgameRule resources.
   * @request GET:/api/boardgame-rules
   * @secure
   */
  export namespace GetBoardgameRules {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': BoardgameRuleJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a BoardgameRule resource.
   * @tags BoardgameRule
   * @name ApiBoardgameRulesIdGet
   * @summary Retrieves a BoardgameRule resource.
   * @request GET:/api/boardgame-rules/{id}
   * @secure
   */
  export namespace GetBoardgameRulesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BoardgameRuleJsonld
  }
  /**
   * @description Retrieves the collection of Brand resources.
   * @tags Brand
   * @name ApiBrandsGetCollection
   * @summary Retrieves the collection of Brand resources.
   * @request GET:/api/brands
   * @secure
   */
  export namespace GetBrands {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': BrandJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Brand resource.
   * @tags Brand
   * @name ApiBrandsIdGet
   * @summary Retrieves a Brand resource.
   * @request GET:/api/brands/{id}
   * @secure
   */
  export namespace GetBrandsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BrandJsonld
  }
  /**
   * @description Retrieves the collection of CardColor resources.
   * @tags CardColor
   * @name ApiCardColorsGetCollection
   * @summary Retrieves the collection of CardColor resources.
   * @request GET:/api/card-colors
   * @secure
   */
  export namespace GetCardColors {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[name]'?: 'asc' | 'desc'
      'order[position]'?: 'asc' | 'desc'
      range?: string
      'range[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardColorJsonldCardColorRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardColor resource.
   * @tags CardColor
   * @name ApiCardColorsIdGet
   * @summary Retrieves a CardColor resource.
   * @request GET:/api/card-colors/{id}
   * @secure
   */
  export namespace GetCardColorsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardColorJsonldCardColorRead
  }
  /**
   * @description Retrieves the collection of CardData resources.
   * @tags CardData
   * @name ApiCardDatasGetCollection
   * @summary Retrieves the collection of CardData resources.
   * @request GET:/api/card-datas
   * @secure
   */
  export namespace GetCardDatas {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'variation.cardProperties.name'?: string
      'variation.cardProperties.name[]'?: string[]
      'variation.cardProperties'?: string
      'variation.cardProperties[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardDataJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardData resource.
   * @tags CardData
   * @name ApiCardDatasIdGet
   * @summary Retrieves a CardData resource.
   * @request GET:/api/card-datas/{id}
   * @secure
   */
  export namespace GetCardDatasId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardDataJsonld
  }
  /**
   * @description Retrieves the collection of CardDeclination resources.
   * @tags CardDeclination
   * @name ApiCardDeclinationsGetCollection
   * @summary Retrieves the collection of CardDeclination resources.
   * @request GET:/api/card-declinations
   * @secure
   */
  export namespace GetCardDeclinations {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[grading.position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardDeclinationJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardDeclination resource.
   * @tags CardDeclination
   * @name ApiCardDeclinationsIdGet
   * @summary Retrieves a CardDeclination resource.
   * @request GET:/api/card-declinations/{id}
   * @secure
   */
  export namespace GetCardDeclinationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardDeclinationJsonld
  }
  /**
   * @description Retrieves the collection of CardEditionCategory resources.
   * @tags CardEditionCategory
   * @name ApiCardEditionCategoriesGetCollection
   * @summary Retrieves the collection of CardEditionCategory resources.
   * @request GET:/api/card-edition-categories
   * @secure
   */
  export namespace GetCardEditionCategories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardEditionCategoryJsonldCardEditionCategoryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CardEditionCategory resource.
   * @tags CardEditionCategory
   * @name ApiCardEditionCategoriesPost
   * @summary Creates a CardEditionCategory resource.
   * @request POST:/api/card-edition-categories
   * @secure
   */
  export namespace PostCardEditionCategories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CardEditionCategoryJsonldCardEditionCategoryWrite
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionCategoryJsonldCardEditionCategoryRead
  }
  /**
   * @description Retrieves a CardEditionCategory resource.
   * @tags CardEditionCategory
   * @name ApiCardEditionCategoriesIdGet
   * @summary Retrieves a CardEditionCategory resource.
   * @request GET:/api/card-edition-categories/{id}
   * @secure
   */
  export namespace GetCardEditionCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionCategoryJsonldCardEditionCategoryRead
  }
  /**
   * @description Updates the CardEditionCategory resource.
   * @tags CardEditionCategory
   * @name ApiCardEditionCategoriesIdPatch
   * @summary Updates the CardEditionCategory resource.
   * @request PATCH:/api/card-edition-categories/{id}
   * @secure
   */
  export namespace PatchCardEditionCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CardEditionCategoryCardEditionCategoryWrite
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionCategoryJsonldCardEditionCategoryRead
  }
  /**
   * @description Retrieves a CardEditionDto resource.
   * @tags CardEditionDto
   * @name ApiCardEditionDtosIdGet
   * @summary Retrieves a CardEditionDto resource.
   * @request GET:/api/card-edition-dtos/{id}
   * @secure
   */
  export namespace GetCardEditionDtosId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionDtoJsonld
  }
  /**
   * @description Retrieves a CardEditionGroupByDate resource.
   * @tags CardEditionGroupByDate
   * @name ApiCardEditionGroupByDatesIdGet
   * @summary Retrieves a CardEditionGroupByDate resource.
   * @request GET:/api/card-edition-group-by-dates/{id}
   * @secure
   */
  export namespace GetCardEditionGroupByDatesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionGroupByDateJsonld
  }
  /**
   * @description Retrieves a CardEditionGroupByName resource.
   * @tags CardEditionGroupByName
   * @name ApiCardEditionGroupByNamesIdGet
   * @summary Retrieves a CardEditionGroupByName resource.
   * @request GET:/api/card-edition-group-by-names/{id}
   * @secure
   */
  export namespace GetCardEditionGroupByNamesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionGroupByNameJsonld
  }
  /**
   * @description Retrieves the collection of CardEditionStoryblokStory resources.
   * @tags CardEditionStoryblokStory
   * @name ApiCardEditionStoryblokStoriesGetCollection
   * @summary Retrieves the collection of CardEditionStoryblokStory resources.
   * @request GET:/api/card-edition-storyblok-stories
   * @secure
   */
  export namespace GetCardEditionStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardEditionStoryblokStoryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CardEditionStoryblokStory resource.
   * @tags CardEditionStoryblokStory
   * @name ApiCardEditionStoryblokStoriesPost
   * @summary Creates a CardEditionStoryblokStory resource.
   * @request POST:/api/card-edition-storyblok-stories
   * @secure
   */
  export namespace PostCardEditionStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CardEditionStoryblokStoryJsonld
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionStoryblokStoryJsonld
  }
  /**
   * @description Retrieves a CardEditionStoryblokStory resource.
   * @tags CardEditionStoryblokStory
   * @name ApiCardEditionStoryblokStoriesIdGet
   * @summary Retrieves a CardEditionStoryblokStory resource.
   * @request GET:/api/card-edition-storyblok-stories/{id}
   * @secure
   */
  export namespace GetCardEditionStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionStoryblokStoryJsonld
  }
  /**
   * @description Removes the CardEditionStoryblokStory resource.
   * @tags CardEditionStoryblokStory
   * @name ApiCardEditionStoryblokStoriesIdDelete
   * @summary Removes the CardEditionStoryblokStory resource.
   * @request DELETE:/api/card-edition-storyblok-stories/{id}
   * @secure
   */
  export namespace DeleteCardEditionStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the CardEditionStoryblokStory resource.
   * @tags CardEditionStoryblokStory
   * @name ApiCardEditionStoryblokStoriesIdPatch
   * @summary Updates the CardEditionStoryblokStory resource.
   * @request PATCH:/api/card-edition-storyblok-stories/{id}
   * @secure
   */
  export namespace PatchCardEditionStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CardEditionStoryblokStory
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionStoryblokStoryJsonld
  }
  /**
   * @description Retrieves the collection of CardEdition resources.
   * @tags CardEdition
   * @name ApiCardEditionsGetCollection
   * @summary Retrieves the collection of CardEdition resources.
   * @request GET:/api/card-editions
   * @secure
   */
  export namespace GetCardEditions {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      family?: string
      'family[]'?: string[]
      'printExclusions.store'?: string
      'printExclusions.store[]'?: string[]
      'family.slug'?: string
      'family.slug[]'?: string[]
      'exists[parentEdition]'?: boolean
      withoutExcludedAt?: string
      'releasedAt[before]'?: string
      'releasedAt[strictly_before]'?: string
      'releasedAt[after]'?: string
      'releasedAt[strictly_after]'?: string
      'order[releasedAt]'?: 'asc' | 'desc'
      'order[name]'?: 'asc' | 'desc'
      withProducts?: boolean
      withCards?: boolean
      search?: string
      featured?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardEditionJsonldCardEditionRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardEdition resource.
   * @tags CardEdition
   * @name ApiCardEditionsIdGet
   * @summary Retrieves a CardEdition resource.
   * @request GET:/api/card-editions/{id}
   * @secure
   */
  export namespace GetCardEditionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardEditionJsonldCardEditionRead
  }
  /**
   * @description Retrieves the collection of CardGrading resources.
   * @tags CardGrading
   * @name ApiCardGradingsGetCollection
   * @summary Retrieves the collection of CardGrading resources.
   * @request GET:/api/card-gradings
   * @secure
   */
  export namespace GetCardGradings {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardGradingJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardGrading resource.
   * @tags CardGrading
   * @name ApiCardGradingsIdGet
   * @summary Retrieves a CardGrading resource.
   * @request GET:/api/card-gradings/{id}
   * @secure
   */
  export namespace GetCardGradingsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardGradingJsonld
  }
  /**
   * @description Retrieves the collection of CardOpening resources.
   * @tags CardOpening
   * @name ApiCardOpeningsGetCollection
   * @summary Retrieves the collection of CardOpening resources.
   * @request GET:/api/card-openings
   * @secure
   */
  export namespace GetCardOpenings {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardOpeningJsonldCardOpeningRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CardOpening resource.
   * @tags CardOpening
   * @name ApiCardOpeningsPost
   * @summary Creates a CardOpening resource.
   * @request POST:/api/card-openings
   * @secure
   */
  export namespace PostCardOpenings {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CardOpeningJsonldCardOpeningWrite
    export type RequestHeaders = {}
    export type ResponseBody = CardOpeningJsonldCardOpeningRead
  }
  /**
   * @description Retrieves a CardOpening resource.
   * @tags CardOpening
   * @name ApiCardOpeningsIdGet
   * @summary Retrieves a CardOpening resource.
   * @request GET:/api/card-openings/{id}
   * @secure
   */
  export namespace GetCardOpeningsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardOpeningJsonldCardOpeningRead
  }
  /**
   * @description Removes the CardOpening resource.
   * @tags CardOpening
   * @name ApiCardOpeningsIdDelete
   * @summary Removes the CardOpening resource.
   * @request DELETE:/api/card-openings/{id}
   * @secure
   */
  export namespace DeleteCardOpeningsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the CardOpening resource.
   * @tags CardOpening
   * @name ApiCardOpeningsIdPatch
   * @summary Updates the CardOpening resource.
   * @request PATCH:/api/card-openings/{id}
   * @secure
   */
  export namespace PatchCardOpeningsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CardOpeningCardOpeningWrite
    export type RequestHeaders = {}
    export type ResponseBody = CardOpeningJsonldCardOpeningRead
  }
  /**
   * @description Retrieves the collection of CardProduct resources.
   * @tags CardProduct
   * @name ApiCardProductsGetCollection
   * @summary Retrieves the collection of CardProduct resources.
   * @request GET:/api/card-products
   * @secure
   */
  export namespace GetCardProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'cardData.variation.cardProperties'?: string
      'cardData.variation.cardProperties[]'?: string[]
      'cardData.variation.edition'?: string
      'cardData.variation.edition[]'?: string[]
      'cardData.variation'?: string
      'cardData.variation[]'?: string[]
      search?: string
      'order[cardData.variation.edition.releasedAt]'?: 'asc' | 'desc'
      'order[cardData.variation.cardProperties.id]'?: 'asc' | 'desc'
      'order[price]'?: 'asc' | 'desc'
      'order[cardData.declination.grading.position]'?: 'asc' | 'desc'
      'order[cardData.declination.lang.id]'?: 'asc' | 'desc'
      'order[cardData.declination.foil]'?: 'asc' | 'desc'
      'onlineQuantity[between]'?: string
      'onlineQuantity[gt]'?: string
      'onlineQuantity[gte]'?: string
      'onlineQuantity[lt]'?: string
      'onlineQuantity[lte]'?: string
      rulesText?: string
      linkedEdition?: string
      'linkedEdition[]'?: string[]
      family?: string
      'family[]'?: string[]
      'lang.code'?: string
      'lang.code[]'?: string[]
      'price[between]'?: string
      'price[gt]'?: string
      'price[gte]'?: string
      'price[lt]'?: string
      'price[lte]'?: string
      'exists[secondHandOf]'?: boolean
      type?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardProductJsonldProductReadProductStats[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CardProduct resource.
   * @tags CardProduct
   * @name ApiCardProductsPost
   * @summary Creates a CardProduct resource.
   * @request POST:/api/card-products
   * @secure
   */
  export namespace PostCardProducts {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CardProductJsonld
    export type RequestHeaders = {}
    export type ResponseBody = CardProductJsonldProductReadProductStats
  }
  /**
   * @description Creates a CardProduct resource.
   * @tags CardProduct
   * @name ApiCardProductsregradeIdPost
   * @summary Creates a CardProduct resource.
   * @request POST:/api/card-products/regrade/{id}
   * @secure
   */
  export namespace PostCardProductsRegradeId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CardProductJsonld
    export type RequestHeaders = {}
    export type ResponseBody = CardProductJsonldProductReadProductStats
  }
  /**
   * @description Creates a CardProduct resource.
   * @tags CardProduct
   * @name ApiCardProductsupdateMkmIdPost
   * @summary Creates a CardProduct resource.
   * @request POST:/api/card-products/update-mkm/{id}
   * @secure
   */
  export namespace PostCardProductsUpdateMkmId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CardProductJsonld
    export type RequestHeaders = {}
    export type ResponseBody = CardProductJsonldProductReadProductStats
  }
  /**
   * @description Retrieves a CardProduct resource.
   * @tags CardProduct
   * @name ApiCardProductsIdGet
   * @summary Retrieves a CardProduct resource.
   * @request GET:/api/card-products/{id}
   * @secure
   */
  export namespace GetCardProductsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardProductJsonldProductReadProductReadItemProductStats
  }
  /**
   * @description Updates the CardProduct resource.
   * @tags CardProduct
   * @name ApiCardProductsIdPatch
   * @summary Updates the CardProduct resource.
   * @request PATCH:/api/card-products/{id}
   * @secure
   */
  export namespace PatchCardProductsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CardProduct
    export type RequestHeaders = {}
    export type ResponseBody = CardProductJsonldProductReadProductReadItemProductStats
  }
  /**
   * @description Retrieves the collection of CardRarity resources.
   * @tags CardRarity
   * @name ApiCardRaritiesGetCollection
   * @summary Retrieves the collection of CardRarity resources.
   * @request GET:/api/card-rarities
   * @secure
   */
  export namespace GetCardRarities {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      family?: string
      'family[]'?: string[]
      'order[name]'?: 'asc' | 'desc'
      'order[position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardRarityJsonldCardRarityRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardRarity resource.
   * @tags CardRarity
   * @name ApiCardRaritiesIdGet
   * @summary Retrieves a CardRarity resource.
   * @request GET:/api/card-rarities/{id}
   * @secure
   */
  export namespace GetCardRaritiesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardRarityJsonldCardRarityRead
  }
  /**
   * @description Retrieves the collection of CardScan resources.
   * @tags CardScan
   * @name ApiCardScansGetCollection
   * @summary Retrieves the collection of CardScan resources.
   * @request GET:/api/card-scans
   * @secure
   */
  export namespace GetCardScans {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardScanJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardScan resource.
   * @tags CardScan
   * @name ApiCardScansIdGet
   * @summary Retrieves a CardScan resource.
   * @request GET:/api/card-scans/{id}
   * @secure
   */
  export namespace GetCardScansId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardScanJsonld
  }
  /**
   * @description Retrieves a CardTranslation resource.
   * @tags CardTranslation
   * @name ApiCardTranslationsIdGet
   * @summary Retrieves a CardTranslation resource.
   * @request GET:/api/card-translations/{id}
   * @secure
   */
  export namespace GetCardTranslationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardTranslationJsonld
  }
  /**
   * @description Retrieves the collection of CardType resources.
   * @tags CardType
   * @name ApiCardTypesGetCollection
   * @summary Retrieves the collection of CardType resources.
   * @request GET:/api/card-types
   * @secure
   */
  export namespace GetCardTypes {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      name?: string
      'name[]'?: string[]
      family?: string
      'family[]'?: string[]
      'order[name]'?: 'asc' | 'desc'
      'order[highlight]'?: 'asc' | 'desc'
      'order[position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardTypeJsonldCardTypesRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardType resource.
   * @tags CardType
   * @name ApiCardTypesIdGet
   * @summary Retrieves a CardType resource.
   * @request GET:/api/card-types/{id}
   * @secure
   */
  export namespace GetCardTypesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardTypeJsonldCardTypesRead
  }
  /**
   * @description Retrieves the collection of CardVariation resources.
   * @tags CardVariation
   * @name ApiCardVariationsGetCollection
   * @summary Retrieves the collection of CardVariation resources.
   * @request GET:/api/card-variations
   * @secure
   */
  export namespace GetCardVariations {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      cardProperties?: string
      'cardProperties[]'?: string[]
      edition?: string
      'edition[]'?: string[]
      'order[insertedAt]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardVariationJsonldCardVariationRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CardVariation resource.
   * @tags CardVariation
   * @name ApiCardVariationsIdGet
   * @summary Retrieves a CardVariation resource.
   * @request GET:/api/card-variations/{id}
   * @secure
   */
  export namespace GetCardVariationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardVariationJsonldCardVariationReadCardVariationReadItem
  }
  /**
   * @description Retrieves the collection of Card resources.
   * @tags Card
   * @name ApiCardsGetCollection
   * @summary Retrieves the collection of Card resources.
   * @request GET:/api/cards
   * @secure
   */
  export namespace GetCards {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      family?: string
      'family[]'?: string[]
      name?: string
      rulesText?: string
      'variations.edition'?: string
      'variations.edition[]'?: string[]
      'variations.edition.name'?: string
      'variations.edition.name[]'?: string[]
      'variations.rarity'?: string
      'variations.rarity[]'?: string[]
      'cardTypes.name'?: string
      'cardTypes.name[]'?: string[]
      'cardTypes.id'?: number
      'cardTypes.id[]'?: number[]
      colors?: string
      'colors[]'?: string[]
      cardTypes?: string
      'cardTypes[]'?: string[]
      features?: string
      'features[]'?: string[]
      'manaValue[between]'?: string
      'manaValue[gt]'?: string
      'manaValue[gte]'?: string
      'manaValue[lt]'?: string
      'manaValue[lte]'?: string
      'power[between]'?: string
      'power[gt]'?: string
      'power[gte]'?: string
      'power[lt]'?: string
      'power[lte]'?: string
      'toughness[between]'?: string
      'toughness[gt]'?: string
      'toughness[gte]'?: string
      'toughness[lt]'?: string
      'toughness[lte]'?: string
      'manaCost[between]'?: string
      'manaCost[gt]'?: string
      'manaCost[gte]'?: string
      'manaCost[lt]'?: string
      'manaCost[lte]'?: string
      manaValue?: number
      'manaValue[]'?: number[]
      'pricingTag.id'?: number
      'pricingTag.id[]'?: number[]
      'taggedBy.id'?: number
      'taggedBy.id[]'?: number[]
      search?: string
      'fulltext[q]'?: string
      'fulltext[order]'?: 'asc' | 'desc'
      isAfterDeadline?: boolean
      'order[name]'?: 'asc' | 'desc'
      'order[englishName]'?: 'asc' | 'desc'
      'order[family.name]'?: 'asc' | 'desc'
      'order[pricingTag.id]'?: 'asc' | 'desc'
      'order[taggedAt]'?: 'asc' | 'desc'
      'order[priceCheckedAt]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CardJsonldCardPropertiesRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Card resource.
   * @tags Card
   * @name ApiCardsIdGet
   * @summary Retrieves a Card resource.
   * @request GET:/api/cards/{id}
   * @secure
   */
  export namespace GetCardsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CardJsonldCardPropertiesReadCardPropertiesReadItem
  }
  /**
   * @description Updates the Card resource.
   * @tags Card
   * @name ApiCardsIdPatch
   * @summary Updates the Card resource.
   * @request PATCH:/api/cards/{id}
   * @secure
   */
  export namespace PatchCardsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CardCardWrite
    export type RequestHeaders = {}
    export type ResponseBody = CardJsonldCardRead
  }
  /**
   * @description Retrieves a CartEntry resource.
   * @tags CartEntry
   * @name ApiCartEntriesIdGet
   * @summary Retrieves a CartEntry resource.
   * @request GET:/api/cart-entries/{id}
   * @secure
   */
  export namespace GetCartEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CartEntryJsonld
  }
  /**
   * @description Retrieves the collection of CashboxControl resources.
   * @tags CashboxControl
   * @name ApiCashboxControlsGetCollection
   * @summary Retrieves the collection of CashboxControl resources.
   * @request GET:/api/cashbox-controls
   * @secure
   */
  export namespace GetCashboxControls {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[createdAt]'?: 'asc' | 'desc'
      'order[closedAt]'?: 'asc' | 'desc'
      store?: string
      'store[]'?: string[]
      createdBy?: string
      'createdBy[]'?: string[]
      status?: string
      'status[]'?: string[]
      'exists[closedAt]'?: boolean
      'createdAt[before]'?: string
      'createdAt[strictly_before]'?: string
      'createdAt[after]'?: string
      'createdAt[strictly_after]'?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CashboxControlJsonldCashControlRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CashboxControl resource.
   * @tags CashboxControl
   * @name ApiCashboxControlsPost
   * @summary Creates a CashboxControl resource.
   * @request POST:/api/cashbox-controls
   * @secure
   */
  export namespace PostCashboxControls {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CashboxControlJsonldCashControlCreate
    export type RequestHeaders = {}
    export type ResponseBody = CashboxControlJsonldCashControlRead
  }
  /**
   * @description Updates the CashboxControl resource.
   * @tags CashboxControl
   * @name ApiCashboxControlscloseIdPatch
   * @summary Updates the CashboxControl resource.
   * @request PATCH:/api/cashbox-controls/close/{id}
   * @secure
   */
  export namespace PatchCashboxControlsCloseId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CashboxControlCashControlClose
    export type RequestHeaders = {}
    export type ResponseBody = CashboxControlJsonldCashControlRead
  }
  /**
   * @description Updates the CashboxControl resource.
   * @tags CashboxControl
   * @name ApiCashboxControlsfundIdPatch
   * @summary Updates the CashboxControl resource.
   * @request PATCH:/api/cashbox-controls/fund/{id}
   * @secure
   */
  export namespace PatchCashboxControlsFundId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CashboxControlCashControlFund
    export type RequestHeaders = {}
    export type ResponseBody = CashboxControlJsonldCashControlRead
  }
  /**
   * @description Updates the CashboxControl resource.
   * @tags CashboxControl
   * @name ApiCashboxControlsvalidateIdPatch
   * @summary Updates the CashboxControl resource.
   * @request PATCH:/api/cashbox-controls/validate/{id}
   * @secure
   */
  export namespace PatchCashboxControlsValidateId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CashboxControlCashControlValidate
    export type RequestHeaders = {}
    export type ResponseBody = CashboxControlJsonldCashControlRead
  }
  /**
   * @description Retrieves a CashboxControl resource.
   * @tags CashboxControl
   * @name ApiCashboxControlsIdGet
   * @summary Retrieves a CashboxControl resource.
   * @request GET:/api/cashbox-controls/{id}
   * @secure
   */
  export namespace GetCashboxControlsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CashboxControlJsonldCashControlRead
  }
  /**
   * @description Retrieves a CatalogResult resource.
   * @tags CatalogResult
   * @name ApiCatalogResultsIdentifierGet
   * @summary Retrieves a CatalogResult resource.
   * @request GET:/api/catalog-results/{identifier}
   * @secure
   */
  export namespace GetCatalogResultsIdentifier {
    export type RequestParams = { identifier: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CatalogResultJsonld
  }
  /**
   * @description Retrieves a Catalog resource.
   * @tags Catalog
   * @name ApiCatalogsCatalogIdentifierGet
   * @summary Retrieves a Catalog resource.
   * @request GET:/api/catalogs/{catalogIdentifier}
   * @secure
   */
  export namespace GetCatalogsCatalogIdentifier {
    export type RequestParams = { catalogIdentifier: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CatalogJsonld
  }
  /**
   * @description Retrieves the collection of CategoryStoryblokStory resources.
   * @tags CategoryStoryblokStory
   * @name ApiCategoryStoryblokStoriesGetCollection
   * @summary Retrieves the collection of CategoryStoryblokStory resources.
   * @request GET:/api/category-storyblok-stories
   * @secure
   */
  export namespace GetCategoryStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CategoryStoryblokStoryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CategoryStoryblokStory resource.
   * @tags CategoryStoryblokStory
   * @name ApiCategoryStoryblokStoriesPost
   * @summary Creates a CategoryStoryblokStory resource.
   * @request POST:/api/category-storyblok-stories
   * @secure
   */
  export namespace PostCategoryStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CategoryStoryblokStoryJsonld
    export type RequestHeaders = {}
    export type ResponseBody = CategoryStoryblokStoryJsonld
  }
  /**
   * @description Retrieves a CategoryStoryblokStory resource.
   * @tags CategoryStoryblokStory
   * @name ApiCategoryStoryblokStoriesIdGet
   * @summary Retrieves a CategoryStoryblokStory resource.
   * @request GET:/api/category-storyblok-stories/{id}
   * @secure
   */
  export namespace GetCategoryStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CategoryStoryblokStoryJsonld
  }
  /**
   * @description Removes the CategoryStoryblokStory resource.
   * @tags CategoryStoryblokStory
   * @name ApiCategoryStoryblokStoriesIdDelete
   * @summary Removes the CategoryStoryblokStory resource.
   * @request DELETE:/api/category-storyblok-stories/{id}
   * @secure
   */
  export namespace DeleteCategoryStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the CategoryStoryblokStory resource.
   * @tags CategoryStoryblokStory
   * @name ApiCategoryStoryblokStoriesIdPatch
   * @summary Updates the CategoryStoryblokStory resource.
   * @request PATCH:/api/category-storyblok-stories/{id}
   * @secure
   */
  export namespace PatchCategoryStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CategoryStoryblokStory
    export type RequestHeaders = {}
    export type ResponseBody = CategoryStoryblokStoryJsonld
  }
  /**
   * @description Retrieves the collection of CollaboratorRoleAction resources.
   * @tags CollaboratorRoleAction
   * @name ApiCollaboratorRoleActionsGetCollection
   * @summary Retrieves the collection of CollaboratorRoleAction resources.
   * @request GET:/api/collaborator-role-actions
   * @secure
   */
  export namespace GetCollaboratorRoleActions {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'collaboratorRoles.franchised'?: boolean
      collaboratorRoles?: string
      'collaboratorRoles[]'?: string[]
      'order[displayName]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CollaboratorRoleActionJsonldCollaboratorActionRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CollaboratorRoleAction resource.
   * @tags CollaboratorRoleAction
   * @name ApiCollaboratorRoleActionsPost
   * @summary Creates a CollaboratorRoleAction resource.
   * @request POST:/api/collaborator-role-actions
   * @secure
   */
  export namespace PostCollaboratorRoleActions {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CollaboratorRoleActionJsonldCollaboratorActionWrite
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorRoleActionJsonldCollaboratorActionRead
  }
  /**
   * @description Retrieves a CollaboratorRoleAction resource.
   * @tags CollaboratorRoleAction
   * @name ApiCollaboratorRoleActionsIdGet
   * @summary Retrieves a CollaboratorRoleAction resource.
   * @request GET:/api/collaborator-role-actions/{id}
   * @secure
   */
  export namespace GetCollaboratorRoleActionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorRoleActionJsonldCollaboratorActionRead
  }
  /**
   * @description Updates the CollaboratorRoleAction resource.
   * @tags CollaboratorRoleAction
   * @name ApiCollaboratorRoleActionsIdPatch
   * @summary Updates the CollaboratorRoleAction resource.
   * @request PATCH:/api/collaborator-role-actions/{id}
   * @secure
   */
  export namespace PatchCollaboratorRoleActionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CollaboratorRoleActionCollaboratorActionWrite
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorRoleActionJsonldCollaboratorActionRead
  }
  /**
   * @description Retrieves the collection of CollaboratorRole resources.
   * @tags CollaboratorRole
   * @name ApiCollaboratorRolesGetCollection
   * @summary Retrieves the collection of CollaboratorRole resources.
   * @request GET:/api/collaborator-roles
   * @secure
   */
  export namespace GetCollaboratorRoles {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      franchised?: boolean
      collaboratorActions?: string
      'collaboratorActions[]'?: string[]
      roleName?: string
      'roleName[]'?: string[]
      'order[name]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CollaboratorRoleJsonldCollaboratorRoleRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CollaboratorRole resource.
   * @tags CollaboratorRole
   * @name ApiCollaboratorRolesPost
   * @summary Creates a CollaboratorRole resource.
   * @request POST:/api/collaborator-roles
   * @secure
   */
  export namespace PostCollaboratorRoles {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CollaboratorRoleJsonldCollaboratorRoleWrite
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorRoleJsonldCollaboratorRoleRead
  }
  /**
   * @description Retrieves a CollaboratorRole resource.
   * @tags CollaboratorRole
   * @name ApiCollaboratorRolesIdGet
   * @summary Retrieves a CollaboratorRole resource.
   * @request GET:/api/collaborator-roles/{id}
   * @secure
   */
  export namespace GetCollaboratorRolesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorRoleJsonldCollaboratorRoleRead
  }
  /**
   * @description Updates the CollaboratorRole resource.
   * @tags CollaboratorRole
   * @name ApiCollaboratorRolesIdPatch
   * @summary Updates the CollaboratorRole resource.
   * @request PATCH:/api/collaborator-roles/{id}
   * @secure
   */
  export namespace PatchCollaboratorRolesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CollaboratorRoleCollaboratorRoleWrite
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorRoleJsonldCollaboratorRoleRead
  }
  /**
   * @description Retrieves the collection of Collaborator resources.
   * @tags Collaborator
   * @name ApiCollaboratorsGetCollection
   * @summary Retrieves the collection of Collaborator resources.
   * @request GET:/api/collaborators
   * @secure
   */
  export namespace GetCollaborators {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      active?: boolean
      'exists[cashboxName]'?: boolean
      role?: string
      withRoles?: boolean
      'not[role]'?: string
      'order[id]'?: 'asc' | 'desc'
      'order[lastname]'?: 'asc' | 'desc'
      'order[firstname]'?: 'asc' | 'desc'
      firstname?: string
      lastname?: string
      email?: string
      stores?: string
      'stores[]'?: string[]
      mainStore?: string
      'mainStore[]'?: string[]
      'collaboratorRoles.roleName'?: string
      'collaboratorRoles.roleName[]'?: string[]
      collaboratorRoles?: string
      'collaboratorRoles[]'?: string[]
      'collaboratorRoles.collaboratorActions'?: string
      'collaboratorRoles.collaboratorActions[]'?: string[]
      'collaboratorRoles.collaboratorActions.name'?: string
      'collaboratorRoles.collaboratorActions.name[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CollaboratorJsonldUserRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsPost
   * @summary Creates a Collaborator resource.
   * @request POST:/api/collaborators
   * @secure
   */
  export namespace PostCollaborators {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CollaboratorJsonldCollaboratorWriteCollaboratorCreate
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserRead
  }
  /**
   * @description Creates a Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsactivateIdPost
   * @summary Activate a collaborator account
   * @request POST:/api/collaborators/activate/{id}
   * @secure
   */
  export namespace PostCollaboratorsActivateId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CollaboratorJsonldCollaboratorActivate
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserRead
  }
  /**
   * @description Creates a Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsdeactivateIdPost
   * @summary Creates a Collaborator resource.
   * @request POST:/api/collaborators/deactivate/{id}
   * @secure
   */
  export namespace PostCollaboratorsDeactivateId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CollaboratorJsonld
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserRead
  }
  /**
   * @description Retrieves a Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorspermissionsIdGet
   * @summary Retrieves a Collaborator resource.
   * @request GET:/api/collaborators/permissions/{id}
   * @secure
   */
  export namespace GetCollaboratorsPermissionsId {
    export type RequestParams = { id?: number }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserRead
  }
  /**
   * @description Creates a Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsresetIdPost
   * @summary Reset a collaborator password
   * @request POST:/api/collaborators/reset/{id}
   * @secure
   */
  export namespace PostCollaboratorsResetId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CollaboratorJsonldCollaboratorReset
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserRead
  }
  /**
   * @description Updates the Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsupdatePasswordPatch
   * @summary Update your password
   * @request PATCH:/api/collaborators/update-password/
   * @secure
   */
  export namespace PatchCollaboratorsUpdatePassword {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CollaboratorCollaboratorUpdatePasswordInput
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserRead
  }
  /**
   * @description Retrieves a Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsIdGet
   * @summary Retrieves a Collaborator resource.
   * @request GET:/api/collaborators/{id}
   * @secure
   */
  export namespace GetCollaboratorsId {
    export type RequestParams = { excludeWarehouse?: boolean; id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserReadUserReadItem
  }
  /**
   * @description Removes the Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsIdDelete
   * @summary Removes the Collaborator resource.
   * @request DELETE:/api/collaborators/{id}
   * @deprecated
   * @secure
   */
  export namespace DeleteCollaboratorsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the Collaborator resource.
   * @tags Collaborator
   * @name ApiCollaboratorsIdPatch
   * @summary Updates the Collaborator resource.
   * @request PATCH:/api/collaborators/{id}
   * @secure
   */
  export namespace PatchCollaboratorsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CollaboratorCollaboratorWrite
    export type RequestHeaders = {}
    export type ResponseBody = CollaboratorJsonldUserRead
  }
  /**
   * @description Retrieves the collection of CommercialOffer resources.
   * @tags CommercialOffer
   * @name ApiCommercialOffersGetCollection
   * @summary Retrieves the collection of CommercialOffer resources.
   * @request GET:/api/commercial-offers
   * @secure
   */
  export namespace GetCommercialOffers {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      type?: string
      'type[]'?: string[]
      family?: string
      'family[]'?: string[]
      hasCards?: boolean
      hasProducts?: boolean
      promoOffer?: boolean
      familySlug?: string
      'startsAt[before]'?: string
      'startsAt[strictly_before]'?: string
      'startsAt[after]'?: string
      'startsAt[strictly_after]'?: string
      'endsAt[before]'?: string
      'endsAt[strictly_before]'?: string
      'endsAt[after]'?: string
      'endsAt[strictly_after]'?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CommercialOfferJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CommercialOffer resource.
   * @tags CommercialOffer
   * @name ApiCommercialOffersIdGet
   * @summary Retrieves a CommercialOffer resource.
   * @request GET:/api/commercial-offers/{id}
   * @secure
   */
  export namespace GetCommercialOffersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CommercialOfferJsonld
  }
  /**
   * @description Retrieves the collection of Company resources.
   * @tags Company
   * @name ApiCompaniesGetCollection
   * @summary Retrieves the collection of Company resources.
   * @request GET:/api/companies
   * @secure
   */
  export namespace GetCompanies {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CompanyJsonldCompanyRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Company resource.
   * @tags Company
   * @name ApiCompaniesIdGet
   * @summary Retrieves a Company resource.
   * @request GET:/api/companies/{id}
   * @secure
   */
  export namespace GetCompaniesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyJsonldCompanyRead
  }
  /**
   * @description Retrieves a Coordinates resource.
   * @tags Coordinates
   * @name ApiCoordinatesIdGet
   * @summary Retrieves a Coordinates resource.
   * @request GET:/api/coordinates/{id}
   * @secure
   */
  export namespace GetCoordinatesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CoordinatesJsonld
  }
  /**
   * @description Retrieves the collection of Country resources.
   * @tags Country
   * @name ApiCountriesGetCollection
   * @summary Retrieves the collection of Country resources.
   * @request GET:/api/countries
   * @secure
   */
  export namespace GetCountries {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      name?: string
      'name[]'?: string[]
      alpha2?: string
      'alpha2[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CountryJsonldCountryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Country resource.
   * @tags Country
   * @name ApiCountriesIdGet
   * @summary Retrieves a Country resource.
   * @request GET:/api/countries/{id}
   * @secure
   */
  export namespace GetCountriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CountryJsonldCountryRead
  }
  /**
   * @description Retrieves the collection of CouponCampaign resources.
   * @tags CouponCampaign
   * @name ApiCouponCampaignsGetCollection
   * @summary Retrieves the collection of CouponCampaign resources.
   * @request GET:/api/coupon-campaigns
   * @secure
   */
  export namespace GetCouponCampaigns {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CouponCampaignJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CouponCampaign resource.
   * @tags CouponCampaign
   * @name ApiCouponCampaignsIdGet
   * @summary Retrieves a CouponCampaign resource.
   * @request GET:/api/coupon-campaigns/{id}
   * @secure
   */
  export namespace GetCouponCampaignsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CouponCampaignJsonld
  }
  /**
   * @description Retrieves the collection of Coupon resources.
   * @tags Coupon
   * @name ApiCouponsGetCollection
   * @summary Retrieves the collection of Coupon resources.
   * @request GET:/api/coupons
   * @secure
   */
  export namespace GetCoupons {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'expiresAt[before]'?: string
      'expiresAt[strictly_before]'?: string
      'expiresAt[after]'?: string
      'expiresAt[strictly_after]'?: string
      valid?: boolean
      'order[code]'?: 'asc' | 'desc'
      customer?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CouponJsonldCouponRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Coupon resource.
   * @tags Coupon
   * @name ApiCouponsPost
   * @summary Creates a Coupon resource.
   * @request POST:/api/coupons
   * @secure
   */
  export namespace PostCoupons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CouponJsonldCouponWrite
    export type RequestHeaders = {}
    export type ResponseBody = CouponJsonldCouponRead
  }
  /**
   * @description Retrieves a Coupon resource.
   * @tags Coupon
   * @name ApiCouponsIdGet
   * @summary Retrieves a Coupon resource.
   * @request GET:/api/coupons/{id}
   * @secure
   */
  export namespace GetCouponsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CouponJsonldCouponRead
  }
  /**
   * @description Updates the Coupon resource.
   * @tags Coupon
   * @name ApiCouponsIdPatch
   * @summary Updates the Coupon resource.
   * @request PATCH:/api/coupons/{id}
   * @secure
   */
  export namespace PatchCouponsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CouponCouponWrite
    export type RequestHeaders = {}
    export type ResponseBody = CouponJsonldCouponRead
  }
  /**
   * @description Retrieves the collection of CustomerId resources.
   * @tags CustomerId
   * @name ApiCustomerIdsGetCollection
   * @summary Retrieves the collection of CustomerId resources.
   * @request GET:/api/customer-ids
   * @secure
   */
  export namespace GetCustomerIds {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CustomerIdJsonldCustomerIdRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a CustomerId resource.
   * @tags CustomerId
   * @name ApiCustomerIdsPost
   * @summary Creates a CustomerId resource.
   * @request POST:/api/customer-ids
   * @secure
   */
  export namespace PostCustomerIds {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CustomerIdCustomerIdWrite
    export type RequestHeaders = {}
    export type ResponseBody = CustomerIdJsonldCustomerIdRead
  }
  /**
   * @description Retrieves a CustomerId resource.
   * @tags CustomerId
   * @name ApiCustomerIdsimageIdGet
   * @summary Retrieves a CustomerId resource.
   * @request GET:/api/customer-ids/image/{id}
   * @secure
   */
  export namespace GetCustomerIdsImageId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CustomerIdJsonldCustomerIdRead
  }
  /**
   * @description Retrieves a CustomerId resource.
   * @tags CustomerId
   * @name ApiCustomerIdsIdGet
   * @summary Retrieves a CustomerId resource.
   * @request GET:/api/customer-ids/{id}
   * @secure
   */
  export namespace GetCustomerIdsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CustomerIdJsonldCustomerIdRead
  }
  /**
   * @description Retrieves the collection of CustomerInternalType resources.
   * @tags CustomerInternalType
   * @name ApiCustomerInternalTypesGetCollection
   * @summary Retrieves the collection of CustomerInternalType resources.
   * @request GET:/api/customer-internal-types
   * @secure
   */
  export namespace GetCustomerInternalTypes {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CustomerInternalTypeJsonldCustomerInternalTypeRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a CustomerInternalType resource.
   * @tags CustomerInternalType
   * @name ApiCustomerInternalTypesIdGet
   * @summary Retrieves a CustomerInternalType resource.
   * @request GET:/api/customer-internal-types/{id}
   * @secure
   */
  export namespace GetCustomerInternalTypesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CustomerInternalTypeJsonldCustomerInternalTypeRead
  }
  /**
   * @description Retrieves the collection of Customer resources.
   * @tags Customer
   * @name ApiCustomersGetCollection
   * @summary Retrieves the collection of Customer resources.
   * @request GET:/api/customers
   * @secure
   */
  export namespace GetCustomers {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      search?: string
      name?: string
      firstname?: string
      lastname?: string
      email?: string
      zipCode?: string
      'zipCode[]'?: string[]
      playinStore?: string
      'playinStore[]'?: string[]
      accountType?: string
      'accountType[]'?: string[]
      'order[lastname]'?: 'asc' | 'desc'
      'order[firstname]'?: 'asc' | 'desc'
      'exists[playinAccountType]'?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CustomerJsonldCustomerRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Customer resource.
   * @tags Customer
   * @name ApiCustomersPost
   * @summary Creates a Customer resource.
   * @request POST:/api/customers
   * @secure
   */
  export namespace PostCustomers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CustomerJsonldCustomerCreate
    export type RequestHeaders = {}
    export type ResponseBody = CustomerJsonldCustomerReadCustomerReadItem
  }
  /**
   * @description Creates a Customer resource.
   * @tags Customer
   * @name ApiCustomersanonymiseIdPost
   * @summary Anonymises a customer account.
   * @request POST:/api/customers/anonymise/{id}
   * @secure
   */
  export namespace PostCustomersAnonymiseId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CustomerJsonldCustomerRemove
    export type RequestHeaders = {}
    export type ResponseBody = CustomerJsonldCustomerReadCustomerReadItem
  }
  /**
   * @description Retrieves the collection of Customer resources.
   * @tags Customer
   * @name ApiCustomersquickSearchIdGetCollection
   * @summary Search across email, name, phone
   * @request GET:/api/customers/quick-search/{id}
   * @secure
   */
  export namespace GetCustomersQuickSearchId {
    export type RequestParams = { id: string }
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      search?: string
      name?: string
      firstname?: string
      lastname?: string
      email?: string
      zipCode?: string
      'zipCode[]'?: string[]
      playinStore?: string
      'playinStore[]'?: string[]
      accountType?: string
      'accountType[]'?: string[]
      'order[lastname]'?: 'asc' | 'desc'
      'order[firstname]'?: 'asc' | 'desc'
      'exists[playinAccountType]'?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': CustomerJsonldCustomerRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Customer resource.
   * @tags Customer
   * @name ApiCustomersresetIdPost
   * @summary Resets a customer password.
   * @request POST:/api/customers/reset/{id}
   * @secure
   */
  export namespace PostCustomersResetId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CustomerJsonld
    export type RequestHeaders = {}
    export type ResponseBody = CustomerJsonldCustomerReadCustomerReadItem
  }
  /**
   * @description Creates a Customer resource.
   * @tags Customer
   * @name ApiCustomerstransformToProIdPost
   * @summary Transforms a customer into a retailer.
   * @request POST:/api/customers/transform-to-pro/{id}
   * @secure
   */
  export namespace PostCustomersTransformToProId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CustomerJsonldCustomerTransformPro
    export type RequestHeaders = {}
    export type ResponseBody = CustomerJsonldCustomerReadCustomerReadItem
  }
  /**
   * @description Retrieves a Customer resource.
   * @tags Customer
   * @name ApiCustomersIdGet
   * @summary Retrieves a Customer resource.
   * @request GET:/api/customers/{id}
   * @secure
   */
  export namespace GetCustomersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CustomerJsonldCustomerReadCustomerReadItem
  }
  /**
   * @description Updates the Customer resource.
   * @tags Customer
   * @name ApiCustomersIdPatch
   * @summary Updates the Customer resource.
   * @request PATCH:/api/customers/{id}
   * @secure
   */
  export namespace PatchCustomersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = CustomerCustomerPatch
    export type RequestHeaders = {}
    export type ResponseBody = CustomerJsonldCustomerReadCustomerReadItem
  }
  /**
   * @description Retrieves the collection of DeckEntry resources.
   * @tags DeckEntry
   * @name ApiDeckEntriesGetCollection
   * @summary Retrieves the collection of DeckEntry resources.
   * @request GET:/api/deck-entries
   * @secure
   */
  export namespace GetDeckEntries {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[sideboard]'?: 'asc' | 'desc'
      'order[card.name]'?: 'asc' | 'desc'
      'order[card.englishName]'?: 'asc' | 'desc'
      'order[card.cardTypes.position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': DeckEntryJsonldDeckEntryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a DeckEntry resource.
   * @tags DeckEntry
   * @name ApiDeckEntriesIdGet
   * @summary Retrieves a DeckEntry resource.
   * @request GET:/api/deck-entries/{id}
   * @secure
   */
  export namespace GetDeckEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DeckEntryJsonldDeckEntryReadDeckEntryReadItem
  }
  /**
   * @description Retrieves a DeckGroupEntry resource.
   * @tags DeckGroupEntry
   * @name ApiDeckGroupEntriesIdentifiersGet
   * @summary Retrieves a DeckGroupEntry resource.
   * @request GET:/api/deck-group-entries/{identifiers}
   * @secure
   */
  export namespace GetDeckGroupEntriesIdentifiers {
    export type RequestParams = { identifiers: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DeckGroupEntryJsonld
  }
  /**
   * @description Retrieves the collection of Deck resources.
   * @tags Deck
   * @name ApiDecksGetCollection
   * @summary Retrieves the collection of Deck resources.
   * @request GET:/api/decks
   * @secure
   */
  export namespace GetDecks {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': DeckJsonldDeckRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Deck resource.
   * @tags Deck
   * @name ApiDecksIdGet
   * @summary Retrieves a Deck resource.
   * @request GET:/api/decks/{id}
   * @secure
   */
  export namespace GetDecksId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DeckJsonldDeckReadDeckReadItem
  }
  /**
   * @description Retrieves the collection of DepositEntry resources.
   * @tags DepositEntry
   * @name ApiDepositEntriesGetCollection
   * @summary Retrieves the collection of DepositEntry resources.
   * @request GET:/api/deposit-entries
   * @secure
   */
  export namespace GetDepositEntries {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      product?: string
      'product[]'?: string[]
      openingEntry?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': DepositEntryJsonldDepositEntryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a DepositEntry resource.
   * @tags DepositEntry
   * @name ApiDepositEntriesIdGet
   * @summary Retrieves a DepositEntry resource.
   * @request GET:/api/deposit-entries/{id}
   * @secure
   */
  export namespace GetDepositEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepositEntryJsonldDepositEntryRead
  }
  /**
   * @description Retrieves the collection of Depositary resources.
   * @tags Depositary
   * @name ApiDepositariesGetCollection
   * @summary Retrieves the collection of Depositary resources.
   * @request GET:/api/depositaries
   * @secure
   */
  export namespace GetDepositaries {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': DepositaryJsonldDepositaryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Depositary resource.
   * @tags Depositary
   * @name ApiDepositariesIdGet
   * @summary Retrieves a Depositary resource.
   * @request GET:/api/depositaries/{id}
   * @secure
   */
  export namespace GetDepositariesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepositaryJsonldDepositaryRead
  }
  /**
   * @description Retrieves the collection of Deposit resources.
   * @tags Deposit
   * @name ApiDepositsGetCollection
   * @summary Retrieves the collection of Deposit resources.
   * @request GET:/api/deposits
   * @secure
   */
  export namespace GetDeposits {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'depositEntries.product'?: string
      'depositEntries.product[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': DepositJsonldDepositRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Deposit resource.
   * @tags Deposit
   * @name ApiDepositsIdGet
   * @summary Retrieves a Deposit resource.
   * @request GET:/api/deposits/{id}
   * @secure
   */
  export namespace GetDepositsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepositJsonldDepositRead
  }
  /**
   * @description Retrieves the collection of EmailType resources.
   * @tags EmailType
   * @name ApiEmailTypesGetCollection
   * @summary Retrieves the collection of EmailType resources.
   * @request GET:/api/email-types
   * @secure
   */
  export namespace GetEmailTypes {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': EmailTypeJsonldEmailTypeRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a EmailType resource.
   * @tags EmailType
   * @name ApiEmailTypesIdGet
   * @summary Retrieves a EmailType resource.
   * @request GET:/api/email-types/{id}
   * @secure
   */
  export namespace GetEmailTypesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmailTypeJsonldEmailTypeRead
  }
  /**
   * @description Retrieves the collection of Family resources.
   * @tags Family
   * @name ApiFamiliesGetCollection
   * @summary Retrieves the collection of Family resources.
   * @request GET:/api/families
   * @secure
   */
  export namespace GetFamilies {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      cards?: boolean
      highlighted?: boolean
      stores?: string
      'stores[]'?: string[]
      status?: number
      'status[]'?: number[]
      slug?: string
      'slug[]'?: string[]
      'order[name]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': FamilyJsonldFamilyRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Family resource.
   * @tags Family
   * @name ApiFamiliesIdGet
   * @summary Retrieves a Family resource.
   * @request GET:/api/families/{id}
   * @secure
   */
  export namespace GetFamiliesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FamilyJsonldFamilyRead
  }
  /**
   * @description Retrieves the collection of FamilyPage resources.
   * @tags FamilyPage
   * @name ApiFamilyPagesGetCollection
   * @summary Retrieves the collection of FamilyPage resources.
   * @request GET:/api/family-pages
   * @secure
   */
  export namespace GetFamilyPages {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      name?: string
      'name[]'?: string[]
      'family.slug'?: string
      'family.slug[]'?: string[]
      'family.id'?: number
      'family.id[]'?: number[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': FamilyPageJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a FamilyPage resource.
   * @tags FamilyPage
   * @name ApiFamilyPagesPost
   * @summary Creates a FamilyPage resource.
   * @request POST:/api/family-pages
   * @secure
   */
  export namespace PostFamilyPages {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = FamilyPageJsonld
    export type RequestHeaders = {}
    export type ResponseBody = FamilyPageJsonld
  }
  /**
   * @description Retrieves a FamilyPage resource.
   * @tags FamilyPage
   * @name ApiFamilyPagesIdGet
   * @summary Retrieves a FamilyPage resource.
   * @request GET:/api/family-pages/{id}
   * @secure
   */
  export namespace GetFamilyPagesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FamilyPageJsonld
  }
  /**
   * @description Replaces the FamilyPage resource.
   * @tags FamilyPage
   * @name ApiFamilyPagesIdPut
   * @summary Replaces the FamilyPage resource.
   * @request PUT:/api/family-pages/{id}
   * @secure
   */
  export namespace PutFamilyPagesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = FamilyPageJsonld
    export type RequestHeaders = {}
    export type ResponseBody = FamilyPageJsonld
  }
  /**
   * @description Removes the FamilyPage resource.
   * @tags FamilyPage
   * @name ApiFamilyPagesIdDelete
   * @summary Removes the FamilyPage resource.
   * @request DELETE:/api/family-pages/{id}
   * @secure
   */
  export namespace DeleteFamilyPagesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the FamilyPage resource.
   * @tags FamilyPage
   * @name ApiFamilyPagesIdPatch
   * @summary Updates the FamilyPage resource.
   * @request PATCH:/api/family-pages/{id}
   * @secure
   */
  export namespace PatchFamilyPagesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = FamilyPage
    export type RequestHeaders = {}
    export type ResponseBody = FamilyPageJsonld
  }
  /**
   * @description Retrieves the collection of FeatureGroup resources.
   * @tags FeatureGroup
   * @name ApiFeatureGroupsGetCollection
   * @summary Retrieves the collection of FeatureGroup resources.
   * @request GET:/api/feature-groups
   * @secure
   */
  export namespace GetFeatureGroups {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      family?: string
      'family[]'?: string[]
      'order[name]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': FeatureGroupJsonldFeatureGroupRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a FeatureGroup resource.
   * @tags FeatureGroup
   * @name ApiFeatureGroupsIdGet
   * @summary Retrieves a FeatureGroup resource.
   * @request GET:/api/feature-groups/{id}
   * @secure
   */
  export namespace GetFeatureGroupsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FeatureGroupJsonldFeatureGroupRead
  }
  /**
   * @description Retrieves the collection of Feature resources.
   * @tags Feature
   * @name ApiFeaturesGetCollection
   * @summary Retrieves the collection of Feature resources.
   * @request GET:/api/features
   * @secure
   */
  export namespace GetFeatures {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[name]'?: 'asc' | 'desc'
      'order[position]'?: 'asc' | 'desc'
      featureGroup?: string
      'featureGroup[]'?: string[]
      'featureGroup.slug'?: string
      'featureGroup.slug[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': FeatureJsonldFeatureRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Feature resource.
   * @tags Feature
   * @name ApiFeaturesIdGet
   * @summary Retrieves a Feature resource.
   * @request GET:/api/features/{id}
   * @secure
   */
  export namespace GetFeaturesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FeatureJsonldFeatureRead
  }
  /**
   * @description Retrieves the collection of FidelityCode resources.
   * @tags FidelityCode
   * @name ApiFidelityCodesGetCollection
   * @summary Retrieves the collection of FidelityCode resources.
   * @request GET:/api/fidelity-codes
   * @secure
   */
  export namespace GetFidelityCodes {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': FidelityCodeJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a FidelityCode resource.
   * @tags FidelityCode
   * @name ApiFidelityCodesPost
   * @summary Creates a FidelityCode resource.
   * @request POST:/api/fidelity-codes
   * @secure
   */
  export namespace PostFidelityCodes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = FidelityCodeJsonld
    export type RequestHeaders = {}
    export type ResponseBody = FidelityCodeJsonld
  }
  /**
   * @description Retrieves a FidelityCode resource.
   * @tags FidelityCode
   * @name ApiFidelityCodesIdGet
   * @summary Retrieves a FidelityCode resource.
   * @request GET:/api/fidelity-codes/{id}
   * @secure
   */
  export namespace GetFidelityCodesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FidelityCodeJsonld
  }
  /**
   * @description Replaces the FidelityCode resource.
   * @tags FidelityCode
   * @name ApiFidelityCodesIdPut
   * @summary Replaces the FidelityCode resource.
   * @request PUT:/api/fidelity-codes/{id}
   * @secure
   */
  export namespace PutFidelityCodesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = FidelityCodeJsonld
    export type RequestHeaders = {}
    export type ResponseBody = FidelityCodeJsonld
  }
  /**
   * @description Removes the FidelityCode resource.
   * @tags FidelityCode
   * @name ApiFidelityCodesIdDelete
   * @summary Removes the FidelityCode resource.
   * @request DELETE:/api/fidelity-codes/{id}
   * @secure
   */
  export namespace DeleteFidelityCodesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the FidelityCode resource.
   * @tags FidelityCode
   * @name ApiFidelityCodesIdPatch
   * @summary Updates the FidelityCode resource.
   * @request PATCH:/api/fidelity-codes/{id}
   * @secure
   */
  export namespace PatchFidelityCodesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = FidelityCode
    export type RequestHeaders = {}
    export type ResponseBody = FidelityCodeJsonld
  }
  /**
   * @description Retrieves the collection of FidelityPointVoucher resources.
   * @tags FidelityPointVoucher
   * @name ApiFidelityPointVouchersGetCollection
   * @summary Retrieves the collection of FidelityPointVoucher resources.
   * @request GET:/api/fidelity-point-vouchers
   * @secure
   */
  export namespace GetFidelityPointVouchers {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': FidelityPointVoucherJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a FidelityPointVoucher resource.
   * @tags FidelityPointVoucher
   * @name ApiFidelityPointVouchersFidelityPointsGet
   * @summary Retrieves a FidelityPointVoucher resource.
   * @request GET:/api/fidelity-point-vouchers/{fidelityPoints}
   * @secure
   */
  export namespace GetFidelityPointVouchersFidelityPoints {
    export type RequestParams = { fidelityPoints: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FidelityPointVoucherJsonld
  }
  /**
   * @description Retrieves the collection of FidelityPoint resources.
   * @tags FidelityPoint
   * @name ApiFidelityPointsGetCollection
   * @summary Retrieves the collection of FidelityPoint resources.
   * @request GET:/api/fidelity-points
   * @secure
   */
  export namespace GetFidelityPoints {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      customer?: string
      'customer[]'?: string[]
      valid?: boolean
      used?: boolean
      active?: boolean
      waiting?: boolean
      activeOrWaiting?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': FidelityPointJsonldFidelityPointRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a FidelityPoint resource.
   * @tags FidelityPoint
   * @name ApiFidelityPointsPost
   * @summary Creates a FidelityPoint resource.
   * @request POST:/api/fidelity-points
   * @secure
   */
  export namespace PostFidelityPoints {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = FidelityPointJsonldFidelityPointWrite
    export type RequestHeaders = {}
    export type ResponseBody = FidelityPointJsonldFidelityPointRead
  }
  /**
   * @description Retrieves a FidelityPoint resource.
   * @tags FidelityPoint
   * @name ApiFidelityPointsIdGet
   * @summary Retrieves a FidelityPoint resource.
   * @request GET:/api/fidelity-points/{id}
   * @secure
   */
  export namespace GetFidelityPointsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FidelityPointJsonldFidelityPointRead
  }
  /**
   * @description Removes the FidelityPoint resource.
   * @tags FidelityPoint
   * @name ApiFidelityPointsIdDelete
   * @summary Removes the FidelityPoint resource.
   * @request DELETE:/api/fidelity-points/{id}
   * @secure
   */
  export namespace DeleteFidelityPointsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the FidelityPoint resource.
   * @tags FidelityPoint
   * @name ApiFidelityPointsIdPatch
   * @summary Updates the FidelityPoint resource.
   * @request PATCH:/api/fidelity-points/{id}
   * @secure
   */
  export namespace PatchFidelityPointsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = FidelityPointFidelityPointWrite
    export type RequestHeaders = {}
    export type ResponseBody = FidelityPointJsonldFidelityPointRead
  }
  /**
   * @description Retrieves a FutureEvent resource.
   * @tags FutureEvent
   * @name ApiFutureEventsIdGet
   * @summary Retrieves a FutureEvent resource.
   * @request GET:/api/future-events/{id}
   * @secure
   */
  export namespace GetFutureEventsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FutureEventJsonld
  }
  /**
   * @description Retrieves the collection of GiftCard resources.
   * @tags GiftCard
   * @name ApiGiftCardsGetCollection
   * @summary Retrieves the collection of GiftCard resources.
   * @request GET:/api/gift-cards
   * @secure
   */
  export namespace GetGiftCards {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      active?: boolean
      used?: boolean
      ean128?: string
      'ean128[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': GiftCardJsonldGiftCardRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a GiftCard resource.
   * @tags GiftCard
   * @name ApiGiftCardsactivateEan128Post
   * @summary Activate a gift card.
   * @request POST:/api/gift-cards/activate/{ean128}
   * @secure
   */
  export namespace PostGiftCardsActivateEan128 {
    export type RequestParams = { ean128: string }
    export type RequestQuery = {}
    export type RequestBody = GiftCardJsonldGiftCardActivate
    export type RequestHeaders = {}
    export type ResponseBody = GiftCardJsonldGiftCardRead
  }
  /**
   * @description Creates a GiftCard resource.
   * @tags GiftCard
   * @name ApiGiftCardsconsumeEan128CustomerPost
   * @summary Consumes a gift card.
   * @request POST:/api/gift-cards/consume/{ean128}/{customer}
   * @secure
   */
  export namespace PostGiftCardsConsumeEan128Customer {
    export type RequestParams = { ean128: string; customer: string }
    export type RequestQuery = {}
    export type RequestBody = AbstractCartAddressRead
    export type RequestHeaders = {}
    export type ResponseBody = GiftCardJsonldGiftCardRead
  }
  /**
   * @description Retrieves a GiftCard resource.
   * @tags GiftCard
   * @name ApiGiftCardsEan128Get
   * @summary Retrieves a GiftCard resource.
   * @request GET:/api/gift-cards/{ean128}
   * @secure
   */
  export namespace GetGiftCardsEan128 {
    export type RequestParams = { ean128: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = GiftCardJsonldGiftCardRead
  }
  /**
   * @description Retrieves the collection of InventoryType resources.
   * @tags InventoryType
   * @name ApiInventoryTypesGetCollection
   * @summary Retrieves the collection of InventoryType resources.
   * @request GET:/api/inventory-types
   * @secure
   */
  export namespace GetInventoryTypes {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': InventoryTypeJsonldInventoryTypeRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a InventoryType resource.
   * @tags InventoryType
   * @name ApiInventoryTypesIdGet
   * @summary Retrieves a InventoryType resource.
   * @request GET:/api/inventory-types/{id}
   * @secure
   */
  export namespace GetInventoryTypesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = InventoryTypeJsonldInventoryTypeRead
  }
  /**
   * @description Retrieves the collection of Lang resources.
   * @tags Lang
   * @name ApiLangsGetCollection
   * @summary Retrieves the collection of Lang resources.
   * @request GET:/api/langs
   * @secure
   */
  export namespace GetLangs {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': LangJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Lang resource.
   * @tags Lang
   * @name ApiLangsIdGet
   * @summary Retrieves a Lang resource.
   * @request GET:/api/langs/{id}
   * @secure
   */
  export namespace GetLangsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = LangJsonld
  }
  /**
   * @description Retrieves the collection of LicenseStoryblokStory resources.
   * @tags LicenseStoryblokStory
   * @name ApiLicenseStoryblokStoriesGetCollection
   * @summary Retrieves the collection of LicenseStoryblokStory resources.
   * @request GET:/api/license-storyblok-stories
   * @secure
   */
  export namespace GetLicenseStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': LicenseStoryblokStoryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a LicenseStoryblokStory resource.
   * @tags LicenseStoryblokStory
   * @name ApiLicenseStoryblokStoriesPost
   * @summary Creates a LicenseStoryblokStory resource.
   * @request POST:/api/license-storyblok-stories
   * @secure
   */
  export namespace PostLicenseStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = LicenseStoryblokStoryJsonld
    export type RequestHeaders = {}
    export type ResponseBody = LicenseStoryblokStoryJsonld
  }
  /**
   * @description Retrieves a LicenseStoryblokStory resource.
   * @tags LicenseStoryblokStory
   * @name ApiLicenseStoryblokStoriesIdGet
   * @summary Retrieves a LicenseStoryblokStory resource.
   * @request GET:/api/license-storyblok-stories/{id}
   * @secure
   */
  export namespace GetLicenseStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = LicenseStoryblokStoryJsonld
  }
  /**
   * @description Removes the LicenseStoryblokStory resource.
   * @tags LicenseStoryblokStory
   * @name ApiLicenseStoryblokStoriesIdDelete
   * @summary Removes the LicenseStoryblokStory resource.
   * @request DELETE:/api/license-storyblok-stories/{id}
   * @secure
   */
  export namespace DeleteLicenseStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the LicenseStoryblokStory resource.
   * @tags LicenseStoryblokStory
   * @name ApiLicenseStoryblokStoriesIdPatch
   * @summary Updates the LicenseStoryblokStory resource.
   * @request PATCH:/api/license-storyblok-stories/{id}
   * @secure
   */
  export namespace PatchLicenseStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = LicenseStoryblokStory
    export type RequestHeaders = {}
    export type ResponseBody = LicenseStoryblokStoryJsonld
  }
  /**
   * @description Retrieves the collection of LicenseTranslation resources.
   * @tags LicenseTranslation
   * @name ApiLicenseTranslationsGetCollection
   * @summary Retrieves the collection of LicenseTranslation resources.
   * @request GET:/api/license-translations
   * @secure
   */
  export namespace GetLicenseTranslations {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': LicenseTranslationJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a LicenseTranslation resource.
   * @tags LicenseTranslation
   * @name ApiLicenseTranslationsIdGet
   * @summary Retrieves a LicenseTranslation resource.
   * @request GET:/api/license-translations/{id}
   * @secure
   */
  export namespace GetLicenseTranslationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = LicenseTranslationJsonld
  }
  /**
   * @description Retrieves the collection of License resources.
   * @tags License
   * @name ApiLicensesGetCollection
   * @summary Retrieves the collection of License resources.
   * @request GET:/api/licenses
   * @secure
   */
  export namespace GetLicenses {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': LicenseJsonldLicenseRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a License resource.
   * @tags License
   * @name ApiLicensesIdGet
   * @summary Retrieves a License resource.
   * @request GET:/api/licenses/{id}
   * @secure
   */
  export namespace GetLicensesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = LicenseJsonldLicenseRead
  }
  /**
   * @description Retrieves the collection of LitigationCategory resources.
   * @tags LitigationCategory
   * @name ApiLitigationCategoriesGetCollection
   * @summary Retrieves the collection of LitigationCategory resources.
   * @request GET:/api/litigation-categories
   * @secure
   */
  export namespace GetLitigationCategories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': LitigationCategoryJsonldLitigationRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a LitigationCategory resource.
   * @tags LitigationCategory
   * @name ApiLitigationCategoriesIdGet
   * @summary Retrieves a LitigationCategory resource.
   * @request GET:/api/litigation-categories/{id}
   * @secure
   */
  export namespace GetLitigationCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = LitigationCategoryJsonldLitigationRead
  }
  /**
   * @description Retrieves the collection of Litigation resources.
   * @tags Litigation
   * @name ApiLitigationsGetCollection
   * @summary Retrieves the collection of Litigation resources.
   * @request GET:/api/litigations
   * @secure
   */
  export namespace GetLitigations {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      customer?: string
      'customer[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': LitigationJsonldLitigationRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Litigation resource.
   * @tags Litigation
   * @name ApiLitigationsPost
   * @summary Creates a Litigation resource.
   * @request POST:/api/litigations
   * @secure
   */
  export namespace PostLitigations {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = LitigationJsonldLitigationWrite
    export type RequestHeaders = {}
    export type ResponseBody = LitigationJsonldLitigationRead
  }
  /**
   * @description Retrieves a Litigation resource.
   * @tags Litigation
   * @name ApiLitigationsIdGet
   * @summary Retrieves a Litigation resource.
   * @request GET:/api/litigations/{id}
   * @secure
   */
  export namespace GetLitigationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = LitigationJsonldLitigationRead
  }
  /**
   * @description Removes the Litigation resource.
   * @tags Litigation
   * @name ApiLitigationsIdDelete
   * @summary Removes the Litigation resource.
   * @request DELETE:/api/litigations/{id}
   * @secure
   */
  export namespace DeleteLitigationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the Litigation resource.
   * @tags Litigation
   * @name ApiLitigationsIdPatch
   * @summary Updates the Litigation resource.
   * @request PATCH:/api/litigations/{id}
   * @secure
   */
  export namespace PatchLitigationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = LitigationLitigationPatch
    export type RequestHeaders = {}
    export type ResponseBody = LitigationJsonldLitigationRead
  }
  /**
   * @description Retrieves the collection of LogEntry resources.
   * @tags LogEntry
   * @name ApiLogEntriesGetCollection
   * @summary Retrieves the collection of LogEntry resources.
   * @request GET:/api/log-entries
   * @secure
   */
  export namespace GetLogEntries {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      collaborator?: string
      'collaborator[]'?: string[]
      target?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': LogEntryJsonldLogEntryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a LogEntry resource.
   * @tags LogEntry
   * @name ApiLogEntriesIdGet
   * @summary Retrieves a LogEntry resource.
   * @request GET:/api/log-entries/{id}
   * @secure
   */
  export namespace GetLogEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = LogEntryJsonldLogEntryRead
  }
  /**
   * @description Retrieves the collection of Opening resources.
   * @tags Opening
   * @name ApiOpeningsGetCollection
   * @summary Retrieves the collection of Opening resources.
   * @request GET:/api/openings
   * @secure
   */
  export namespace GetOpenings {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'depositEntries.product'?: string
      'depositEntries.product[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OpeningJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Opening resource.
   * @tags Opening
   * @name ApiOpeningsIdGet
   * @summary Retrieves a Opening resource.
   * @request GET:/api/openings/{id}
   * @secure
   */
  export namespace GetOpeningsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OpeningJsonld
  }
  /**
   * @description Retrieves the collection of OrderCheckReady resources.
   * @tags OrderCheckReady
   * @name ApiOrderCheckReadiesGetCollection
   * @summary Retrieves the collection of OrderCheckReady resources.
   * @request GET:/api/order-check-readies
   * @secure
   */
  export namespace GetOrderCheckReadies {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderCheckReadyJsonldOrderCheckReadyRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a OrderCheckReady resource.
   * @tags OrderCheckReady
   * @name ApiOrderCheckReadiesPost
   * @summary Creates a OrderCheckReady resource.
   * @request POST:/api/order-check-readies
   * @secure
   */
  export namespace PostOrderCheckReadies {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = OrderCheckReadyJsonldOrderCheckReadyWrite
    export type RequestHeaders = {}
    export type ResponseBody = OrderCheckReadyJsonldOrderCheckReadyRead
  }
  /**
   * @description Retrieves a OrderCheckReady resource.
   * @tags OrderCheckReady
   * @name ApiOrderCheckReadiesIdGet
   * @summary Retrieves a OrderCheckReady resource.
   * @request GET:/api/order-check-readies/{id}
   * @secure
   */
  export namespace GetOrderCheckReadiesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OrderCheckReadyJsonldOrderCheckReadyRead
  }
  /**
   * @description Updates the OrderCheckReady resource.
   * @tags OrderCheckReady
   * @name ApiOrderCheckReadiesIdPatch
   * @summary Updates the OrderCheckReady resource.
   * @request PATCH:/api/order-check-readies/{id}
   * @secure
   */
  export namespace PatchOrderCheckReadiesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderCheckReadyOrderCheckReadyPatch
    export type RequestHeaders = {}
    export type ResponseBody = OrderCheckReadyJsonldOrderCheckReadyRead
  }
  /**
   * @description Retrieves the collection of OrderCoupon resources.
   * @tags OrderCoupon
   * @name ApiOrderCouponsGetCollection
   * @summary Retrieves the collection of OrderCoupon resources.
   * @request GET:/api/order-coupons
   * @secure
   */
  export namespace GetOrderCoupons {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderCouponJsonldOrderCouponRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a OrderCoupon resource.
   * @tags OrderCoupon
   * @name ApiOrderCouponsPost
   * @summary Creates a OrderCoupon resource.
   * @request POST:/api/order-coupons
   * @secure
   */
  export namespace PostOrderCoupons {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = OrderCouponJsonldOrderCouponWrite
    export type RequestHeaders = {}
    export type ResponseBody = OrderCouponJsonldOrderCouponRead
  }
  /**
   * @description Retrieves a OrderCoupon resource.
   * @tags OrderCoupon
   * @name ApiOrderCouponsIdGet
   * @summary Retrieves a OrderCoupon resource.
   * @request GET:/api/order-coupons/{id}
   * @secure
   */
  export namespace GetOrderCouponsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OrderCouponJsonldOrderCouponRead
  }
  /**
   * @description Removes the OrderCoupon resource.
   * @tags OrderCoupon
   * @name ApiOrderCouponsIdDelete
   * @summary Removes the OrderCoupon resource.
   * @request DELETE:/api/order-coupons/{id}
   * @secure
   */
  export namespace DeleteOrderCouponsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the OrderCoupon resource.
   * @tags OrderCoupon
   * @name ApiOrderCouponsIdPatch
   * @summary Updates the OrderCoupon resource.
   * @request PATCH:/api/order-coupons/{id}
   * @secure
   */
  export namespace PatchOrderCouponsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderCouponOrderCouponWrite
    export type RequestHeaders = {}
    export type ResponseBody = OrderCouponJsonldOrderCouponRead
  }
  /**
   * @description Retrieves the collection of OrderEntry resources.
   * @tags OrderEntry
   * @name ApiOrderEntriesGetCollection
   * @summary Retrieves the collection of OrderEntry resources.
   * @request GET:/api/order-entries
   * @secure
   */
  export namespace GetOrderEntries {
    export type RequestParams = {}
    export type RequestQuery = {
      pagination?: boolean
      product?: string
      'product[]'?: string[]
      order?: string
      'order[]'?: string[]
      'order.paymentReceived'?: string
      'order.paymentReceived[]'?: string[]
      'order.validated'?: string
      'order.validated[]'?: string[]
      hideChild?: boolean
      productType?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderEntryJsonldOrderEntryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a OrderEntry resource.
   * @tags OrderEntry
   * @name ApiOrderEntriesPost
   * @summary Creates a OrderEntry resource.
   * @request POST:/api/order-entries
   * @secure
   */
  export namespace PostOrderEntries {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = OrderEntryJsonldOrderEntryWrite
    export type RequestHeaders = {}
    export type ResponseBody = OrderEntryJsonldOrderEntryRead
  }
  /**
   * @description Report a set of order entries as stock errors.
   * @tags OrderEntry
   * @name ReportStockError
   * @summary Report a set of order entries as stock errors.
   * @request POST:/api/order-entries/report-stock-error
   * @secure
   */
  export namespace PostOrderEntriesReportStockError {
    export type RequestParams = {}
    export type RequestQuery = { orderId: any }
    export type RequestBody = OrderOrderSplitInputJsonld
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Retrieves a OrderEntry resource.
   * @tags OrderEntry
   * @name ApiOrderEntriesIdGet
   * @summary Retrieves a OrderEntry resource.
   * @request GET:/api/order-entries/{id}
   * @secure
   */
  export namespace GetOrderEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OrderEntryJsonldOrderEntryReadOrderEntryReadItem
  }
  /**
   * @description Removes the OrderEntry resource.
   * @tags OrderEntry
   * @name ApiOrderEntriesIdDelete
   * @summary Removes the OrderEntry resource.
   * @request DELETE:/api/order-entries/{id}
   * @secure
   */
  export namespace DeleteOrderEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the OrderEntry resource.
   * @tags OrderEntry
   * @name ApiOrderEntriesIdPatch
   * @summary Updates the OrderEntry resource.
   * @request PATCH:/api/order-entries/{id}
   * @secure
   */
  export namespace PatchOrderEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderEntryOrderEntryWrite
    export type RequestHeaders = {}
    export type ResponseBody = OrderEntryJsonldOrderEntryRead
  }
  /**
   * @description Retrieves the collection of OrderPayment resources.
   * @tags OrderPayment
   * @name ApiOrderPaymentsGetCollection
   * @summary Retrieves the collection of OrderPayment resources.
   * @request GET:/api/order-payments
   * @secure
   */
  export namespace GetOrderPayments {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderPaymentJsonldOrderPaymentRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a OrderPayment resource.
   * @tags OrderPayment
   * @name ApiOrderPaymentsIdGet
   * @summary Retrieves a OrderPayment resource.
   * @request GET:/api/order-payments/{id}
   * @secure
   */
  export namespace GetOrderPaymentsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OrderPaymentJsonldOrderPaymentRead
  }
  /**
   * @description Removes the OrderPayment resource.
   * @tags OrderPayment
   * @name ApiOrderPaymentsIdDelete
   * @summary Removes the OrderPayment resource.
   * @request DELETE:/api/order-payments/{id}
   * @secure
   */
  export namespace DeleteOrderPaymentsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Retrieves the collection of OrderPrint resources.
   * @tags OrderPrint
   * @name ApiOrderPrintsGetCollection
   * @summary Retrieves the collection of OrderPrint resources.
   * @request GET:/api/order-prints
   * @secure
   */
  export namespace GetOrderPrints {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'printedAt[before]'?: string
      'printedAt[strictly_before]'?: string
      'printedAt[after]'?: string
      'printedAt[strictly_after]'?: string
      store?: string
      'store[]'?: string[]
      type?: string
      'type[]'?: string[]
      printedBy?: string
      'printedBy[]'?: string[]
      hasCards?: boolean
      'order[printedAt]'?: 'asc' | 'desc'
      'order[type]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderPrintJsonldOrderPrintRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a OrderPrint resource.
   * @tags OrderPrint
   * @name ApiOrderPrintsIdGet
   * @summary Retrieves a OrderPrint resource.
   * @request GET:/api/order-prints/{id}
   * @secure
   */
  export namespace GetOrderPrintsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OrderPrintJsonldOrderPrintRead
  }
  /**
   * @description Retrieves the collection of Order resources.
   * @tags Order
   * @name ApiOrdersGetCollection
   * @summary Retrieves the collection of Order resources.
   * @request GET:/api/orders
   * @secure
   */
  export namespace GetOrders {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      status?: string
      'status[]'?: string[]
      'customer.accountType'?: string
      'customer.accountType[]'?: string[]
      billNumber?: string
      'billNumber[]'?: string[]
      trackingNumber?: string
      'trackingNumber[]'?: string[]
      sourceReference?: string
      'sourceReference[]'?: string[]
      hiboutikId?: number
      'hiboutikId[]'?: number[]
      customer?: string
      'customer[]'?: string[]
      'entries.product'?: string
      'entries.product[]'?: string[]
      'entries.product.linkedEdition'?: string
      'entries.product.linkedEdition[]'?: string[]
      'entries.product.family'?: string
      'entries.product.family[]'?: string[]
      stock?: string
      'stock[]'?: string[]
      shippingMode?: string
      'shippingMode[]'?: string[]
      'shippingMode.shippingType'?: string
      'shippingMode.shippingType[]'?: string[]
      treated?: boolean
      'treated[]'?: boolean[]
      cardPreparedSecondaryStatus?: string
      'cardPreparedSecondaryStatus[]'?: string[]
      cardCheckedSecondaryStatus?: string
      'cardCheckedSecondaryStatus[]'?: string[]
      productPreparedSecondaryStatus?: string
      'productPreparedSecondaryStatus[]'?: string[]
      productCheckedSecondaryStatus?: string
      'productCheckedSecondaryStatus[]'?: string[]
      'customer.playinAccountType.codeName'?: string
      'customer.playinAccountType.codeName[]'?: string[]
      storePlace?: string
      'storePlace[]'?: string[]
      'createdAt[before]'?: string
      'createdAt[strictly_before]'?: string
      'createdAt[after]'?: string
      'createdAt[strictly_after]'?: string
      'printedAt[before]'?: string
      'printedAt[strictly_before]'?: string
      'printedAt[after]'?: string
      'printedAt[strictly_after]'?: string
      'restockedAt[before]'?: string
      'restockedAt[strictly_before]'?: string
      'restockedAt[after]'?: string
      'restockedAt[strictly_after]'?: string
      'preorderAt[before]'?: string
      'preorderAt[strictly_before]'?: string
      'preorderAt[after]'?: string
      'preorderAt[strictly_after]'?: string
      ready?: boolean
      cancelRequested?: boolean
      preorder?: boolean
      printed?: boolean
      excludedFromPrints?: boolean
      cardInTransit?: boolean
      productInTransit?: boolean
      returnedPackage?: boolean
      'exists[preparedAt]'?: boolean
      'exists[restockedAt]'?: boolean
      'exists[preorderAt]'?: boolean
      'exists[customer.collaboratorAccount]'?: boolean
      'exists[customer.playinAccountType]'?: boolean
      'exists[warningInCheckReady]'?: boolean
      'order[storePlace]'?: 'asc' | 'desc'
      'order[createdAt]'?: 'asc' | 'desc'
      'order[stock.shortName]'?: 'asc' | 'desc'
      'order[status]'?: 'asc' | 'desc'
      'order[shippingMode.name]'?: 'asc' | 'desc'
      'order[printed]'?: 'asc' | 'desc'
      'order[paymentReceived]'?: 'asc' | 'desc'
      'order[checkedReadyAt]'?: 'asc' | 'desc'
      'order[inCurrentCheck]'?: 'asc' | 'desc'
      'order[productSent]'?: 'asc' | 'desc'
      'subTotal[between]'?: string
      'subTotal[gt]'?: string
      'subTotal[gte]'?: string
      'subTotal[lt]'?: string
      'subTotal[lte]'?: string
      orderContainsCard?: boolean
      search?: string
      canceled?: boolean
      paymentSent?: boolean
      productSent?: boolean
      paymentReceived?: boolean
      'entries.sealedProduct.linkedEdition'?: string
      'entries.cardProduct.linkedEdition'?: string
      sentOrReceivedBy?: string
      totalPaid?: number
      paymentMode?: string
      currentOrder?: boolean
      'sentOrReceivedBy[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderJsonldOrderRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrdersPost
   * @summary Creates a Order resource.
   * @request POST:/api/orders
   * @secure
   */
  export namespace PostOrders {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderWrite
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrdersaddPaymentIdPaymentModeValuePost
   * @summary Creates a Order resource.
   * @request POST:/api/orders/add-payment/{id}/{paymentMode}/{value}
   * @secure
   */
  export namespace PostOrdersAddPaymentIdPaymentModeValue {
    export type RequestParams = { id: string; paymentMode: string; value: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderAddPayment
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Updates the Order resource.
   * @tags Order
   * @name ApiOrdersassignBillNumberIdPatch
   * @summary Assigns a bill number to the order.
   * @request PATCH:/api/orders/assign-bill-number/{id}
   * @secure
   */
  export namespace PatchOrdersAssignBillNumberId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderAssignBillNumber
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderscancelReadyIdPost
   * @summary Returns a ready order back into preparation.
   * @request POST:/api/orders/cancel-ready/{id}
   * @secure
   */
  export namespace PostOrdersCancelReadyId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderCancelReady
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderscancelSendIdPost
   * @summary Cancels the sending of an order.
   * @request POST:/api/orders/cancel-send/{id}
   * @secure
   */
  export namespace PostOrdersCancelSendId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderCancelSend
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderscancelTreatIdPost
   * @summary Cancels the treatment of an order.
   * @request POST:/api/orders/cancel-treat/{id}
   * @secure
   */
  export namespace PostOrdersCancelTreatId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderCancelTreat
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderscancelWithRefundIdPost
   * @summary Cancels an order.
   * @request POST:/api/orders/cancel-with-refund/{id}
   * @secure
   */
  export namespace PostOrdersCancelWithRefundId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderCancelInputJsonld
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Updates the Order resource.
   * @tags Order
   * @name ApiOrderscardSecondaryStatusIdPatch
   * @summary Updates the Order resource.
   * @request PATCH:/api/orders/card-secondary-status/{id}
   * @deprecated
   * @secure
   */
  export namespace PatchOrdersCardSecondaryStatusId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderCardSecondaryStatus
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderRead
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderscheckIdPost
   * @summary Checks a ready order.
   * @request POST:/api/orders/check/{id}
   * @secure
   */
  export namespace PostOrdersCheckId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonld
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderRead
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderscloneOfIdPost
   * @summary Creates a fresh order cloning data from another order.
   * @request POST:/api/orders/clone-of/{id}
   * @secure
   */
  export namespace PostOrdersCloneOfId {
    export type RequestParams = { id: number }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderClone
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderscreditNoteCreatePost
   * @summary Creates a Order resource.
   * @request POST:/api/orders/credit-note-create
   * @secure
   */
  export namespace PostOrdersCreditNoteCreate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = OrderCreditNoteInputJsonld
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrdersdeleteCouponIdPost
   * @summary Creates a Order resource.
   * @request POST:/api/orders/delete-coupon/{id}
   * @secure
   */
  export namespace PostOrdersDeleteCouponId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderDeletedCoupon
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrdersdeleteEntriesIdPost
   * @summary Creates a Order resource.
   * @request POST:/api/orders/delete-entries/{id}
   * @secure
   */
  export namespace PostOrdersDeleteEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderDeleteEntriesInputJsonld
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrdersdeleteVouchersIdPost
   * @summary Creates a Order resource.
   * @request POST:/api/orders/delete-vouchers/{id}
   * @secure
   */
  export namespace PostOrdersDeleteVouchersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderDeleteVouchersInputJsonld
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Sends an email
   * @tags Order
   * @name OrderSendEmail
   * @summary Send an email.
   * @request POST:/api/orders/email/{id}
   * @secure
   */
  export namespace PostOrdersEmailId {
    export type RequestParams = { id?: any }
    export type RequestQuery = {}
    export type RequestBody = Email
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Retrieves the collection of Order resources.
   * @tags Order
   * @name ApiOrdersexcludedFromPrintsIdGetCollection
   * @summary List orders excluded from prints at a specific store.
   * @request GET:/api/orders/excluded-from-prints/{id}
   * @secure
   */
  export namespace GetOrdersExcludedFromPrintsId {
    export type RequestParams = { store: number; id: string }
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      status?: string
      'status[]'?: string[]
      'customer.accountType'?: string
      'customer.accountType[]'?: string[]
      billNumber?: string
      'billNumber[]'?: string[]
      trackingNumber?: string
      'trackingNumber[]'?: string[]
      sourceReference?: string
      'sourceReference[]'?: string[]
      hiboutikId?: number
      'hiboutikId[]'?: number[]
      customer?: string
      'customer[]'?: string[]
      'entries.product'?: string
      'entries.product[]'?: string[]
      'entries.product.linkedEdition'?: string
      'entries.product.linkedEdition[]'?: string[]
      'entries.product.family'?: string
      'entries.product.family[]'?: string[]
      stock?: string
      'stock[]'?: string[]
      shippingMode?: string
      'shippingMode[]'?: string[]
      'shippingMode.shippingType'?: string
      'shippingMode.shippingType[]'?: string[]
      treated?: boolean
      'treated[]'?: boolean[]
      cardPreparedSecondaryStatus?: string
      'cardPreparedSecondaryStatus[]'?: string[]
      cardCheckedSecondaryStatus?: string
      'cardCheckedSecondaryStatus[]'?: string[]
      productPreparedSecondaryStatus?: string
      'productPreparedSecondaryStatus[]'?: string[]
      productCheckedSecondaryStatus?: string
      'productCheckedSecondaryStatus[]'?: string[]
      'customer.playinAccountType.codeName'?: string
      'customer.playinAccountType.codeName[]'?: string[]
      storePlace?: string
      'storePlace[]'?: string[]
      'createdAt[before]'?: string
      'createdAt[strictly_before]'?: string
      'createdAt[after]'?: string
      'createdAt[strictly_after]'?: string
      'printedAt[before]'?: string
      'printedAt[strictly_before]'?: string
      'printedAt[after]'?: string
      'printedAt[strictly_after]'?: string
      'restockedAt[before]'?: string
      'restockedAt[strictly_before]'?: string
      'restockedAt[after]'?: string
      'restockedAt[strictly_after]'?: string
      'preorderAt[before]'?: string
      'preorderAt[strictly_before]'?: string
      'preorderAt[after]'?: string
      'preorderAt[strictly_after]'?: string
      ready?: boolean
      cancelRequested?: boolean
      preorder?: boolean
      printed?: boolean
      excludedFromPrints?: boolean
      cardInTransit?: boolean
      productInTransit?: boolean
      returnedPackage?: boolean
      'exists[preparedAt]'?: boolean
      'exists[restockedAt]'?: boolean
      'exists[preorderAt]'?: boolean
      'exists[customer.collaboratorAccount]'?: boolean
      'exists[customer.playinAccountType]'?: boolean
      'exists[warningInCheckReady]'?: boolean
      'order[storePlace]'?: 'asc' | 'desc'
      'order[createdAt]'?: 'asc' | 'desc'
      'order[stock.shortName]'?: 'asc' | 'desc'
      'order[status]'?: 'asc' | 'desc'
      'order[shippingMode.name]'?: 'asc' | 'desc'
      'order[printed]'?: 'asc' | 'desc'
      'order[paymentReceived]'?: 'asc' | 'desc'
      'order[checkedReadyAt]'?: 'asc' | 'desc'
      'order[inCurrentCheck]'?: 'asc' | 'desc'
      'order[productSent]'?: 'asc' | 'desc'
      'subTotal[between]'?: string
      'subTotal[gt]'?: string
      'subTotal[gte]'?: string
      'subTotal[lt]'?: string
      'subTotal[lte]'?: string
      orderContainsCard?: boolean
      search?: string
      canceled?: boolean
      paymentSent?: boolean
      productSent?: boolean
      paymentReceived?: boolean
      'entries.sealedProduct.linkedEdition'?: string
      'entries.cardProduct.linkedEdition'?: string
      sentOrReceivedBy?: string
      totalPaid?: number
      paymentMode?: string
      currentOrder?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderJsonldOrderReadOrderReadItem[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Updates the Order resource.
   * @tags Order
   * @name ApiOrdersnotifyReadyIdPatch
   * @summary Marks an order as ready.
   * @request PATCH:/api/orders/notify-ready/{id}
   * @secure
   */
  export namespace PatchOrdersNotifyReadyId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderNotifyReady
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrdersprepareIdPost
   * @summary Marks an order as prepared. Check if an order is prepared and prepares it if it is not.
   * @request POST:/api/orders/prepare/{id}
   * @secure
   */
  export namespace PostOrdersPrepareId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderPrepare
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Return the number of orders to print
   * @tags Order
   * @name ReturnTotalOrdersPrint
   * @summary Return the number of orders to print.
   * @request GET:/api/orders/purchase-order-multiple/count/{id}
   * @secure
   */
  export namespace GetOrdersPurchaseOrderMultipleCountId {
    export type RequestParams = { id?: any }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Retrieves the collection of Order resources.
   * @tags Order
   * @name ApiOrdersquickSearchIdGetCollection
   * @summary Search across order number, customer name, tracking number or marketplace ID
   * @request GET:/api/orders/quick-search/{id}
   * @secure
   */
  export namespace GetOrdersQuickSearchId {
    export type RequestParams = { id: string }
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      status?: string
      'status[]'?: string[]
      'customer.accountType'?: string
      'customer.accountType[]'?: string[]
      billNumber?: string
      'billNumber[]'?: string[]
      trackingNumber?: string
      'trackingNumber[]'?: string[]
      sourceReference?: string
      'sourceReference[]'?: string[]
      hiboutikId?: number
      'hiboutikId[]'?: number[]
      customer?: string
      'customer[]'?: string[]
      'entries.product'?: string
      'entries.product[]'?: string[]
      'entries.product.linkedEdition'?: string
      'entries.product.linkedEdition[]'?: string[]
      'entries.product.family'?: string
      'entries.product.family[]'?: string[]
      stock?: string
      'stock[]'?: string[]
      shippingMode?: string
      'shippingMode[]'?: string[]
      'shippingMode.shippingType'?: string
      'shippingMode.shippingType[]'?: string[]
      treated?: boolean
      'treated[]'?: boolean[]
      cardPreparedSecondaryStatus?: string
      'cardPreparedSecondaryStatus[]'?: string[]
      cardCheckedSecondaryStatus?: string
      'cardCheckedSecondaryStatus[]'?: string[]
      productPreparedSecondaryStatus?: string
      'productPreparedSecondaryStatus[]'?: string[]
      productCheckedSecondaryStatus?: string
      'productCheckedSecondaryStatus[]'?: string[]
      'customer.playinAccountType.codeName'?: string
      'customer.playinAccountType.codeName[]'?: string[]
      storePlace?: string
      'storePlace[]'?: string[]
      'createdAt[before]'?: string
      'createdAt[strictly_before]'?: string
      'createdAt[after]'?: string
      'createdAt[strictly_after]'?: string
      'printedAt[before]'?: string
      'printedAt[strictly_before]'?: string
      'printedAt[after]'?: string
      'printedAt[strictly_after]'?: string
      'restockedAt[before]'?: string
      'restockedAt[strictly_before]'?: string
      'restockedAt[after]'?: string
      'restockedAt[strictly_after]'?: string
      'preorderAt[before]'?: string
      'preorderAt[strictly_before]'?: string
      'preorderAt[after]'?: string
      'preorderAt[strictly_after]'?: string
      ready?: boolean
      cancelRequested?: boolean
      preorder?: boolean
      printed?: boolean
      excludedFromPrints?: boolean
      cardInTransit?: boolean
      productInTransit?: boolean
      returnedPackage?: boolean
      'exists[preparedAt]'?: boolean
      'exists[restockedAt]'?: boolean
      'exists[preorderAt]'?: boolean
      'exists[customer.collaboratorAccount]'?: boolean
      'exists[customer.playinAccountType]'?: boolean
      'exists[warningInCheckReady]'?: boolean
      'order[storePlace]'?: 'asc' | 'desc'
      'order[createdAt]'?: 'asc' | 'desc'
      'order[stock.shortName]'?: 'asc' | 'desc'
      'order[status]'?: 'asc' | 'desc'
      'order[shippingMode.name]'?: 'asc' | 'desc'
      'order[printed]'?: 'asc' | 'desc'
      'order[paymentReceived]'?: 'asc' | 'desc'
      'order[checkedReadyAt]'?: 'asc' | 'desc'
      'order[inCurrentCheck]'?: 'asc' | 'desc'
      'order[productSent]'?: 'asc' | 'desc'
      'subTotal[between]'?: string
      'subTotal[gt]'?: string
      'subTotal[gte]'?: string
      'subTotal[lt]'?: string
      'subTotal[lte]'?: string
      orderContainsCard?: boolean
      search?: string
      canceled?: boolean
      paymentSent?: boolean
      productSent?: boolean
      paymentReceived?: boolean
      'entries.sealedProduct.linkedEdition'?: string
      'entries.cardProduct.linkedEdition'?: string
      sentOrReceivedBy?: string
      totalPaid?: number
      paymentMode?: string
      currentOrder?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': OrderJsonldOrderRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrdersremovePaymentIdPaymentModeValuePost
   * @summary Creates a Order resource.
   * @request POST:/api/orders/remove-payment/{id}/{paymentMode}/{value}
   * @secure
   */
  export namespace PostOrdersRemovePaymentIdPaymentModeValue {
    export type RequestParams = { id: string; paymentMode: string; value: string }
    export type RequestQuery = {}
    export type RequestBody = OrderJsonldOrderRemovePayment
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Updates the Order resource.
   * @tags Order
   * @name ApiOrderssecondaryStatusIdPatch
   * @summary Change the OrderSecondaryStatus.
   * @request PATCH:/api/orders/secondary-status/{id}
   * @secure
   */
  export namespace PatchOrdersSecondaryStatusId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderSecondaryStatus
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderRead
  }
  /**
   * @description Updates the Order resource.
   * @tags Order
   * @name ApiOrderssendIdPatch
   * @summary Marks an order as sent.
   * @request PATCH:/api/orders/send/{id}
   * @secure
   */
  export namespace PatchOrdersSendId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderSend
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a Order resource.
   * @tags Order
   * @name ApiOrderssplitIdPost
   * @summary Creates a Order resource.
   * @request POST:/api/orders/split/{id}
   * @secure
   */
  export namespace PostOrdersSplitId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderSplitInputJsonld
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Retrieves a Order resource.
   * @tags Order
   * @name ApiOrdersIdGet
   * @summary Retrieves a Order resource.
   * @request GET:/api/orders/{id}
   * @secure
   */
  export namespace GetOrdersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Updates the Order resource.
   * @tags Order
   * @name ApiOrdersIdPatch
   * @summary Updates the Order resource.
   * @request PATCH:/api/orders/{id}
   * @secure
   */
  export namespace PatchOrdersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = OrderOrderPatch
    export type RequestHeaders = {}
    export type ResponseBody = OrderJsonldOrderReadOrderReadItem
  }
  /**
   * @description Retrieves the collection of Payback resources.
   * @tags Payback
   * @name ApiPaybacksGetCollection
   * @summary Retrieves the collection of Payback resources.
   * @request GET:/api/paybacks
   * @secure
   */
  export namespace GetPaybacks {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': PaybackJsonldPaybackRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Payback resource.
   * @tags Payback
   * @name ApiPaybacksReportPost
   * @summary Creates a Payback resource.
   * @request POST:/api/paybacks-report
   * @secure
   */
  export namespace PostPaybacksReport {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = PaybackPaybackInputJsonld
    export type RequestHeaders = {}
    export type ResponseBody = PaybackJsonldPaybackRead
  }
  /**
   * @description Retrieves a Payback resource.
   * @tags Payback
   * @name ApiPaybacksIdGet
   * @summary Retrieves a Payback resource.
   * @request GET:/api/paybacks/{id}
   * @secure
   */
  export namespace GetPaybacksId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaybackJsonldPaybackRead
  }
  /**
   * @description Retrieves the collection of PaymentMode resources.
   * @tags PaymentMode
   * @name ApiPaymentModesGetCollection
   * @summary Retrieves the collection of PaymentMode resources.
   * @request GET:/api/payment-modes
   * @secure
   */
  export namespace GetPaymentModes {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      availableInShop?: boolean
      availableInStand?: boolean
      availableInStock?: boolean
      availableInShipping?: boolean
      availableInEvent?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': PaymentModeJsonldPaymentModeRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a PaymentMode resource.
   * @tags PaymentMode
   * @name ApiPaymentModesIdGet
   * @summary Retrieves a PaymentMode resource.
   * @request GET:/api/payment-modes/{id}
   * @secure
   */
  export namespace GetPaymentModesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PaymentModeJsonldPaymentModeRead
  }
  /**
   * @description Retrieves the collection of PostalRelay resources.
   * @tags PostalRelay
   * @name ApiPostalRelaysGetCollection
   * @summary Retrieves the collection of PostalRelay resources.
   * @request GET:/api/postal-relays
   * @secure
   */
  export namespace GetPostalRelays {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': PostalRelayJsonldRelayRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a PostalRelay resource.
   * @tags PostalRelay
   * @name ApiPostalRelaysPost
   * @summary Creates a PostalRelay resource.
   * @request POST:/api/postal-relays
   * @deprecated
   * @secure
   */
  export namespace PostPostalRelays {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = PostalRelayJsonldRelayWrite
    export type RequestHeaders = {}
    export type ResponseBody = PostalRelayJsonldRelayRead
  }
  /**
   * @description Creates a PostalRelay resource.
   * @tags PostalRelay
   * @name ApiPostalRelayswithExternalIdPost
   * @summary Creates a PostalRelay resource.
   * @request POST:/api/postal-relays/with-external-id
   * @secure
   */
  export namespace PostPostalRelaysWithExternalId {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = PostalRelayPostalRelayCreateInputJsonldRelayWrite
    export type RequestHeaders = {}
    export type ResponseBody = PostalRelayJsonldRelayRead
  }
  /**
   * @description Retrieves a PostalRelay resource.
   * @tags PostalRelay
   * @name ApiPostalRelaysIdGet
   * @summary Retrieves a PostalRelay resource.
   * @request GET:/api/postal-relays/{id}
   * @secure
   */
  export namespace GetPostalRelaysId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PostalRelayJsonldRelayRead
  }
  /**
   * @description Retrieves the collection of PricingPolicy resources.
   * @tags PricingPolicy
   * @name ApiPricingPoliciesGetCollection
   * @summary Retrieves the collection of PricingPolicy resources.
   * @request GET:/api/pricing-policies
   * @secure
   */
  export namespace GetPricingPolicies {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': PricingPolicyJsonldPricingPolicyRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a PricingPolicy resource.
   * @tags PricingPolicy
   * @name ApiPricingPoliciesIdGet
   * @summary Retrieves a PricingPolicy resource.
   * @request GET:/api/pricing-policies/{id}
   * @secure
   */
  export namespace GetPricingPoliciesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PricingPolicyJsonldPricingPolicyRead
  }
  /**
   * @description Retrieves the collection of PricingTag resources.
   * @tags PricingTag
   * @name ApiPricingTagsGetCollection
   * @summary Retrieves the collection of PricingTag resources.
   * @request GET:/api/pricing-tags
   * @secure
   */
  export namespace GetPricingTags {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': PricingTagJsonldPricingTagRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a PricingTag resource.
   * @tags PricingTag
   * @name ApiPricingTagsIdGet
   * @summary Retrieves a PricingTag resource.
   * @request GET:/api/pricing-tags/{id}
   * @secure
   */
  export namespace GetPricingTagsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PricingTagJsonldPricingTagRead
  }
  /**
   * @description Retrieves the collection of PrintExclusion resources.
   * @tags PrintExclusion
   * @name ApiPrintExclusionsGetCollection
   * @summary Retrieves the collection of PrintExclusion resources.
   * @request GET:/api/print-exclusions
   * @secure
   */
  export namespace GetPrintExclusions {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      edition?: string
      'edition[]'?: string[]
      store?: string
      'store[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': PrintExclusionJsonldPrintExclusionRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a PrintExclusion resource.
   * @tags PrintExclusion
   * @name ApiPrintExclusionsPost
   * @summary Creates a PrintExclusion resource.
   * @request POST:/api/print-exclusions
   * @secure
   */
  export namespace PostPrintExclusions {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = PrintExclusionJsonldPrintExclusionWrite
    export type RequestHeaders = {}
    export type ResponseBody = PrintExclusionJsonldPrintExclusionRead
  }
  /**
   * @description Retrieves a PrintExclusion resource.
   * @tags PrintExclusion
   * @name ApiPrintExclusionsIdGet
   * @summary Retrieves a PrintExclusion resource.
   * @request GET:/api/print-exclusions/{id}
   * @secure
   */
  export namespace GetPrintExclusionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = PrintExclusionJsonldPrintExclusionRead
  }
  /**
   * @description Removes the PrintExclusion resource.
   * @tags PrintExclusion
   * @name ApiPrintExclusionsIdDelete
   * @summary Removes the PrintExclusion resource.
   * @request DELETE:/api/print-exclusions/{id}
   * @secure
   */
  export namespace DeletePrintExclusionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Retrieves the collection of ProductAssociation resources.
   * @tags ProductAssociation
   * @name ApiProductAssociationsGetCollection
   * @summary Retrieves the collection of ProductAssociation resources.
   * @request GET:/api/product-associations
   * @secure
   */
  export namespace GetProductAssociations {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      product?: string
      'product[]'?: string[]
      orderEntry?: string
      'orderEntry[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductAssociationJsonldAssociationRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductAssociation resource.
   * @tags ProductAssociation
   * @name ApiProductAssociationsIdGet
   * @summary Retrieves a ProductAssociation resource.
   * @request GET:/api/product-associations/{id}
   * @secure
   */
  export namespace GetProductAssociationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductAssociationJsonldAssociationRead
  }
  /**
   * @description Retrieves the collection of ProductBadge resources.
   * @tags ProductBadge
   * @name ApiProductBadgesGetCollection
   * @summary Retrieves the collection of ProductBadge resources.
   * @request GET:/api/product-badges
   * @secure
   */
  export namespace GetProductBadges {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductBadgeJsonldProductBadgeRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductBadge resource.
   * @tags ProductBadge
   * @name ApiProductBadgesIdGet
   * @summary Retrieves a ProductBadge resource.
   * @request GET:/api/product-badges/{id}
   * @secure
   */
  export namespace GetProductBadgesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductBadgeJsonldProductBadgeRead
  }
  /**
   * @description Retrieves the collection of ProductCategory resources.
   * @tags ProductCategory
   * @name ApiProductCategoriesGetCollection
   * @summary Retrieves the collection of ProductCategory resources.
   * @request GET:/api/product-categories
   * @secure
   */
  export namespace GetProductCategories {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      family?: string
      'family[]'?: string[]
      'exists[parent]'?: boolean
      'order[name]'?: 'asc' | 'desc'
      'order[position]'?: 'asc' | 'desc'
      'order[productCategoryGroup.position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductCategoryJsonldCategoryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductCategory resource.
   * @tags ProductCategory
   * @name ApiProductCategoriesIdGet
   * @summary Retrieves a ProductCategory resource.
   * @request GET:/api/product-categories/{id}
   * @secure
   */
  export namespace GetProductCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCategoryJsonldCategoryRead
  }
  /**
   * @description Retrieves the collection of ProductCategoryGroup resources.
   * @tags ProductCategoryGroup
   * @name ApiProductCategoryGroupsGetCollection
   * @summary Retrieves the collection of ProductCategoryGroup resources.
   * @request GET:/api/product-category-groups
   * @secure
   */
  export namespace GetProductCategoryGroups {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductCategoryGroupJsonldCategoryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductCategoryGroup resource.
   * @tags ProductCategoryGroup
   * @name ApiProductCategoryGroupsIdGet
   * @summary Retrieves a ProductCategoryGroup resource.
   * @request GET:/api/product-category-groups/{id}
   * @secure
   */
  export namespace GetProductCategoryGroupsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCategoryGroupJsonldCategoryRead
  }
  /**
   * @description Retrieves the collection of ProductCollectionCategory resources.
   * @tags ProductCollectionCategory
   * @name ApiProductCollectionCategoriesGetCollection
   * @summary Retrieves the collection of ProductCollectionCategory resources.
   * @request GET:/api/product-collection-categories
   * @secure
   */
  export namespace GetProductCollectionCategories {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      name?: string
      collection?: string
      'collection[]'?: string[]
      id?: number
      'id[]'?: number[]
      'order[position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductCollectionCategoryJsonldProductCollectionCategoryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductCollectionCategory resource.
   * @tags ProductCollectionCategory
   * @name ApiProductCollectionCategoriesPost
   * @summary Creates a ProductCollectionCategory resource.
   * @request POST:/api/product-collection-categories
   * @secure
   */
  export namespace PostProductCollectionCategories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody =
      ProductCollectionCategoryProductCollectionCategoryCreateInputJsonldProductCollectionCategoryCreate
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionCategoryJsonldProductCollectionCategoryRead
  }
  /**
   * @description Retrieves a ProductCollectionCategory resource.
   * @tags ProductCollectionCategory
   * @name ApiProductCollectionCategoriesIdGet
   * @summary Retrieves a ProductCollectionCategory resource.
   * @request GET:/api/product-collection-categories/{id}
   * @secure
   */
  export namespace GetProductCollectionCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionCategoryJsonldProductCollectionCategoryRead
  }
  /**
   * @description Removes the ProductCollectionCategory resource.
   * @tags ProductCollectionCategory
   * @name ApiProductCollectionCategoriesIdDelete
   * @summary Removes the ProductCollectionCategory resource.
   * @request DELETE:/api/product-collection-categories/{id}
   * @secure
   */
  export namespace DeleteProductCollectionCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the ProductCollectionCategory resource.
   * @tags ProductCollectionCategory
   * @name ApiProductCollectionCategoriesIdPatch
   * @summary Updates the ProductCollectionCategory resource.
   * @request PATCH:/api/product-collection-categories/{id}
   * @secure
   */
  export namespace PatchProductCollectionCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionCategoryProductCollectionCategoryWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionCategoryJsonldProductCollectionCategoryRead
  }
  /**
   * @description Retrieves a ProductCollectionCategoryDto resource.
   * @tags ProductCollectionCategoryDto
   * @name ApiProductCollectionCategoryDtosIdGet
   * @summary Retrieves a ProductCollectionCategoryDto resource.
   * @request GET:/api/product-collection-category-dtos/{id}
   * @secure
   */
  export namespace GetProductCollectionCategoryDtosId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionCategoryDtoJsonld
  }
  /**
   * @description Retrieves the collection of ProductCollectionEntry resources.
   * @tags ProductCollectionEntry
   * @name ApiProductCollectionEntriesGetCollection
   * @summary Retrieves the collection of ProductCollectionEntry resources.
   * @request GET:/api/product-collection-entries
   * @secure
   */
  export namespace GetProductCollectionEntries {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductCollectionEntryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductCollectionEntry resource.
   * @tags ProductCollectionEntry
   * @name ApiProductCollectionEntriesPost
   * @summary Creates a ProductCollectionEntry resource.
   * @request POST:/api/product-collection-entries
   * @secure
   */
  export namespace PostProductCollectionEntries {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionEntryProductCollectionEntryCreateInputJsonldProductCollectionEntryCreate
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionEntryJsonldProductCollectionEntryRead
  }
  /**
   * @description Retrieves a ProductCollectionEntry resource.
   * @tags ProductCollectionEntry
   * @name ApiProductCollectionEntriesIdGet
   * @summary Retrieves a ProductCollectionEntry resource.
   * @request GET:/api/product-collection-entries/{id}
   * @secure
   */
  export namespace GetProductCollectionEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionEntryJsonldProductCollectionEntryRead
  }
  /**
   * @description Removes the ProductCollectionEntry resource.
   * @tags ProductCollectionEntry
   * @name ApiProductCollectionEntriesIdDelete
   * @summary Removes the ProductCollectionEntry resource.
   * @request DELETE:/api/product-collection-entries/{id}
   * @secure
   */
  export namespace DeleteProductCollectionEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the ProductCollectionEntry resource.
   * @tags ProductCollectionEntry
   * @name ApiProductCollectionEntriesIdPatch
   * @summary Updates the ProductCollectionEntry resource.
   * @request PATCH:/api/product-collection-entries/{id}
   * @secure
   */
  export namespace PatchProductCollectionEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionEntryProductCollectionEntryWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionEntryJsonldProductCollectionEntryRead
  }
  /**
   * @description Retrieves a ProductCollectionEntryDto resource.
   * @tags ProductCollectionEntryDto
   * @name ApiProductCollectionEntryDtosIdGet
   * @summary Retrieves a ProductCollectionEntryDto resource.
   * @request GET:/api/product-collection-entry-dtos/{id}
   * @secure
   */
  export namespace GetProductCollectionEntryDtosId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionEntryDtoJsonld
  }
  /**
   * @description Retrieves the collection of ProductCollectionStoryblokStory resources.
   * @tags ProductCollectionStoryblokStory
   * @name ApiProductCollectionStoryblokStoriesGetCollection
   * @summary Retrieves the collection of ProductCollectionStoryblokStory resources.
   * @request GET:/api/product-collection-storyblok-stories
   * @secure
   */
  export namespace GetProductCollectionStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductCollectionStoryblokStoryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductCollectionStoryblokStory resource.
   * @tags ProductCollectionStoryblokStory
   * @name ApiProductCollectionStoryblokStoriesPost
   * @summary Creates a ProductCollectionStoryblokStory resource.
   * @request POST:/api/product-collection-storyblok-stories
   * @secure
   */
  export namespace PostProductCollectionStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionStoryblokStoryJsonld
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionStoryblokStoryJsonld
  }
  /**
   * @description Retrieves a ProductCollectionStoryblokStory resource.
   * @tags ProductCollectionStoryblokStory
   * @name ApiProductCollectionStoryblokStoriesIdGet
   * @summary Retrieves a ProductCollectionStoryblokStory resource.
   * @request GET:/api/product-collection-storyblok-stories/{id}
   * @secure
   */
  export namespace GetProductCollectionStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionStoryblokStoryJsonld
  }
  /**
   * @description Removes the ProductCollectionStoryblokStory resource.
   * @tags ProductCollectionStoryblokStory
   * @name ApiProductCollectionStoryblokStoriesIdDelete
   * @summary Removes the ProductCollectionStoryblokStory resource.
   * @request DELETE:/api/product-collection-storyblok-stories/{id}
   * @secure
   */
  export namespace DeleteProductCollectionStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the ProductCollectionStoryblokStory resource.
   * @tags ProductCollectionStoryblokStory
   * @name ApiProductCollectionStoryblokStoriesIdPatch
   * @summary Updates the ProductCollectionStoryblokStory resource.
   * @request PATCH:/api/product-collection-storyblok-stories/{id}
   * @secure
   */
  export namespace PatchProductCollectionStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionStoryblokStory
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionStoryblokStoryJsonld
  }
  /**
   * @description Retrieves the collection of ProductCollection resources.
   * @tags ProductCollection
   * @name ApiProductCollectionsGetCollection
   * @summary Retrieves the collection of ProductCollection resources.
   * @request GET:/api/product-collections
   * @secure
   */
  export namespace GetProductCollections {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      name?: string
      onFamilyHome?: boolean
      onCollectionHome?: boolean
      dedicatedPage?: boolean
      active?: boolean
      'order[name]'?: 'asc' | 'desc'
      randomOrder?: boolean
      not_id?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductCollectionJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductCollection resource.
   * @tags ProductCollection
   * @name ApiProductCollectionsPost
   * @summary Creates a ProductCollection resource.
   * @request POST:/api/product-collections
   * @secure
   */
  export namespace PostProductCollections {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionJsonldProductCollectionWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionJsonldProductCollectionRead
  }
  /**
   * @description Retrieves a ProductCollection resource.
   * @tags ProductCollection
   * @name ApiProductCollectionsIdGet
   * @summary Retrieves a ProductCollection resource.
   * @request GET:/api/product-collections/{id}
   * @secure
   */
  export namespace GetProductCollectionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionJsonldProductCollectionReadProductCollectionReadItem
  }
  /**
   * @description Removes the ProductCollection resource.
   * @tags ProductCollection
   * @name ApiProductCollectionsIdDelete
   * @summary Removes the ProductCollection resource.
   * @request DELETE:/api/product-collections/{id}
   * @secure
   */
  export namespace DeleteProductCollectionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the ProductCollection resource.
   * @tags ProductCollection
   * @name ApiProductCollectionsIdPatch
   * @summary Updates the ProductCollection resource.
   * @request PATCH:/api/product-collections/{id}
   * @secure
   */
  export namespace PatchProductCollectionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionProductCollectionWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionJsonldProductCollectionRead
  }
  /**
   * @description Creates a ProductCollection resource.
   * @tags ProductCollection
   * @name ApiProductCollectionsIdimagePost
   * @summary Creates a ProductCollection resource.
   * @request POST:/api/product-collections/{id}/image
   * @secure
   */
  export namespace PostProductCollectionsIdImage {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ProductCollectionProductCollectionUploadImage
    export type RequestHeaders = {}
    export type ResponseBody = ProductCollectionJsonldProductCollectionRead
  }
  /**
   * @description Retrieves the collection of ProductCreationRequest resources.
   * @tags ProductCreationRequest
   * @name ApiProductCreationRequestsGetCollection
   * @summary Retrieves the collection of ProductCreationRequest resources.
   * @request GET:/api/product-creation-requests
   * @secure
   */
  export namespace GetProductCreationRequests {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[createdAt]'?: 'asc' | 'desc'
      'order[name]'?: 'asc' | 'desc'
      createdBy?: string
      'createdBy[]'?: string[]
      name?: string
      status?: 'created' | 'validated' | 'rejected'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductCreationRequestJsonldProductCreationRequestRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductCreationRequest resource.
   * @tags ProductCreationRequest
   * @name ApiProductCreationRequestsPost
   * @summary Creates a ProductCreationRequest resource.
   * @request POST:/api/product-creation-requests
   * @secure
   */
  export namespace PostProductCreationRequests {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProductCreationRequestJsonldProductCreationRequestWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductCreationRequestJsonldProductCreationRequestRead
  }
  /**
   * @description Updates the ProductCreationRequest resource.
   * @tags ProductCreationRequest
   * @name ApiProductCreationRequestsrejectIdPatch
   * @summary Updates the ProductCreationRequest resource.
   * @request PATCH:/api/product-creation-requests/reject/{id}
   * @secure
   */
  export namespace PatchProductCreationRequestsRejectId {
    export type RequestParams = { id?: number }
    export type RequestQuery = {}
    export type RequestBody = ProductCreationRequestProductCreationRequestReject
    export type RequestHeaders = {}
    export type ResponseBody = ProductCreationRequestJsonldProductCreationRequestRead
  }
  /**
   * @description Updates the ProductCreationRequest resource.
   * @tags ProductCreationRequest
   * @name ApiProductCreationRequestsvalidateIdPatch
   * @summary Updates the ProductCreationRequest resource.
   * @request PATCH:/api/product-creation-requests/validate/{id}
   * @secure
   */
  export namespace PatchProductCreationRequestsValidateId {
    export type RequestParams = { id?: number }
    export type RequestQuery = {}
    export type RequestBody = ProductCreationRequestProductCreationRequestValidate
    export type RequestHeaders = {}
    export type ResponseBody = ProductCreationRequestJsonldProductCreationRequestRead
  }
  /**
   * @description Retrieves a ProductCreationRequest resource.
   * @tags ProductCreationRequest
   * @name ApiProductCreationRequestsIdGet
   * @summary Retrieves a ProductCreationRequest resource.
   * @request GET:/api/product-creation-requests/{id}
   * @secure
   */
  export namespace GetProductCreationRequestsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductCreationRequestJsonldProductCreationRequestRead
  }
  /**
   * @description Updates the ProductCreationRequest resource.
   * @tags ProductCreationRequest
   * @name ApiProductCreationRequestsIdPatch
   * @summary Updates the ProductCreationRequest resource.
   * @request PATCH:/api/product-creation-requests/{id}
   * @secure
   */
  export namespace PatchProductCreationRequestsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ProductCreationRequestProductCreationRequestWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductCreationRequestJsonldProductCreationRequestRead
  }
  /**
   * @description Retrieves the collection of ProductDistributor resources.
   * @tags ProductDistributor
   * @name ApiProductDistributorsGetCollection
   * @summary Retrieves the collection of ProductDistributor resources.
   * @request GET:/api/product-distributors
   * @secure
   */
  export namespace GetProductDistributors {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductDistributorJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductDistributor resource.
   * @tags ProductDistributor
   * @name ApiProductDistributorsIdGet
   * @summary Retrieves a ProductDistributor resource.
   * @request GET:/api/product-distributors/{id}
   * @secure
   */
  export namespace GetProductDistributorsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductDistributorJsonld
  }
  /**
   * @description Retrieves the collection of ProductEditor resources.
   * @tags ProductEditor
   * @name ApiProductEditorsGetCollection
   * @summary Retrieves the collection of ProductEditor resources.
   * @request GET:/api/product-editors
   * @secure
   */
  export namespace GetProductEditors {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductEditorJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductEditor resource.
   * @tags ProductEditor
   * @name ApiProductEditorsIdGet
   * @summary Retrieves a ProductEditor resource.
   * @request GET:/api/product-editors/{id}
   * @secure
   */
  export namespace GetProductEditorsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductEditorJsonld
  }
  /**
   * @description Retrieves the collection of ProductImage resources.
   * @tags ProductImage
   * @name ApiProductImagesGetCollection
   * @summary Retrieves the collection of ProductImage resources.
   * @request GET:/api/product-images
   * @secure
   */
  export namespace GetProductImages {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductImageJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductImage resource.
   * @tags ProductImage
   * @name ApiProductImagesIdGet
   * @summary Retrieves a ProductImage resource.
   * @request GET:/api/product-images/{id}
   * @secure
   */
  export namespace GetProductImagesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductImageJsonld
  }
  /**
   * @description Retrieves the collection of ProductInventory resources.
   * @tags ProductInventory
   * @name ApiProductInventoriesGetCollection
   * @summary Retrieves the collection of ProductInventory resources.
   * @request GET:/api/product-inventories
   * @secure
   */
  export namespace GetProductInventories {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      ended?: boolean
      archived?: boolean
      store?: string
      'store[]'?: string[]
      'exists[comparisonInventory]'?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductInventoryJsonldInventoryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductInventory resource.
   * @tags ProductInventory
   * @name ApiProductInventoriesPost
   * @summary Creates a ProductInventory resource.
   * @request POST:/api/product-inventories
   * @secure
   */
  export namespace PostProductInventories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProductInventoryJsonldInventoryCreate
    export type RequestHeaders = {}
    export type ResponseBody = ProductInventoryJsonldInventoryRead
  }
  /**
   * @description Creates a ProductInventory resource.
   * @tags ProductInventory
   * @name ApiInventoriesCompare
   * @summary Compares two inventories to find discrepancies.
   * @request POST:/api/product-inventories/compare/{inventory}/{otherInventory}
   * @secure
   */
  export namespace PostProductInventoriesCompareInventoryOtherInventory {
    export type RequestParams = { inventory: number; otherInventory: number }
    export type RequestQuery = {}
    export type RequestBody = ProductInventoryJsonldInventoryCompare
    export type RequestHeaders = {}
    export type ResponseBody = ProductInventoryJsonldInventoryReadInventoryReadItem
  }
  /**
   * @description Creates a ProductInventory resource.
   * @tags ProductInventory
   * @name ApiInventoriesMerge
   * @summary Merge two inventories.
   * @request POST:/api/product-inventories/merge/{inventory}/{otherInventory}
   * @secure
   */
  export namespace PostProductInventoriesMergeInventoryOtherInventory {
    export type RequestParams = { inventory: number; otherInventory: number }
    export type RequestQuery = {}
    export type RequestBody = ProductInventoryJsonldInventoryMerge
    export type RequestHeaders = {}
    export type ResponseBody = ProductInventoryJsonldInventoryReadInventoryReadItem
  }
  /**
   * @description Retrieves a ProductInventory resource.
   * @tags ProductInventory
   * @name ApiProductInventoriesIdGet
   * @summary Retrieves a ProductInventory resource.
   * @request GET:/api/product-inventories/{id}
   * @secure
   */
  export namespace GetProductInventoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductInventoryJsonldInventoryReadInventoryReadItem
  }
  /**
   * @description Retrieves the collection of ProductInventoryEntry resources.
   * @tags ProductInventoryEntry
   * @name ApiProductInventoryEntriesGetCollection
   * @summary Retrieves the collection of ProductInventoryEntry resources.
   * @request GET:/api/product-inventory-entries
   * @secure
   */
  export namespace GetProductInventoryEntries {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductInventoryEntryJsonldInventoryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductInventoryEntry resource.
   * @tags ProductInventoryEntry
   * @name ApiProductInventoryEntriesIdGet
   * @summary Retrieves a ProductInventoryEntry resource.
   * @request GET:/api/product-inventory-entries/{id}
   * @secure
   */
  export namespace GetProductInventoryEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductInventoryEntryJsonldInventoryRead
  }
  /**
   * @description Retrieves the collection of ProductPack resources.
   * @tags ProductPack
   * @name ApiProductPacksGetCollection
   * @summary Retrieves the collection of ProductPack resources.
   * @request GET:/api/product-packs
   * @secure
   */
  export namespace GetProductPacks {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductPackJsonldProductPackRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductPack resource.
   * @tags ProductPack
   * @name ApiProductPacksIdGet
   * @summary Retrieves a ProductPack resource.
   * @request GET:/api/product-packs/{id}
   * @secure
   */
  export namespace GetProductPacksId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductPackJsonldProductPackRead
  }
  /**
   * @description Retrieves the collection of ProductRepurchase resources.
   * @tags ProductRepurchase
   * @name ApiProductRepurchasesGetCollection
   * @summary Retrieves the collection of ProductRepurchase resources.
   * @request GET:/api/product-repurchases
   * @secure
   */
  export namespace GetProductRepurchases {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductRepurchaseJsonldProductRepurchaseRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductRepurchase resource.
   * @tags ProductRepurchase
   * @name ApiProductRepurchasesIdGet
   * @summary Retrieves a ProductRepurchase resource.
   * @request GET:/api/product-repurchases/{id}
   * @secure
   */
  export namespace GetProductRepurchasesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductRepurchaseJsonldProductRepurchaseRead
  }
  /**
   * @description Retrieves the collection of ProductStats resources.
   * @tags ProductStats
   * @name ApiProductStatsGetCollection
   * @summary Retrieves the collection of ProductStats resources.
   * @request GET:/api/product-stats
   * @secure
   */
  export namespace GetProductStats {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      product?: string
      'product[]'?: string[]
      store?: string
      'store[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductStatsJsonldProductStatsRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductStats resource.
   * @tags ProductStats
   * @name ApiProductStatsIdGet
   * @summary Retrieves a ProductStats resource.
   * @request GET:/api/product-stats/{id}
   * @secure
   */
  export namespace GetProductStatsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductStatsJsonldProductStatsRead
  }
  /**
   * @description Retrieves the collection of ProductStore resources.
   * @tags ProductStore
   * @name ApiProductStoresGetCollection
   * @summary Retrieves the collection of ProductStore resources.
   * @request GET:/api/product-stores
   * @secure
   */
  export namespace GetProductStores {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      productWithoutPosition?: string
      store?: string
      'store[]'?: string[]
      storeZone?: string
      'storeZone[]'?: string[]
      localPosition?: string
      'localPosition[]'?: string[]
      'store.warehouse'?: boolean
      'order[store.name]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductStoreJsonldProductStoreRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductStore resource.
   * @tags ProductStore
   * @name ApiProductStoresPost
   * @summary Creates a ProductStore resource.
   * @request POST:/api/product-stores
   * @secure
   */
  export namespace PostProductStores {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProductStoreJsonldProductStoreCreateProductStoreWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductStoreJsonldProductStoreRead
  }
  /**
   * @description Retrieves a ProductStore resource.
   * @tags ProductStore
   * @name ApiProductStoresIdGet
   * @summary Retrieves a ProductStore resource.
   * @request GET:/api/product-stores/{id}
   * @secure
   */
  export namespace GetProductStoresId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductStoreJsonldProductStoreRead
  }
  /**
   * @description Updates the ProductStore resource.
   * @tags ProductStore
   * @name ApiProductStoresIdPatch
   * @summary Updates the ProductStore resource.
   * @request PATCH:/api/product-stores/{id}
   * @secure
   */
  export namespace PatchProductStoresId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ProductStoreProductStoreWrite
    export type RequestHeaders = {}
    export type ResponseBody = ProductStoreJsonldProductStoreRead
  }
  /**
   * @description Retrieves the collection of ProductSuggestion resources.
   * @tags ProductSuggestion
   * @name ApiProductSuggestionsGetCollection
   * @summary Retrieves the collection of ProductSuggestion resources.
   * @request GET:/api/product-suggestions
   * @secure
   */
  export namespace GetProductSuggestions {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductSuggestionJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductSuggestion resource.
   * @tags ProductSuggestion
   * @name ApiProductSuggestionsIdGet
   * @summary Retrieves a ProductSuggestion resource.
   * @request GET:/api/product-suggestions/{id}
   * @secure
   */
  export namespace GetProductSuggestionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductSuggestionJsonld
  }
  /**
   * @description Retrieves the collection of ProductTag resources.
   * @tags ProductTag
   * @name ApiProductTagsGetCollection
   * @summary Retrieves the collection of ProductTag resources.
   * @request GET:/api/product-tags
   * @secure
   */
  export namespace GetProductTags {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductTagJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductTag resource.
   * @tags ProductTag
   * @name ApiProductTagsIdGet
   * @summary Retrieves a ProductTag resource.
   * @request GET:/api/product-tags/{id}
   * @secure
   */
  export namespace GetProductTagsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductTagJsonld
  }
  /**
   * @description Retrieves the collection of ProductType resources.
   * @tags ProductType
   * @name ApiProductTypesGetCollection
   * @summary Retrieves the collection of ProductType resources.
   * @request GET:/api/product-types
   * @secure
   */
  export namespace GetProductTypes {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductTypeJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ProductType resource.
   * @tags ProductType
   * @name ApiProductTypesIdGet
   * @summary Retrieves a ProductType resource.
   * @request GET:/api/product-types/{id}
   * @secure
   */
  export namespace GetProductTypesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductTypeJsonld
  }
  /**
   * @description Retrieves the collection of ProductVideo resources.
   * @tags ProductVideo
   * @name ApiProductVideosGetCollection
   * @summary Retrieves the collection of ProductVideo resources.
   * @request GET:/api/product-videos
   * @secure
   */
  export namespace GetProductVideos {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductVideoJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ProductVideo resource.
   * @tags ProductVideo
   * @name ApiProductVideosPost
   * @summary Creates a ProductVideo resource.
   * @request POST:/api/product-videos
   * @secure
   */
  export namespace PostProductVideos {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ProductVideoJsonld
    export type RequestHeaders = {}
    export type ResponseBody = ProductVideoJsonld
  }
  /**
   * @description Retrieves a ProductVideo resource.
   * @tags ProductVideo
   * @name ApiProductVideosIdGet
   * @summary Retrieves a ProductVideo resource.
   * @request GET:/api/product-videos/{id}
   * @secure
   */
  export namespace GetProductVideosId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductVideoJsonld
  }
  /**
   * @description Retrieves the collection of Product resources.
   * @tags Product
   * @name ApiProductsGetCollection
   * @summary Retrieves the collection of Product resources.
   * @request GET:/api/products
   * @secure
   */
  export namespace GetProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      linkedEdition?: string
      'linkedEdition[]'?: string[]
      family?: string
      'family[]'?: string[]
      'lang.code'?: string
      'lang.code[]'?: string[]
      'price[between]'?: string
      'price[gt]'?: string
      'price[gte]'?: string
      'price[lt]'?: string
      'price[lte]'?: string
      'exists[secondHandOf]'?: boolean
      type?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ProductJsonldProductReadGqlPublic[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Product resource.
   * @tags Product
   * @name ApiProductsIdGet
   * @summary Retrieves a Product resource.
   * @request GET:/api/products/{id}
   * @secure
   */
  export namespace GetProductsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ProductJsonldProductReadItemProductReadGqlPublic
  }
  /**
   * @description Retrieves the collection of RestockPrint resources.
   * @tags RestockPrint
   * @name ApiRestockPrintsGetCollection
   * @summary Retrieves the collection of RestockPrint resources.
   * @request GET:/api/restock-prints
   * @secure
   */
  export namespace GetRestockPrints {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      store?: string
      'store[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': RestockPrintJsonldRestockPrintRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a RestockPrint resource.
   * @tags RestockPrint
   * @name ApiRestockPrintsIdGet
   * @summary Retrieves a RestockPrint resource.
   * @request GET:/api/restock-prints/{id}
   * @secure
   */
  export namespace GetRestockPrintsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = RestockPrintJsonldRestockPrintRead
  }
  /**
   * @description Retrieves the collection of Retailer resources.
   * @tags Retailer
   * @name ApiRetailersGetCollection
   * @summary Retrieves the collection of Retailer resources.
   * @request GET:/api/retailers
   * @secure
   */
  export namespace GetRetailers {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      search?: string
      name?: string
      firstname?: string
      lastname?: string
      email?: string
      zipCode?: string
      'zipCode[]'?: string[]
      playinStore?: string
      'playinStore[]'?: string[]
      accountType?: string
      'accountType[]'?: string[]
      'order[lastname]'?: 'asc' | 'desc'
      'order[firstname]'?: 'asc' | 'desc'
      'exists[playinAccountType]'?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': RetailerJsonldRetailerReadCustomerRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Retailer resource.
   * @tags Retailer
   * @name ApiRetailerstransformToCustomerIdPost
   * @summary Transforms a retailer into a customer.
   * @request POST:/api/retailers/transform-to-customer/{id}
   * @secure
   */
  export namespace PostRetailersTransformToCustomerId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = RetailerJsonldRetailerTransformPro
    export type RequestHeaders = {}
    export type ResponseBody = RetailerJsonldCustomerRead
  }
  /**
   * @description Retrieves a Retailer resource.
   * @tags Retailer
   * @name ApiRetailersIdGet
   * @summary Retrieves a Retailer resource.
   * @request GET:/api/retailers/{id}
   * @secure
   */
  export namespace GetRetailersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = RetailerJsonldRetailerReadCustomerRead
  }
  /**
   * @description Updates the Retailer resource.
   * @tags Retailer
   * @name ApiRetailersIdPatch
   * @summary Updates the Retailer resource.
   * @request PATCH:/api/retailers/{id}
   * @secure
   */
  export namespace PatchRetailersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = Retailer
    export type RequestHeaders = {}
    export type ResponseBody = RetailerJsonldCustomerPatch
  }
  /**
   * @description Retrieves the collection of Review resources.
   * @tags Review
   * @name ApiReviewsGetCollection
   * @summary Retrieves the collection of Review resources.
   * @request GET:/api/reviews
   * @secure
   */
  export namespace GetReviews {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      rating?: number
      'rating[]'?: number[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ReviewJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Review resource.
   * @tags Review
   * @name ApiReviewsPost
   * @summary Creates a Review resource.
   * @request POST:/api/reviews
   * @secure
   */
  export namespace PostReviews {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ReviewJsonld
    export type RequestHeaders = {}
    export type ResponseBody = ReviewJsonld
  }
  /**
   * @description Retrieves a Review resource.
   * @tags Review
   * @name ApiReviewsIdGet
   * @summary Retrieves a Review resource.
   * @request GET:/api/reviews/{id}
   * @secure
   */
  export namespace GetReviewsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ReviewJsonld
  }
  /**
   * @description Retrieves the collection of RewardCategory resources.
   * @tags RewardCategory
   * @name ApiRewardCategoriesGetCollection
   * @summary Retrieves the collection of RewardCategory resources.
   * @request GET:/api/reward-categories
   * @secure
   */
  export namespace GetRewardCategories {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[position]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': RewardCategoryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a RewardCategory resource.
   * @tags RewardCategory
   * @name ApiRewardCategoriesIdGet
   * @summary Retrieves a RewardCategory resource.
   * @request GET:/api/reward-categories/{id}
   * @secure
   */
  export namespace GetRewardCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = RewardCategoryJsonld
  }
  /**
   * @description Retrieves the collection of RewardCategoryStoryblokStory resources.
   * @tags RewardCategoryStoryblokStory
   * @name ApiRewardCategoryStoryblokStoriesGetCollection
   * @summary Retrieves the collection of RewardCategoryStoryblokStory resources.
   * @request GET:/api/reward-category-storyblok-stories
   * @secure
   */
  export namespace GetRewardCategoryStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': RewardCategoryStoryblokStoryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a RewardCategoryStoryblokStory resource.
   * @tags RewardCategoryStoryblokStory
   * @name ApiRewardCategoryStoryblokStoriesPost
   * @summary Creates a RewardCategoryStoryblokStory resource.
   * @request POST:/api/reward-category-storyblok-stories
   * @secure
   */
  export namespace PostRewardCategoryStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = RewardCategoryStoryblokStoryJsonld
    export type RequestHeaders = {}
    export type ResponseBody = RewardCategoryStoryblokStoryJsonld
  }
  /**
   * @description Retrieves a RewardCategoryStoryblokStory resource.
   * @tags RewardCategoryStoryblokStory
   * @name ApiRewardCategoryStoryblokStoriesIdGet
   * @summary Retrieves a RewardCategoryStoryblokStory resource.
   * @request GET:/api/reward-category-storyblok-stories/{id}
   * @secure
   */
  export namespace GetRewardCategoryStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = RewardCategoryStoryblokStoryJsonld
  }
  /**
   * @description Removes the RewardCategoryStoryblokStory resource.
   * @tags RewardCategoryStoryblokStory
   * @name ApiRewardCategoryStoryblokStoriesIdDelete
   * @summary Removes the RewardCategoryStoryblokStory resource.
   * @request DELETE:/api/reward-category-storyblok-stories/{id}
   * @secure
   */
  export namespace DeleteRewardCategoryStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the RewardCategoryStoryblokStory resource.
   * @tags RewardCategoryStoryblokStory
   * @name ApiRewardCategoryStoryblokStoriesIdPatch
   * @summary Updates the RewardCategoryStoryblokStory resource.
   * @request PATCH:/api/reward-category-storyblok-stories/{id}
   * @secure
   */
  export namespace PatchRewardCategoryStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = RewardCategoryStoryblokStory
    export type RequestHeaders = {}
    export type ResponseBody = RewardCategoryStoryblokStoryJsonld
  }
  /**
   * @description Retrieves the collection of Reward resources.
   * @tags Reward
   * @name ApiRewardsGetCollection
   * @summary Retrieves the collection of Reward resources.
   * @request GET:/api/rewards
   * @secure
   */
  export namespace GetRewards {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': RewardJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Reward resource.
   * @tags Reward
   * @name ApiRewardsIdGet
   * @summary Retrieves a Reward resource.
   * @request GET:/api/rewards/{id}
   * @secure
   */
  export namespace GetRewardsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = RewardJsonld
  }
  /**
   * @description Retrieves the collection of SealedProductStoryblokStory resources.
   * @tags SealedProductStoryblokStory
   * @name ApiSealedProductStoryblokStoriesGetCollection
   * @summary Retrieves the collection of SealedProductStoryblokStory resources.
   * @request GET:/api/sealed-product-storyblok-stories
   * @secure
   */
  export namespace GetSealedProductStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': SealedProductStoryblokStoryJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a SealedProductStoryblokStory resource.
   * @tags SealedProductStoryblokStory
   * @name ApiSealedProductStoryblokStoriesPost
   * @summary Creates a SealedProductStoryblokStory resource.
   * @request POST:/api/sealed-product-storyblok-stories
   * @secure
   */
  export namespace PostSealedProductStoryblokStories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = SealedProductStoryblokStoryJsonld
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductStoryblokStoryJsonld
  }
  /**
   * @description Retrieves a SealedProductStoryblokStory resource.
   * @tags SealedProductStoryblokStory
   * @name ApiSealedProductStoryblokStoriesIdGet
   * @summary Retrieves a SealedProductStoryblokStory resource.
   * @request GET:/api/sealed-product-storyblok-stories/{id}
   * @secure
   */
  export namespace GetSealedProductStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductStoryblokStoryJsonld
  }
  /**
   * @description Removes the SealedProductStoryblokStory resource.
   * @tags SealedProductStoryblokStory
   * @name ApiSealedProductStoryblokStoriesIdDelete
   * @summary Removes the SealedProductStoryblokStory resource.
   * @request DELETE:/api/sealed-product-storyblok-stories/{id}
   * @secure
   */
  export namespace DeleteSealedProductStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the SealedProductStoryblokStory resource.
   * @tags SealedProductStoryblokStory
   * @name ApiSealedProductStoryblokStoriesIdPatch
   * @summary Updates the SealedProductStoryblokStory resource.
   * @request PATCH:/api/sealed-product-storyblok-stories/{id}
   * @secure
   */
  export namespace PatchSealedProductStoryblokStoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = SealedProductStoryblokStory
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductStoryblokStoryJsonld
  }
  /**
   * @description Retrieves a SealedProductTranslation resource.
   * @tags SealedProductTranslation
   * @name ApiSealedProductTranslationsIdGet
   * @summary Retrieves a SealedProductTranslation resource.
   * @request GET:/api/sealed-product-translations/{id}
   * @secure
   */
  export namespace GetSealedProductTranslationsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductTranslationJsonld
  }
  /**
   * @description Retrieves the collection of SealedProduct resources.
   * @tags SealedProduct
   * @name ApiSealedProductsGetCollection
   * @summary Retrieves the collection of SealedProduct resources.
   * @request GET:/api/sealed-products
   * @secure
   */
  export namespace GetSealedProducts {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      search?: string
      name?: string
      barCode?: string
      'fulltext[q]'?: string
      'fulltext[order]'?: 'asc' | 'desc'
      hasStockIn?: string
      topCategory?: string
      'order[secondHand]'?: 'asc' | 'desc'
      'order[family.id]'?: 'asc' | 'desc'
      'order[family.name]'?: 'asc' | 'desc'
      'order[old]'?: 'asc' | 'desc'
      'order[name]'?: 'asc' | 'desc'
      pack?: boolean
      'family.tcg'?: boolean
      old?: boolean
      id?: number
      'id[]'?: number[]
      'productStores.store'?: string
      'productStores.store[]'?: string[]
      category?: string
      'category[]'?: string[]
      secondaryCategories?: string
      'secondaryCategories[]'?: string[]
      rewards?: string
      'rewards[]'?: string[]
      'rewards.category'?: string
      'rewards.category[]'?: string[]
      commercialOffers?: string
      'commercialOffers[]'?: string[]
      'databaseVisibility[between]'?: string
      'databaseVisibility[gt]'?: string
      'databaseVisibility[gte]'?: string
      'databaseVisibility[lt]'?: string
      'databaseVisibility[lte]'?: string
      'releasedAt[before]'?: string
      'releasedAt[strictly_before]'?: string
      'releasedAt[after]'?: string
      'releasedAt[strictly_after]'?: string
      'exists[productRange]'?: boolean
      linkedEdition?: string
      'linkedEdition[]'?: string[]
      family?: string
      'family[]'?: string[]
      'lang.code'?: string
      'lang.code[]'?: string[]
      'price[between]'?: string
      'price[gt]'?: string
      'price[gte]'?: string
      'price[lt]'?: string
      'price[lte]'?: string
      'exists[secondHandOf]'?: boolean
      type?: string
      'topCategory[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': SealedProductJsonldProductRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves the collection of SealedProduct resources.
   * @tags SealedProduct
   * @name ApiSealedProductsstatsGetCollection
   * @summary Get sell statistics about all products.
   * @request GET:/api/sealed-products/stats
   * @secure
   */
  export namespace GetSealedProductsStats {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      search?: string
      name?: string
      barCode?: string
      'fulltext[q]'?: string
      'fulltext[order]'?: 'asc' | 'desc'
      hasStockIn?: string
      topCategory?: string
      'order[secondHand]'?: 'asc' | 'desc'
      'order[family.id]'?: 'asc' | 'desc'
      'order[family.name]'?: 'asc' | 'desc'
      'order[old]'?: 'asc' | 'desc'
      'order[name]'?: 'asc' | 'desc'
      pack?: boolean
      'family.tcg'?: boolean
      old?: boolean
      id?: number
      'id[]'?: number[]
      'productStores.store'?: string
      'productStores.store[]'?: string[]
      category?: string
      'category[]'?: string[]
      secondaryCategories?: string
      'secondaryCategories[]'?: string[]
      rewards?: string
      'rewards[]'?: string[]
      'rewards.category'?: string
      'rewards.category[]'?: string[]
      commercialOffers?: string
      'commercialOffers[]'?: string[]
      'databaseVisibility[between]'?: string
      'databaseVisibility[gt]'?: string
      'databaseVisibility[gte]'?: string
      'databaseVisibility[lt]'?: string
      'databaseVisibility[lte]'?: string
      'releasedAt[before]'?: string
      'releasedAt[strictly_before]'?: string
      'releasedAt[after]'?: string
      'releasedAt[strictly_after]'?: string
      'exists[productRange]'?: boolean
      linkedEdition?: string
      'linkedEdition[]'?: string[]
      family?: string
      'family[]'?: string[]
      'lang.code'?: string
      'lang.code[]'?: string[]
      'price[between]'?: string
      'price[gt]'?: string
      'price[gte]'?: string
      'price[lt]'?: string
      'price[lte]'?: string
      'exists[secondHandOf]'?: boolean
      type?: string
      'topCategory[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': SealedProductJsonldProductReadProductStats[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a SealedProduct resource.
   * @tags SealedProduct
   * @name ApiSealedProductsstatsIdGet
   * @summary Get sell statistics about a product.
   * @request GET:/api/sealed-products/stats/{id}
   * @secure
   */
  export namespace GetSealedProductsStatsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductJsonldProductReadProductStats
  }
  /**
   * @description Updates the SealedProduct resource.
   * @tags SealedProduct
   * @name ApiSealedProductsstockPlaceIdPatch
   * @summary Updates the SealedProduct resource.
   * @request PATCH:/api/sealed-products/stock-place/{id}
   * @secure
   */
  export namespace PatchSealedProductsStockPlaceId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = SealedProductProductWritePlace
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductJsonldProductRead
  }
  /**
   * @description Retrieves a SealedProduct resource.
   * @tags SealedProduct
   * @name ApiSealedProductsIdGet
   * @summary Retrieves a SealedProduct resource.
   * @request GET:/api/sealed-products/{id}
   * @secure
   */
  export namespace GetSealedProductsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductJsonldProductReadItemProductRead
  }
  /**
   * @description Updates the SealedProduct resource.
   * @tags SealedProduct
   * @name ApiSealedProductsIdPatch
   * @summary Updates the SealedProduct resource.
   * @request PATCH:/api/sealed-products/{id}
   * @secure
   */
  export namespace PatchSealedProductsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = SealedProductProductWrite
    export type RequestHeaders = {}
    export type ResponseBody = SealedProductJsonldProductRead
  }
  /**
   * @description Retrieves the collection of SearchResultSuggestionKeyword resources.
   * @tags SearchResultSuggestionKeyword
   * @name ApiSearchResultSuggestionKeywordsGetCollection
   * @summary Retrieves the collection of SearchResultSuggestionKeyword resources.
   * @request GET:/api/search-result-suggestion-keywords
   * @secure
   */
  export namespace GetSearchResultSuggestionKeywords {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': SearchResultSuggestionKeywordJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a SearchResultSuggestionKeyword resource.
   * @tags SearchResultSuggestionKeyword
   * @name ApiSearchResultSuggestionKeywordsIdGet
   * @summary Retrieves a SearchResultSuggestionKeyword resource.
   * @request GET:/api/search-result-suggestion-keywords/{id}
   * @secure
   */
  export namespace GetSearchResultSuggestionKeywordsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SearchResultSuggestionKeywordJsonld
  }
  /**
   * @description Retrieves the collection of SearchResultSuggestion resources.
   * @tags SearchResultSuggestion
   * @name ApiSearchResultSuggestionsGetCollection
   * @summary Retrieves the collection of SearchResultSuggestion resources.
   * @request GET:/api/search-result-suggestions
   * @secure
   */
  export namespace GetSearchResultSuggestions {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': SearchResultSuggestionJsonldSearchResultSuggestionRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a SearchResultSuggestion resource.
   * @tags SearchResultSuggestion
   * @name ApiSearchResultSuggestionsIdGet
   * @summary Retrieves a SearchResultSuggestion resource.
   * @request GET:/api/search-result-suggestions/{id}
   * @secure
   */
  export namespace GetSearchResultSuggestionsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SearchResultSuggestionJsonldSearchResultSuggestionRead
  }
  /**
   * @description Gets role actions.
   * @tags Security
   * @name RoleActions
   * @summary Gets role actions.
   * @request GET:/api/security-actions
   * @secure
   */
  export namespace GetSecurityActions {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = RoleActions
  }
  /**
   * @description Gets role hierarchy.
   * @tags Security
   * @name Hierarchy
   * @summary Gets role hierarchy.
   * @request GET:/api/security-hierarchy
   * @secure
   */
  export namespace GetSecurityHierarchy {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Hierarchy
  }
  /**
   * @description Sends an email
   * @tags Email
   * @name SendEmail
   * @summary Send an email.
   * @request POST:/api/send-email
   * @secure
   */
  export namespace PostSendEmail {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = Email
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Retrieves the collection of ShippingLabelLog resources.
   * @tags ShippingLabelLog
   * @name ApiShippingLabelLogsGetCollection
   * @summary Retrieves the collection of ShippingLabelLog resources.
   * @request GET:/api/shipping-label-logs
   * @secure
   */
  export namespace GetShippingLabelLogs {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'printedAt[before]'?: string
      'printedAt[strictly_before]'?: string
      'printedAt[after]'?: string
      'printedAt[strictly_after]'?: string
      'order[printedAt]'?: 'asc' | 'desc'
      'relatedOrder.billNumber'?: string
      'relatedOrder.billNumber[]'?: string[]
      shippingNumber?: string
      'shippingNumber[]'?: string[]
      'printedBy.id'?: number
      'printedBy.id[]'?: number[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ShippingLabelLogJsonldShippingLabelLogRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a ShippingLabelLog resource.
   * @tags ShippingLabelLog
   * @name ApiShippingLabelLogsPost
   * @summary Creates a ShippingLabelLog resource.
   * @request POST:/api/shipping-label-logs
   * @secure
   */
  export namespace PostShippingLabelLogs {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ShippingLabelLogJsonld
    export type RequestHeaders = {}
    export type ResponseBody = ShippingLabelLogJsonldShippingLabelLogRead
  }
  /**
   * @description Retrieves a ShippingLabelLog resource.
   * @tags ShippingLabelLog
   * @name ApiShippingLabelLogsIdGet
   * @summary Retrieves a ShippingLabelLog resource.
   * @request GET:/api/shipping-label-logs/{id}
   * @secure
   */
  export namespace GetShippingLabelLogsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ShippingLabelLogJsonldShippingLabelLogRead
  }
  /**
   * @description Updates the ShippingLabelLog resource.
   * @tags ShippingLabelLog
   * @name ApiShippingLabelLogsIdPatch
   * @summary Updates the ShippingLabelLog resource.
   * @request PATCH:/api/shipping-label-logs/{id}
   * @secure
   */
  export namespace PatchShippingLabelLogsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = ShippingLabelLog
    export type RequestHeaders = {}
    export type ResponseBody = ShippingLabelLogJsonldShippingLabelLogRead
  }
  /**
   * @description Retrieves the collection of ShippingMode resources.
   * @tags ShippingMode
   * @name ApiShippingModesGetCollection
   * @summary Retrieves the collection of ShippingMode resources.
   * @request GET:/api/shipping-modes
   * @secure
   */
  export namespace GetShippingModes {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[name]'?: 'asc' | 'desc'
      store?: string
      'store[]'?: string[]
      shippingType?: string
      'shippingType[]'?: string[]
      'store.active'?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ShippingModeJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves the collection of ShippingMode resources.
   * @tags ShippingMode
   * @name ApiShippingModesavailableForOrderOrdercountryCountryGetCollection
   * @summary Retrieves the collection of ShippingMode resources.
   * @request GET:/api/shipping-modes/available-for-order/{order}/country/{country}
   * @secure
   */
  export namespace GetShippingModesAvailableForOrderOrderCountryCountry {
    export type RequestParams = { order: number; country: number }
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[name]'?: 'asc' | 'desc'
      store?: string
      'store[]'?: string[]
      shippingType?: string
      'shippingType[]'?: string[]
      'store.active'?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ShippingModeJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves the collection of ShippingMode resources.
   * @tags ShippingMode
   * @name ApiShippingModespriceOfShippingModeorderOrdercountryCountryGetCollection
   * @summary Retrieves the collection of ShippingMode resources.
   * @request GET:/api/shipping-modes/price-of/{shippingMode}/order/{order}/country/{country}
   * @deprecated
   * @secure
   */
  export namespace GetShippingModesPriceOfShippingModeOrderOrderCountryCountry {
    export type RequestParams = { shippingMode: string; order: string; country: string }
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[name]'?: 'asc' | 'desc'
      store?: string
      'store[]'?: string[]
      shippingType?: string
      'shippingType[]'?: string[]
      'store.active'?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ShippingModeJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ShippingMode resource.
   * @tags ShippingMode
   * @name ApiShippingModesIdGet
   * @summary Retrieves a ShippingMode resource.
   * @request GET:/api/shipping-modes/{id}
   * @secure
   */
  export namespace GetShippingModesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ShippingModeJsonld
  }
  /**
   * @description Retrieves the collection of ShippingType resources.
   * @tags ShippingType
   * @name ApiShippingTypesGetCollection
   * @summary Retrieves the collection of ShippingType resources.
   * @request GET:/api/shipping-types
   * @secure
   */
  export namespace GetShippingTypes {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': ShippingTypeJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a ShippingType resource.
   * @tags ShippingType
   * @name ApiShippingTypesIdGet
   * @summary Retrieves a ShippingType resource.
   * @request GET:/api/shipping-types/{id}
   * @secure
   */
  export namespace GetShippingTypesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ShippingTypeJsonld
  }
  /**
   * @description Retrieves a SortOrder resource.
   * @tags SortOrder
   * @name ApiSortOrdersGet
   * @summary Retrieves a SortOrder resource.
   * @request GET:/api/sort-orders
   * @secure
   */
  export namespace GetSortOrders {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SortOrderJsonld
  }
  /**
   * @description Retrieves a StockAlert resource.
   * @tags StockAlert
   * @name ApiStockAlertsIdGet
   * @summary Retrieves a StockAlert resource.
   * @request GET:/api/stock-alerts/{id}
   * @secure
   */
  export namespace GetStockAlertsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StockAlertJsonld
  }
  /**
   * @description Retrieves the collection of StockEntry resources.
   * @tags StockEntry
   * @name ApiStockEntriesGetCollection
   * @summary Retrieves the collection of StockEntry resources.
   * @request GET:/api/stock-entries
   * @secure
   */
  export namespace GetStockEntries {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      product?: string
      'product[]'?: string[]
      'stock.store'?: string
      'stock.store[]'?: string[]
      'stock.status'?: string
      'stock.status[]'?: string[]
      'stock.validated'?: boolean
      'order[addedAt]'?: 'asc' | 'desc'
      'order[stock.createdAt]'?: 'asc' | 'desc'
      inStock?: boolean
      transfer?: boolean
      remainingQuantity?: 'asc' | 'desc'
      withRemainingQuantity?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StockEntryJsonldStockEntryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StockEntry resource.
   * @tags StockEntry
   * @name ApiStockEntriesPost
   * @summary Creates a StockEntry resource.
   * @request POST:/api/stock-entries
   * @secure
   */
  export namespace PostStockEntries {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StockEntryJsonldStockEntryWrite
    export type RequestHeaders = {}
    export type ResponseBody = StockEntryJsonldStockEntryRead
  }
  /**
   * @description Retrieves the collection of StockEntry resources.
   * @tags StockEntry
   * @name ApiStockEntriestransferredFromIdEntryGetCollection
   * @summary Retrieves the collection of StockEntry resources.
   * @request GET:/api/stock-entries/transferred-from/{id}/{entry}
   * @secure
   */
  export namespace GetStockEntriesTransferredFromIdEntry {
    export type RequestParams = { stock: number; entry?: number; id: string }
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      product?: string
      'product[]'?: string[]
      'stock.store'?: string
      'stock.store[]'?: string[]
      'stock.status'?: string
      'stock.status[]'?: string[]
      'stock.validated'?: boolean
      'order[addedAt]'?: 'asc' | 'desc'
      'order[stock.createdAt]'?: 'asc' | 'desc'
      inStock?: boolean
      transfer?: boolean
      remainingQuantity?: 'asc' | 'desc'
      withRemainingQuantity?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StockEntryJsonldStockEntryTransferred[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a StockEntry resource.
   * @tags StockEntry
   * @name ApiStockEntriesIdGet
   * @summary Retrieves a StockEntry resource.
   * @request GET:/api/stock-entries/{id}
   * @secure
   */
  export namespace GetStockEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StockEntryJsonldStockEntryRead
  }
  /**
   * @description Removes the StockEntry resource.
   * @tags StockEntry
   * @name ApiStockEntriesIdDelete
   * @summary Removes the StockEntry resource.
   * @request DELETE:/api/stock-entries/{id}
   * @secure
   */
  export namespace DeleteStockEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the StockEntry resource.
   * @tags StockEntry
   * @name ApiStockEntriesIdPatch
   * @summary Updates the StockEntry resource.
   * @request PATCH:/api/stock-entries/{id}
   * @secure
   */
  export namespace PatchStockEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StockEntryStockEntryWrite
    export type RequestHeaders = {}
    export type ResponseBody = StockEntryJsonldStockEntryRead
  }
  /**
   * @description Retrieves the collection of StockIssue resources.
   * @tags StockIssue
   * @name ApiStockIssuesGetCollection
   * @summary Retrieves the collection of StockIssue resources.
   * @request GET:/api/stock-issues
   * @secure
   */
  export namespace GetStockIssues {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      solved?: boolean
      stock?: string
      'stock[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StockIssueJsonldStockIssueRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a StockIssue resource.
   * @tags StockIssue
   * @name ApiStockIssuesIdGet
   * @summary Retrieves a StockIssue resource.
   * @request GET:/api/stock-issues/{id}
   * @secure
   */
  export namespace GetStockIssuesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StockIssueJsonldStockIssueRead
  }
  /**
   * @description Retrieves the collection of StockTransfer resources.
   * @tags StockTransfer
   * @name ApiStockTransfersGetCollection
   * @summary Retrieves the collection of StockTransfer resources.
   * @request GET:/api/stock-transfers
   * @secure
   */
  export namespace GetStockTransfers {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      transferredFrom?: string
      'transferredFrom[]'?: string[]
      'order[createdAt]'?: 'asc' | 'desc'
      transferredOrReceivedBy?: string
      search?: string
      validated?: boolean
      treated?: boolean
      id?: number
      'id[]'?: number[]
      store?: string
      'store[]'?: string[]
      'entries.product'?: string
      'entries.product[]'?: string[]
      status?: string
      'status[]'?: string[]
      billNumber?: string
      'billNumber[]'?: string[]
      retailer?: string
      'retailer[]'?: string[]
      'exists[cardOpening]'?: boolean
      'order[restock]'?: 'asc' | 'desc'
      transfer?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StockTransferJsonldStockReadStockTransferRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StockTransfer resource.
   * @tags StockTransfer
   * @name ApiStockTransfersPost
   * @summary Creates a StockTransfer resource.
   * @request POST:/api/stock-transfers
   * @secure
   */
  export namespace PostStockTransfers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StockTransferJsonldStockTransferCreate
    export type RequestHeaders = {}
    export type ResponseBody = StockTransferJsonldStockReadStockTransferRead
  }
  /**
   * @description Retrieves a StockTransfer resource.
   * @tags StockTransfer
   * @name ApiStockTransfersIdGet
   * @summary Retrieves a StockTransfer resource.
   * @request GET:/api/stock-transfers/{id}
   * @secure
   */
  export namespace GetStockTransfersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StockTransferJsonldStockReadStockTransferRead
  }
  /**
   * @description Retrieves the collection of Stock resources.
   * @tags Stock
   * @name ApiStocksGetCollection
   * @summary Retrieves the collection of Stock resources.
   * @request GET:/api/stocks
   * @secure
   */
  export namespace GetStocks {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      search?: string
      validated?: boolean
      treated?: boolean
      id?: number
      'id[]'?: number[]
      store?: string
      'store[]'?: string[]
      'entries.product'?: string
      'entries.product[]'?: string[]
      status?: string
      'status[]'?: string[]
      billNumber?: string
      'billNumber[]'?: string[]
      retailer?: string
      'retailer[]'?: string[]
      'exists[cardOpening]'?: boolean
      'order[createdAt]'?: 'asc' | 'desc'
      'order[restock]'?: 'asc' | 'desc'
      transfer?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StockJsonldStockRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Stock resource.
   * @tags Stock
   * @name ApiStocksPost
   * @summary Creates a Stock resource.
   * @request POST:/api/stocks
   * @secure
   */
  export namespace PostStocks {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StockJsonldStockCreate
    export type RequestHeaders = {}
    export type ResponseBody = StockJsonldStockRead
  }
  /**
   * @description Retrieves a Stock resource.
   * @tags Stock
   * @name ApiStocksstatsIdGet
   * @summary Retrieves stock with additional sell statistics.
   * @request GET:/api/stocks/stats/{id}
   * @secure
   */
  export namespace GetStocksStatsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StockJsonldStockReadItemStockReadStockStats
  }
  /**
   * @description Creates a Stock resource.
   * @tags Stock
   * @name ApiStockssynchronizeHiboutikPost
   * @summary Synchronizes stocks with Hiboutik.
   * @request POST:/api/stocks/synchronize-hiboutik
   * @secure
   */
  export namespace PostStocksSynchronizeHiboutik {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StockJsonld
    export type RequestHeaders = {}
    export type ResponseBody = StockJsonld
  }
  /**
   * @description Retrieves a Stock resource.
   * @tags Stock
   * @name ApiStocksIdGet
   * @summary Retrieves a Stock resource.
   * @request GET:/api/stocks/{id}
   * @secure
   */
  export namespace GetStocksId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StockJsonldStockReadItemStockRead
  }
  /**
   * @description Updates the Stock resource.
   * @tags Stock
   * @name ApiStocksIdPatch
   * @summary Updates the Stock resource.
   * @request PATCH:/api/stocks/{id}
   * @secure
   */
  export namespace PatchStocksId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StockStockWrite
    export type RequestHeaders = {}
    export type ResponseBody = StockJsonldStockReadStockReadItem
  }
  /**
   * @description Retrieves the collection of StoreEventCustomer resources.
   * @tags StoreEventCustomer
   * @name ApiStoreEventCustomersGetCollection
   * @summary Retrieves the collection of StoreEventCustomer resources.
   * @request GET:/api/store-event-customers
   * @secure
   */
  export namespace GetStoreEventCustomers {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      event?: string
      'event[]'?: string[]
      'linkedOrder.customer'?: string
      'linkedOrder.customer[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreEventCustomerJsonldEventCustomerRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreEventCustomer resource.
   * @tags StoreEventCustomer
   * @name ApiStoreEventCustomersPost
   * @summary Creates a StoreEventCustomer resource.
   * @request POST:/api/store-event-customers
   * @secure
   */
  export namespace PostStoreEventCustomers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreEventCustomerStoreEventCustomerCreateInputJsonldEventCustomerWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventCustomerJsonldEventCustomerRead
  }
  /**
   * @description Creates a StoreEventCustomer resource.
   * @tags StoreEventCustomer
   * @name ApiStoreEventCustomerscancelIdPost
   * @summary Cancels a customer's subscription to an event.
   * @request POST:/api/store-event-customers/cancel/{id}
   * @secure
   */
  export namespace PostStoreEventCustomersCancelId {
    export type RequestParams = { id: string }
    export type RequestQuery = { refund?: boolean }
    export type RequestBody = StoreEventCustomerStoreEventCustomerCancelInputJsonldEventCustomerWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventCustomerJsonldEventCustomerRead
  }
  /**
   * @description Retrieves a StoreEventCustomer resource.
   * @tags StoreEventCustomer
   * @name ApiStoreEventCustomersIdGet
   * @summary Retrieves a StoreEventCustomer resource.
   * @request GET:/api/store-event-customers/{id}
   * @secure
   */
  export namespace GetStoreEventCustomersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventCustomerJsonldEventCustomerRead
  }
  /**
   * @description Updates the StoreEventCustomer resource.
   * @tags StoreEventCustomer
   * @name ApiStoreEventCustomersIdPatch
   * @summary Updates the StoreEventCustomer resource.
   * @request PATCH:/api/store-event-customers/{id}
   * @secure
   */
  export namespace PatchStoreEventCustomersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreEventCustomerStoreEventCustomerModifyInputEventCustomerWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventCustomerJsonldEventCustomerRead
  }
  /**
   * @description Retrieves a StoreEventDto resource.
   * @tags StoreEventDto
   * @name ApiStoreEventDtosIdGet
   * @summary Retrieves a StoreEventDto resource.
   * @request GET:/api/store-event-dtos/{id}
   * @secure
   */
  export namespace GetStoreEventDtosId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventDtoJsonld
  }
  /**
   * @description Retrieves the collection of StoreEventFormat resources.
   * @tags StoreEventFormat
   * @name ApiStoreEventFormatsGetCollection
   * @summary Retrieves the collection of StoreEventFormat resources.
   * @request GET:/api/store-event-formats
   * @secure
   */
  export namespace GetStoreEventFormats {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      range?: string
      'range[]'?: string[]
      'order[name]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreEventFormatJsonldEventFormatRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreEventFormat resource.
   * @tags StoreEventFormat
   * @name ApiStoreEventFormatsPost
   * @summary Creates a StoreEventFormat resource.
   * @request POST:/api/store-event-formats
   * @secure
   */
  export namespace PostStoreEventFormats {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreEventFormatJsonldEventFormatWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventFormatJsonldEventFormatRead
  }
  /**
   * @description Retrieves a StoreEventFormat resource.
   * @tags StoreEventFormat
   * @name ApiStoreEventFormatsIdGet
   * @summary Retrieves a StoreEventFormat resource.
   * @request GET:/api/store-event-formats/{id}
   * @secure
   */
  export namespace GetStoreEventFormatsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventFormatJsonldEventFormatRead
  }
  /**
   * @description Updates the StoreEventFormat resource.
   * @tags StoreEventFormat
   * @name ApiStoreEventFormatsIdPatch
   * @summary Updates the StoreEventFormat resource.
   * @request PATCH:/api/store-event-formats/{id}
   * @secure
   */
  export namespace PatchStoreEventFormatsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreEventFormatEventFormatWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventFormatJsonldEventFormatRead
  }
  /**
   * @description Retrieves the collection of StoreEventOrder resources.
   * @tags StoreEventOrder
   * @name ApiStoreEventOrdersGetCollection
   * @summary Retrieves the collection of StoreEventOrder resources.
   * @request GET:/api/store-event-orders
   * @secure
   */
  export namespace GetStoreEventOrders {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreEventOrderJsonldEventOrderRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreEventOrder resource.
   * @tags StoreEventOrder
   * @name ApiStoreEventOrdersPost
   * @summary Creates a StoreEventOrder resource.
   * @request POST:/api/store-event-orders
   * @secure
   */
  export namespace PostStoreEventOrders {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreEventOrderJsonldEventOrderWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventOrderJsonldEventOrderRead
  }
  /**
   * @description Retrieves a StoreEventOrder resource.
   * @tags StoreEventOrder
   * @name ApiStoreEventOrdersIdGet
   * @summary Retrieves a StoreEventOrder resource.
   * @request GET:/api/store-event-orders/{id}
   * @secure
   */
  export namespace GetStoreEventOrdersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventOrderJsonldEventOrderRead
  }
  /**
   * @description Updates the StoreEventOrder resource.
   * @tags StoreEventOrder
   * @name ApiStoreEventOrdersIdPatch
   * @summary Updates the StoreEventOrder resource.
   * @request PATCH:/api/store-event-orders/{id}
   * @secure
   */
  export namespace PatchStoreEventOrdersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreEventOrder
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventOrderJsonld
  }
  /**
   * @description Retrieves the collection of StoreEventRange resources.
   * @tags StoreEventRange
   * @name ApiStoreEventRangesGetCollection
   * @summary Retrieves the collection of StoreEventRange resources.
   * @request GET:/api/store-event-ranges
   * @secure
   */
  export namespace GetStoreEventRanges {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[name]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreEventRangeJsonldEventFormatRangeRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreEventRange resource.
   * @tags StoreEventRange
   * @name ApiStoreEventRangesPost
   * @summary Creates a StoreEventRange resource.
   * @request POST:/api/store-event-ranges
   * @secure
   */
  export namespace PostStoreEventRanges {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreEventRangeJsonldEventFormatRangeWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventRangeJsonldEventFormatRangeRead
  }
  /**
   * @description Retrieves a StoreEventRange resource.
   * @tags StoreEventRange
   * @name ApiStoreEventRangesIdGet
   * @summary Retrieves a StoreEventRange resource.
   * @request GET:/api/store-event-ranges/{id}
   * @secure
   */
  export namespace GetStoreEventRangesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventRangeJsonldEventFormatRangeRead
  }
  /**
   * @description Updates the StoreEventRange resource.
   * @tags StoreEventRange
   * @name ApiStoreEventRangesIdPatch
   * @summary Updates the StoreEventRange resource.
   * @request PATCH:/api/store-event-ranges/{id}
   * @secure
   */
  export namespace PatchStoreEventRangesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreEventRangeEventFormatRangeWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventRangeJsonldEventFormatRangeRead
  }
  /**
   * @description Retrieves the collection of StoreEvent resources.
   * @tags StoreEvent
   * @name ApiStoreEventsGetCollection
   * @summary Retrieves the collection of StoreEvent resources.
   * @request GET:/api/store-events
   * @secure
   */
  export namespace GetStoreEvents {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'occursAt[before]'?: string
      'occursAt[strictly_before]'?: string
      'occursAt[after]'?: string
      'occursAt[strictly_after]'?: string
      'order[eventRange.name]'?: 'asc' | 'desc'
      'order[occursAt]'?: 'asc' | 'desc'
      'order[startsAt]'?: 'asc' | 'desc'
      name?: string
      'name[]'?: string[]
      'eventRange.name'?: string
      'eventRange.name[]'?: string[]
      store?: string
      'store[]'?: string[]
      format?: string
      'format[]'?: string[]
      eventRange?: string
      'eventRange[]'?: string[]
      status?: 'created' | 'waitingVoucher' | 'treated' | 'canceled' | 'current'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreEventJsonldEventRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreEvent resource.
   * @tags StoreEvent
   * @name ApiStoreEventsPost
   * @summary Creates a StoreEvent resource.
   * @request POST:/api/store-events
   * @secure
   */
  export namespace PostStoreEvents {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreEventJsonldEventWriteEventCreate
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventJsonldEventRead
  }
  /**
   * @description Retrieves a StoreEventsGroupByDate resource.
   * @tags StoreEventsGroupByDate
   * @name ApiStoreEventsGroupByDatesIdGet
   * @summary Retrieves a StoreEventsGroupByDate resource.
   * @request GET:/api/store-events-group-by-dates/{id}
   * @secure
   */
  export namespace GetStoreEventsGroupByDatesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventsGroupByDateJsonld
  }
  /**
   * @description Creates a StoreEvent resource.
   * @tags StoreEvent
   * @name ApiStoreEventscancelIdPost
   * @summary Cancels an event.
   * @request POST:/api/store-events/cancel/{id}
   * @secure
   */
  export namespace PostStoreEventsCancelId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = AbstractCartAddressRead
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventJsonldEventReadEventReadItem
  }
  /**
   * @description Creates a StoreEvent resource.
   * @tags StoreEvent
   * @name ApiStoreEventscloneOfIdPost
   * @summary Creates a StoreEvent resource.
   * @request POST:/api/store-events/clone-of/{id}
   * @secure
   */
  export namespace PostStoreEventsCloneOfId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreEventStoreEventDuplicateInputJsonldEventWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventJsonldEventRead
  }
  /**
   * @description Creates a StoreEvent resource.
   * @tags StoreEvent
   * @name ApiStoreEventscloseIdPost
   * @summary Manually closes an event.
   * @request POST:/api/store-events/close/{id}
   * @secure
   */
  export namespace PostStoreEventsCloseId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = AbstractCartAddressRead
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventJsonldEventReadEventReadItem
  }
  /**
   * @description Creates a StoreEvent resource.
   * @tags StoreEvent
   * @name ApiStoreEventsdeliverVouchersIdPost
   * @summary Creates a StoreEvent resource.
   * @request POST:/api/store-events/deliver-vouchers/{id}
   * @secure
   */
  export namespace PostStoreEventsDeliverVouchersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreEventStoreEventDeliverVouchersInputJsonldEventWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventJsonldEventReadEventReadItem
  }
  /**
   * @description Retrieves a StoreEvent resource.
   * @tags StoreEvent
   * @name ApiStoreEventsIdGet
   * @summary Retrieves a StoreEvent resource.
   * @request GET:/api/store-events/{id}
   * @secure
   */
  export namespace GetStoreEventsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventJsonldEventReadEventReadItem
  }
  /**
   * @description Updates the StoreEvent resource.
   * @tags StoreEvent
   * @name ApiStoreEventsIdPatch
   * @summary Updates the StoreEvent resource.
   * @request PATCH:/api/store-events/{id}
   * @secure
   */
  export namespace PatchStoreEventsId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreEventEventWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreEventJsonldEventReadEventReadItem
  }
  /**
   * @description Retrieves the collection of StoreExceptionalHours resources.
   * @tags StoreExceptionalHours
   * @name ApiStoreExceptionalHoursGetCollection
   * @summary Retrieves the collection of StoreExceptionalHours resources.
   * @request GET:/api/store-exceptional-hours
   * @secure
   */
  export namespace GetStoreExceptionalHours {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      store?: string
      'store[]'?: string[]
      'exceptionalDate[before]'?: string
      'exceptionalDate[strictly_before]'?: string
      'exceptionalDate[after]'?: string
      'exceptionalDate[strictly_after]'?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreExceptionalHoursJsonldStoreExcHoursRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreExceptionalHours resource.
   * @tags StoreExceptionalHours
   * @name ApiStoreExceptionalHoursPost
   * @summary Creates a StoreExceptionalHours resource.
   * @request POST:/api/store-exceptional-hours
   * @secure
   */
  export namespace PostStoreExceptionalHours {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreExceptionalHoursJsonldStoreExcHoursWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreExceptionalHoursJsonldStoreExcHoursRead
  }
  /**
   * @description Retrieves a StoreExceptionalHours resource.
   * @tags StoreExceptionalHours
   * @name ApiStoreExceptionalHoursIdGet
   * @summary Retrieves a StoreExceptionalHours resource.
   * @request GET:/api/store-exceptional-hours/{id}
   * @secure
   */
  export namespace GetStoreExceptionalHoursId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreExceptionalHoursJsonldStoreExcHoursRead
  }
  /**
   * @description Removes the StoreExceptionalHours resource.
   * @tags StoreExceptionalHours
   * @name ApiStoreExceptionalHoursIdDelete
   * @summary Removes the StoreExceptionalHours resource.
   * @request DELETE:/api/store-exceptional-hours/{id}
   * @secure
   */
  export namespace DeleteStoreExceptionalHoursId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the StoreExceptionalHours resource.
   * @tags StoreExceptionalHours
   * @name ApiStoreExceptionalHoursIdPatch
   * @summary Updates the StoreExceptionalHours resource.
   * @request PATCH:/api/store-exceptional-hours/{id}
   * @secure
   */
  export namespace PatchStoreExceptionalHoursId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreExceptionalHoursStoreExcHoursWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreExceptionalHoursJsonldStoreExcHoursRead
  }
  /**
   * @description Retrieves the collection of StoreHours resources.
   * @tags StoreHours
   * @name ApiStoreHoursGetCollection
   * @summary Retrieves the collection of StoreHours resources.
   * @request GET:/api/store-hours
   * @secure
   */
  export namespace GetStoreHours {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      store?: string
      'store[]'?: string[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreHoursJsonldStoreHoursRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreHours resource.
   * @tags StoreHours
   * @name ApiStoreHoursPost
   * @summary Creates a StoreHours resource.
   * @request POST:/api/store-hours
   * @secure
   */
  export namespace PostStoreHours {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreHoursJsonldStoreHoursWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreHoursJsonldStoreHoursRead
  }
  /**
   * @description Retrieves a StoreHours resource.
   * @tags StoreHours
   * @name ApiStoreHoursIdGet
   * @summary Retrieves a StoreHours resource.
   * @request GET:/api/store-hours/{id}
   * @secure
   */
  export namespace GetStoreHoursId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreHoursJsonldStoreHoursRead
  }
  /**
   * @description Removes the StoreHours resource.
   * @tags StoreHours
   * @name ApiStoreHoursIdDelete
   * @summary Removes the StoreHours resource.
   * @request DELETE:/api/store-hours/{id}
   * @secure
   */
  export namespace DeleteStoreHoursId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the StoreHours resource.
   * @tags StoreHours
   * @name ApiStoreHoursIdPatch
   * @summary Updates the StoreHours resource.
   * @request PATCH:/api/store-hours/{id}
   * @secure
   */
  export namespace PatchStoreHoursId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreHoursStoreHoursWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreHoursJsonldStoreHoursRead
  }
  /**
   * @description Retrieves the collection of StorePicture resources.
   * @tags StorePicture
   * @name ApiStorePicturesGetCollection
   * @summary Retrieves the collection of StorePicture resources.
   * @request GET:/api/store-pictures
   * @secure
   */
  export namespace GetStorePictures {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StorePictureJsonldStorePictureRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StorePicture resource.
   * @tags StorePicture
   * @name ApiStorePicturesPost
   * @summary Creates a StorePicture resource.
   * @request POST:/api/store-pictures
   * @secure
   */
  export namespace PostStorePictures {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StorePictureJsonldStorePictureWrite
    export type RequestHeaders = {}
    export type ResponseBody = StorePictureJsonldStorePictureRead
  }
  /**
   * @description Retrieves a StorePicture resource.
   * @tags StorePicture
   * @name ApiStorePicturesIdGet
   * @summary Retrieves a StorePicture resource.
   * @request GET:/api/store-pictures/{id}
   * @secure
   */
  export namespace GetStorePicturesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StorePictureJsonldStorePictureRead
  }
  /**
   * @description Updates the StorePicture resource.
   * @tags StorePicture
   * @name ApiStorePicturesIdPatch
   * @summary Updates the StorePicture resource.
   * @request PATCH:/api/store-pictures/{id}
   * @secure
   */
  export namespace PatchStorePicturesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StorePictureStorePictureWrite
    export type RequestHeaders = {}
    export type ResponseBody = StorePictureJsonldStorePictureRead
  }
  /**
   * @description Retrieves the collection of StorePlaceOrder resources.
   * @tags StorePlaceOrder
   * @name ApiStorePlaceOrdersGetCollection
   * @summary Retrieves the collection of StorePlaceOrder resources.
   * @request GET:/api/store-place-orders
   * @secure
   */
  export namespace GetStorePlaceOrders {
    export type RequestParams = {}
    export type RequestQuery = { pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StorePlaceOrderJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a StorePlaceOrder resource.
   * @tags StorePlaceOrder
   * @name ApiStorePlaceOrdersIdGet
   * @summary Retrieves a StorePlaceOrder resource.
   * @request GET:/api/store-place-orders/{id}
   * @secure
   */
  export namespace GetStorePlaceOrdersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StorePlaceOrderJsonld
  }
  /**
   * @description Retrieves the collection of StoreZone resources.
   * @tags StoreZone
   * @name ApiStoreZonesGetCollection
   * @summary Retrieves the collection of StoreZone resources.
   * @request GET:/api/store-zones
   * @secure
   */
  export namespace GetStoreZones {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      store?: string
      'store[]'?: string[]
      'order[name]'?: 'asc' | 'desc'
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreZoneJsonldStoreZoneRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a StoreZone resource.
   * @tags StoreZone
   * @name ApiStoreZonesPost
   * @summary Creates a StoreZone resource.
   * @request POST:/api/store-zones
   * @secure
   */
  export namespace PostStoreZones {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreZoneJsonldStoreZoneWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreZoneJsonldStoreZoneRead
  }
  /**
   * @description Retrieves a StoreZone resource.
   * @tags StoreZone
   * @name ApiStoreZonesIdGet
   * @summary Retrieves a StoreZone resource.
   * @request GET:/api/store-zones/{id}
   * @secure
   */
  export namespace GetStoreZonesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreZoneJsonldStoreZoneRead
  }
  /**
   * @description Removes the StoreZone resource.
   * @tags StoreZone
   * @name ApiStoreZonesIdDelete
   * @summary Removes the StoreZone resource.
   * @request DELETE:/api/store-zones/{id}
   * @secure
   */
  export namespace DeleteStoreZonesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the StoreZone resource.
   * @tags StoreZone
   * @name ApiStoreZonesIdPatch
   * @summary Updates the StoreZone resource.
   * @request PATCH:/api/store-zones/{id}
   * @secure
   */
  export namespace PatchStoreZonesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreZoneStoreZoneWrite
    export type RequestHeaders = {}
    export type ResponseBody = StoreZoneJsonldStoreZoneRead
  }
  /**
   * @description Retrieves the collection of Store resources.
   * @tags Store
   * @name ApiStoresGetCollection
   * @summary Retrieves the collection of Store resources.
   * @request GET:/api/stores
   * @secure
   */
  export namespace GetStores {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      'order[city]'?: 'asc' | 'desc'
      'order[franchised]'?: 'asc' | 'desc'
      'order[active]'?: 'asc' | 'desc'
      'order[shortName]'?: 'asc' | 'desc'
      active?: boolean
      warehouse?: boolean
      id?: number
      'id[]'?: number[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': StoreJsonldStoreRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Store resource.
   * @tags Store
   * @name ApiStoresPost
   * @summary Creates a Store resource.
   * @request POST:/api/stores
   * @secure
   */
  export namespace PostStores {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StoreJsonldStoreCreate
    export type RequestHeaders = {}
    export type ResponseBody = StoreJsonld
  }
  /**
   * @description Retrieves a Store resource.
   * @tags Store
   * @name ApiStoresIdGet
   * @summary Retrieves a Store resource.
   * @request GET:/api/stores/{id}
   * @secure
   */
  export namespace GetStoresId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = StoreJsonldStoreReadStoreReadItem
  }
  /**
   * @description Updates the Store resource.
   * @tags Store
   * @name ApiStoresIdPatch
   * @summary Updates the Store resource.
   * @request PATCH:/api/stores/{id}
   * @secure
   */
  export namespace PatchStoresId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = StoreStorePatch
    export type RequestHeaders = {}
    export type ResponseBody = StoreJsonldStoreReadStoreReadItem
  }
  /**
   * @description Retrieves the collection of Tax resources.
   * @tags Tax
   * @name ApiTaxesGetCollection
   * @summary Retrieves the collection of Tax resources.
   * @request GET:/api/taxes
   * @secure
   */
  export namespace GetTaxes {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': TaxJsonldTaxRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a Tax resource.
   * @tags Tax
   * @name ApiTaxesIdGet
   * @summary Retrieves a Tax resource.
   * @request GET:/api/taxes/{id}
   * @secure
   */
  export namespace GetTaxesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TaxJsonldTaxRead
  }
  /**
   * @description Retrieves the collection of VoucherCategory resources.
   * @tags VoucherCategory
   * @name ApiVoucherCategoriesGetCollection
   * @summary Retrieves the collection of VoucherCategory resources.
   * @request GET:/api/voucher-categories
   * @secure
   */
  export namespace GetVoucherCategories {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean; onlyInOrder?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': VoucherCategoryJsonldVoucherCategoryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a VoucherCategory resource.
   * @tags VoucherCategory
   * @name ApiVoucherCategoriesPost
   * @summary Creates a VoucherCategory resource.
   * @request POST:/api/voucher-categories
   * @secure
   */
  export namespace PostVoucherCategories {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = VoucherCategoryJsonldVoucherCategoryWrite
    export type RequestHeaders = {}
    export type ResponseBody = VoucherCategoryJsonldVoucherCategoryRead
  }
  /**
   * @description Retrieves a VoucherCategory resource.
   * @tags VoucherCategory
   * @name ApiVoucherCategoriesIdGet
   * @summary Retrieves a VoucherCategory resource.
   * @request GET:/api/voucher-categories/{id}
   * @secure
   */
  export namespace GetVoucherCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = VoucherCategoryJsonldVoucherCategoryRead
  }
  /**
   * @description Updates the VoucherCategory resource.
   * @tags VoucherCategory
   * @name ApiVoucherCategoriesIdPatch
   * @summary Updates the VoucherCategory resource.
   * @request PATCH:/api/voucher-categories/{id}
   * @secure
   */
  export namespace PatchVoucherCategoriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = VoucherCategoryVoucherCategoryWrite
    export type RequestHeaders = {}
    export type ResponseBody = VoucherCategoryJsonldVoucherCategoryRead
  }
  /**
   * @description Retrieves the collection of Voucher resources.
   * @tags Voucher
   * @name ApiVouchersGetCollection
   * @summary Retrieves the collection of Voucher resources.
   * @request GET:/api/vouchers
   * @secure
   */
  export namespace GetVouchers {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      customer?: string
      'customer[]'?: string[]
      store?: string
      'store[]'?: string[]
      valid?: boolean
      'order[expiresAt]'?: 'asc' | 'desc'
      active?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': VoucherJsonldVoucherRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a Voucher resource.
   * @tags Voucher
   * @name ApiVouchersPost
   * @summary Creates a Voucher resource.
   * @request POST:/api/vouchers
   * @secure
   */
  export namespace PostVouchers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = VoucherJsonldVoucherWrite
    export type RequestHeaders = {}
    export type ResponseBody = VoucherJsonldVoucherRead
  }
  /**
   * @description Creates a Voucher resource.
   * @tags Voucher
   * @name ApiVouchersfromFidelityCustomerFidelityAmountPost
   * @summary Creates a Voucher resource.
   * @request POST:/api/vouchers/from-fidelity/{customer}/{fidelityAmount}
   * @secure
   */
  export namespace PostVouchersFromFidelityCustomerFidelityAmount {
    export type RequestParams = { customer: string; fidelityAmount: string }
    export type RequestQuery = {}
    export type RequestBody = VoucherJsonldVoucherWrite
    export type RequestHeaders = {}
    export type ResponseBody = VoucherJsonldVoucherFromFidelity
  }
  /**
   * @description Retrieves a Voucher resource.
   * @tags Voucher
   * @name ApiVouchersIdGet
   * @summary Retrieves a Voucher resource.
   * @request GET:/api/vouchers/{id}
   * @secure
   */
  export namespace GetVouchersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = VoucherJsonldVoucherRead
  }
  /**
   * @description Removes the Voucher resource.
   * @tags Voucher
   * @name ApiVouchersIdDelete
   * @summary Removes the Voucher resource.
   * @request DELETE:/api/vouchers/{id}
   * @secure
   */
  export namespace DeleteVouchersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the Voucher resource.
   * @tags Voucher
   * @name ApiVouchersIdPatch
   * @summary Updates the Voucher resource.
   * @request PATCH:/api/vouchers/{id}
   * @secure
   */
  export namespace PatchVouchersId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = VoucherVoucherPatch
    export type RequestHeaders = {}
    export type ResponseBody = VoucherJsonldVoucherRead
  }
  /**
   * @description Retrieves the collection of WarehouseProductStats resources.
   * @tags WarehouseProductStats
   * @name ApiWarehouseProductStatsGetCollection
   * @summary Retrieves the collection of WarehouseProductStats resources.
   * @request GET:/api/warehouse-product-stats
   * @secure
   */
  export namespace GetWarehouseProductStats {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': WarehouseProductStatsJsonldWarehouseProductDataRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a WarehouseProductStats resource.
   * @tags WarehouseProductStats
   * @name ApiWarehouseProductStatsProductGet
   * @summary Retrieves a WarehouseProductStats resource.
   * @request GET:/api/warehouse-product-stats/{product}
   * @secure
   */
  export namespace GetWarehouseProductStatsProduct {
    export type RequestParams = { product: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WarehouseProductStatsJsonldWarehouseProductDataRead
  }
  /**
   * @description Retrieves the collection of WarehouseStockEntry resources.
   * @tags WarehouseStockEntry
   * @name ApiWarehouseStockEntriesGetCollection
   * @summary Retrieves the collection of WarehouseStockEntry resources.
   * @request GET:/api/warehouse-stock-entries
   * @secure
   */
  export namespace GetWarehouseStockEntries {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      product?: string
      'product[]'?: string[]
      'exists[stock]'?: boolean
      inStock?: boolean
      transfer?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': WarehouseStockEntryJsonldWarehouseStockEntryRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a WarehouseStockEntry resource.
   * @tags WarehouseStockEntry
   * @name ApiWarehouseStockEntriesPost
   * @summary Creates a WarehouseStockEntry resource.
   * @request POST:/api/warehouse-stock-entries
   * @secure
   */
  export namespace PostWarehouseStockEntries {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockEntryJsonldWarehouseStockEntryWrite
    export type RequestHeaders = {}
    export type ResponseBody = WarehouseStockEntryJsonldWarehouseStockEntryRead
  }
  /**
   * @description Retrieves a WarehouseStockEntry resource.
   * @tags WarehouseStockEntry
   * @name ApiWarehouseStockEntriesIdGet
   * @summary Retrieves a WarehouseStockEntry resource.
   * @request GET:/api/warehouse-stock-entries/{id}
   * @secure
   */
  export namespace GetWarehouseStockEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WarehouseStockEntryJsonldWarehouseStockEntryRead
  }
  /**
   * @description Removes the WarehouseStockEntry resource.
   * @tags WarehouseStockEntry
   * @name ApiWarehouseStockEntriesIdDelete
   * @summary Removes the WarehouseStockEntry resource.
   * @request DELETE:/api/warehouse-stock-entries/{id}
   * @secure
   */
  export namespace DeleteWarehouseStockEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
  /**
   * @description Updates the WarehouseStockEntry resource.
   * @tags WarehouseStockEntry
   * @name ApiWarehouseStockEntriesIdPatch
   * @summary Updates the WarehouseStockEntry resource.
   * @request PATCH:/api/warehouse-stock-entries/{id}
   * @secure
   */
  export namespace PatchWarehouseStockEntriesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockEntryWarehouseStockEntryWrite
    export type RequestHeaders = {}
    export type ResponseBody = WarehouseStockEntryJsonldWarehouseStockEntryRead
  }
  /**
   * @description Retrieves the collection of WarehouseStockIssue resources.
   * @tags WarehouseStockIssue
   * @name ApiWarehouseStockIssuesGetCollection
   * @summary Retrieves the collection of WarehouseStockIssue resources.
   * @request GET:/api/warehouse-stock-issues
   * @secure
   */
  export namespace GetWarehouseStockIssues {
    export type RequestParams = {}
    export type RequestQuery = { page?: number; itemsPerPage?: number; pagination?: boolean }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': WarehouseStockIssueJsonld[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Retrieves a WarehouseStockIssue resource.
   * @tags WarehouseStockIssue
   * @name ApiWarehouseStockIssuesIdGet
   * @summary Retrieves a WarehouseStockIssue resource.
   * @request GET:/api/warehouse-stock-issues/{id}
   * @secure
   */
  export namespace GetWarehouseStockIssuesId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WarehouseStockIssueJsonld
  }
  /**
   * @description Retrieves the collection of WarehouseStock resources.
   * @tags WarehouseStock
   * @name ApiWarehouseStocksGetCollection
   * @summary Retrieves the collection of WarehouseStock resources.
   * @request GET:/api/warehouse-stocks
   * @secure
   */
  export namespace GetWarehouseStocks {
    export type RequestParams = {}
    export type RequestQuery = {
      page?: number
      itemsPerPage?: number
      pagination?: boolean
      transfer?: boolean
      'exists[parent]'?: boolean
      validated?: string
      'validated[]'?: string[]
      treated?: boolean
      'treated[]'?: boolean[]
      'entries.product'?: string
      'entries.product[]'?: string[]
      retailer?: string
      'retailer[]'?: string[]
      canceled?: string
      'canceled[]'?: string[]
      'order[createdAt]'?: 'asc' | 'desc'
      currentCustomerSales?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = {
      'hydra:member': WarehouseStockJsonldWarehouseStockRead[]
      'hydra:totalItems'?: number
      'hydra:view'?: {
        '@id'?: string
        '@type'?: string
        'hydra:first'?: string
        'hydra:last'?: string
        'hydra:previous'?: string
        'hydra:next'?: string
      }
      'hydra:search'?: {
        '@type'?: string
        'hydra:template'?: string
        'hydra:variableRepresentation'?: string
        'hydra:mapping'?: { '@type'?: string; variable?: string; property?: string | null; required?: boolean }[]
      }
    }
  }
  /**
   * @description Creates a WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStocksPost
   * @summary Creates a WarehouseStock resource.
   * @request POST:/api/warehouse-stocks
   * @secure
   */
  export namespace PostWarehouseStocks {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockJsonldWarehouseStockWrite
    export type RequestHeaders = {}
    export type ResponseBody = WarehouseStockJsonldWarehouseStockRead
  }
  /**
   * @description Creates a WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStockscancelTreatIdPost
   * @summary Cancels the treatement of a treated warehouse stock.
   * @request POST:/api/warehouse-stocks/cancel-treat/{id}
   * @secure
   */
  export namespace PostWarehouseStocksCancelTreatId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockJsonldWarehouseStockTreat
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStockscancelValidateIdPost
   * @summary Cancels the validation of a validated warehouse stock.
   * @request POST:/api/warehouse-stocks/cancel-validate/{id}
   * @secure
   */
  export namespace PostWarehouseStocksCancelValidateId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockJsonldStockValidate
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStocksmarkReceivedIdPost
   * @summary Marks a warehouse stock as received.
   * @request POST:/api/warehouse-stocks/mark-received/{id}
   * @secure
   */
  export namespace PostWarehouseStocksMarkReceivedId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockJsonldWarehouseStockMarkReceived
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStockstreatIdPost
   * @summary Treats a valid and untreated warehouse stock.
   * @request POST:/api/warehouse-stocks/treat/{id}
   * @secure
   */
  export namespace PostWarehouseStocksTreatId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockJsonldWarehouseStockTreat
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Creates a WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStocksvalidateIdPost
   * @summary Validates an unvalidated warehouse stock.
   * @request POST:/api/warehouse-stocks/validate/{id}
   * @secure
   */
  export namespace PostWarehouseStocksValidateId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = WarehouseStockJsonldWarehouseStockValidate
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Retrieves a WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStocksIdGet
   * @summary Retrieves a WarehouseStock resource.
   * @request GET:/api/warehouse-stocks/{id}
   * @secure
   */
  export namespace GetWarehouseStocksId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WarehouseStockJsonldWarehouseStockRead
  }
  /**
   * @description Removes the WarehouseStock resource.
   * @tags WarehouseStock
   * @name ApiWarehouseStocksIdDelete
   * @summary Removes the WarehouseStock resource.
   * @request DELETE:/api/warehouse-stocks/{id}
   * @secure
   */
  export namespace DeleteWarehouseStocksId {
    export type RequestParams = { id: string }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = void
  }
}

export namespace Authenticate {
  /**
   * @description Creates a user token.
   * @tags Login Check
   * @name LoginCheckPost
   * @summary Creates a user token.
   * @request POST:/authenticate
   * @secure
   */
  export namespace PostAuthenticate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = { email: string; password: string }
    export type RequestHeaders = {}
    export type ResponseBody = { token: string }
  }
}
