import { editOrder, GetOrderByIdResponse, orderByIdQueryKey } from 'api/orders'
import Button from 'components/Button/Button'
import InputRadio from 'components/InputRadio/InputRadio'
import Select from 'components/Select/Select'
import { Text } from 'components/Text/Text.styles'
import { Title2 } from 'components/Title/Title.styles'
import { SaveIcon } from 'constants/icons'
import { useMutation } from 'hooks/useAxiosMutation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Order } from 'types/entities'
import { UnmodifiableOrderStatuses } from 'types/enums'
import { OrderSecondaryStatus, Role } from 'types/playInApiInterfaces'
import { SortingDirection } from 'types/sorting'
import { PraparationInputRadioContainer, TwoColGridRow } from './PreparationCardSection.style'
import { useCollaboratorsOptions, useProductAssemblingOptions, useProductCheckingOptions } from './PreparationOptions'

interface PreparationProductSectionProps {
  order?: GetOrderByIdResponse
}

interface PreparationProductSectionInputs {
  productPreparedSecondaryStatus: OrderSecondaryStatus | null
  productCheckedSecondaryStatus: OrderSecondaryStatus | null
  productPreparer: string | null
  productChecker: string | null
  productInTransit: boolean
}

export default function PreparationProductSection({ order }: PreparationProductSectionProps) {
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm<PreparationProductSectionInputs>({
    mode: 'onBlur',
    defaultValues: {
      productCheckedSecondaryStatus: order?.productCheckedSecondaryStatus,
      productPreparedSecondaryStatus: order?.productPreparedSecondaryStatus,
      productPreparer: order?.productPreparer?.['@id'],
      productChecker: order?.productChecker?.['@id'],
      productInTransit: !!order?.productInTransit
    }
  })
  const queryClient = useQueryClient()
  const key = orderByIdQueryKey(`${order?.id}`)
  const { mutate, isLoading } = useMutation((body: PreparationProductSectionInputs) => editOrder(body, order?.id!), {
    onSuccess: (data) => {
      const prev = queryClient.getQueryData<Order>(key)
      queryClient.setQueryData(key, { ...prev, ...data })
      document.getElementById('orderTrackingNumberInput')?.focus()
    }
  })

  const onSubmit = (data: PreparationProductSectionInputs) => {
    mutate({
      productInTransit: data.productInTransit,
      productCheckedSecondaryStatus: data.productCheckedSecondaryStatus || null,
      productPreparedSecondaryStatus: data.productPreparedSecondaryStatus || null,
      productPreparer: data.productPreparer || null,
      productChecker: data.productChecker || null
    })
  }
  const { options: productAssemblingOptions } = useProductAssemblingOptions()
  const { options: productCheckingOptions } = useProductCheckingOptions()

  const { options: preparatorsOptions, paginationProps: preparatorsPaginationProps } = useCollaboratorsOptions({
    stores: `${order?.stock?.id}`,
    'collaboratorRoles.roleName': Role.ROLE_PREPARATION_SEALED,
    'order[id]': SortingDirection.Asc
  })
  const actualprepator = order?.productPreparer
    ? {
        label: `${order?.productPreparer.id} - ${order?.productPreparer.lastname} ${order?.productPreparer.firstname}`,
        value: order?.productPreparer['@id'] as string
      }
    : null
  const fullPreparatorsOptions = actualprepator ? [...preparatorsOptions, actualprepator] : preparatorsOptions

  const { options: checkerOptions, paginationProps: checkerPaginationProps } = useCollaboratorsOptions({
    stores: `${order?.stock?.id}`,
    'collaboratorRoles.roleName': Role.ROLE_PACKAGING_SEALED,
    'order[id]': SortingDirection.Asc
  })
  const actualChecker = order?.productChecker
    ? {
        label: `${order?.productChecker.id} - ${order?.productChecker.lastname} ${order?.productChecker.firstname}`,
        value: order?.productChecker['@id'] as string
      }
    : null
  const fullCheckersOptions = actualChecker ? [...checkerOptions, actualChecker] : checkerOptions

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title2>{t('page.order.detail.tab.prepare.productSectionTitle')}</Title2>
      <TwoColGridRow>
        <Select
          id="productPreparedSecondaryStatus"
          label={t('page.order.detail.tab.prepare.productAssemblingLabel')}
          placeholder={t('page.order.detail.tab.prepare.statusPlaceholder')}
          options={productAssemblingOptions}
          control={control}
          enableUnselect
        />
        <Select
          id="productCheckedSecondaryStatus"
          label={t('page.order.detail.tab.prepare.productCheckingLabel')}
          placeholder={t('page.order.detail.tab.prepare.statusPlaceholder')}
          options={productCheckingOptions}
          control={control}
          enableUnselect
        />
        <Select
          id="productPreparer"
          label={t('page.order.detail.tab.prepare.productPreparatorLabel')}
          placeholder={t('common.select.defaultOptions.collaborators')}
          options={fullPreparatorsOptions}
          control={control}
          {...preparatorsPaginationProps}
          enableUnselect
        />
        <Select
          id="productChecker"
          label={t('page.order.detail.tab.prepare.productCheckerLabel')}
          placeholder={t('common.select.defaultOptions.collaborators')}
          options={fullCheckersOptions}
          control={control}
          {...checkerPaginationProps}
          enableUnselect
        />
        <PraparationInputRadioContainer>
          <InputRadio display="checkbox" control={control} id="productInTransit" />
          <Text>{t('page.order.detail.tab.prepare.transitLabel')}</Text>
        </PraparationInputRadioContainer>
        <Button
          buttonType="submit"
          shape="circle"
          icon={SaveIcon}
          disabled={UnmodifiableOrderStatuses.includes(order?.status!)}
          isLoading={isLoading}
        />
      </TwoColGridRow>
    </form>
  )
}
